import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { ITab } from 'src/app/shared.standalone/tabs/interfaces/tab';
import { InputField } from 'src/app/core/interfaces/form/input-field';


// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { Countery } from 'src/app/core/enums/global/country';
import { usersType } from 'src/app/core/enums/user/user-type';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { accountState } from 'src/app/core/enums/user/account-state';
import { BROWSER_PERMISSION } from 'src/app/core/enums/user/browser-permission';
import { PASSWORD_LIMIT } from 'src/app/core/enums/form/controls/password-limit';

// custome Service
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome reusable
import { Def_Validators } from 'src/app/core/reusable-objects/validators/default-v';
import { Def_Msg_Validators } from 'src/app/core/reusable-objects/validators/default-msg-v';

 // funcations helper
import { ArrayHasSameValue } from 'src/app/core/funcations-helper/arrays/array-has-value';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  // init props & inputs is coming
  routeData: Data
  type = usersType
  errorsMsg: any[] = []
  FormSingIn!: UntypedFormGroup
  navigation!: Inavigation
  isLoading: boolean = false
  userTypeTabs: ITab[] = []
  listInput: InputField[] = []
  getCode: string = Countery.code

  constructor(
    private G: GlobleService,
    private Auth: AuthService,
    private FB: UntypedFormBuilder,
    private Notifi: NotificationService,
    private API_RESPONSE: ApiResponseService,

    // public
    public route: ActivatedRoute,
  ) { 
    //** init value DI class **/
    this.FormSingIn = this.FB.group({
      phone: [null, Validators.required],
      password: [null, Def_Validators(PASSWORD_LIMIT.lessMin, PASSWORD_LIMIT.max)],
    })
    

  }

  ngOnInit(): void {

    // set value props

    this.route.data.subscribe(res => this.routeData = res)

    this.userTypeTabs = [
      {title: 'مستخدم', route: '/sign-in-user'},
      {title: 'معلن عقاري', route: '/sign-in-office'},
    ]

    this.navigation = {label: 'تسجيل دخول', path: '/'}

    this.listInput = [
      {
        type: 'text',
        name: 'phone',
        title: 'رقم الجوال',
        placeholder: '5x xxx xxxx',
      },
      {
        type: 'password',
        name: 'password',
        placeholder: 'أكتب كلمة المرور من هنا ..',
        title: 'كلمة المرور', validators: Def_Msg_Validators(PASSWORD_LIMIT.lessMin, PASSWORD_LIMIT.max)
      },
    ]

  }


  // function will get all date about input
  getControl(name: string) {
    return this.FormSingIn.controls[name]
  }

  // get info country from component drop-dows as outPut
  getCode_hasSelected(obj: {[key: string]: any}) {
    this.getCode = obj.countryCode
  }   
  
  
  // function auth sign up user
  singIn() {

    this.errorsMsg = []
    this.isLoading = true;

    // es6 spread operato
    let FormData = {
      ...this.FormSingIn.value, 
      phone:  this.getCode + this.FormSingIn.value.phone
    }

    this.API_RESPONSE.signIn(FormData).subscribe(

      (res: any) => {

        this.isLoading = false

        // ES6 destructuring
        const { accountType, isVerified, id, approved, type, parent} = (res.user ? res.user : res) || {};

        if (ArrayHasSameValue(this.routeData?.USERS_TYPES, type)) {
  
          if (res.isVerified == false) {
              // navigate & hashing data
              this.G.navigate(['verify'], {
                countdown: true,
                phone: this.G.encryptData(UrlRoute.QUERY_PARAM, FormData.phone)
              })
          }
          else if (isVerified && accountType == accountState.process) {
  
            // save data in localStorage
            // we save date becouse next step has some info from user
            this.G.saveUserData(User.info, res.user)

            //
            if (type == usersType.office && approved == false) this.G.navigate(['account-state'])

          /**
           * after sign in don't force user to complete profile,
           * but make it complete profile data any time from profile page
           */            
            // if (type == usersType.normalUser) {
            //   this.G.navigate(['complete-profile', this.G.encryptData(UrlRoute.PARAM, id)])
            // } 
            // else if (type == usersType.office) {
            //   if (approved == false) {
            //     this.G.navigate(['account-state'])
            //   } else {
            //     this.G.navigate(['complete-profile-office', this.G.encryptData(UrlRoute.PARAM, id)])
            //   }
            // }
          }
          else if (accountType == accountState.active) {
  
            // save data in localStorage
            if (res.token) {
  
              this.G.saveUserData(User.token, res.token)
              
              let token$ =  this.Auth.Token.subscribe(token => {
  
                this.isLoading = true
    
                if (token) {

                  // ES6 destructuring
                  const { redirectTo  } = this.route.snapshot.queryParams;

                  if (type != usersType.superVisor) {
                    // get more info for user
                    this.Auth.getUser(this.routeData?.navigationTo || redirectTo).subscribe()
                  }
                  else {
                    this.Auth.getUserParent(parent?.id, res.user, redirectTo).subscribe()
                  }

                  this.isLoading = false
                  token$.unsubscribe()
                }
              })
              
              // update obvs
              this.Auth.updateToken(res.token ? res.token : null)
            }
            else {
              this.errorsMsg.push({ msg: 'حساب نشط لكن لم يتم الموافقه عليه' }) 
            }
          }
          else {
            this.errorsMsg.push({ msg: 'حساب غير نشط' }) 
          }
          
        }
        else this.errorsMsg.push({msg: 'مستخدم غير موجود'})
        
      },
      err => {
        this.isLoading = false
        this.errorsMsg = err.error.errors
      },
    )
  }

}
