import { 
  Input, 
  OnInit, 
  Output,
  OnChanges, 
  Component,
  EventEmitter,
  SimpleChanges 
} from '@angular/core';
import { Observable } from 'rxjs';
import { formatCurrency } from '@angular/common';

// libs
import { AnimationOptions } from 'ngx-lottie';

// custom enums
import { User } from 'src/app/core/enums/user/info-user';
import { ADS_TYPES } from 'src/app/core/enums/ads/ads-types';
import { Price_Type } from 'src/app/core/enums/ads/price-types';
import { Status_Type } from 'src/app/core/enums/ads/status-types';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { REQ_STATUS_TYPE } from 'src/app/core/enums/http-request/Status';
import { Features_Type } from 'src/app/core/enums/features/features-type';
import { CATEGOR_VALUE_TYPE } from 'src/app/core/enums/category/cate-value-types';
import { CONTACT_REQUEST_SOCKET_KEY } from 'src/app/core/enums/sockets/contact-request-socket';

// pipes
import { shortCurrencyPipe } from 'src/app/core/pipes/numbers/short-currency';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// animations
import { fadeIn } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  animations: [fadeIn],
  providers: [shortCurrencyPipe]
})
export class InfoCardComponent implements OnInit, OnChanges {

  // init inputs
  @Input() type: ADS_TYPES
  @Input() fav_icon: boolean = false
  @Input() directTo: boolean = false
  @Input() canDelete: boolean = false
  @Input() public VipAds: boolean = false
  @Input() public viewState: boolean | null = null
  @Input('showAction') public showAction: boolean = false
  @Input('info') set Info(value: {[key: string]: any}) {

    // ES6 Destructuring
    const { properties } = value;

    this.info = value

    this.ListOfProperties = properties

  }
  
  // output
  @Output() Delete = new EventEmitter<any>();
  @Output() useChange = new EventEmitter<any>();
  @Output() DropDownAction = new EventEmitter<any>();
  
  // init props
  options: AnimationOptions = {
    path: '/assets/lottie/3d_icon.json',
  };

  styles: Partial<CSSStyleDeclaration> | null = {
    width: '60px',
    height: '50px',    
    transform: 'scale(1.8)'
  }
  
  // boolean
  openPopUp: boolean = false

  // enums
  ads_type = ADS_TYPES
  price_type = Price_Type
  statusType = Status_Type
  FeaturesType = Features_Type

  // arrays
  dropDownItems: any[] = []
  ListOfProperties: any[] = []

  // obj
  info!: {[key: string]: any}
  userInfo!: {[key: string]: any}


  constructor(
    public G: GlobleService,
    private Alert: AlertService,
    private API_RESPONSE: ApiResponseService,
    private _shortCurrency: shortCurrencyPipe,
  ) {

    
  }
  
  ngOnInit(): void {
    
    // set
    this.userInfo = this.G.getUserData(User.info) || null  

    this.dropDownItems = [
      { name: 'قبول', value: CONTACT_REQUEST_SOCKET_KEY.contactRequest },
      { name: 'رفض', value: CONTACT_REQUEST_SOCKET_KEY.refuseAds },
    ]

  }
  
  ngOnChanges(changes: SimpleChanges): void {
      // console.log(this.info)
  }

  
  onEvent() {
    this.useChange.emit(this.info)
  }

  dropDownAction(value: any) {
    this.DropDownAction.emit({
      action: value,
      info: this.info
    })
  }

  deleteAds() {

    // hide 
    this.openPopUp = false

    // ES6 Destructuring
    const { id, depositPaidUser = null } = this.info;
    
    if (id) {

      if (depositPaidUser) {
        this.Alert.show({
          type: LAYOUTS_TYPE.alertFailure,
          msg: 'حذف هذا العقار اصبخ غير ممكن بسبب دفع عربون'
        })

        return

      } 

      //
      this.emitOutSide(REQ_STATUS_TYPE.p)

      //
      let removeAds: Observable<Object> = new Observable();

      if (this.type == ADS_TYPES.advertisement) {
        removeAds = this.API_RESPONSE.removeAds(id, { params: {[RANDOM_VALUE.loader]: true }})
      }
      else if (this.type == ADS_TYPES.request) {
        removeAds = this.API_RESPONSE.removeAdsRequest(id, { params: {[RANDOM_VALUE.loader]: true }})
      }

      //
      removeAds.subscribe(
        res => this.emitOutSide(REQ_STATUS_TYPE.s),
        err => this.emitOutSide(REQ_STATUS_TYPE.f)
      )

    }

  }

  // emit
  emitOutSide = (status: REQ_STATUS_TYPE) => {
    this.Delete.emit({
      status,
      data: this.info,
    })
  }

  public shortText(text: string, limit: number = 1): string {

    if (typeof text == 'string') {

      const transform = text.split(' ').slice(0, limit).join(' ');

      return transform || ''

    }

    return ''

  }

  // getters
  public get PiecesTitleType(): { total: string, available: string } {

    switch(this.info?.category?.type) {
      case CATEGOR_VALUE_TYPE.BL:
        return { total: 'عدد الوحدات', available: 'الوحدات المتاحة' };
      case CATEGOR_VALUE_TYPE.PL:
        return { total: 'عدد الأراضي', available: 'الأراضي المتاحة' };
      case CATEGOR_VALUE_TYPE.VL:
        return { total: 'عدد الفلل', available: 'الفلل المتاحة' };
      default:
        return { total: 'العدد الاجمالي', available: 'العدد المتاح' };
    }

  }

  /**
   * handler Price
   */
  public get Price(): string | null {

    // ES6 Destruction
    const { priceType, priceFrom, priceTo, price } = this.info;

    switch(this.type) {
      /**
       * handler price for adAdvertisement type
       */      
      case ADS_TYPES.advertisement:
      default:
        /**
         * handler returned price for priceType
         */
        switch(priceType) {
          case Price_Type.soom:
            return `${this._shortCurrency.transform(price)} السوم`
          default:
            return this._shortCurrency.transform(price)
        }
      /**
       * handler price for adRequest type
       */
      case ADS_TYPES.request:
        /**
         * handler returned price for priceType
         */
        switch(priceType) {
          case Price_Type.normal:
            if (priceFrom && priceTo) return `
              السعر من
              ${formatCurrency(priceFrom, 'en-US', '', '', '0.0-0').trim()}
              الي
              ${ formatCurrency(priceTo, 'en-US', '', '', '0.0-0').trim()}
              ريال
            `;
            return null
          case Price_Type.open:
            return 'السعر مفتوح';
          default:
            return null
        }
    }
    
  }

  /**
   * PriceTitleType
   */
  public get PriceTitleType(): string {

    switch(this.info?.priceType) {
      case Price_Type.soom:
        if (this.info?.price) return 'وصل السوم إلى'
        return 'علي السوم';
      case Price_Type.open:
        return 'السعر مفتوح';
      case Price_Type.startsFrom:
        return 'تبدأ الأسعار من';
      default:
        return 'السعر';
    }

  }


  /**
   * handler Size
   */
  public get Size(): string | null {
    
    // ES6 Destruction
    const { sizeFrom, sizeTo } = this.info;

    if (sizeFrom && sizeTo) return `المساحة من ${sizeFrom} الي ${sizeTo} متر`;

    return null;

  }

  /**
   * 
   */
  public get StatusTitleType(): {title: string, class: string} | null {

    switch(this.info?.status) {
      case Status_Type.N:
      case Status_Type.PR:  
      case Status_Type.Started:
        return { title: 'متاح', class: 'new' };
      case Status_Type.COM:
        return { title: 'مباع', class: 'wrong' };
      case Status_Type.booking:
        return { title: 'محجوز', class: 'progress_' };
      default:
        return null;
    }

  }

  public get AdsRequestActionShow(): boolean {

    if (
      this.info?.owner?.id != this.G.CorrectUserId
      &&
      (
        this.info?.status == Status_Type.N
        ||
        this.info?.status == Status_Type.PR
      )
    ) return true

    return false
  }

}
