import { map, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// services
import { MainApiService } from 'src/app/core/services/main/main-api.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// enums
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';

// interfaces
import { IAboutUs } from 'src/app/core/interfaces/app/aboutUs';

@Injectable({
  providedIn: 'root'
})
export class TopLevelSingleService {

  // Observable
  private Terms: BehaviorSubject<any> = new BehaviorSubject(null);
  private Setting: BehaviorSubject<any> = new BehaviorSubject(null);
  private countries: BehaviorSubject<any> = new BehaviorSubject(null);
  private Categories: BehaviorSubject<any> = new BehaviorSubject(null);
  private About: BehaviorSubject<IAboutUs> = new BehaviorSubject(null);

  // loading
  private loadingAbout: boolean = false
  private loadingTerms: boolean = false
  private loadingSetting: boolean = false
  private loadingCountries: boolean = false
  private loadingCategories: boolean = false

  constructor(
    private API: MainApiService, 
    private API_RESPONSE: ApiResponseService
  ) { }

  // check if countries has data or no
  public get countries$(): Observable<any> {
    if (!this.loadingCountries && !this.countries.value) {
      return this.getCountries().pipe(map(value => {
        this.countries.next(value)
        this.loadingCountries = false
        return value
      }))
    }

    return this.countries

  }

  public get categories$() {

    if(!this.Categories.value && !this.loadingCategories) {

      this.loadingCategories = true;
      return this.API_RESPONSE.mainCategories({ 
        params: { [RANDOM_VALUE.loader]: true } 
      }).pipe(map(res => {
        this.Categories.next(res)
        this.loadingCategories = false
        return res
      }))

    }
    else return this.Categories
   
  }  

  public get about$() {

    if(!this.About.value && !this.loadingAbout) {

      this.loadingAbout = true;

      this.API_RESPONSE.AboutUs().subscribe(res => {
        this.About.next(res)
        this.loadingAbout = false        
      })

    }
    
    return this.About
   
  }

  public get setting$() {
    
    if(!this.loadingSetting && !this.Setting.value) {
      
      this.loadingSetting = true

      this.API_RESPONSE.Setting().subscribe(r => {
        this.Setting.next(r)
        this.loadingSetting = false
      })

    }
    
    return this.Setting
   
  }  

  public get terms$() {
    if(!this.loadingTerms && !this.Terms.value) {
      return this.getTerms().pipe(map(value => {
        this.Terms.next(value?.Termss[0])
        this.loadingTerms = false
        return value?.Termss[0]
      })) 
    }

    return this.Terms
   
  }


  // function get All countries a top level app
  private getCountries(): Observable<any> {
    return this.API.mainGetAll('countries');
  }

  private getTerms(): Observable<any> {
    this.loadingTerms = true
    return this.API.mainGetAll('terms');
  }
}
