import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

// interfaces
import { ICustomAlert } from 'src/app/core/interfaces/alert/custom-alert';

// enums
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';

// services
import { AlertService } from 'src/app/core/services/alert/alert.service';

@Component({
  selector: 'app-c-alert',
  templateUrl: './c-alert.component.html',
  styleUrls: ['./c-alert.component.scss'],
  animations: [
    trigger('fied', [
      transition(':enter', [
        style({opacity: '0', transform: 'translate(0px, 100px)'}),
        animate('150ms', style({opacity: '1', transform: 'translate(0px, 0px)'}),),
      ]),
      transition(':leave', [
        style({opacity: '1', transform: 'translate(0px, 0px)'}),
        animate('150ms', style({opacity: '0', transform: 'translate(0px, 100px)'}),),
      ])
    ])
  ]
})
export class CAlertComponent implements OnInit, OnDestroy {

  //inputs
  @Input() public alertInfo: ICustomAlert | boolean

  // enums
  public LAYOUTS_TYPE = LAYOUTS_TYPE

  constructor(public Alert: AlertService) { }

  ngOnInit(): void {

    document.documentElement.classList.add('overflow-hidden')
  }

  ngOnDestroy(): void {
    document.documentElement.classList.remove('overflow-hidden')
  }

}
