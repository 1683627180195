import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-card-model',
  templateUrl: './info-card-model.component.html',
  styleUrls: ['./info-card-model.component.scss']
})
export class InfoCardModelComponent implements OnInit {

  // init Inputs
  @Input('viewState') viewState: boolean | null = null
  @Input('data') data: {[key: string]: any} | null | undefined = null

  // init OutPut
  @Output() CloseModel =  new EventEmitter<null>()

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    
    this.CloseModel.emit(null)
    
    this.data = null

  }

}
