<!-- import app-c-alert -->
<app-c-alert *ngIf="alertInfo" [alertInfo]="alertInfo"></app-c-alert>

<!-- import app-navbar -->
<app-navbar *ngIf="!dashboardIsOpen && !mobileView && (Layouts?.navbarLayout | async)"></app-navbar>

<!-- // start outlet Router here to view components -->
<router-outlet></router-outlet>
<!-- // end outlet Router here to view components -->

<div 
    class="wrap_list"
    *ngIf="!mobileView && Notifi.PushNotification.length > 0 && (Layouts?.notificationsLayout | async)"
>
    <ul class="list_none alert_notif">
        <ng-container *ngFor="let notif of Notifi.PushNotification | slice: 0 : 1; let i = index">
            <li 
                @auto_slider
                [ngClass]="{'un_read': !notif.read}"
                (click)="Notifi.actiondNotif(notif)"
                (mouseenter)="Notifi.stopMoveNotification()"
                (mouseleave)="Notifi.startMoveNotification(1500)"
                class="alert_it border_r_2th border_def_2th position-relative"
            >
                <!-- // import app-notification-card -->
                <app-notification-card 
                    [Index]="i"
                    [Info]="notif" 
                    (Show)="onShowNotifi($event)"                    
                    (Close)="onCloseNotifi($event)"
                >
                </app-notification-card>                
            </li>
        </ng-container>
    </ul>
</div>

<!-- import app-footer -->
<app-footer *ngIf="!dashboardIsOpen && !mobileView && (Layouts?.footerLayout | async)"></app-footer>

<!-- // app-floating-chat-box  -->
<a href="https://wa.me/+966530107471" target="_blank" class="icon_chat effect fix pointer" >
    <div class="wrap_icon">
        <img src="/assets/img/chat.png" alt="whatsapp chat">
    </div>
</a>



<!-- import app-loader -->
<!-- its will show if app call server  -->
<app-loader *ngIf="show" @fadeOut [class]="dashboardIsOpen ? 'has_side_bar' : ''"></app-loader>