import { Observable, Subject } from 'rxjs';
import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

export interface ICountDownKeys  {
    title: string
    value: string | number
    LessThenDate?: LessThenDate
}

export interface LessThenDate extends ICountDownKeys {
}

@Pipe({
  name: 'countDownDate',
  pure: true
})
export class CountDownPipe implements PipeTransform, OnDestroy {

    //
    Clear: number;
    Obs$: Subject<ICountDownKeys> | null = null

    // static value
    updateSecond: number = 0;
    numberOfUnitsMills: number = 1000;
    numberOfUnitsSecond: number = 60;
    numberOfUnitsMinute: number = 60;
    numberOfUnitsHour: number = 24;
    numberOfUnitsMonth: number = 30.4167;
    numberOfUnitsYear: number = 12;

    // times
    private Mills: number = 0;
    private Seconds: number = 0;
    private Minutes: number = 0;
    private Hours: number = 0;
    private Days: number = 0;
    private Months: number = 0;
    private Years: number = 0;
    
    // less then
    private LessThenYear: number = 0;
    private LessThenMonth: number = 0;
    private LessThenDay: number = 0;
    private LessThenHour: number = 0;
    private LessThenMinute: number = 0;


    constructor() {}

    transform(value: any, ...args: any[]): Observable<ICountDownKeys> | null {

        /**
         * init
         */
        this.Obs$ = new Subject();

        try {

            if (value == null) throw new Error(`arg value is ${value}`);
            else if (isFinite(new Date(+value || value) as unknown as number) == false) throw new Error(`arg value is not valid number ${value}`);
            else if (new Date(+value || value).getTime() <= Date.now()) throw new Error(`sale date is available ${value}`);

            /**
             * 
             */
            this.Clear =  window.setInterval(() => {

                /**
                 * handler to skip first Delay to update view to view in 0s
                 * then updateSecond to update view every 1s
                 */
                if (this.updateSecond == 0) this.updateSecond = 1000;
                
                // decrease tow hours from Time zone
                const setTimezoneOffset = new Date(+value || value).getTime() - (120 * 60 * 1000)

                this.Mills = setTimezoneOffset - Date.now();
                this.Seconds = Math.floor(this.Mills / this.numberOfUnitsMills);
                this.Minutes = Math.floor(this.Seconds / this.numberOfUnitsSecond);
                this.Hours = Math.floor(this.Minutes / this.numberOfUnitsMinute);
                this.Days = Math.floor(this.Hours / this.numberOfUnitsHour);
                this.Months = Math.floor(this.Days / this.numberOfUnitsMonth);
                this.Years = Math.floor(this.Months / this.numberOfUnitsYear);
                
                // Less then
                const LessYear = this.getLessThenBaseValue(this.Months / this.numberOfUnitsYear, 12)
                const LessMonth = this.getLessThenBaseValue(this.Days / this.numberOfUnitsMonth, this.numberOfUnitsMonth)
                const LessDay = this.getLessThenBaseValue(this.Hours / this.numberOfUnitsHour, this.numberOfUnitsHour)
                const LessHour = this.getLessThenBaseValue(this.Minutes / this.numberOfUnitsMinute, this.numberOfUnitsMinute)
                const LessMin = this.getLessThenBaseValue(this.Seconds / this.numberOfUnitsSecond, this.numberOfUnitsSecond)

                this.LessThenYear = Math.round(LessYear);
                this.LessThenMonth = Math.floor(LessMonth);
                this.LessThenDay = Math.floor(LessDay);
                this.LessThenHour = Math.floor(LessHour);
                this.LessThenMinute = Math.floor(LessMin);

                // clearInterval(clear)
                //
                const Max = Math.max(this.Mills, this.Seconds, this.Minutes, this.Hours, this.Months, this.Days, this.Years)

                if (Max > 0) this.Obs$.next(this.countDownType)
                else {
                    this.Obs$.next(null as any)
                    this.Obs$.complete()
                    clearInterval(this.Clear)
                }

            }, this.updateSecond)

            return this.Obs$.asObservable()

        }
        catch (err) {
            console.log(err)
            this.Obs$.next(null as any)
            this.Obs$.complete()
        }
    

    }

    private get countDownType(): ICountDownKeys {

        if (this.Years) return { 
            title: 'سنة', value: this.Years, LessThenDate: {
                title: this.LessThenDate.title, value: this.LessThenDate.value
            }
        }
        else if (this.Months) return { 
            title: 'شهر', value: this.Months, LessThenDate: {
                title: this.LessThenDate.title, value: this.LessThenDate.value
            }
        }
        else if (this.Days) return { 
            title: 'يوم', value: this.Days, LessThenDate: {
                title: this.LessThenDate.title, value: this.LessThenDate.value
            }
        }
        else if (this.Hours || this.Minutes > 60) return { 
            title: 'ساعة', value: this.Hours, LessThenDate: {
                title: this.LessThenDate.title, value: this.LessThenDate.value
            }
        }
        else if (this.Minutes || this.Seconds > 60) return { 
            title: 'دقيقة', value: this.Minutes, LessThenDate: {
                title: this.LessThenDate.title, value: this.LessThenDate.value
            } 
        }
        else if (this.Seconds) return { title: 'ثانية', value: this.Seconds }
        else return { title: '', value: 0 }

    } 

    private get LessThenDate(): LessThenDate {
        
        if (this.LessThenYear) return { title: 'شهر', value: this.LessThenYear }
        else if (this.LessThenMonth) return { title: 'يوم', value: this.LessThenMonth }
        else if (this.LessThenDay) return { title: 'ساعة', value: this.LessThenDay }
        else if (this.LessThenHour) return { title: 'دقيقة', value: this.LessThenHour }
        else if (this.LessThenMinute) return { title: 'ثانية', value: this.LessThenMinute }
        else return { title: '', value: 0 }

    }

    private getLessThenBaseValue(value: number, multipFactor: number) {

        if (value > 1) {
            return  (value % 1) * multipFactor;
        }

        return 0

    }

    ngOnDestroy(): void {
        if (this.Obs$ && !this.Obs$.closed) {
            this.Obs$.next(null as any)
            this.Obs$.complete()
        }
        if (this.Clear) clearInterval(this.Clear)
    }

}
