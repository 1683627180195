<!-- start app-account-state -->
<div class="account_state def_p bluer_section">
    <div class="custom_container position-relative">

        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div> 

        <div class="wrap_container">
            <div class="wapper">
                <div class="wrap_userImg border_def">
                    <img 
                    alt="user photo"
                    class="img_obj_cover mg-fluid "
                    [src]="userInfo?.img ? userInfo?.img : '../../../../../assets/img/def_user_img.png'" 
                />
                </div>
                <p class="name label_lg_bold">
                    {{userInfo?.fullname ? userInfo?.fullname : 'مكتب عقاري'}}
                </p>
                <p class="msg label_lg_normal">
                    {{userInfo?.approved ? 'تمت الموافقة على حسابكم بنجاح، الرجاء تعبئة الخيارات ادناه' : 'شكراً لكم، تمت عملية التسجيل بنجاح'}}
                </p>
                <ng-container *ngIf="!userInfo?.approved">
                    <p class="state label_lg_normal">
                        حسابكم قيد المراجعة من قبل الإدارة سيتم تنبيهكم
                    </p>
                    <span class="owner label_lg_bold d-block text-start">إدارة تطبيق ساعي</span>
                </ng-container>
            </div>

            <div class="wrap_btn text-center" *ngIf="!userInfo?.approved">
                <app-btn routerLink='/' title="ذكرني"></app-btn>
            </div>
        </div>
    </div>
</div>
<!-- end app-account-state  -->