import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.scss'],
  animations: [
    trigger('fied', [
      transition(':enter', [
        style({opacity: '0', transform: 'translate(0px, 100px)'}),
        animate('150ms', style({opacity: '1', transform: 'translate(0px, 0px)'}),),
      ]),
      transition(':leave', [
        style({opacity: '1', transform: 'translate(0px, 0px)'}),
        animate('150ms', style({opacity: '0', transform: 'translate(0px, 100px)'}),),
      ])
    ])
  ]  
})
export class FailureComponent implements OnInit {

  // init props & inputs is coming
  @Input('msg') msg: string = ''
  @Input('state') state: boolean = true
  @Input('titleBtn') titleBtn: string = ''

  /// out
  @Output() Submit = new EventEmitter()


  constructor() { }

  ngOnInit(): void {
  }

  // 
  submit() {
    this.Submit.emit(true)
  }

}
