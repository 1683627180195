import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

// services
import { GlobleService } from 'src/app/core/services/globle.service';

export const userInfoResolver: ResolveFn<IuserInfo> = (route, state) => {

  const userInfo = inject(GlobleService).userInfo

  return userInfo;
  
};
