import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// enums
import { TERMS_TYPE } from 'src/app/core/enums/user/terms-types';

// services
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

@Component({
  selector: 'app-terms-and-condititons',
  templateUrl: './terms-and-condititons.component.html',
  styleUrls: ['./terms-and-condititons.component.scss']
})
export class TermsAndCondititonsComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  pageData: any
  navigation!: Inavigation
  pageTitle: string = null;
  obs: Subscription = new Subscription;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _api: ApiResponseService,
    private TopLevelApi: TopLevelSingleService
  ) { }

  ngOnInit(): void {

    this.navigation = {path: '/'}

    /**
     * 
     */
    this.handlerTermsTypes()

  }

  private handlerTermsTypes(): void {

    /**
     * 
     */
    const { type } = this._route.snapshot.queryParams;

    //
    let req: Observable<any> = null

    switch(type) {
      case TERMS_TYPE.Market:
          req = this._api.MarketTerms();
          this.pageTitle = 'شروط واحكام سوق';
        break
      case TERMS_TYPE.Default:
      default:
        req = this.TopLevelApi.terms$;
        this.pageTitle = 'الشروط و الاحكام';
        break;
    }

    //
    this.obs = req.subscribe(
      res => this.pageData = res,
      err => this._router.navigate(['/'])
    )    

  }

  ngOnDestroy(): void {
    // stop listen
    this.obs.unsubscribe()
  }

}
