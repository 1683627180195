import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

// firebase
import { getToken, onMessage, Messaging } from '@angular/fire/messaging';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custom enums
import { User } from 'src/app/core/enums/user/info-user';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { LOCAL_DATA } from 'src/app/core/enums/global/local-data';
import { ACTION_TYPE } from 'src/app/core/enums/global/action-types';
import { LOCAL_METHOD } from 'src/app/core/enums/global/local-methods';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { NOTIFICATION_TYPE } from 'src/app/core/enums/user/notification-types';

// interfaces
import { IContactRequest } from 'src/app/core/interfaces/ads/contact-request';

export interface INotification {
  data: { 
    title?: string
    body?: string
    read?: boolean
    index?: number
    itemID?: string
    deepId?: string
    join_at?: number
    resource?: number
    createdAt?: string,
    description?: string
    id?: number | string
    type?: NOTIFICATION_TYPE
    info?: NOTIFICATION_TYPE
    contactRequest?: IContactRequest
    
  }
  from: string;
  notification: {
    body: string;
    title: string;
  };
  priority?: string;
}


@Injectable()
export class NotificationService {

  // numbers
  NextNotifTime: number = 5000
  clearTimeOut: number | undefined

  // obsv
  notificationCount = new BehaviorSubject<number>(0);

  // araays
  pushNotification: INotification['data'][] = [];

  // obj
  userInfo: {[key: string]: any}

  constructor(
    private msg: Messaging,
    private G: GlobleService,
    private API_RESPONSE: ApiResponseService,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    // set value proparty
    this.userInfo = this.G.getUserData(User.info) || null
  }

  public get PushNotification() {
    return this.pushNotification
  }

  public set setPushNotification(config: {value: INotification['data'] | INotification['data'][], actionType: ACTION_TYPE}) {

    // ES6 destructuring
    const { value, actionType } = config
    
    // if value just one obj 
    if (actionType == ACTION_TYPE.add && !Array.isArray(value)) this.pushNotification.push(value)
    // if value is arrat of multiple obj 
    else if (actionType == ACTION_TYPE.del && Array.isArray(value)) this.pushNotification = value

  }

  public get NotificationCount() {
    return this.notificationCount
  }

  public set setNotificationCount(value: number) {
    this.notificationCount.next(value)
  }

  /**
   * @description requst permission to send notifications
   */
   public getToken(): Promise<string> {
    
    return getToken(this.msg)
  }

  /**
   * @description receve messages
   * @returns message observable
   */
   public get receiveMessage(): Observable<INotification> {

    if (isPlatformBrowser(this.platformId)) {

      const x = new Observable<INotification>(obs => {
        onMessage(this.msg, (e) => obs.next(e as any))
      })
  
      return x

    }
    

  } 
  
  public actiondNotif(data: INotification['data']) {

    // es6 destructuring
    const { id, itemID } = data;

    this.readNotification((id || itemID) as string)?.subscribe(
      (res: any) => {
        // finaliy run Notifi.redirectAction function
        // to start navigation
        this.redirectAction(data)

        // remove it after redirect
        this.removeNotification()
      },
      err => {
        // finaliy run Notifi.redirectAction function
        // to start navigation
        this.redirectAction(data)  
        
        // remove it after redirect
        this.removeNotification()
        
      }
    )

  }

  public removeNotification() {
    
    console.log('removeNotification')

    // remove first index
    this.pushNotification.splice(0, 1)

    // set new array of Notification
    this.setPushNotification = {
      value: this.pushNotification,
      actionType: ACTION_TYPE.del
    }
    
    //
    this.stopMoveNotification()

  }

  public startMoveNotification(time: number = this.NextNotifTime) {

    console.log('startMoveNotification')

    switch(this.pushNotification.length) {
      case 0:
        this.stopMoveNotification()
        break;
      default:
        if (!this.clearTimeOut) {
          this.clearTimeOut = window.setTimeout(() => {
            this.removeNotification()
          }, time)
        }
        break;
    }
    
  }
 
  public stopMoveNotification() {
    console.log('stopMoveNotification')
    if (this.clearTimeOut) {
      clearTimeout(this.clearTimeOut)
      this.clearTimeOut = undefined
    }
    
  }

  public redirectAction(data: INotification['data'], config?: {[key: string]: any}) {
    
    // es6 destructuring
    const { msgRedirect, push } = config || {};
    const { 
        type,
        info,
        deepId,
        itemID,
        contactRequest: { id: contactRequestId } = { id: 0 } 
    } = data || {};

    switch(type ? type : info) {

      case NOTIFICATION_TYPE.app:
      case NOTIFICATION_TYPE.rate:
        this.G.saveLocalData(LOCAL_DATA.random, data, LOCAL_METHOD.session)
        this.G.navigate(['/info-page'])
        break;
        case NOTIFICATION_TYPE.marketPlace:
          this.G.navigate(['/setting'])
          break;        
      case NOTIFICATION_TYPE.ads:
      case NOTIFICATION_TYPE.fav:
      case NOTIFICATION_TYPE.marketer:
        this.G.navigate(['/realty-management/view-realty-advertisement', deepId ? deepId : itemID])
        break;
      case NOTIFICATION_TYPE.adsRequest:
        this.G.navigate(['/realty-management/view-realty-request', deepId ? deepId : itemID])
        break;
      case NOTIFICATION_TYPE.news:
        this.G.navigate(['/news', 'view', deepId ? deepId : itemID])
        break;        
      case NOTIFICATION_TYPE.contact:
        this.G.navigate(['/info_with_chats', itemID ? itemID : deepId, 'chats'])
        break;
      case NOTIFICATION_TYPE.msg:
        this.G.navigate(['/info_with_chats', contactRequestId ? contactRequestId : (itemID ? itemID : deepId), 'chats'])
      case NOTIFICATION_TYPE.client:
        this.G.navigate(['/marketing-management', 'my-marketing-clients', 'view', deepId ? deepId : itemID])
        break;        
        // if (msgRedirect) 
        // else {
        //   this.G.navigate(['/request_and_realty/contact_request'], { owner: 
        //     this.G.encryptData(UrlRoute.QUERY_PARAM, this.G.CorrectUserId)
        //   })          
        // }
        break;
    }

  }

  public readNotification(id: string) {

    return this.API_RESPONSE.readNotification(id,     { 
      params: { 
        [RANDOM_VALUE.loader]: true 
      } 
    })

  }

  // sound
  playSound() {
    let notificationSound = new Audio('/assets/audio/windows-11-notification-sound-download.mp3');
    notificationSound.play()
  }

}
