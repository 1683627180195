<!-- start app-terms -->
<div class="terms">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">{{pageTitle}}</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>
    <div class="wrap_content_terms">
        <div class="custom_container">
            <ng-container *ngIf="pageData?.terms">
                <h3 class="sub_title text-center color_black">الشروط والأحكام</h3>
                <p *ngIf="pageData?.terms" textFormat class="label_lg_normal terms" textFormat [innerHTML]="pageData?.terms"></p>
            </ng-container>
            <ng-container *ngIf="pageData?.privacy">
                <h3 class="sub_title text-center color_black">سياسه التطبيق</h3>
                <p *ngIf="pageData?.privacy" textFormat class="label_lg_normal terms" textFormat [innerHTML]="pageData?.privacy"></p>
            </ng-container>
        </div>
    </div>
</div>
<!-- start app-terms -->