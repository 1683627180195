import { Pipe, PipeTransform } from '@angular/core';

// services
import { GlobleService } from 'src/app/core/services/globle.service';

// enums
import { User } from 'src/app/core/enums/user/info-user';
import { usersType } from '../../enums/user/user-type';

@Pipe({
  name: 'idType'
})
export class idTypePipe implements PipeTransform {

    // init props
    userInfo: any

    constructor(private G: GlobleService) {
        this.userInfo = this.G.getUserData(User.info) || {}
    }

    transform(value: any[], ...args: any[]) {

        // ES6 Destrucating
        const { type } = this.userInfo 

        return value.filter(v => (v.status as usersType[]).includes(type))

    }

}
