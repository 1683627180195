import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';

// services
import { INotification } from 'src/app/core/services/notification/notification.service';

// enums
import { ACTION_TYPE } from 'src/app/core/enums/global/action-types';
@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() Show = new EventEmitter<{[key: string]: any}>()
  @Output() Close = new EventEmitter<{[key: string]: any}>()

  // init inputs is comong
  @Input('Index') Index: string | number
  @Input('Info') Info: INotification['data']

  constructor() { }

  ngOnInit(): void {}
  
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.Show.emit({
        info: this.Info,
        index: this.Index,
        type: ACTION_TYPE.add,
      })
    })
  }

  ngOnDestroy(): void {
    this.Close.emit({
      info: this.Info,
      index: this.Index,
      type: ACTION_TYPE.del,
    })
  }

}
