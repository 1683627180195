import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// enums
import { URL_TYPE } from 'src/app/core/enums/http-request/resources-url/resources-url-types';

@Pipe({
  name: 'safeUrl'
})
export class safeUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, ...args: URL_TYPE[]): unknown {
    
    const [ urlType ] = args;

    if (value) {
      
      if (urlType == URL_TYPE.resourceURL ) return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      else return this.sanitizer.bypassSecurityTrustUrl(value);
    }
    return ''
  }

}