<!-- start app-input-text -->

<ng-container [formGroup]="FormParent" *ngIf="FormParent; else else_Form">

    <div 
        class="thumbnails text-center border_r_2th" 
        [ngClass]="{'border_def_3th': getControl.value && getControl.touched}"
    >
        <div 
            class="wrap_close text-center" 
            *ngIf="viewFileHasSelected.length > 0 && (uploadProgress == 0 || uploadProgress == HTTP_EVENT_TYPE.response)"
        >
            <app-close (click)="reset(); upLoadFile($event)"></app-close>
        </div>
        <div class="wrap">
            
            <label [for]="name">
                <input 
                    type="file"
                    [id]="name"
                    [name]="name"
                    [multiple]="multiple"
                    [formControlName]="name"
                    class="custom_input_file"
                    (change)="upLoadFile($event)"
                    [accept]="fileType ? fileType?.accept : 'image/*'"
                />
            </label>

            <ng-container 
                *ngIf="viewFileHasSelected?.length == 0; else else_img"
            >
                <img 
                    class="img-fluid" 
                    alt="thumbnails"
                    [src]="urlIcon ? urlIcon : '/assets/img/thumbnails.png'" 
                />
            </ng-container>
            
            <ng-template #else_img>
                <ng-container *ngIf="!multiple">
                    <ng-container 
                        *ngIf="viewFileHasSelected[0]?.type?.includes('image'); else TypeNotImg"
                    >
                        <img 
                            class="img-fluid img_view" 
                            alt="thumbnails"
                            [src]="viewFileHasSelected[0] | createUrl | safeUrl" 
                        />
                    </ng-container>
                    <ng-template #TypeNotImg>
                        <span class="label_lg_bold d-block">
                            {{viewFileHasSelected[0].name}}
                        </span>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="multiple">
                    <div class="wrap_img_info d-inline-block" *ngIf="viewFileHasSelected.length > 0">
                        <!-- <div class="remove_img d-inline-block">
                            <app-close></app-close>
                        </div> -->
                        <span class="label_lg_bold d-block">
                                تم اضافة
                            {{viewFileHasSelected?.length}}
                            صور
                        </span>
                    </div>
                </ng-container>
            </ng-template>
            <span class="label_lg_normal d-block box_title" *ngIf="viewFileHasSelected.length <= 0">{{title}}</span>
            <div 
                class="wrap_file_progress_bar"
                *ngIf="uploadProgress && uploadProgress != HTTP_EVENT_TYPE.errorResponse"
                >
                <div 
                    class="bar position-relative"
                    *ngIf="$any(uploadProgress) > 0 && uploadProgress != HTTP_EVENT_TYPE.response"
                >
                    <span class="file_progress_bar position-absolute" [style]="{width: (uploadProgress + '%')}"></span>
                </div>
                <span class="count label_lg_bold" *ngIf="$any(uploadProgress) > 0">{{uploadProgress + '%'}}</span>
                <span class="text_done main_color label_lg_bold" *ngIf="uploadProgress == HTTP_EVENT_TYPE.response">اكتمل التحميل</span>
            </div>
        </div>
    </div>
    
</ng-container>

<ng-template #else_Form>
    <div class="thumbnails text-center border_r_2th" [ngClass]="{'border_def_3th': viewFileHasSelected.length > 0}">
        <div class="wrap">
            <div 
                class="wrap_close text-center" 
                *ngIf="viewFileHasSelected.length > 0 && (uploadProgress == null || uploadProgress == HTTP_EVENT_TYPE.response)"
            >
                <app-close (click)="reset(); upLoadFile($event)"></app-close>
            </div>
            <label 
                [for]="name"
                *ngIf="viewFileHasSelected.length <= 0"
            >
                <input 
                    type="file"
                    [id]="name"
                    [name]="name"
                    (click)="touched()"
                    [multiple]="multiple"
                    class="custom_input_file"
                    (change)="upLoadFile($event)"
                    [accept]="fileType ? (fileType?.mainType + '/*') : 'image/*'"
                />
            </label>

            <ng-container *ngIf="viewFileHasSelected?.length == 0; else else_img">
                <img 
                    alt="thumbnails"
                    class="img-fluid" 
                    [src]="urlIcon ? urlIcon : '/assets/img/thumbnails.png'" 
                />
            </ng-container>
            
            <ng-template #else_img>
                <ng-container *ngIf="!multiple">
                    <ng-container 
                        *ngIf="viewFileHasSelected[0]?.type?.includes('image'); else TypeNotImg"
                    >
                        <img 
                            class="img-fluid img_view" 
                            alt="thumbnails"
                            [src]="viewFileHasSelected[0] | createUrl | safeUrl" 
                        />
                    </ng-container>
                    <ng-template #TypeNotImg>
                        <span class="label_lg_bold d-block">
                            {{viewFileHasSelected[0].name}}
                        </span>
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="multiple">
                    <div class="wrap_img_info d-inline-block" *ngIf="viewFileHasSelected.length > 0">
                        <!-- <div class="remove_img d-inline-block">
                            <app-close></app-close>
                        </div> -->
                        <span class="label_lg_bold d-block">
                            تم اضافة
                            {{viewFileHasSelected?.length}}
                            صور
                        </span>
                    </div>
                </ng-container>
            </ng-template>
            <span class="label_lg_normal d-block box_title" *ngIf="viewFileHasSelected.length <= 0">{{title}}</span>
            <div 
                class="wrap_file_progress_bar"
                *ngIf="uploadProgress && uploadProgress != HTTP_EVENT_TYPE.errorResponse"
                >
                <div 
                    class="bar position-relative"
                    *ngIf="$any(uploadProgress) > 0 && uploadProgress != HTTP_EVENT_TYPE.response"
                >
                    <span class="file_progress_bar position-absolute" [style]="{width: (uploadProgress + '%')}"></span>
                </div>
                <span class="count label_lg_bold" *ngIf="$any(uploadProgress) > 0">{{uploadProgress + '%'}}</span>
                <span class="text_done main_color label_lg_bold" *ngIf="uploadProgress == HTTP_EVENT_TYPE.response">اكتمل التحميل</span>
            </div>
        </div>
    </div>   
</ng-template>
<!-- // -->
<div class="wrap_validation mt_5" *ngIf="errMsg">
    <app-validation-input 
        class="mb-0"
        [text]="errMsg"
    >
    </app-validation-input>
</div>
<!-- end app-input-text -->