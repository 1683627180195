import { usersType } from 'src/app/core/enums/user/user-type';
import { Pipe, PipeTransform } from '@angular/core';

// services
import { GlobleService } from 'src/app/core/services/globle.service';

// enums
import { User } from 'src/app/core/enums/user/info-user';
import { CATEGOR_VALUE_TYPE } from 'src/app/core/enums/category/cate-value-types';

@Pipe({
  name: 'allowedCategory'
})
export class allowedCategoryTypesPipe implements PipeTransform {

  userInfo: {[key: string]: any}

  constructor(private G: GlobleService) {

    // set
    this.userInfo = this.G.getUserData(User.info);

  }

  transform(value: any[], ...args: any[]) {

    // ES6 destructuring
    const { hasAdsPackage, adsPackage, type } = this.userInfo || {};
    const { building, plan } = adsPackage || {};

    // init
    let v: any[] = [];

    if (type == usersType.normalUser || !hasAdsPackage || (!building && !plan)) {

      v = value.map(cate => {

        if (cate.type == CATEGOR_VALUE_TYPE.PL || cate.type == CATEGOR_VALUE_TYPE.BL || cate.type == CATEGOR_VALUE_TYPE.VL) return {...cate, disabled: true}
        else return cate
        
      })

      return v
      
    }
    else {
      if (!building) {
        return value.map(cate => {

          if (cate.type == CATEGOR_VALUE_TYPE.BL || cate.type == CATEGOR_VALUE_TYPE.VL) return {...cate, disabled: true}
          else return cate
          
        })
      }
      else if (!plan) {
        return value.map(cate => {

          if (cate.type == CATEGOR_VALUE_TYPE.PL) return {...cate, disabled: true}
          else return cate
          
        })        
      }
    }

    return value
  }

}
