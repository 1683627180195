<!-- start app-second-header -->
<div 
    class="second_header bluer_section_pseudo position-relative"
    [ngStyle]="{'background-image': 'url(' + (backGroundImg ? backGroundImg : '/assets/img/pre_header.png') + ')'}"
>
    <div class="custom_container">
        <div class="wrap position-relative">
            <ng-content></ng-content>
        </div>
    </div>
</div>
<!-- end app-second-header -->