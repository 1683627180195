import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Countery } from '../../../core/enums/global/country';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit, OnChanges {

  // init props & inputs is coming
  @Input('defaultCountry') defaultCountry: string = Countery.isoCode;
  @Input('data')  set data(value: any[]) {
    // filter data
    value.map((c: any) => this.countries.push({...c}))
  }

  // init outPut Data
  @Output() changeCode = new EventEmitter()

  // init vars & props
  index: number = 0
  countries: any[] = []
  isOpen: boolean = false

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (!this.defaultCountry) this.defaultCountry = Countery.isoCode;

    if (this.countries.length > 0) {
      this.countries.map((c, i) => {
        if (this.defaultCountry.includes(c.isoCode) || this.defaultCountry.includes(c.countryCode)) this.selected(c, i)
      })
    }

  }

  ngOnInit(): void {

  }

  toggle() {
    this.isOpen = !this.isOpen
  }

  selected(info: {}, index: number) {

    // get index code
    this.index = index
    
    // send country code to parent Component
    this.changeCode.emit(info)
  }

}
