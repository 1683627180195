export enum NOTIFICATION_TYPE {
    app = 'APP',
    ads = 'ADS',
    news = 'NEWS',
    rate = 'RATE',
    msg = 'MESSAGE',
    client = 'CLIENT',
    fav = 'FAVOURITE',
    marketer = 'MARKETER-ADS',
    adsRequest = 'ADS-REQUEST',
    contact = 'CONTACT-REQUEST',
    marketPlace = 'MARKET-PLACE',
}