import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// lib modules
import { NgxPaginationModule } from 'ngx-pagination';
import { GoogleMapsModule } from '@angular/google-maps';

// services
import { SearchService } from './services/search.service';

// modules
import { SharedModule } from 'src/app/shared.modules/shared.module';

// shardd components 
import { SearchInputsBoxComponent } from './search-inputs-box/search-inputs-box.component';
import { SearchResultBoxComponent } from './search-result-box/search-result-box.component';




@NgModule({
  declarations: [
    SearchInputsBoxComponent,
    SearchResultBoxComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    //
    GoogleMapsModule,
    NgxPaginationModule,
    //    
  ],
  exports: [
    SearchInputsBoxComponent,
    SearchResultBoxComponent    
  ],
  providers: [ SearchService ]
})
export class SearchModule { }
