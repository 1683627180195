// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

// enums
import { usersType } from 'src/app/core/enums/user/user-type';

abstract class UserHandler {

    /**
     * 
     */
    constructor() {};

    static isUserProfileCompleted(user: IuserInfo): boolean {

        if (typeof user != 'object') throw new Error(`arg user in not Object, current value is ${user}`);

        // ES6 Destructing
        const { type, idNumber, idType, isVerifiedIdNumber } = user || {};

        if ( idType && idNumber && (type == usersType.office ? isVerifiedIdNumber : true)) return true

        return false

    }

}

export default UserHandler
