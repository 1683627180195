<app-success
    titleBtn="موافق" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="'تم تعديل ملفك بنجاح'"
>
</app-success>

<app-failure
    titleBtn="موافق" 
    *ngIf="alertFailure"
    (Submit)="failure()"
    [msg]="'لكي تتمكن من اضافة اعلان او حجز ايجار او دفع عربون او شراء باقة يجب استكمال باقي البيانات الخاصه للملف الشخصي'"
>
</app-failure>

<!-- start app-profile -->
<div class="profile def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container pt-4 pt-md-0">
            <form id="max1" [formGroup]="FormUpdateProfile" (ngSubmit)="updateProfile()">

                <div class="wrao_top_info text-center">
                    
                    <div class="wrap_close text-center position-relative" *ngIf="viewImgHasSelected">
                        <app-close (click)="removeFile()"></app-close>
                    </div>

                    <div class="wra_img_user border_def position-relative">
                        <div 
                            *ngIf="!editable" 
                            (click)="openImgFullScreen()"
                            class="custom_disabled none border_r pointer"
                        >
                        </div>
                        <label for="user_img">
                            <input 
                                type="file" 
                                class="d-none" 
                                id="user_img"
                                formControlName="img" 
                                (change)="upLoadFile($event)"
                            />
                        </label>
                        <img 
                            appImgUrlCheck
                            alt="user photo"
                            class="img_obj_cover"
                            [ngClass]="{'has_img': userInfo?.img || viewImgHasSelected}"
                            #appNgxViewer="NgxViewer" appNgxViewer [viewerOptions]="{transition: true}"
                            [src]="viewImgHasSelected ? (viewImgHasSelected |  safeUrl) : userInfo?.img ? userInfo?.img : '/assets/img/def_user_img.png'" 
                        />
                    </div>

                    <!-- import app-badge -->
                    <app-badge 
                        [style]="{padding: '0 9px'}"
                        [title]="userInfo.type | userTitle" 
                    >
                    </app-badge>
                    <a [href]="'tel:' + userInfo?.phone" class="a_none d-inline-block">
                        <div class="wrap d-flex algin-items-center">
                            <app-badge 
                                [style]="{
                                    padding: '0 16px',
                                    lineHeight: '30px',
                                    borderRadius: '999px'
                                }"
                                Class="text_md_bold"
                                [title]="userInfo.phone | phoneNumber" 
                            >
                            </app-badge>
                        </div>
                    </a>
                </div>

                <ng-container *ngFor="let field of listInput">
                    <ng-container *ngIf="!field.hideElement">
                        <!-- import app-label -->
                        <app-label 
                            [for]="field.name"
                            [title]="field.title" 
                            [style]="{color: '#fff'}"
                        >
                        </app-label>
                        
                        <!-- import app-input-text  -->
                        <app-input-text 
                            [type]="field.type" 
                            [name]="field.name" 
                            [customDisabled]="!editable"
                            [FormParent]="FormUpdateProfile"
                            [placeholder]="field.placeholder"
                            *ngIf="field.type != 'box_select'"
                            [validations]="field?.validators || []"
                            [validatorsErrorMsg]="field?.validatorsErrorMsg || false"
                        >
                        </app-input-text>                
    
                        <div 
                            *ngIf="field.type == 'box_select'"
                            class="wrap_select position-relative border_r" 
                        >
                            <div *ngIf="!editable" class="custom_disabled border_r"></div>
                            <ng-select
                                class="md"
                                [items]="citys"
                                bindValue="id"
                                bindLabel="cityName"
                                [clearable]="false"
                                [searchable]="true"
                                placeholder="المدن"
                                formControlName="city"
                                notFoundText="لا يوجد مدن متاحة!"
                            >
                            </ng-select>
                        </div>
                    </ng-container>
                </ng-container>
                
                <ng-container *ngIf="userInfo.type == usersType.normalUser">
                    
                    <div class="wrap_select">
                        <!-- import app-label -->
                        <app-label
                            [title]="'نوع الهوية'" 
                            [style]="{color: '#fff'}"
                        >
                        </app-label>
                        <div class="wrap_select position-relative border_r">
                            <div *ngIf="!editable" class="custom_disabled border_r"></div>
                            <ng-select
                                class="sm"
                                bindValue="id"
                                bindLabel="name"
                                [clearable]="false"
                                [searchable]="false"
                                placeholder="اختيار"
                                formControlName="idType"
                                [items]="listOfIdType | idType"
                                notFoundText="لا يوجد انواع مستخدمين متاحة!"
                            >
                            </ng-select>
                        </div>
                    </div>

                    <div class="wrap_input">

                        <div>
                            <!-- import app-label -->
                            <app-label 
                                title="رقم الهوية" 
                                [style]="{color: '#fff'}"
                                class="d-inline-block me-1"
                            >
                            </app-label>

                            <!-- import app-badge -->
                            <app-badge
                                [title]="Nafath.countDown?.toString() + ' ثانية '"
                                *ngIf="Nafath.countDown && !(Auth.userInfo | async)?.isVerifiedIdNumber"
                            >
                            </app-badge>
                            
                        </div>
        
                        <!-- import app-input-text  -->
                        <app-input-text 
                            type="text" 
                            name="idNumber"
                            placeholder="... اكتب هنا"
                            [customDisabled]="!editable"
                            [validatorsErrorMsg]="true"
                            [FormParent]="FormUpdateProfile"
                        >
                        </app-input-text>
                    </div>

                </ng-container>


                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="isSuccess">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let validator of isSuccess?.errors">
                                <!-- import app-validation-input  -->
                                <app-validation-input 
                                    [text]="validator.msg"
                                    *ngIf="!isSuccess.success"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn
                        title="تعديل" 
                        [submit]="false"
                        *ngIf="!editable"
                        (click)="editable = true"
                    >
                    </app-btn>
                    <app-btn
                        title="حفظ" 
                        [submit]="true"
                        *ngIf="editable"
                        [isLoading]="Nafath.IsLoadingIdnumber"
                        [disabled]="!FormUpdateProfile.dirty || isLoading || FormUpdateProfile.invalid || Nafath.IsLoadingIdnumber" 
                    >
                    </app-btn>           
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-profile-->