<!-- start app-input-text -->
<ng-container [formGroup]="FormParent" *ngIf="FormParent; else withOutForm">
    
    <ng-container *ngIf="type !='file'">
        <div class="wrap_field position-relative overflow-hidden border_r">
            <input 
                [type]="type"
                dir="auto"
                [name]="name"
                [style]="style"
                [value]="value"
                [formControlName]="name"
                (input)="getValue($event)"
                [placeholder]="placeholder" 
                class="custom_input text_md_bold border_def_2th border_r text-start {{class}}"
                [ngClass]="{'border_def_3th': getControl?.valid && getControl?.value?.length > 0, 'async_validation' : getControl?.asyncValidator?.length}"
            /> 
            <div *ngIf="customDisabled" class="custom_disabled"></div>
            <div class="wrap_state" *ngIf="getControl?.asyncValidator?.length && !getControl?.disabled" [ngClass]="{'disabled': disabled}">
                <i class="fas fa-check success" *ngIf="!getControl?.errors && !getControl?.pending" @fade></i>
                <i class="fas fa-times wrong" *ngIf="getControl?.errors?.asyncError && !getControl?.pending" @fade></i>
                <i class="fas fa-spinner loading" *ngIf="getControl?.pending" @fade></i>
            </div>
        </div>
    </ng-container>
 
    <ng-container *ngIf="type =='file'">

        <div class="wrap_input_file position-relative overflow-hidden border_r">
            <div *ngIf="customDisabled" class="custom_disabled"></div>
            <div class="wrap_close text-center" *ngIf="nameFile" (click)="$event.stopPropagation()">
                <app-close (click)="removeFile(); upLoadFile($event)"></app-close>
            </div>             
            <label 
                [for]="name" 
                class="lable_file text_md_bold border_def_2th border_r text-center"
                [ngClass]="{'border_def_3th': getControl?.valid && getControl?.value?.length > 0}"            
            >       
                <input 
                    #refInput
                    [id]="name"
                    [type]="type"
                    [name]="name"
                    [style]="style"
                    [class]="class"
                    [formControlName]="name"
                    (change)="upLoadFile($event)"
                    [ngClass]="{'input_file': type == 'file' }"
                />
                <img *ngIf="getControl?.invalid" src="/assets/img/file-2.svg" alt="file">
                <span *ngIf="getControl?.valid" class="d-block file_name elp">{{nameFile}}</span>
            </label>      

        </div>
    </ng-container>

    <!-- // show if validations have errors -->
    <ng-container *ngIf="validations">
        <ng-container *ngFor="let validation of validations">
            <!-- import app-validation-input -->
            <app-validation-input
                [text]="getError[validation.text] ? getError[validation.text] : validation?.text"
                *ngIf="
                getError
                && 
                getControl?.touched 
                && 
                getError[validation.typeValidation]
                "
            >
            </app-validation-input>
        </ng-container>
    </ng-container>

    <app-validation-input
        [text]="ErrorMsg"
        *ngIf="validatorsErrorMsg && ErrorMsg && getControl?.touched"
    >
    </app-validation-input>
    
</ng-container>
<ng-template #withOutForm>
    <div class="wrap_field position-relative overflow-hidden border_r">
        <input 
            [type]="type"
            dir="auto"
            [name]="name"
            [style]="style"
            [value]="value"
            [disabled]="disabled"
            (input)="getValue($event)"
            [placeholder]="placeholder" 
            class="custom_input text_md_bold border_def_2th border_r text-start {{class}}"
        /> 
        <div *ngIf="customDisabled" class="custom_disabled"></div>
    </div>    
</ng-template>
<!-- end app-input-text -->