import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { usersType } from 'src/app/core/enums/user/user-type';

@Injectable({
  providedIn: 'root'
})
export class TypeOfficeGuard implements CanActivate {
  constructor(private G: GlobleService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const userInfo = this.G.getUserData(User.info)

      if (userInfo.type == usersType.office) {
        return true
      }
      else  {
        // navigate to home
        this.G.navigate(['/'])
        return false
      }
  }
}
