import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(value: any[], deepKey: string, keys: string[]) {

        if (!Array.isArray(value)) return [];

        return value.filter(it =>  keys.includes(it[deepKey]))

    }

}