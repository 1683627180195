import { UntypedFormGroup, NgControl, ValidationErrors } from '@angular/forms';
import { 
  Input,
  OnInit,
  Output,
  Component,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  Optional,
  Self,
} from '@angular/core';

// custome interfaces
import { Ivalidation } from 'src/app/core/interfaces/form/validation';

// custom animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  animations: [fade]
})
export class InputTextComponent implements OnInit, OnChanges {

  // init props & inputs is coming
  @Input('style') style: any;
  @Input('value') value: string = '';
  @Input('class') class: string = '';
  @Input('type') type: string = 'text';
  @Input('name') name: string = 'input';
  @Input('loading') loading: boolean = false;
  @Input('disabled') disabled!: boolean;
  @Input('FormParent') FormParent!: UntypedFormGroup
  @Input('customDisabled') customDisabled!: boolean;
  @Input('validations') validations: Ivalidation[] = []
  @Input('placeholder') placeholder: string = 'type here...';
  @Input('validatorsErrorMsg') validatorsErrorMsg: boolean = false;
  // init props & inputs is coming

  // init data will outPut
  @Output() file = new EventEmitter()
  @Output() InputValue = new EventEmitter()  

  // init props
  nameFile: string = ''
  viewImgHasSelected: string = ''  

  constructor(
    @Self()
    @Optional()
    public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  /**
 * Write form value to the DOM element (model => view)
 */
    writeValue(value: any): void {
    this.value = value;
    this.InputValue.emit(value)
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  onChange(en: Event) { }
  onTouched() { }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (this.disabled != undefined) {

      if (this.disabled) this.getControl?.disable()
      else this.getControl?.enable()

    }

  }

  ngOnInit(): void {}

  // function will catch all errors in input
  get getError() {
    return (this.FormParent?.controls[this.name]?.errors as ValidationErrors) || {}
  }

  get ErrorMsg() {
    
    if (Object.keys(this.getError || {}).length > 0 && this.validatorsErrorMsg) {

      let msg: any;

      for (const key in (this.getError || {})) {
        if (this.getError[key]?.toString()) {
          msg = this.getError[key]
          break;
        }
      }

      return msg;

    }

    return null

  }

  // function will get all date about input
  get getControl() {
    return this.FormParent?.controls[this.name]
  }

  removeFile() {
    if (this.getControl?.value?.length > 0) {
      this.nameFile = ''
      if (this.FormParent) this.getControl?.reset()
    }
  }

  getValue(event: Event) {

    let value = (event.target as HTMLInputElement)?.value
    
    if (!this.getControl || !this.ngControl) this.InputValue.emit(value)
  }

  upLoadFile(event: Event) {

    if (event.type == 'change') {

      const file = (event.target as HTMLInputElement).files?.item(0)
  
      // outPut data
      this.file.emit(file)
      this.nameFile = file?.name as string
    } else {
      this.file.emit(null)
    }
  }

}
