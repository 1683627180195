import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { standardEmail } from '../../enums/regx/regx-match';
class OverrideValidators {

    constructor() {}

    static required(control: AbstractControl): ValidationErrors | null {

        if (!control.value?.toString()?.trim()) return { required: 'هذا الحقل مطلوب' }

        return null

    }

    static email(control: AbstractControl): ValidationErrors | null {
        
        if (control.value && !standardEmail.test(control.value)) return { email: 'صيغة البريد غير صحيح' }

        return null

    }    

    static pattern(pattern: string | RegExp, errorMsg: string = ''): ValidatorFn {
        
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.value && !(new RegExp(pattern).test(control.value))) return { pattern: errorMsg ? errorMsg : 'صيغة المدخلات غير صحيحه' }
    
            return null
    
        }
    }

    static maxlength(max: string | number, errorMsg: string = ''): ValidatorFn {
        
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.value && control?.value?.toString()?.length > max) return { maxlength: errorMsg ? errorMsg : `حقل يحتوي علي اكثر من ${max} حرف` }
    
            return null
    
        }
    }

    static minlength(min: string | number, errorMsg: string = ''): ValidatorFn {
        
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.value  && control?.value?.toString()?.length < min) return { minlength: errorMsg ? errorMsg : `اقل عدد من الحروف ${min}` }
    
            return null
    
        }
    }

}

export default OverrideValidators