import { Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

// enums
import { BREAD_CRUMB_TYPE } from './enums/types';
import { Contract_Type } from 'src/app/core/enums/ads/contract-types';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit, OnChanges, OnDestroy {

  // init props & inputs is coming
  @Input('AdsInfo') public AdsInfo?: any
  @Input('VipAds') public VipAds?: boolean = false;
  @Input({alias: 'type'}) public type: BREAD_CRUMB_TYPE

  // init props
  public contract_Type = Contract_Type
  
  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  // getters

  /**
   * 
   */
  public get breadCrumbTypes(): typeof BREAD_CRUMB_TYPE {
    return BREAD_CRUMB_TYPE
  }

  /**
   * @description toggle between advertiserName or fullname for owner,
   * if advertiserName notfound will show fullname owner
   */
  public get ownerName(): string {

    // ES6 Destruction & Nested
    const { advertiserName, owner: { fullname} } = this.AdsInfo;

    const name: string = (advertiserName || fullname);

    return name.split(' ').slice(0, 3).join(' ');
    
  }
  

  ngOnDestroy(): void {}

}
