import { Directive, ElementRef, HostListener, OnInit, OnChanges, SimpleChanges, DoCheck, AfterContentInit } from '@angular/core';

// enums
import { numberEn_Ar_Only } from 'src/app/core/enums/regx/regx-match';
import { NgControl, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[matchNumbersOnly]'
})
export class MatchNumbersOnlyDirective implements OnInit, AfterContentInit {

  ctr: AbstractControl | null;

  @HostListener('input') replace () {

    if (this.ctr?.value) {
      this.ctr?.patchValue(this.ctr?.value?.toString()?.replace(new RegExp(numberEn_Ar_Only, 'gi'), ''))
    }
        
  }

  constructor(
    private el: ElementRef<any>,
    private ngControl: NgControl,
  ) { }
  
  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.ctr?.value) this.ctr.patchValue(this.ctr.value)
    }, 1)
  }

  ngOnInit(): void {
    this.el.nativeElement.setAttribute('dir', 'auto');

    if (this.ngControl) this.ctr = this.ngControl?.control

  }

}
