<app-success
    titleBtn="موافق" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="'تم استكمال ملفك بنجاح الحساب قيد الاستخدام الان'"
>
</app-success>

<!-- start app-complete-profile -->
<div class="complete_profile def_p bluer_section">
    <div class="custom_container position-relative">

        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container">
            <form [formGroup]="FormCompleteProfile" (ngSubmit)="updateProfile()">

                
                <ng-container *ngFor="let field of listInput">

                    <!-- if type == file -->
                    <ng-container *ngIf="field.type == 'file'">
                        <div class="wrap_input_img">
                            <!-- import input-img -->
                            <app-input-img
                                [name]="field.name"
                                [title]="field.title" 
                                (files)="getFile($event)"
                                [FormParent]="FormCompleteProfile"
                                [validations]="field.validators || []"                    
                            >
                            </app-input-img>
                        </div>
        
                        <!-- import app-validation-input  -->
                        <ng-container *ngFor="let validation of field.validators">
                            <app-validation-input
                                [text]="validation.text"
                                [style]="{'margin-inline-start': 0,'text-align': 'center'}"
                                *ngIf="getControl(field.name)?.touched && getControl(field.name)?.errors"
                            >
                            </app-validation-input>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type != 'file'">
                        <!-- import app-label -->
                        <app-label 
                            [for]="field.name"
                            [title]="field.title" 
                            [style]="{color: '#fff'}"
                        >
                        </app-label>
                    </ng-container>

                    <ng-container *ngIf="(field.type != 'file') && (field.type != 'box_select')">
    
                        <!-- import app-input-text  -->
                        <app-input-text 
                            [type]="field.type" 
                            [name]="field.name" 
                            [FormParent]="FormCompleteProfile"
                            [placeholder]="field.placeholder"
                            [validations]="field.validators || []"
                        >
                        </app-input-text>
                    </ng-container>

                    <div class="wrap_select" *ngIf="field.type == 'box_select'">
                        <ng-select
                            class="sm"
                            [items]="citys"
                            bindValue="id"
                            bindLabel="cityName"
                            [clearable]="false"
                            [searchable]="true"
                            placeholder="المدن"
                            formControlName="city"
                            notFoundText="لا يوجد مدن متاحة!"
                        >
                        </ng-select>
                        <!-- import app-validation-input  -->
                        <app-validation-input
                            text="هذا الحقل مطلوب"
                            *ngIf="getControl('city')?.touched && getControl('city')?.errors"
                        >
                        </app-validation-input>                       
                    </div>                    
                </ng-container>

                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="isSuccess">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let validator of isSuccess?.errors">
                                <!-- import app-validation-input  -->
                                <app-validation-input 
                                    [text]="validator.msg"
                                    *ngIf="!isSuccess.success"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>                

                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn
                        title="ارسال" 
                        [submit]="true"
                        [disabled]="FormCompleteProfile.invalid ||  FormCompleteProfile.value.city == 'المدن' || isLoading" 
                    >
                    </app-btn>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-complete-profile -->