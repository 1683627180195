<!-- start app-home -->
<main class="home_component">
    
    <!-- // start outlet Router here to view  child components -->
    <router-outlet></router-outlet>
    <!-- // end outlet Router here to view  child components -->


    <!-- import app-search-inputs-box -->
    <app-search-inputs-box></app-search-inputs-box>

    
    <!-- import app-search-result-box -->
    <app-search-result-box></app-search-result-box>
    
    <!-- import app-section-service -->
    <app-section-service></app-section-service>
    
</main>
<!-- end app-home -->