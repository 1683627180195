// defualt modeule in built in angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// env
import { environment } from 'src/environments/environment';

// // Module libs outside
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgSelectModule } from '@ng-select/ng-select';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// // custom components
import { BtnComponent } from './shared.components/btn/btn.component';
import { BadgeComponent } from './shared.components/badge/badge.component';
import { CloseComponent } from './shared.components/close/close.component';
import { LabelComponent } from './shared.components/label/label.component';
import { NavbarComponent } from './shared.components/navbar/navbar.component';
import { FooterComponent } from './shared.components/footer/footer.component';
import { HeaderComponent } from './shared.components/header/header.component';
import { LoaderComponent } from './shared.components/loader/loader.component';
import { CAlertComponent } from './shared.components/c-alert/c-alert.component';
import { FailureComponent } from './shared.components/failure/failure.component';
import { SuccessComponent } from './shared.components/success/success.component';
import { AdsBillComponent } from './shared.components/ads-bill/ads-bill.component';
import { AdsInfoComponent } from './shared.components/ads-info/ads-info.component';
import { BoxRateComponent } from './shared.components/box-rate/box-rate.component';
import { UserImgComponent } from './shared.components/user-img/user-img.component';
import { DropDownComponent } from './shared.components/drop-down/drop-down.component';
import { InputImgComponent } from './shared.components/input-img/input-img.component';
import { InfoCardComponent } from './shared.components/info-card/info-card.component';
import { UserCardComponent } from './shared.components/user-card/user-card.component';
import { InputFileComponent } from './shared.components/input-file/input-file.component';
import { InputTextComponent } from './shared.components/input-text/input-text.component';
import { BoxSelectComponent } from './shared.components/box-select/box-select.component';
import { NavigationComponent } from './shared.components/navigation/navigation.component';
import { InputFieldComponent } from './shared.components/input-field/input-field.component';
import { ShareLinksComponent } from './shared.components/share-links/share-links.component';
import { InputCheckComponent } from './shared.components/input-check/input-check.component';
import { InputRangeComponent } from './shared.components/input-range/input-range.component';
import { BreadCrumbComponent } from './shared.components/bread-crumb/bread-crumb.component';
import { InputPhoneComponent } from './shared.components/input-phone/input-phone.component';
import { FavouritBtnComponent } from './shared.components/favourit-btn/favourit-btn.component';
import { SlideToggleComponent } from './shared.components/slide-toggle/slide-toggle.component';
import { ContentCardComponent } from './shared.components/content-card/content-card.component';
import { BoxTextAreaComponent } from './shared.components/box-text-area/box-text-area.component';
import { SecondHeaderComponent } from './shared.components/second-header/second-header.component';
import { OrderDetailsComponent } from './shared.components/order-details/order-details.component';
import { PopOversTitleComponent } from './shared.components/pop-overs-title/pop-overs-title.component';
import { InfoCardModelComponent } from './shared.components/info-card-model/info-card-model.component';
import { FloatDropDownComponent } from './shared.components/float-drop-down/float-drop-down.component';
import { SectionServiceComponent } from './shared.components/section-service/section-service.component';
import { RateStarsCountComponent } from './shared.components/rate-stars-count/rate-stars-count.component';
import { ValidationInputComponent } from './shared.components/validation-input/validation-input.component';
// import { SliderListCitysComponent } from './shared.components/slider-list-citys/slider-list-citys.component';
import { RequestInfoCardComponent } from './shared.components/request-info-card/request-info-card.component';
import { OverlayContainerComponent } from './shared.components/overlay-container/overlay-container.component';
import { NotificationCardComponent } from './shared.components/notification-card/notification-card.component';
import { DescriptionPopUpComponent } from './shared.components/description-pop-up/description-pop-up.component';
import { TermsAndConditionsComponent } from './shared.components/terms-and-conditions/terms-and-conditions.component';
import { AvailablePaumentMethodsComponent } from './shared.components/available-paument-methods/available-paument-methods.component';


// // custome pipes
import { idTypePipe } from 'src/app/core/pipes/enmus/id-types';
import { UserTypePipe } from 'src/app/core/pipes/enmus/user-types';
import { FilterPipe } from 'src/app/core/pipes/array/filter..pipe';
import { rentTypePipe } from 'src/app/core/pipes/enmus/rent-types';
import { ReversePipe } from 'src/app/core/pipes/array/reverse.pipe';
import { userTitlePipe } from 'src/app/core/pipes/users/user-title';
import { safeUrlPipe } from 'src/app/core/pipes/files/safe-url.pipe';
import { CountDownPipe } from 'src/app/core/pipes/numbers/count-down';
import { percentagePipe } from 'src/app/core/pipes/numbers/percentage';
import { createUrlPipe } from 'src/app/core/pipes/files/create-url.pipe';
import { accountStatePipe } from 'src/app/core/pipes/users/account-state';
import { limitCountPipe } from 'src/app/core/pipes/numbers/limited-count';
import { shortNumberPipe } from 'src/app/core/pipes/numbers/short-number';
import { ContractTypePipe } from 'src/app/core/pipes/enmus/contract-types';
import { shortCurrencyPipe } from 'src/app/core/pipes/numbers/short-currency';
import { convertArDigitalPipe } from 'src/app/core/pipes/numbers/convert-ar-en';
import { AgencyTypeTitlePipe } from 'src/app/core/pipes/enmus/agencyType-title';
import { PhoneNumberPipe } from 'src/app/core/pipes/numbers/formate-phone-number';
import { CountdownDatePipe } from 'src/app/core/pipes/numbers/countdown-date.pipe'
import { CheckAdvUrlRegxPipe } from 'src/app/core/pipes/text/check-adv-url.regx.pipe';
import { MarketingStatusTitlePipe } from '../core/pipes/enmus/marketing-status-title';
import { AdvertiserTypeTitlePipe } from 'src/app/core/pipes/enmus/advertiserType-title';
import { allowedCategoryTypesPipe } from 'src/app/core/pipes/array/allowed-category-types';
import { AdsStatusTitlePipe } from 'src/app/core/pipes/enmus/ads-status-title/ads-status-title'


// // custome directives
import { UrlIfDirective } from 'src/app/core/directives/structural/url-if.directive';
import { TextFormatDirective } from 'src/app/core/directives/text/text-format.directive';
import { ImgUrlCheckDirective } from 'src/app/core/directives/img/img-url-check.directive';
import { MessageTypeDirective } from 'src/app/core/directives/text/message-type.directive';
import { NgxViewerDirective } from 'src/app/core/directives/ngx-viewer/ngx-viewer.directive';
import { ConvertToAmountDirective } from 'src/app/core/directives/text-and-numbers/match-amount';
import { CustomMapInfoWindow } from 'src/app/core/directives/google-maps/custom-map-info-window';
import { StopPropagationDirective } from 'src/app/core/directives/dom/stop-propagation.directive';
import { ParseArabicToEnglishDirective } from 'src/app/core/directives/numbers/from-arabic-to-ASCII';
import { LimitCharactershDirective } from 'src/app/core/directives/text-and-numbers/limit-characters';
import { MatchNumbersOnlyDirective } from 'src/app/core/directives/text-and-numbers/match-numbers-only';
import { RemoveZerosAtFirstDirective } from 'src/app/core/directives/text-and-numbers/remove-zeros-at-first';
import { PhoneWithCountryCodeDirective } from 'src/app/core/directives/text-and-numbers/phone-with-CountryCode';
import { MatchLettersNumbersOnlyDirective } from 'src/app/core/directives/text-and-numbers/match-letters-numbers-only';

// // init
const ConfigShareBtn: ShareButtonsConfig = {
  
};

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    UserCardComponent,
    BtnComponent,
    LabelComponent,
    BadgeComponent,
    CloseComponent,
    LoaderComponent,
    CAlertComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    BoxRateComponent,
    FailureComponent,
    SuccessComponent,
    AdsBillComponent,
    AdsInfoComponent,
    UserImgComponent,
    InfoCardComponent,
    DropDownComponent,
    InputImgComponent,
    InputFileComponent,
    InputTextComponent,
    BoxSelectComponent,
    BreadCrumbComponent,
    InputCheckComponent,
    InputFieldComponent,
    ShareLinksComponent,
    InputRangeComponent,
    NavigationComponent,
    InputPhoneComponent,
    BoxTextAreaComponent,
    FavouritBtnComponent,
    ContentCardComponent,
    SlideToggleComponent,
    SecondHeaderComponent,
    OrderDetailsComponent,
    PopOversTitleComponent,
    InfoCardModelComponent,
    FloatDropDownComponent,
    SectionServiceComponent,
    RateStarsCountComponent,
    ValidationInputComponent,
    // SliderListCitysComponent,
    RequestInfoCardComponent,
    NotificationCardComponent,
    DescriptionPopUpComponent,
    OverlayContainerComponent,
    TermsAndConditionsComponent,
    AvailablePaumentMethodsComponent,

    // start pipes declare
    idTypePipe,
    FilterPipe,
    safeUrlPipe,
    ReversePipe,
    UserTypePipe,
    rentTypePipe,
    CountDownPipe,
    userTitlePipe,
    createUrlPipe,
    percentagePipe,
    limitCountPipe,
    PhoneNumberPipe,
    shortNumberPipe,
    accountStatePipe,
    ContractTypePipe,
    shortCurrencyPipe,
    CountdownDatePipe,
    AdsStatusTitlePipe,
    CheckAdvUrlRegxPipe,
    AgencyTypeTitlePipe,
    convertArDigitalPipe,
    AdvertiserTypeTitlePipe,
    allowedCategoryTypesPipe,
    MarketingStatusTitlePipe,
    // end pipes declare

    // start directive declare
    UrlIfDirective,
    NgxViewerDirective,
    CustomMapInfoWindow,
    TextFormatDirective,
    ImgUrlCheckDirective,
    MessageTypeDirective,
    StopPropagationDirective,
    ConvertToAmountDirective,
    LimitCharactershDirective,
    MatchNumbersOnlyDirective,
    RemoveZerosAtFirstDirective,
    PhoneWithCountryCodeDirective,
    ParseArabicToEnglishDirective,
    MatchLettersNumbersOnlyDirective,
    // end directive declare    
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // start libs Module
    NgSelectModule,
    CarouselModule,
    NgbTooltipModule,
    LottieModule.forRoot({ player: playerFactory }),
    // end libs Module
  ],
  exports: [
    UserCardComponent,
    BtnComponent,
    LabelComponent,
    BadgeComponent,
    CloseComponent,
    LoaderComponent,
    NavbarComponent,
    FooterComponent,
    CAlertComponent,
    HeaderComponent,
    BoxRateComponent,
    UserImgComponent,
    FailureComponent,
    SuccessComponent,
    AdsBillComponent,
    AdsInfoComponent,
    InfoCardComponent,
    DropDownComponent,
    InputImgComponent,
    InputFileComponent,
    InputTextComponent,
    BoxSelectComponent,
    InputPhoneComponent,
    BreadCrumbComponent,
    InputCheckComponent,
    InputFieldComponent,
    InputRangeComponent,
    ShareLinksComponent,
    NavigationComponent,
    BoxTextAreaComponent,
    FavouritBtnComponent,
    ContentCardComponent,
    SlideToggleComponent,
    SecondHeaderComponent,
    OrderDetailsComponent,
    PopOversTitleComponent,
    InfoCardModelComponent,
    FloatDropDownComponent,
    RateStarsCountComponent,
    SectionServiceComponent,
    ValidationInputComponent,
    // SliderListCitysComponent,
    RequestInfoCardComponent,
    NotificationCardComponent,
    DescriptionPopUpComponent,
    OverlayContainerComponent,
    TermsAndConditionsComponent,
    AvailablePaumentMethodsComponent,

    // start modues
    // AgmOverlays,
    LottieModule,
    CarouselModule,
    NgSelectModule,
    ShareIconsModule,
    NgbTooltipModule,
    FontAwesomeModule,
    ShareButtonsModule,
    ReactiveFormsModule,
    // AgmJsMarkerClustererModule,
    // end modues

    // start pipes export
    idTypePipe,
    FilterPipe,
    safeUrlPipe,
    ReversePipe,
    UserTypePipe,
    rentTypePipe,
    CountDownPipe,
    userTitlePipe,
    createUrlPipe,
    percentagePipe,
    limitCountPipe,
    PhoneNumberPipe,
    shortNumberPipe,
    accountStatePipe,
    ContractTypePipe,
    shortCurrencyPipe,
    CountdownDatePipe,
    AdsStatusTitlePipe,
    AgencyTypeTitlePipe,
    CheckAdvUrlRegxPipe,
    convertArDigitalPipe,
    AdvertiserTypeTitlePipe,
    allowedCategoryTypesPipe,
    MarketingStatusTitlePipe,
    // end pipes export 

    // start directive export
    UrlIfDirective,
    NgxViewerDirective,
    CustomMapInfoWindow,
    TextFormatDirective,
    ImgUrlCheckDirective,
    MessageTypeDirective,
    StopPropagationDirective,
    ConvertToAmountDirective,
    LimitCharactershDirective,
    MatchNumbersOnlyDirective,
    RemoveZerosAtFirstDirective,
    PhoneWithCountryCodeDirective,
    ParseArabicToEnglishDirective,
    MatchLettersNumbersOnlyDirective,
    // end directive export
  ],
})
export class SharedModule { }
