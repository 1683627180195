import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Injector, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

// libs
import { GoogleMap } from '@angular/google-maps';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Directive({
    inputs: ['marker'],
    selector: 'app-map-info-window',
    host: {'style': 'display: none'},
})  
export class CustomMapInfoWindow extends MapInfoWindow implements OnInit, AfterViewInit, OnDestroy {

    //
    public marker: MapMarker

    constructor(
        public NgZone: NgZone,
        private injector: Injector,
        public googleMap: GoogleMap,
        public el: ElementRef<HTMLElement>,
        @Inject(PLATFORM_ID) private platformId: any        
    ) {
        
        super(googleMap, el, NgZone)

    }
    
    
    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            if (this.googleMap._isBrowser) this.open(this.marker, true)
        }
    }

    override ngOnDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.close()
            this.infoWindow.close()
        }
    }
        
}