import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// custome services
import { GlobleService } from '../../services/globle.service';

// custome enums
import { User } from '../../enums/user/info-user';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard implements CanActivate {

  constructor(private G: GlobleService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

      const token = this.G.getUserData(User.token) || null

      if (token) {
        // navigate to home
        this.G.navigate(['/'])
        return false
      }
      else return true
  }
  
}
