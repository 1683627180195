import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';

// env
import { environment } from 'src/environments/environment';

// services
import { AuthService } from 'src/app/core/services/user/auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { MainApiService } from 'src/app/core/services/main/main-api.service';

// interfaces
import { IArea } from 'src/app/core/interfaces/location/area';
import { ICountry } from 'src/app/core/interfaces/location/country';
import { IBillInfo } from 'src/app/core/interfaces/ads/ads-bill-info';
import { ICustomAlert } from 'src/app/core/interfaces/alert/custom-alert';
import { IRegaBody, IRegaResponse } from 'src/app/core/interfaces/ads/rega';
import { IMetaDataDeposit } from 'src/app/core/interfaces/payment/meta-data-deposit';
import { IMarketingStatistic } from 'src/app/main.components/marketing-management/interfaces/marketing-statistic';
import { IMarketingClientBase, IMarketingClientStatistics } from 'src/app/main.components/marketing-management/interfaces/marketing-client';

// enums
import { BILL_TYPE } from 'src/app/core/enums/ads/bill/bill-type';
import { accountState } from 'src/app/core/enums/user/account-state';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { PAYMENT_TYPE } from 'src/app/core/enums/payment/payment-types';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { PAYMENT_AUTH_RES } from 'src/app/core/enums/payment/authorize-response';

@Injectable({
    providedIn: 'root'
})
export class ApiResponseService {

    constructor(
        private Auth: AuthService,
        private API: MainApiService,
        private Alert: AlertService,
    ) {}

    // general
    public AboutUs() {
        return this.API.mainGetAll('about').pipe(map((res: any) => res?.about));
    }

    public Setting() {
        return this.API.mainGetAll('setting', {
            params: { [RANDOM_VALUE.loader]: true }
        }).pipe(
            share(),
            map((v: any) => v?.setting)
        )        
    }

    //
    public JoinOurInterest(data: {}): Observable<any> {
        return this.API.mainPost('interests', data)
    }    

    public Anoncement() {
        return this.API.mainGetAll('anoncement/withoutPagenation/get')
        .pipe(map((res: any) => res?.anoncements));
    }

    // *** start user *** //
    public toggleActivatingNotif(id: string | number, status: boolean) {
        return this.API.mainPutBySubPath('user', id, 'updateInfo', {enableNotif: status?.toString()})
        .pipe(map((v: any) => {
            
            // es6 destructuring
            const { token } = v || {}            

            // update token
            this.Auth.updateToken(token)

            this.Auth.getUser('/setting').subscribe()

            return v
        }))
    }

    public notificationWithPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('notif', options)
    }

    public readNotification(id: string | number, options = {}): Observable<any> {
        return this.API.mainPutBySubPath('notif', id, 'read', null, options)
    }

    // getUser
    public userInfoById(id: string | number): Observable<any> {
        return this.API.mainGetAll(`${id}/getUser`)
    }

    //
    public completeUserProfile(id: string | number, data: {}): Observable<any> {
        return this.API.mainPost(`${id}/completeProfile`, data)
    }

    //
    public signUp(data: {}): Observable<any> {
        return this.API.mainPost('signup', data)
    }

    public signIn(data: {}): Observable<any> {
        return this.API.mainPost('signin', data)
    }

    public sendCode(data: {}): Observable<any> {
        return this.API.mainPost('sendCode-phone', data)
    }

    public addFCMToken(token: string): Observable<any> {
        return this.API.mainPost('addToken', { token }, {
            params: { [RANDOM_VALUE.loader]: true }
        })
    }

    //
    public userProfileUpdate(id: string | number, data: {}): Observable<any> {
        return this.API.mainPutBySubPath('user', id, 'updateInfo', data)
    }

    //
    public createUser(data: {}, options = {}): Observable<any> {
        return  this.API.mainPost('addUser', data, options)
    }    

    //
    public usersWithPagination(options = {}): Observable<any> {
        return  this.API.mainGetAll('find', options)
    }

    //
    public removeUser(id: string | number, options = {}): Observable<any> {
        return  this.API.mainDeleteByFirstId(id, 'delete', options)
    }

    //
    public toggleActivateUser(id: string | number, status: accountState, options = {}): Observable<any> {
        return  this.API.mainPutByFirstId(id, status == accountState.active ? 'dis-active' : 'active', undefined, options)
    }    

    //
    public favourites(options = {}): Observable<any> {
        return this.API.mainGetAll('favourites', options)
    }

    public AddToFavourites(id: number | string, options = {}) {
        return this.API.mainPostBySubPath('favourites', id, 'add', undefined, options).pipe(
            map((v: any) => v?.success) 
        )
    }

    public RemoveFromFavourites(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('favourites', id, options).pipe(
            map((v: any) => v?.success)
        )
    }

    //
    public resetPassword(data: {}, options= {}): Observable<any> {
        return  this.API.mainPost('reset-password-phone', data, options)
    }

    public getUserIp(): Observable<any> {
        return  this.API.mainGetAll('https://api.ipify.org?format=json', {
            params: { [RANDOM_VALUE.loader]: true }
        }).pipe(map((v: any) => v.ip))
    }

    public nafathLogin(data: {}, options= {}): Observable<any> {
        return  this.API.mainPost('nafath/login', data, options)
    }    

    // *** end user *** //

    // ** start location **/
    public getCountries(): Observable<ICountry> {
        return this.API.mainGetAll('countries/withoutPagenation/get').pipe(
            map((v: any) => v.countries)
        )
    }

    public getRegions(countryId: string | number, options = {}) {
        return this.API.mainGetBySubPath('regions', countryId, 'withoutPagenation/get', options).pipe(
            map((v: any) => v?.regions)
        );
    }

    public getCitiesByRegion(regionId: string | number, options = {}) {
        return this.API.mainGetBySubPath('countries', regionId, 'cities/withoutPagenation/get', options).pipe(
            map((v: any) => v?.cities)
        );
    }

    public getCitiesByCountry(counterId: string | number, options: {[key: string]: any} = {}) {
        
        let o = options?.params;

        return this.API.mainGetBySubPath('countries', counterId || 1, 'cities/withoutPagenation/get', {
            params: {
                ...o,
                country: counterId,
            },

        }).pipe(
            map((v: any) => v?.cities)
        );
    }

    public cityAreas(cityId: string | number, options = {}): Observable<IArea[]> {
        return this.API.mainGetBySubPath('areas',  cityId, 'withoutPagenation/get', options).pipe(
            map((v: any) => v?.areas)
        )
    }

    public citiesAreas(citiesId: string, options: {[key: string]: any} = {}): Observable<any> {
        
        options['params'] = {
            ...options['params'],
            city: citiesId,
        }

        return this.API.mainGetAll('areas/withoutPagenation/get', options).pipe(
            map((v: any) => v?.areas)
        )
    }    
    // ** end location **//

    // categories
    public mainCategories(options: {} = {}): Observable<any> {
        return this.API.mainGetAll('categories', options).pipe(
            map((v: any) => v?.categories)
        )
    }

    public subCategories(categoryId: string | number, options = {}): Observable<any> {

        const req = this.API.mainGetBySubPath('categories', categoryId, 'sub-categories', options)
        .pipe(
            map((v: any) => v?.categories)
        )

        return req
    }

    ///
    public getBillInfo(config: {data: any,  params?: any}): Observable<IBillInfo> {

        // ES6 Destructuring
        const { data, params } = config || {};
        
        const req = this.API.mainPost(`${environment.endPointAds}/billInfo/get`, data, { 
            params
        }) as Observable<IBillInfo>

        return req
    }

    public getTaxInvoice(id: string | number, options = {}): Observable<any> {

        const req = this.API.mainGetById('transactions', id, options).pipe(
            map((v: any) => v?.data)
        )

        return req
    }

    // package
    public packages(params = {}): Observable<any> {

        const req = this.API.mainGetAll('packages', { params });
        
        return req
    }

    public packageById(id: string | number, options = {}): Observable<any> {

        const req = this.API.mainGetById('packages', id, options).pipe(
            map((v: any) => v?.data)
        )

        return req
    }    


    //
    public getBookingCalendar(id: number | string, options = {}) {
        return this.API.mainGetBySubPath(`${environment.endPointAds}/booking`, id, 'getCalendar', options).pipe(
            map((v: any) => v?.bookingDays)
        )
    }

    public postAddBooking(id: number | string, data: any, options = {}) {
        return this.API.mainPostBySubPath(environment.endPointAds, id, 'addBooking', data, options).pipe(
            map((v: any) => v?.data)
        )
    }

    // *** start market place *** //
        public postAddMarketPlace(data: any, options = {}) {
            return this.API.mainPost('marketPlace', data, options).pipe(
                map((v: any) => v?.data),
                catchError(err => throwError(err?.error?.errors)),
            )
        }

        public PutMarketPlace(id: string | number, data: any, options = {}) {
            return this.API.mainPutById('marketPlace', id, data, options).pipe(
                map((v: any) => v?.data),
                catchError(err => throwError(err?.error?.errors)),
            )
        }

        public marketPlaceByUserId(id: string | number, options = {}): Observable<any> {

            const req = this.API.mainGetBySubPath('marketPlace', id,'checkOfficeMarket', options)
    
            return req
            
        }          
    // *** start market place *** //

    // *** start payment *** //
        //
        public getPaymentTerms(options = {}) {
            return this.API.mainGetAll('paymentTerms', options).pipe(
                map((v: any) => v?.data[0])
            )
        }

        // deposit
        public depositPay(adsId: number, params: {} = {}): Observable<IMetaDataDeposit> {
        
            let data = {
              ads: adsId,
              billType: BILL_TYPE.depost,
            }
             
            // request
            return this.getBillInfo({data, params}).pipe(
                map(res => {

                    const metaDataDeposit: IMetaDataDeposit = {
                        ...res,
                        adsId,
                        client: 0,
                        type: PAYMENT_TYPE.deposit,
                        description: 'يتم دفع العربون علي الاعلان',
                    }

                    return metaDataDeposit

                })
            )
            
        }

        public paymentCallback(res: {status: any, paymentType: PAYMENT_TYPE}): void {
    
            // ES6 Destructuring
            const { status, paymentType  } = res || {};

            let showSuccess: ICustomAlert = {
                titleBtn: 'موافق',
                type: LAYOUTS_TYPE.alertSucess,
                msg: 'تم بشكل ناجح',
                callback: () => {
                  this.Alert.hide()
                }
            };

            let showFailuer: ICustomAlert = {
                titleBtn: 'موافق',
                type: LAYOUTS_TYPE.alertFailure,
                msg: 'لم يتم دفع بشكل ناجح!',
                callback: () => {
                  this.Alert.hide()
                }
            }

            switch(paymentType) {
                case PAYMENT_TYPE.ads:
                    showSuccess.msg = 'تم الدفع بشكل ناجح الاعلان متاح للعرض الان';
                    break
                case PAYMENT_TYPE.deposit:
                    showSuccess.msg = 'تك دفع العربون بشكل ناجح'
                    break
                default:
                    showSuccess.msg = 'تم الدفع بشكل ناجح'
                    break
            }
            
            if (status == PAYMENT_AUTH_RES.captured) this.Alert.show(showSuccess)
            else  this.Alert.show(showFailuer) 
            
        }
    // *** end payment *** //

    // ** start Ads **//
    public createAds(data: {}, options = {}) {
        return this.API.mainPost(environment.endPointAds, data, options).pipe(
            map((v: any) => v?.advertisement)
        )
    }

    public editAds(id: string | number, data: {}, options = {}) {
        return this.API.mainPutById(environment.endPointAds, id ,data, options).pipe(
            map((v: any) => v?.advertisement)
        )
    }
    
    public removeAds(id: string | number, options = {}) {
        return this.API.mainDeletetById(environment.endPointAds, id, options)
    }    

    public AdsById(id: string | number, options = {}) {
        return this.API.mainGetById(environment.endPointAds, id, options).pipe(
            map((v: any) => v?.advertisement)
        )
    }

    public findAllAdvertisementUnits(id: number, options = {}) {
        return this.API.mainGetBySubPath('ads', id, 'findAllAdvertisementUnits', options)
    }

    public AdsWithPagination(options = {}) {
        return this.API.mainGetAll(environment.endPointAds, options)
    }

    public AdsWithOutToken(options = {}) {
        return this.API.mainGetAll(`${environment.endPointAds}/withoutToken`, options)
    }    
    
    public putAvailable(adsId: string | number, status: boolean, options = {}) {
        return this.API.mainPutBySubPath('ads', adsId, status ? 'dis-available' : 'available', null, options).pipe(
            map((v: any) => v?.data)
        )
    }

    public AcceptMarketTerms (options = {}) {
        return this.API.mainPut('acceptMarketTerms', {acceptMarketTerms: true}, options)
    }

    public MarketTerms(options = {}) {
        return this.API.mainGetAll('marketTerms', options).pipe(
            map((v: any) => v?.data[0])
        )
    }    

    public createMarketingAdSharing (data: { shareLink: string, ads: number }, options = {}) {
        return this.API.mainPost('marketerAds', data, options)
    }

    public MarketingAdSharing(options = {}) {
        return this.API.mainGetAll('marketerAds', options)
    }

    public MarketingClients(options = {}) {
        return this.API.mainGetAll('clients', options)
    } 

    public createMarketingClient(data: IMarketingClientBase, options = {}) {
        return this.API.mainPost('clients', data, options)
    }

    public updateMarketingClient(id: number, data: IMarketingClientBase, options = {}) {
        return this.API.mainPutById('clients', id, data, options)
    }
    
    public MarketingClientById(id: string | number, options = {}) {
        return this.API.mainGetById('clients', id, options).pipe(
            map((v: any) => v.data)
        )
    }  
    
    public removeMarketingClient(id: string | number, options = {}) {
        return this.API.mainDeletetById('clients', id, options)
    }    

    public MarketingClientStatisticStatistic(options = {}) {
        return this.API.mainGetAll('clients/statistic/get', options) as Observable<IMarketingClientStatistics>
    }

    public MarketingStatistic(options = {}) {
        return this.API.mainGetAll('marketStatistic', options) as Observable<IMarketingStatistic>
    }

    public relatedAds(options = {}) {
        return this.API.mainGetAll(`${environment.endPointAds}/withoutToken`, options).pipe(
            map((v: any) => v?.data)
        )
    }

    public uploadFilesForAd(data: FormData, options = {}): Observable<any> {
        
        /**
         * 
         */
        options = {
            observe: 'events',
            reportProgress: true,
            params: { [RANDOM_VALUE.loader]: true },  
            ...options           
        }

        /**
         * 
         */
        const 
            req = this.API.mainPost('ads/uploadImgs', data, options)
            .pipe(map((event: any) => this.API.handlerProgressHttpEvent(event)))

        return req
    }     

    // Features
    public Adsfeatures(options = {}) {
        return this.API.mainGetAll('features/withoutPagenation/get', options).pipe(
            map((v: any) => v?.features)
        )
    }

    public AfterSalesService(options = {}) {
        return this.API.mainGetAll('services/withoutPagenation/get', options).pipe(
            map((v: any) => v?.services)
        )
    }  
    
    public banks(options = {}) {
        return this.API.mainGetAll('banks/withoutPagenation/get', options).pipe(
            map((v: any) => v?.data)
        )
    }   
    
    public enableStartSaleNotif(adsId: number | string, options = {}) {
        return this.API.mainPutBySubPath('ads', adsId, 'enableStartSaleNotif', null, options).pipe(
            map((v: any) => v)
        )
    }

    public disableStartSaleNotif(adsId: number | string, options = {}) {
        return this.API.mainPutBySubPath('ads', adsId, 'disableStartSaleNotif', null, options).pipe(
            map((v: any) => v)
        )
    }    

    // live search
    public liveSearch(options = {}): Observable<any> {
        return this.API.mainGetAll(`${environment.endPointAds}/liveSearch/get`, options).pipe(
            map((v: any) => v?.data)
        )
    }

    public regaGetData(data: IRegaBody, options = {}): Observable<IRegaResponse> {
        return this.API.mainPost('ads/RgeaGetData', data, options).pipe(
            map((v: any) => v?.data)
        )
    }
    // ** end Ads **//

    // ** Start real Estate managment **//

    //
    public realEstatePagination(options = {}): Observable<any> {
        return  this.API.mainGetAll('realEstates', options)
    }
    //
    public createAdsPropertyManagement(id: string | number, data: {}): Observable<any>  {
        return this.API.mainPostBySubPath('realEstates', id, 'add', data)
    }
    //
    public editAdsPropertyManagement(id: string | number, data: {}): Observable<any> {
        return this.API.mainPutById('realEstates', id, data)
    }
    //
    public removeRealEstate(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('realEstates', id, options)
    }
    //
    public toggleActivateRealEstate(id: string | number, status: boolean, options = {}): Observable<any> {
        return this.API.mainPutBySubPath('realEstates', id, status ? 'dis-active' : 'active', null, options)
    }

    // renters
    public rentersWithPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('renters', options)
    }
    //
    public createRenter(data: {}, options = {}): Observable<any> {
        return this.API.mainPost('renters', data, options)
    }
    //
    public editRenter(id: string | number, data: {}, options = {}): Observable<any> {
        return this.API.mainPutById('renters', id, data, options)
    }
    //
    public removeRenter(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('renters', id, options)
    }
    //
    public toggleContractStatus(id: string | number, status: string, options = {}): Observable<any> {
        return this.API.mainPutBySubPath('renters', id, status, null, options)
    }

    // financial History
    public financialHistorywithPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('financialHistory', options)
    }
    //
    public removefinancialHistory(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('financialHistory', id, options)
    }
    //
    public createfinancialHistory(renterId: number, realEstateId: number, data: {}, options = {}): Observable<any> {
        return this.API.mainPost(`financialHistory/${renterId}/renter/${realEstateId}/realEstate`, data, options)
    }
    //
    public editFinancialHistory(id: string | number, data: {}, options = {}): Observable<any> {
        return this.API.mainPutById('financialHistory', id, data, options)
    }

    // 
    // financial History
    public expenseswithPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('expenses', options)
    }
    // //
    public removeExpenses(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('expenses', id, options)
    }
    // //
    public createExpenses(id: number, data: {}, options = {}): Observable<any> {
        return this.API.mainPostBySubPath('expenses', id, 'realEstate', data, options)
    }
    //
    public editExpenses(id: string | number, data: {}, options = {}): Observable<any> {
        return this.API.mainPutById('expenses', id, data, options)
    }    
    
    // owner
    public createRealEstateOwner(data: {}): Observable<any> {
        return this.API.mainPost('owners', data)
    }

    public editRealEstateOwner(id: string | number, data: {}): Observable<any> {
        return this.API.mainPutById('owners', id, data)
    }

    public realestateOwners(options = {}): Observable<any> {
        return this.API.mainGetAll('owners', options)
    }

    public ownersWithOutPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('owners/withoutPagenation/get', options).pipe(
            map((v: any) => v?.owner)
        )
    }

    public removeRealestateOwner(id: string | number, options = {}): Observable<any> {
        return this.API.mainDeletetById('owners', id, options)
    }

    // report
    public realEstateReportById(id: string | number, options = {}): Observable<any> {
        return this.API.mainGetBySubPath('realEstates', id, 'realStateReport', options)
    }
    // ** End real Estate managment **//

    // Ads Request
    public AdsRequestById(id: string | number, options = {}) {
        return this.API.mainGetById('adsRequest', id, options).pipe(
            map((v: any) => v?.advertisement)
        )
    }

    public AdsRequestWithPagination(options = {}) {
        return this.API.mainGetAll('adsRequest', options)
    }

    public removeAdsRequest(id: string | number, options = {}) {
        return this.API.mainDeletetById('adsRequest', id, options)
    }     

    /* Start news */
    public realEstateNews(options = {}): Observable<any> {
        return this.API.mainGetAll('news', options)
    }

    public realEstateNewsById(id: string | number, options = {}) {
        return this.API.mainGetById('news', id, options).pipe(
            map((v: any) => v?.news)
        )
    }
    /* End news */

    /* Start contactRequest & chats */
    public contactRequestById(id: string | number): Observable<any> {
        return this.API.mainGetById('contactRequest', id).pipe(
            map((v: any) => v?.contactRequest)
        )
    }
    
    public contactRequestWithPagination(options = {}): Observable<any> {
        return this.API.mainGetAll('contactRequest', options)
    }

    public unreadMessages(options = {}): Observable<any> {
        return this.API.mainGetAll('messages/unseenCount', options).pipe(
            map((v: any) => v?.unseen)
        )
    }

    public  readingMessages(options = {}): Observable<boolean> {
        return this.API.mainPut('messages', null, options).pipe(
            map((v: any) => v?.success)
        )
    }

    public lastMessagesSent(options = {}): Observable<any> {
        return this.API.mainGetAll('messages', options)
    }

    public uploadFileToChat(data: {}, options = {}): Observable<any> {
        return this.API.mainPost('messages/upload', data, options)
    }
    /* End contactRequest & chats */

    // faq
    public Faq(options = {}): Observable<any> {
        return this.API.mainGetAll('questions', options).pipe(
            map((v: any) => v?.data)
        )
    }

    // contact us
    public sendcontactUsRequest(data: {}): Observable<any> {
        return this.API.mainPost('contact-us' , data)
    }

}