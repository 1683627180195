import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortCurrency'
})
export class shortCurrencyPipe implements PipeTransform {

    transform(number: number | string | null, args?: any): any {
        if (number === null) return null;
        if (number === 0) return '0 ريال ';
        if (isNaN(+number)) return null; // will only work value is a number
        let abs = Math.abs(+number);
        const rounder = Math.pow(10, 1);
        const isNegative = (number as number) < 0; // will also work for Negetive numbers
        let key = '';

        const powers = [
            {key: ' Q ', value: Math.pow(10, 15)},
            {key: ' ترليون ', value: Math.pow(10, 12)},
            {key: ' بليون ', value: Math.pow(10, 9)},
            {key: ' مليون ', value: Math.pow(10, 6)},
            {key: ' الف ', value: 1000}
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = Math.floor(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }
        return (isNegative ? ' - ' : ' ') + abs + (key ? key : ' ') + ' ريال ';
    }

}
