import { Pipe, PipeTransform } from '@angular/core';

// reusable
import { listOfUserType } from 'src/app/core/reusable-objects/globle-objs/user-types';

@Pipe({
  name: 'userTitle'
})
export class userTitlePipe implements PipeTransform {

  transform(value: string, ...args: any[]) {

    let v = listOfUserType.find((user: any) => {
        if (user.type == value) return user.name

    })

    return v ? v.name : 'غير مطابق'

  }

}
