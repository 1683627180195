export enum ELEMENT_FORM_TYPE {
    text = 'TEXT_INPUT',
    file = 'FILE_INPUT',
    email ='EMAIL_INPUT',
    select = 'SELECT_BOX',
    number = 'NUMBER_INPUT',
    password = 'PASSWORD_INPUT',
    checkbox = 'CHECK_BOX_INPUT',
    phoneNumber = 'PHONE_NUMBER',
    viewImg = 'VIEW_IMG_PREVIEW',
}