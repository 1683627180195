import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// env
import { environment } from 'src/environments/environment';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// enums
import { User } from 'src/app/core/enums/user/info-user';

@Component({
  selector: 'app-checkpoint-delete-account',
  templateUrl: './checkpoint-delete-account.component.html',
  styleUrls: ['./checkpoint-delete-account.component.scss']
})
export class CheckpointDeleteAccountComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  isSuccess!: IhttpError
  navigation!: Inavigation
  FormDeleteAccount!: UntypedFormGroup

  // boolean
  isLoading: boolean = false

  // env
  siteKey = environment.SIT_KEY

  // obj
  userInfo?: {[key: string]: any} 
  
  
  constructor(
    private G: GlobleService,
    private Auth: AuthService,
    private FB: UntypedFormBuilder,
    private API_RESPONSE: ApiResponseService,
  ) { 

    //
    this.userInfo = this.G.getUserData(User.info);

    //** init value DI class **/
    this.FormDeleteAccount = this.FB.group({
      des: [null],
      recaptcha: ['', Validators.required],
    })

  }

  ngOnInit(): void {

    // set value props
    this.navigation = {path: '/'}

  }

  checkpointDeleteAccount() {

    //
    this.isLoading = true;

    //
    const { id } = this.userInfo || {}

    this.API_RESPONSE.removeUser(id).subscribe(
      (res: any) => {

        //
        this.isLoading = false
        this.isSuccess = {errors: [], success: false}

        //
        this.Auth.logOut()

      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      }
    )    
  }

  recaptchaSuccess(a: any) {
    console.log('recaptchaSuccess')
  }

  ngOnDestroy(): void {}

}