<!-- start app-tabs -->
<div class="app_tabs">
    <ul class="list_none border_r d-block d-md-flex">
        <ng-container *ngFor="let tab of Tabs">
            <li class="text-center">
                <ng-container *ngIf="tab?.route; else manuallyTab">
                    <a 
                        [routerLink]="[tab.route]" 
                        routerLinkActive="active"
                        [queryParams]="tab?.queryParams"
                        [routerLinkActiveOptions]="{exact: true}"
                        class="app_tab a_none d-block label_lg_bold border_r effect" 
                        [queryParamsHandling]="tab?.queryParamsHandling || 'preserve'"
                        (click)="ActiveTabChange(tab)"
                    >
                        <ng-container *ngTemplateOutlet="tabContent; context: { title: tab?.title, note: tab.note }" />
                    </a>
                </ng-container>
                <ng-template #manuallyTab>
                    <div
                        (click)="ActiveTabChange(tab)"
                        [class.active]="ActiveTab == tab?.id" 
                        class="app_tab a_none d-block label_lg_bold border_r effect pointer"
                    >
                        <ng-container *ngTemplateOutlet="tabContent; context: { title: tab?.title, note: tab.note }" />
                    </div>
                </ng-template>
            </li>
        </ng-container>
    </ul>
</div>
<!-- start app-tabs -->
 
<!-- start tabContent -->
<ng-template #tabContent let-title="title" let-note="note">
    <span class="app_title">{{title}}</span>
    <small class="note text_sm_bold" *ngIf="noteCount > 0 && note">{{noteCount}}</small>
</ng-template>
<!-- end tabContent -->