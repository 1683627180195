import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-validation-input',
  templateUrl: './validation-input.component.html',
  styleUrls: ['./validation-input.component.scss']
})
export class ValidationInputComponent implements OnInit {

  // init props & inputs is coming
  @Input('text') text?: string = ''
  @Input('class') class: string = ''
  @Input('style') style: {} = {}

  constructor() { }

  ngOnInit(): void {
  }

}
