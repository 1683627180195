export const NumberOnly = (value: any, withOutZero?: boolean) => {

    // conver to string
    let str = value?.toString() as string;

    if (withOutZero) {
        if (str[0] == '0') return str.substring(1, str.length).replace(/\D/g, '')
    }
    
    // defualt return
    return str.replace(/\D/g, '')

}