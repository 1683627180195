import { Ivalidation } from "../../interfaces/form/validation";

export const Def_Msg_Validators = (min: number, max: number, pattern?: string) => {
    let msg_v: Ivalidation [] = [
        {typeValidation: 'pattern', text: pattern ? pattern : ''},
        {typeValidation: 'minlength', text: `اقل عدد من حرف ${min}`},
        {typeValidation: 'required', text: 'من فضلك يرجي مل هذا الحقل '},
        {typeValidation: 'maxlength', text: `حقل يحتوي علي اكثر من ${max} حرف`}
    ]

    //
    return msg_v
}