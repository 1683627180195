import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// enums
import { POSITION_VALUE } from 'src/app/core/enums/global/position-value';

// animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-float-drop-down',
  templateUrl: './float-drop-down.component.html',
  styleUrls: ['./float-drop-down.component.scss'],
  animations: [fade]
})
export class FloatDropDownComponent implements OnInit {

  // init props is coming
  @Input('Items') Items: any[];
  @Input('iconStyle') iconStyle?: {};
  @Input('dropDownPosition') dropDownPosition?: POSITION_VALUE
  

  // init props will come out to parent
  @Output() Open = new EventEmitter<any>();
  @Output() Close = new EventEmitter<any>();
  @Output() Action = new EventEmitter<any>();

  // init 

  // enums
  POSITION_VALUE = POSITION_VALUE

  // boolean
  isOpen: boolean = false

  constructor() { }

  ngOnInit(): void {
  }

  selectedTab(data: any) {
    this.Action.emit(data)
  }

  open() {
    
    if(!this.isOpen) {
      this.isOpen = true
      this.Open.emit()
    }

  }

  close() {

    if (this.isOpen) {
      this.isOpen = false
      this.Close.emit()
    }
  }

}
