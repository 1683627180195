import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener } from '@angular/core';


// enums
import { mainArtical } from 'src/app/core/enums/regx/regx-match';

@Directive({
  selector: '[matchLettersWithNumbers]'
})
export class MatchLettersNumbersOnlyDirective {

  @HostListener('input', ['$event']) replace (event: Event) {

    // el
    const element = event.target as HTMLInputElement | HTMLTextAreaElement;

    // ES6
    const { value } = element || {}

    //
    const replacedValue = value?.replace(new RegExp(mainArtical, 'gim'), '');

    // dir
    if (/^[A-z0-9]/gm.test(replacedValue)) element.setAttribute('dir', 'ltr')
    else if (/^[\u0621-\u064A\u0660-\u06690-9]/gm.test(replacedValue)) element.setAttribute('dir', 'rtl')

    if (this.ngControl) {
      this.ngControl.control?.patchValue(replacedValue)
    }
    else (event.target as HTMLInputElement | HTMLTextAreaElement).value = replacedValue
        
  }

  constructor(
    private ngControl: NgControl,
    private el: ElementRef<HTMLInputElement | HTMLTextAreaElement>
  ) { }

  ngOnInit(): void {}

}
