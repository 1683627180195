import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ValidationsPhone(limit: number): ValidatorFn {

    return (control: AbstractControl) => {
        
        // ES6 destructuring
        const { value } = control;

        // init
        let str: string = value?.toString(); 

        if (str != '' || str != null) {
            // handel Validator
            if (str && str.length < limit) return { min_length: `اقل عدد من الارقام ${limit}` }
        }

        return null

    }


}