<div 
    @fade
    class="terms_and_conditions" 
    (click)="closePup_up()" 
>
    <div class="wrap_close text-center">
        <app-close></app-close>
    </div>

    <div 
        class="wrap position-relative" 
        (click)="$event.stopPropagation()"
        [ngClass]="{has_accept_btn: acceptButton}"
    >
        <!-- // import app-loader -->
        <app-loader [sm]="true" size="fa-8x" *ngIf="loading" />

        <!-- // -->
        <div 
            class="wrap_content custom_scroll md"
            (scroll)="checkForScrollToEndOfView($event); $event.stopPropagation()"
        >
            <ng-container *ngIf="termsData?.terms">
                <h3 class="sub_title text-center"> {{termsTitle || 'الشروط والأحكام'}}</h3>
                <ul>
                    <li
                        textFormat
                        class="label_lg_normal"
                        [innerHTML]="termsData?.terms"
                    >
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="termsData?.privacy">
                <h3 class="sub_title text-center">{{privacyTitle || 'سياسه التطبيق'}}</h3>
                <ul>
                    <li 
                        textFormat
                        class="label_lg_normal"
                        [innerHTML]="termsData?.privacy"
                    >
                    </li>
                </ul>
            </ng-container>
        </div>
        <!-- // -->
        <div class="wrap_accept_btn text-center mt-3" *ngIf="acceptButton">
            <app-btn 
                title="موافق"
                [disabled]="!isReadDone"
                [call]="acceptTAndC.bind(this)"
            />
        </div>
    </div>
</div>