import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';

// custom services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { CountersService } from 'src/app/core/services/counters/counters.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

// custom enums
import { User } from 'src/app/core/enums/user/info-user';
import { usersType } from 'src/app/core/enums/user/user-type';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';

// animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [fade]
})
export class NavbarComponent implements OnInit, OnDestroy {

  // init props

  // random
  token: boolean | string | null = null

  // enums
  UrlRoute = UrlRoute
  usersType = usersType
  
  // boolean
  isOpenAds: boolean = false
  isOpenMenu: boolean = false
  isOpenProfileMdScreen: boolean = false

  // number
  unReadCountNotifi: number = 0
  adsRequestCount: number | null = 0
  contactRequestCount: number | null = 0

  // array
  unSubs: Subscription[] = []
  
  // obj
  userInfo: {[key: string]: any} | null = null

  //
  nglottieStyle: Partial<CSSStyleDeclaration> | null 

  constructor(
    public G: GlobleService,
    private Auth: AuthService, 
    private Counters: CountersService,
    private Notifi: NotificationService,
  ) { }

  ngOnInit(): void {

    this.nglottieStyle = {
      width: '60px',
      height: '50px',
      // transform: 'scale(2.5)'
    }    

    // liten to obvs to take condition
    let Token$ = this.Auth.Token.subscribe(token => this.token = token)

    let userInfo$ = this.Auth.userInfo.subscribe(info => {
      if (info) this.userInfo = info 
      else this.userInfo = null
    })
    
    let AdsRequestCount$ = this.Counters.CountAdsRequest.subscribe(count => this.adsRequestCount = count )

    let NotificationCount$ = this.Notifi.NotificationCount.subscribe(count => this.unReadCountNotifi = count )

    let CountContactRequest$ = this.Counters.CountContactRequest.subscribe(count => this.contactRequestCount = count )


    //
    this.unSubs.push(
      Token$,
      userInfo$,
      AdsRequestCount$,
      NotificationCount$,
      CountContactRequest$
    )

  }

  // if function run => state user info will show like popup
  // in md screen 
  openProfile() {
    this.isOpenProfileMdScreen = !this.isOpenProfileMdScreen
  }

  // if function run =>  info navbar will slide from right
  openMenu() {
    if (window.innerWidth < 720) this.isOpenMenu = !this.isOpenMenu
  }

  toggleAds(path: string | null, event?: Event) {
    
    
    if (event?.type == 'blur' && !path) this.isOpenAds = false
    else if (!path) this.isOpenAds = !this.isOpenAds
    else {
      this.G.navigate([path])
      this.isOpenAds = false
    }

  }

  // //
  logOut() {
    this.Auth.logOut()
  }
  
  ngOnDestroy(): void {
    this.unSubs.map(s => {
      
      if (s) s.unsubscribe()

    })
  }

}
