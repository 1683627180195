enum PERMISSION_KEY {
    allow = 'ALLOW',
    block = 'BLOCK'
}

export enum BROWSER_PERMISSION {
    AllowMic = PERMISSION_KEY.allow + '_MIC',
    BlockMic = PERMISSION_KEY.block + '_MIC',    
    AllowLocation = PERMISSION_KEY.allow + '_LOCATION',
    BlockLocation = PERMISSION_KEY.block + '_LOCATION',
    AllowNotification = PERMISSION_KEY.allow + '_NOTIFICATION',
    BlockNotification = PERMISSION_KEY.block + '_NOTIFICATION', 
}