import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// custome interfaces
import { ICity } from 'src/app/core/interfaces/location/city';
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { ITab } from 'src/app/shared.standalone/tabs/interfaces/tab';
import { InputField } from 'src/app/core/interfaces/form/input-field';

// custome Service
import { GlobleService } from 'src/app/core/services/globle.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

//
// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { ID_TYPE } from 'src/app/core/enums/user/id-types';
import { Countery } from 'src/app/core/enums/global/country';
import { usersType } from 'src/app/core/enums/user/user-type';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { agencyType } from 'src/app/core/enums/user/agency-type';
import { FILE_TYPE } from 'src/app/core/enums/global/files-types';
import { ELEMENT_FORM_TYPE } from 'src/app/core/enums/form/elements-types';
import { FULLNAME_LIMIT } from 'src/app/core/enums/form/controls/fullName-limit';
import { ID_NUMBER_LIMIT, LICENSE_NUMBER_LIMIT, BROKER_LICENSE_NUMBER_LIMIT } from 'src/app/core/enums/form/controls/length-limitation';

// static list
import { ListOfAgencyType } from 'src/app/core/reusable-objects/globle-objs/agency-types-list';

// validations
import PasswordValidators from 'src/app/core/validations/password/validators-password';
import { Validations_LettersOnly } from 'src/app/core/validations/validations-letters-only';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

// funcations-helper
import { EnglishWithNumbers } from 'src/app/core/funcations-helper/text-and-numbers/english-with-number';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpOfficeComponent implements OnInit {

  // init props & inputs is coming
  imgOffice: any
  commercialFile: any
  navigation!: Inavigation
  FormSingUpOffice!: UntypedFormGroup

  // string

  // boolean
  isLoading: boolean = false
  showTerms: boolean = false
  _isFreeWrok: boolean = false
  alertSuccess: boolean = false

  // enums
  getCode: string = Countery.code
  getIdCountry: string = Countery.id
  ELEMENT_FORM_TYPE = ELEMENT_FORM_TYPE

  // arrays
  hasErrors: any[] = []
  userTypeTabs: ITab[] = []
  listInput: InputField[] = []
  listFiles: { [key: string]: File } = {}
  countries: { [key: string]: any }[] = []

  constructor(
    private G: GlobleService,
    private route: ActivatedRoute,
    private FB: UntypedFormBuilder,
    private API_RESPONSE: ApiResponseService,
  ) { }

  ngOnInit(): void {
    
    //** init value DI class **/
    this.FormSingUpOffice = this.FB.group({
      img: [null],
      fullname: [null,
        [
          Validations_LettersOnly,
          OverrideValidators.required,
          OverrideValidators.maxlength(FULLNAME_LIMIT.max),
        ]
      ],
      email: [null, OverrideValidators.email],
      city: [null, Validators.required],
      phone: [null, Validators.required],
      idNumber: [
        null,
        [
          OverrideValidators.required,
          OverrideValidators.minlength(ID_NUMBER_LIMIT.min),
          OverrideValidators.maxlength(ID_NUMBER_LIMIT.max)
        ]
      ],
      licenseNumber: [
        null,
        [
          OverrideValidators.required,
          OverrideValidators.minlength(LICENSE_NUMBER_LIMIT.min),
          OverrideValidators.maxlength(LICENSE_NUMBER_LIMIT.max)
        ]
      ],
      brokerLicenseNumber: [
        null,
        [
          OverrideValidators.required,
          OverrideValidators.minlength(BROKER_LICENSE_NUMBER_LIMIT.min),
          OverrideValidators.maxlength(BROKER_LICENSE_NUMBER_LIMIT.max)
        ]
      ],
      commercialFile: [null],
      acceptTerms: [null, Validators.requiredTrue],
      idType: [ID_TYPE.office, Validators.required],
      agencyType: [agencyType.office, Validators.required],
      password: [null, [OverrideValidators.required, PasswordValidators.defaultVaidators()]],
    })    

    // set value props
    this.userTypeTabs = [
      { title: 'مستخدم', route: '/sign-up-user' },
      { title: 'معلن عقاري', route: '/sign-up-office' },
    ]

    this.navigation = { label: 'التسجيل', path: '/' }

    this.listInput = [
      {
        type: 'file',
        name: 'img',
        placeholder: '',
        disabledLabel: true,
        title: 'شعار المعلن',
        elementType: ELEMENT_FORM_TYPE.viewImg,
        validators: [
          { text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required' },
        ]
      },
      {
        type: 'text', name: 'fullname', placeholder: 'أكتب الاسم هنا ..', title: 'اسم المعلن', validatorsErrorMsg: true
      },
      {
        type: 'text',
        name: 'phone',
        title: 'رقم الجوال',
        placeholder: '5x xxx xxxx',
        elementType: ELEMENT_FORM_TYPE.phoneNumber
      },
      {
        type: 'password',
        name: 'password',
        title: 'كلمة المرور',
        validatorsErrorMsg: true,
        placeholder: 'أكتب كلمة المرور من هنا ..',
      },
      {
        type: 'email', name: 'email', placeholder: 'اكتب البريد الالكتروني هنا ..', title: 'البريد الالكتروني', validators: [
          { text: 'صيغة البريد غير صحيح', typeValidation: 'email' },
          // { text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required' },
        ]
      },
      {
        type: '',
        name: 'city',
        bindValue: 'id',
        bindLabel: 'cityName',
        placeholder: 'مدينة',
        items: this.listOfcities,
        title: 'عنوان المعلن-المدينة',
        notFoundText: "لا يوجد مدن متاحة!",
        elementType: ELEMENT_FORM_TYPE.select,
        validators: [
          { text: 'من فضلك تحديد مل هذا الحقل ', typeValidation: 'required' },
        ],
      },      
      {
        name: 'agencyType',
        bindLabel: 'name',        
        bindValue: 'value',        
        items: ListOfAgencyType,
        placeholder: 'نوع المعلن',
        title: 'نوع المعلن العقاري',
        elementType: ELEMENT_FORM_TYPE.select,
        validators: [
          { text: 'من فضلك تحديد مل هذا الحقل ', typeValidation: 'required' },
        ],
        callback: (selectedData = {}) => {

          // ES6 Destructuring
          const { value } = selectedData;

          setTimeout(() => {

            if (value == agencyType.freeWork) {

              //
              this.getControl('commercialFile').disable()
              this.getControl('idType').patchValue(ID_TYPE.citizen)
              this.getControl('licenseNumber').removeValidators(OverrideValidators.required)

              //
              this.isFreeWork = true

            }
            else {

              this.getControl('commercialFile').enable()
              this.getControl('idType').patchValue(ID_TYPE.office)
              this.getControl('licenseNumber').addValidators(OverrideValidators.required)

              //
              this.isFreeWork = false

            }

            //  
            this.getControl('licenseNumber').updateValueAndValidity()

          }, 1)

        }
      },
      {
        type: 'text', name: 'idNumber', placeholder: 'أكتب الرقم هنا ..', title: 'رقم الهوية',
        callback: (value: string) => {
          this.getControl('idNumber').setValue(EnglishWithNumbers(value))
        },
        validatorsErrorMsg: true
      },
      {
        type: 'number',
        name: 'licenseNumber',
        validatorsErrorMsg: true,
        title: this.licenseNumberTitle,
        placeholder: 'أكتب الرقم هنا ..',
      },
      {
        type: 'number',
        name: 'brokerLicenseNumber',
        placeholder: 'أكتب الرقم هنا ..',
        title: 'رقم ترخيص الوسيط (فال)',
        validatorsErrorMsg: true
      },
      {
        type: 'file', name: 'commercialFile', elementType: ELEMENT_FORM_TYPE.file, placeholder: '', title: 'السجل التجاري', validators: [
          { text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required' },
          { text: 'نوع الملف غير صالح', typeValidation: 'type' },
        ]
      },
    ]


  }

  public get isFreeWork(): boolean {
    return this._isFreeWrok
  }

  public set isFreeWork(value: boolean) {
    this._isFreeWrok = value
  }

  public get licenseNumberTitle(): string {

    if (this.isFreeWork) return 'رقم وثيقة العمل الحر  - ( اختياري )'
    return 'الرقم الموحد للمنشأة'

  }

  public get listOfcities(): ICity[] {
    
    // ES6 Destructuring
    const { cities } = this.route.snapshot.data;

    if (Array.isArray(cities) && cities.length > 0) return cities

    return []

  }


  // function will get all date about input
  getControl(name: string) {
    return this.FormSingUpOffice.controls[name]
  }

  // get info country from component drop-dows as outPut
  getCode_hasSelected(obj: { [key: string]: any }) {
    this.getIdCountry = obj.id
    this.getCode = obj.countryCode
  }

  getFileImgs(file: File) {

    if (file && file?.type.includes('image')) this.listFiles['img'] = file
    else delete this.listFiles['img']

  }

  getCommercialFile(file: File) {

    if (file && (
      file.type.includes(FILE_TYPE.pdf) ||
      file.type.includes(FILE_TYPE.text) ||
      file.type.includes(FILE_TYPE.word) ||
      file.type.includes(FILE_TYPE.img)
    )
    ) {

      this.listFiles['commercialFile'] = file
      this.getControl('commercialFile').setValue('/')

    }
    else if (file == null) {
      delete this.listFiles['commercialFile']
      this.getControl('commercialFile').setValue(null)
    }
    else {
      this.getControl('commercialFile').setErrors({ type: true })
    }

  }

  success() {
    this.alertSuccess = false

    // navigate & hashing data
    this.G.navigate(['verify'], {
      countdown: true,
      phone: this.G.encryptData(UrlRoute.QUERY_PARAM, this.getCode + this.FormSingUpOffice.value.phone)
    })
  }

  // function auth sign up user
  singUpUser() {

    this.isLoading = true

    this.hasErrors = []

    var formData = new FormData();

    for (const key in this.FormSingUpOffice.value) {
      if (this.FormSingUpOffice.value[key]) {
        if (key == 'phone') {
          formData.append(key, this.getCode + this.FormSingUpOffice.value[key])
        }
        else if (key == 'img') {
          formData.append(key, this.listFiles[key])
        }
        else if (key == 'commercialFile') {
          formData.append(key, this.listFiles[key])
        } else {
          formData.append(key, this.FormSingUpOffice.value[key])
        }
      }
    }

    formData.append('type', usersType.office)
    formData.append('country', this.getIdCountry)

    // after get id country => post sign up data  
    this.API_RESPONSE.signUp(formData).subscribe(
      (res: any) => {

        this.isLoading = false

        this.alertSuccess = true

        // save data in localStorage
        this.G.saveUserData(User.info, res.user)
      },
      (err: HttpErrorResponse) => {

        this.isLoading = false
        console.log(err.error)
        if (err.status == 0) this.hasErrors.push({ msg: ` 1mb حجم الملف اكبر من ` })
        else this.hasErrors.push(...err.error.errors)
      },
    )
  }

}
