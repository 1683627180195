import { UntypedFormGroup, ValidationErrors, AbstractControl, Validators } from '@angular/forms';

export function Validations_Property(required: boolean) {

    return (control: AbstractControl): ValidationErrors | null => {
        
        let group = control as UntypedFormGroup
        
        const { from, to } = group.controls;
    
        //
        if (from.value) {
            to.markAsTouched()
            to.setValidators([Validators.required, Validators.min(1)])
            to.updateValueAndValidity({onlySelf: true})
        }
        if (to.value) {
            from.markAsTouched()
            from.setValidators([Validators.required, Validators.min(1)])
            from.updateValueAndValidity({onlySelf: true})            
        }
        if (!from.value && !to.value && !required) {
            to.clearValidators()
            from.clearValidators()
            to.updateValueAndValidity({onlySelf: true})
            from.updateValueAndValidity({onlySelf: true})    
        }

        //
        if (Number(from.value) > Number(to.value) && to.value) return { inverse:  'قيمة الحقول  معكوسه'}
        else if (Number(from.value) == Number(to.value) && from.value && to.value ) return { equal:  'قيمة الحقول  متساوية'}
    
        return null
    }

}