import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

// environments
import { environment } from 'src/environments/environment';

// enums
import { FAQ_TYPE } from 'src/app/core/enums/global/FAQ_TYPE';
import { FILE_TYPE } from 'src/app/core/enums/global/files-types';

// services
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// funcations helper
import { scrollToElement } from 'src/app/core/funcations-helper/Dom/scroll-to-element';

// animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [fade]
})
export class FaqComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  faqData: any
  navigation!: Inavigation

  //environment
  app_url = environment.APP_URL

  // boolean
  showShareBtn: number = 0

  constructor(
    private route: ActivatedRoute,
    private Analytics: AnalyticsService,
    private API_RESPONSE: ApiResponseService,
  ) { }

  ngOnInit(): void {

    // set value props
    this.navigation = {path: '/'}

    //
    this.Analytics.logEvent('FaqScreen')

    this.route.queryParams.subscribe(queryParams => {

      // ES6 Destrucating
      const { question, ...params } = queryParams;
  
      //
      this.API_RESPONSE.Faq({ params }).subscribe((res: any) => {
  
        //
        this.faqData = res;
  
        setTimeout(() => {
  
          let el = document.getElementById(question);
  
          el?.classList.add('active')
  
          scrollToElement(question)
  
        }, 500)
  
      })

    })

    
  }

  // getters
  
  /**
   * 
   */
  public get File_Type(): typeof FILE_TYPE {
    return FILE_TYPE
  }

  /**
   * FaqType
   */
  public get FaqType(): FAQ_TYPE {
    return this.route.snapshot.queryParams?.type
  }

  /**
   * FaqPageTitle
   */
  public get FaqPageTitle(): string {
    switch(this.FaqType) {
      case FAQ_TYPE.Market:
        return 'دليل خدمة سوق';
      case FAQ_TYPE.Default:
      default:
        return 'خدمات تثقيفية';
    }
  }


  /**
   * 
   * @param id question Id
   */
  toggelShare(id: number) {

    if (this.showShareBtn) this.showShareBtn = 0
    else this.showShareBtn = id

  }

  ngOnDestroy(): void {}

}
