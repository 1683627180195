import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome enums
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// custome reusable
import PasswordValidators from 'src/app/core/validations/password/validators-password';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  getPhone!: string
  isSuccess!: IhttpError
  navigation!: Inavigation
  isLoading: boolean = false
  listInput: InputField[] = []
  alertSuccess: boolean = false
  FormResetPassword!: UntypedFormGroup

  constructor(
    private FB: UntypedFormBuilder,
    private G: GlobleService,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private API_RESPONSE: ApiResponseService,
  ) { 
    
    // data from route
    if (this.route.snapshot.queryParams) {
      // get queryParams
      this.getPhone = this.G.decryptData(UrlRoute.QUERY_PARAM, this.route.snapshot.queryParams['phone'])
    }

    //** init value DI class **/
    // set value props
    this.FormResetPassword = this.FB.group({
      phone: [this.getPhone, Validators.required],
      newPassword: [null, [OverrideValidators.required, PasswordValidators.defaultVaidators()]],
      confirmPassword: [null, [OverrideValidators.required, PasswordValidators.confirmPassword('newPassword', 'confirmPassword')]],
    })        

  }

  ngOnInit(): void {


    this.navigation = {label: 'نسيت كلمة المرور', path: '/sign-in-user'}


    this.listInput = [
      {
        type: 'password', 
        name: 'newPassword',
        title: 'كلمة المرور',
        placeholder: 'كلمة المرور الجديدة',
      },
      {
        title: '',
        type: 'password',
        name: 'confirmPassword',
        placeholder: 'تأكيد كلمة المرور الجديدة',
      },      
    ]    

  }

  success() {
    this.alertSuccess = false
    this.G.navigate(['sign-in-user'])  
  
  }   

  resetPassword() {
    
    this.isLoading = true

    this.API_RESPONSE.resetPassword(this.FormResetPassword.value).subscribe(
      (res: any) => {
        this.isLoading = false
        this.alertSuccess = true
      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      }      
    )
  }

  ngOnDestroy(): void {
    this.Auth.setStepsForgotPassword(1)
  }
}
