<a 
    class="notifi_card d-flex align-items-center justify-content-between a_none pointer"
>
    <div class="wrap_main_saide">
        <span class="label_lg_bold title float-start">
            {{Info?.title}}
        </span>
        <span class="text_md_normal data float-end dir_left">
            {{Info?.createdAt == undefined ? 'منذ قليل' : (Info?.createdAt| date: 'MM/dd/yyy | h:mm a')}}
        </span>
        <div class="clearfix"></div>
        <p class="des mb-0" textFormat [innerHTML]="Info?.description ? Info?.description : Info?.body"></p>
    </div>
    <div class="wrap_icon">
        <img src="/assets/img/arrow.svg" alt="">
    </div>
</a>