<!-- start app-box-textarea -->

<ng-container *ngIf="ngControl; else Else">
    <textarea 
        name="more_Info" 
        [value]="fieldValue"
        [disabled]="disabled"
        (input)="change($event)"
        [ngClass]="{'vd': ngControl.valid && ngControl.value?.length > 0}"
        class="text_area border_def_2th label_lg_normal border_r_2th custom_scroll" 
        [placeholder]="placeholder ? placeholder : 'من فضلك ادخل المزيز من التفاصيل سوف تساعدم جدا!'"
    ></textarea>

</ng-container>

<ng-template #Else>
    <textarea 
        #area
        [style]="Style"
        name="more_Info" 
        [value]="fieldValue"
        [disabled]="disabled"
        (input)="change($event)"
        [ngClass]="{'vd': area.value.length > 0}"
        class="text_area border_def_2th label_lg_normal border_r_2th custom_scroll" 
        [placeholder]="placeholder ? placeholder : 'من فضلك ادخل المزيز من التفاصيل سوف تساعدم جدا!'"
    ></textarea>
</ng-template>

<!-- // show if validations have errors -->
<ng-container *ngIf="validations">
    <ng-container *ngFor="let validation of validations">
        <!-- import app-validation-input -->
        <app-validation-input
            [text]="validation.text"
            *ngIf="
            ngControl?.errors
            && 
            ngControl?.touched 
            && 
            ngControl?.errors[validation.typeValidation]
            "
        >
        </app-validation-input>
    </ng-container>
</ng-container>
<!-- end app-box-textarea -->