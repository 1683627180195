import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { ICustomAlert } from 'src/app/core/interfaces/alert/custom-alert';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private isOpen: boolean = false;
    private alert$ = new Subject<boolean | ICustomAlert>()

    constructor () {}

    private get IsOpen(): boolean {
        return this.isOpen
    }

    private set IsOpen(value: boolean) {
        this.isOpen = value
    }

    public get alert() {
        return this.alert$
    }
    
    public show(info?: ICustomAlert) {
        //
        if (this.IsOpen) this.hide()

        //
        this.IsOpen = true

        //
        this.alert$.next(info ? info : true)
    }

    public hide() {
        this.IsOpen = false
        this.alert$.next(false)
    }

}