import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// custome interfaces
import { usersType } from 'src/app/core/enums/user/user-type';
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { ITab } from 'src/app/shared.standalone/tabs/interfaces/tab';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// custome enums
import { Countery } from 'src/app/core/enums/global/country';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';

// custome Service
import { GlobleService } from 'src/app/core/services/globle.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome reusable
import { Def_Msg_Validators } from 'src/app/core/reusable-objects/validators/default-msg-v';

// validations
import PasswordValidators from 'src/app/core/validations/password/validators-password';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpUserComponent implements OnInit {

  // init props & inputs is coming
  isSuccess!: IhttpError
  navigation!: Inavigation
  isLoading: boolean = false
  FormSingUpUser!: UntypedFormGroup
  userTypeTabs: ITab[] = []
  listInput: InputField[] = []
  showTerms: boolean = false
  alertSuccess: boolean = false
  getCode: string = Countery.code
  countries: {[key: string]: any}[] = []
  getIdCountry: string | number = Countery.id

  constructor(
    private FB: UntypedFormBuilder,
    private G: GlobleService,
    private API_RESPONSE: ApiResponseService
    ) { 
    //** init value DI class **/
    this.FormSingUpUser = this.FB.group({
      type: [usersType.normalUser],
      phone: [null,  Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
      password: [null, [OverrideValidators.required, PasswordValidators.defaultVaidators()]],
    })
    

  }

  ngOnInit(): void {

    // set value props
    this.userTypeTabs = [
      {title: 'مستخدم', route: '/sign-up-user'},
      {title: 'مكتب عقاري', route: '/sign-up-office'},
    ]

    this.navigation = {label: 'التسجيل', path: '/'}

    this.listInput = [
      {
        type: 'text',
        name: 'phone',
        title: 'رقم الجوال', 
        placeholder: '5x xxx xxxx',
        validators: Def_Msg_Validators(9, 10,' ادخل رقم الجوال صحيح بدون حروف')
      },
      {
        type: 'password',
        name: 'password',
        title: 'كلمة المرور',
        validatorsErrorMsg: true,
        placeholder: 'أكتب كلمة المرور من هنا ..',
      },
    ]

  }

  // function will get all date about input
  getControl(name: string) {
    return this.FormSingUpUser.controls[name]
  }

  // get info country from component drop-dows as outPut
  getCode_hasSelected(obj: {[key: string]: any}) {
    this.getIdCountry = obj.id
    this.getCode = obj.countryCode
  }

  success() {
    this.alertSuccess = false

    // navigate & hashing data
    this.G.navigate(['verify'], {
      countdown: true,
      phone: this.G.encryptData(UrlRoute.QUERY_PARAM, this.getCode + this.FormSingUpUser.value.phone)
    })
  }
  
  // function auth sign up user
  singUpUser() {
    this.isLoading = true

    // es6 destructuring
    // let { id } = res.data[1];

    // spride ECMAScript 2018
    let Formdata = {
      ...this.FormSingUpUser.value,
      country: this.getIdCountry,
      phone: this.getCode + this.FormSingUpUser.value.phone
    }

    // after get id country => post sign up data  
    this.API_RESPONSE.signUp(Formdata).subscribe(
      (res: any) => {
        // es6 destructuring
        this.isLoading = false
        this.alertSuccess = true
      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      },
    )
  }

}
