import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// services
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  aboutData: any
  navigation!: Inavigation
  obs: Subscription = new Subscription;

  constructor(
    private Analytics: AnalyticsService,
    public TopLevelApi: TopLevelSingleService,
  ) { }

  ngOnInit(): void {

    this.navigation = {path: '/'}

    //
    this.Analytics.logEvent('AboutScreen')

  }

  ngOnDestroy(): void {
    // stop listen
    this.obs.unsubscribe()
  }

}
