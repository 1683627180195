import { Pipe, PipeTransform } from '@angular/core';
import { listOfRentType } from 'src/app/core/reusable-objects/globle-objs/rent-list';

@Pipe({
  name: 'rentType'
})
export class rentTypePipe implements PipeTransform {

  transform(value: string, ...args: any[]) {

    let v = listOfRentType.find((rent: any) => {
        if (rent.id == value) return rent.name

    })

    return v ? v.name : 'غير متابق'

  }

}
