<app-success
    *ngIf="alert"
    titleBtn="موافق" 
    (Submit)="alert = false"
    [msg]="'لا تتوفر تطبيقات في الوقت الحالي انتظرونا قريبا!'"
>
</app-success>

<!-- start section header -->
<section class="header  d-md-flex align-items-center">
    <div class="custom_container">
        <div class="wrapper d-block d-md-flex align-items-center">
            <div class="wrap wrap_start_up float-start text-center text-md-start my-4 my-md-0">
                <h1 class="title">هل تريد بيع أو شراء عقار جديد؟</h1>
                <h2 class="sub_title">مع ساعي ستجد كل ماتريد وأكثر ..</h2>
                <div class="wrap_btn">
                    <app-btn 
                        type='none'
                        title="لنبدأ" 
                        *ngIf="!userAuth"
                        routerLink="sign-up-user"
                        [style]="{'line-height': '0', 'min-height': '40px'}"
                    >
                    </app-btn>
                    <span *ngIf="userAuth" class="welcome d-inline-block border_r text-center">
                        مرحبا
                    </span>
                </div>
                <div class="wrao_store_apps">
                    <a [href]="(TopLevelApi.about$ | async)?.androidAppLink" target="_blank">
                        <img 
                            alt="icon_google_play"
                            class="img-fluid pointer" 
                            src="/assets/img/icon_google_play.png" 
                        >
                    </a>
                    <a [href]="(TopLevelApi.about$ | async)?.iosAppLink" target="_blank">
                        <img 
                            alt="icon_app_store"
                            class="img-fluid pointer" 
                            src="/assets/img/icon_app_store.png" 
                        >
                    </a>
                </div>
            </div>
            <div class="wrap wrap_img_app float-start text-center text-md-end my-4 my-md-0">
                <img class="img-fluid" src="/assets/img/img_app_saaei.png" alt="img app saaei">
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
    <!-- end section header -->