<!-- import app-success -->
<app-success
    titleBtn="تسجيل الدخول" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="'تم تغير كلمة المرور بشكل ناجح'"
>
</app-success>

<!-- start app-reset-password -->
<div class="rest_password def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container">
            <form [formGroup]="FormResetPassword" (ngSubmit)="resetPassword()">
                <ng-container *ngFor="let Input of listInput">
                    <!-- import app-label -->
                    <app-label 
                        *ngIf="Input.title"
                        [for]="Input.name"
                        [title]="Input.title" 
                        [style]="{color: '#fff'}"
                    >
                    </app-label>
        
                    <!-- import app-input-text  -->
                    <app-input-text 
                        [type]="Input.type" 
                        [name]="Input.name" 
                        [validatorsErrorMsg]="true"
                        [FormParent]="FormResetPassword"
                        [placeholder]="Input.placeholder"
                    >
                    </app-input-text>
                </ng-container>
    
                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="isSuccess">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let validator of isSuccess?.errors">
                                <app-validation-input 
                                    [text]="validator.msg"
                                    *ngIf="!isSuccess.success"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                
                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn 
                        title="حفظ" 
                        [submit]="true"
                        [disabled]="FormResetPassword.invalid || isLoading" 
                    >
                    </app-btn>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-reset-password -->