export const ArrayHasSameValue = (arr: any[], compareValue: any, deepIn?: []): boolean | null => {
    
    if (Array.isArray(arr)) {

        const state = arr.some(it => it == compareValue);
        
        return state

    }

    console.warn(`first Arg not Array => ${arr}`)
    return null
}