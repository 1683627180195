<div 
    tabindex="-1"
    (click)="
        $event.preventDefault();
        $event.stopPropagation()
    "
    (blur)="close()"
    class="float_drop_down position-relative"
>
    <div class="wrap_icon">
        <i
            [style]="iconStyle"
            (click)="isOpen ? close() : open()"
            class="fas fa-ellipsis-v pointer effect d-block" 
        >
        </i>
        <ul 
            @fade
            *ngIf="isOpen"
            class="list_none def_shadow"
            [ngClass]="{
                'left': dropDownPosition == POSITION_VALUE.left,
                'right': dropDownPosition == POSITION_VALUE.right
            }"
        >
            <li
                (click)="
                    close();
                    selectedTab(tab)
                "
                *ngFor="let tab of Items"
            >
                {{tab?.name}}
            </li>
        </ul>
    </div>
</div>