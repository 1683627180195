import { Component, OnInit } from '@angular/core';

// services
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private Analytics: AnalyticsService) { }

  ngOnInit(): void {

    //
    this.Analytics.logEvent('HomeScreen')    

  }

}
