import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

// custome interfaces
import { Ivalidation } from 'src/app/core/interfaces/form/validation';

// enums
import { HTTP_EVENT_TYPE } from 'src/app/core/enums/http-request/httpEventType';

@Component({
  selector: 'app-input-img',
  templateUrl: './input-img.component.html',
  styleUrls: ['./input-img.component.scss']
})
export class InputImgComponent implements OnInit, OnChanges {

  // init props & inputs is coming
  @Input('name') name: string = '';
  @Input('title') title: string = '';
  @Input('icon') urlIcon: string = '';
  @Input('FormParent') FormParent!: UntypedFormGroup
  @Input('multiple') multiple: boolean = false
  @Input('disabled') disabled: boolean = false;
  @Input('limitFiles') limitFiles: number = 0;
  @Input('defaultData') defaultData: any[] = []
  @Input('validations') validations: Ivalidation[] = []  
  @Input('fileType') fileType?: {accept: string, mainType: string}
  @Input('uploadProgress') uploadProgress: number | HTTP_EVENT_TYPE | null = null

  // init data will outPut
  @Output() files = new EventEmitter()

  // init props
  errMsg: string = ''
  limit: boolean = false
  viewFileHasSelected: any[] = []
  HTTP_EVENT_TYPE = HTTP_EVENT_TYPE

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.defaultData.length > 0) this.viewFileHasSelected = this.defaultData;

    if (this.uploadProgress == HTTP_EVENT_TYPE.errorResponse) this.viewFileHasSelected = []

  }

  ngOnInit(): void {
  }

  // function will catch all errors in input
  get getError() {
    return (this.FormParent.controls[this.name].errors as ValidationErrors)
  }

  // function will get all date about input
  get getControl() {
    return this.FormParent.controls[this.name]
  }

  removeFile() {

  }

  reset() {
    this.errMsg = ''
    this.limit = false
    this.viewFileHasSelected = []
    if (this.FormParent) this.getControl.reset()
  }
  
  touched() {
    this.files.emit(null)
  }

  upLoadFile(event: Event) {
    
    if (event.type == 'change') {

      let arr: any[] = []

      // get data 
      const files = (event.target as HTMLInputElement).files;
      
      if (files?.length) {

        // loop
        for (let i = 0; i < files.length; i++) {
          if (this.fileType) {
            if (files.item(i)?.type?.includes(this.fileType?.mainType)) arr.push(files.item(i))
          }
          else arr.push(files.item(i))
        }
          
        // outPut data
        if (this.multiple) {
  
          if (arr.length > this.limitFiles)  arr.splice(this.limitFiles, arr.length - 1)

          if (arr.length > 0) {
            this.errMsg = ''
            this.viewFileHasSelected = arr
            this.files.emit(this.viewFileHasSelected)
          }
          else this.errMsg = `نوع الملف المطلوب هو ${this.fileType?.accept}`

        }
        else {
          if (this.fileType) {
            if (arr[0].type.includes(this.fileType?.mainType)) {
              this.errMsg = ''
              this.files.emit(arr[0])
              this.viewFileHasSelected = arr
            }
            else {
              this.errMsg = `نوع الملف المطلوب هو ${this.fileType.accept}`
            }

          }
          else {
            this.files.emit(arr[0])
            this.viewFileHasSelected = arr
          }

        }
        
      }

    } 
    else {
      this.files.emit(null)
    }
    
  }

}
