import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

// custome services
import { GlobleService } from '../../services/globle.service';

// custome enums
import { User } from '../../enums/user/info-user';
import { AuthService } from '../../services/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthGuard implements CanActivate {

  constructor(
    private Auth: AuthService,
    private G: GlobleService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      try {
        const token = this.G.getUserData(User.token) || null

        if (token) return true;

        else {
          
          //
          this.Auth.logOut({ withoutApiRequest: true })

          // navigate to home
          this.G.navigate(['/sign-in-user'])

          return false
        } 

      }
      catch {
        // navigate to home
        this.G.navigate(['/sign-in-user'])        
      }
      

  }
  
}
