import { AbstractControl, NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

// funcations
import { parseArabic } from 'src/app/core/funcations-helper/text-and-numbers/parseArabic';

@Directive({
  selector: '[parseArabicToEnglish]'
})
export class ParseArabicToEnglishDirective implements OnInit {


  /**
   * decorators
   */
  @HostListener('input') replace() {

    // ES6 Des
    const { value } = this.el.nativeElement;

    //
    const parse = parseArabic(this.abstractControl ? this.abstractControl.value : value)

    if (this.abstractControl) {

      this.abstractControl.setValue(parse)
    }
    else this.el.nativeElement.value = parse

  }

  /**
   * fields
   */
  abstractControl: AbstractControl | null

  /**
   * 
   * @param ngControl 
   * @param el 
   */
  constructor(
    private ngControl: NgControl,
    private el: ElementRef<HTMLInputElement>,
  ) { }

  /**
   * 
   */
  ngOnInit(): void {

    this.el.nativeElement.setAttribute('dir', 'auto')

    if (this.ngControl) this.abstractControl = this.ngControl.control

  }

}
