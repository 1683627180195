export const limitCharacters = (value: string, limit: number) => {
    
    let characters = value?.toString();

    if (characters || characters?.length > limit) {

        return characters.slice(0, limit);
    }

    return ''

}