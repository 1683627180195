import { UntypedFormGroup, ValidationErrors, AbstractControl, Validators } from '@angular/forms';

export function Validations_Comparison(controlsName: string[], required?:boolean) {

    return (control: AbstractControl): ValidationErrors | null => {
        
        // force defined interface
        let group = control as UntypedFormGroup

        // ES6 Destructiong Array to get names by index
        const [ from_control, to_cotrol, main ] = controlsName;

        // get controls
        const to = group.get(to_cotrol) as AbstractControl;
        const from = group.get(from_control) as AbstractControl;
    
        // if current control has change
        // We'll do some interactions with his other sibling control
        // In order to be properly filled
            if (from.value) {
                to.markAsTouched()
                to.setValidators([Validators.required, Validators.pattern('^[0-9,]+$')])
            }
            else to.markAsUntouched()

            if (to.value) {
                from.markAsTouched()
                from.setValidators([Validators.required, Validators.pattern('^[0-9,]+$')])
            }
            else from.markAsUntouched()
        
        // if siblings controls not has any value
        // and controls not required => run metthod clearValidators
        if (!from.value && !to.value && !required) {
            to.clearValidators()
            from.clearValidators()
        }        
        
        // after detect any change
        // we will run method updateValueAndValidity
        // to detect any change to view
        to.updateValueAndValidity({onlySelf: true})
        from.updateValueAndValidity({onlySelf: true})

        // here we convert controls value to string and
        // and remove any comma from string
        // and finally convert string to number
        // to To facilitate the comparison of values
        /*
            // notes
            why we convert value to string
            because method replace dont work with number
            At the same time, avoid errors
        */
        let str_to = parseInt(to.value?.toString().replace(/\D/g,''));
        let str_from = parseInt(from.value?.toString()?.replace(/\D/g,''));

        // retuen object error or null
        if (str_from > str_to && str_to) return { ['inverse_' + main]:  'قيمة الحقول  معكوسه'}
        else if (str_from == str_to && str_from && str_to ) return { ['equal_' + main]:  'قيمة الحقول  متساوية'}
        
        return null
    }

}