import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { NafathService } from 'src/app/core/services/user/nafath.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome enums
import { usersType } from 'src/app/core/enums/user/user-type';
import { agencyType } from 'src/app/core/enums/user/agency-type';
// import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { PAYMENT_AUTH_RES } from 'src/app/core/enums/payment/authorize-response';
import { FULLNAME_LIMIT } from 'src/app/core/enums/form/controls/fullName-limit';
import { BROKER_LICENSE_NUMBER_LIMIT, ID_NUMBER_LIMIT, LICENSE_NUMBER_LIMIT } from 'src/app/core/enums/form/controls/length-limitation';

// directives
import { NgxViewerDirective } from 'src/app/core/directives/ngx-viewer/ngx-viewer.directive';

// custome interfaces
import { ICity } from 'src/app/core/interfaces/location/city';
// import { IArea } from 'src/app/core/interfaces/location/area';
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';
// import { ICategory } from 'src/app/core/interfaces/category/category';

// reusable
import { listOfIdType } from 'src/app/core/reusable-objects/globle-objs/id-types-user';

// validations
import { Validations_LettersOnly } from 'src/app/core/validations/validations-letters-only';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

// functions helper
import { NumberOnly } from 'src/app/core/funcations-helper/Numbers-only';
import { EnglishWithNumbers } from 'src/app/core/funcations-helper/text-and-numbers/english-with-number';


@Component({
  selector: 'app-profile-office',
  templateUrl: './profile-office.component.html',
  styleUrls: ['./profile-office.component.scss']
})
export class ProfileOfficeComponent implements OnInit {
  
  //
  @ViewChild('appNgxViewer') ngxViewer: NgxViewerDirective;

  // init props & input is coming

  // enums
  usersType = usersType

  // interfaces
  navigation!: Inavigation
  FormProfileOffice!: UntypedFormGroup
  
  // any
  getFile: any

  // string
  id: string = ''
  alertMessage: string = ''
  viewImgHasSelected: string = ''
  
  // obj
  userInfo!: IuserInfo
  setFormObj: {[key: string ]: any} = {}  
  
  // array of any
  errorsMsg: any[] = []
  /**
   * we will move those function for anther component => ads request setting
   */
  // categories: any[] = []
  // listOfAreas: any[] = []
  // subCategories: any[] = []  
  listOfcities: any[] = []
  listOfIdType = listOfIdType
  
  // boolean
  realty: boolean = false
  editable: boolean = false
  isLoading: boolean = false
  fullScreen: boolean = false
  alertFailure: boolean = false
  alertSuccess: boolean = false
  isFormHasChange: boolean = false
  /**
   * we will move those function for anther component => ads request setting
   */
  // loadingWorkArea: boolean = false
  // loadingworkSubCategory: boolean = false  

  // functions
  NumberOnly = NumberOnly
  EnglishWithNumbers = EnglishWithNumbers



  constructor(
    private G: GlobleService,
    private Alert: AlertService,
    private route: ActivatedRoute,
    private FB: UntypedFormBuilder,
    private API_RESPONSE: ApiResponseService,
    
    // public
    public Auth: AuthService,
    public Nafath: NafathService,
  ) { 


  }
  

  ngOnInit(): void {
    
    // ES6 Destructing
    const { 
      cities,
      userInfo,
    } = this.route.snapshot.data as {
      cities: ICity[],
      userInfo: IuserInfo,
    }
    
    //
    this.userInfo = userInfo

    //
    this.listOfcities = cities


    // get params
    this.route.queryParams.subscribe(res =>  {
      if (res['realty']) {
        this.realty = true
        this.editable = true
        this.alertFailure = true
      }
    })

    //

    //** init value DI class **/
    this.FormProfileOffice = this.FB.group(
      {
        bio: [null],
        img: [null],
        city: [null, Validators.required],
        idType: [null, Validators.required],
        /**
         * we will move those function for anther component => ads request setting
         */
        // workArea: [null, Validators.required],
        // workCity: [null, Validators.required],
        idNumber: [
          null,
          [
            OverrideValidators.required,
            OverrideValidators.minlength(ID_NUMBER_LIMIT.min),
            OverrideValidators.maxlength(ID_NUMBER_LIMIT.max)
          ]          
        ],      
        licenseNumber: [
          null,
          [
            OverrideValidators.required,
            OverrideValidators.minlength(LICENSE_NUMBER_LIMIT.min),
            OverrideValidators.maxlength(LICENSE_NUMBER_LIMIT.max)
          ]
        ],
        brokerLicenseNumber: [
          null,
          [
            OverrideValidators.required,
            OverrideValidators.minlength(BROKER_LICENSE_NUMBER_LIMIT.min),
            OverrideValidators.maxlength(BROKER_LICENSE_NUMBER_LIMIT.max)
          ]          
        ],      
        /**
         *  we will move those function for anther component => ads request setting
         * */  
        // workCategory: [null, Validators.required],
        // workSubCategory: [null, Validators.required],
        email: [null, OverrideValidators.email],
        type: [usersType.office, Validators.required],
        fullname: [null, [Validators.required, Validators.maxLength(FULLNAME_LIMIT.max) ,Validations_LettersOnly]],
      },
    ) 

    // set value proparty
    this.navigation = {label: 'تعديل ملفي', path: '/'}    

    // ES6 Destructing
    const {
      bio,
      email,
      idType,
      idNumber,
      fullname,
      licenseNumber,
      city: { id: cityId },
      agencyType: agency,
      brokerLicenseNumber,

      //
      workArea,
      workCity,
      workCategory,
      workSubCategory
    } = this.userInfo || {}


    // patch Form
    this.FormProfileOffice.patchValue({
      bio: bio,
      email: email,
      city: cityId,
      idType: +idType,
      idNumber: idNumber,
      fullname: fullname,
      agencyType: agencyType,
      licenseNumber: licenseNumber || null,
      brokerLicenseNumber: brokerLicenseNumber || null
    })

    if (agency == agencyType.freeWork) {
      this.getControl('licenseNumber').removeValidators(OverrideValidators.required)
      this.getControl('licenseNumber').updateValueAndValidity()
    }

    //
    this.cloneCurrentFormValue()

    // watch FormUpdateProfile if has any change
    this.FormProfileOffice.valueChanges.subscribe(res => {
      
      // loop in value FormUpdateProfile
      // and return any action if user update his info
      for (const key in this.setFormObj) {

        if (JSON.stringify(this.setFormObj[key]) == JSON.stringify(res[key])) {
          this.isFormHasChange = false
        } else {
          this.isFormHasChange = true
          break;
        }
      }
    })   
    
    this.Auth.userInfo.subscribe(res => {
      // disabled
      if (res?.isVerifiedIdNumber && this.getControl('idNumber')?.valid) {
        this.getControl('idNumber').disable()
      }
    })

  }

  // getters
  public get isFreeWork(): boolean {
    return (this.userInfo?.parentAgencyType || this.userInfo?.agencyType) == agencyType.freeWork
  }

  public get licenseNumberTitle(): string {

    if (this.isFreeWork) return 'رقم وثيقة العمل الحر - ( اختياري )'
    return 'الرقم الموحد للمنشأة'
    
  }

  public get submitDisabled(): boolean {

    if (
      this.FormProfileOffice.invalid
      ||
      this.FormProfileOffice.pending
      ||
      this.isLoading
    ) return true

    //
    return false

  }

  //
  public paymentCallback(res: any) {
    
    // ES6 Destructuring
    const { status, metadata } = res || {};

    
    if (status == PAYMENT_AUTH_RES.captured) {

      // update user data
      this.Auth.getUser(null, true).subscribe()

      //
      this.Alert.show({
        titleBtn: 'موافق',
        msg: `تم شراء باقة ${metadata?.packageName} بشكل ناجح`,
        callback: () => {
          this.Alert.hide()
        }
      })
    }
    else {
      this.Alert.show({
        titleBtn: 'موافق',
        type: LAYOUTS_TYPE.alertFailure,
        msg: 'لم يتم شراء باقة بشكل ناجح!',
        callback: () => {
          this.Alert.hide()
        }
      }) 
    }
    
  } 

  openImgFullScreen() {
    // if (state) this.fullScreen = true
    // else this.fullScreen = false
    this.ngxViewer.instance.show()
  }  

  // function will get all date about input
  getControl(name: string) {
    return this.FormProfileOffice.controls[name]
  }

  cloneCurrentFormValue() {
    for (const key in this.FormProfileOffice.value) {
      this.setFormObj[key] = this.FormProfileOffice.value[key]
    }
  }

  removeFile() {
    if (this.FormProfileOffice.controls['img'].value.length > 0) {
      this.FormProfileOffice.patchValue({
        img: ''
      })
      this.viewImgHasSelected = ''
    }
  }  

  
  // select file & format him
  upLoadFile(event: Event) {
    // console.log(event)
    const file = (event.target as HTMLInputElement).files?.item(0)  as File

    this.getFile = file

    // check if user update file & back remove the file
    if (file) {
      this.getFile = file
      this.viewImgHasSelected = URL.createObjectURL(file)
    }
    else  {
      this.getFile = null
      this.viewImgHasSelected = ''
    }
  }  
 
  success() {

    this.alertSuccess = false

    // ES6 Destructing
    const { idNumber, isVerifiedIdNumber } = this.userInfo || {}

    // if user coming from realty-management module
    if (this.realty && isVerifiedIdNumber ) this.G.navigate(['/realty-management/add-realty-advertisement'])

    //
    if (!isVerifiedIdNumber) this.Nafath.idNumberCheck(idNumber)    

  }  

  updateProfile() {

    this.isLoading = true

    // init
    const formData = new FormData();

    // ES6 Destruction
    const { value } = this.FormProfileOffice;

    for (const key in this.FormProfileOffice.getRawValue()) {
      
      if (value[key] != null && value[key] != '') {
        if (key == 'workCity' || key == 'workArea' || key == 'workCategory' || key == 'workSubCategory' ) {
          formData.append(key, `[${value[key]}]`)
        }
        else if (key == 'img') {
          formData.append(key, this.getFile)
        }
        else {
          formData.append(key, value[key])
        }
      }

    }

    this.API_RESPONSE.userProfileUpdate(this.userInfo.id, formData).subscribe(
      (res: any) => {
        
        // 
        const { 
          user, 
          token,
          user: { idNumber, isVerifiedIdNumber } 
        } = res || {};

        // es6 spread operator 
        let  userInfo = {...this.userInfo, ...user,}

        // update component
        this.errorsMsg = []
        this.editable = false
        this.isLoading = false
        this.userInfo = userInfo
        this.viewImgHasSelected = ''

        // show alert
        this.alertSuccess = true



        // update data
        this.Auth.updateToken(token)
        this.Auth.updateUserInfo(userInfo)        
        
        //
        this.cloneCurrentFormValue()
        this.FormProfileOffice.updateValueAndValidity()        

        //
        this.G.goTo_TopPage()
      },
      err => {

        this.isLoading = false
        
        // ES6 Destructing
        const { errors } = err.error

        this.errorsMsg = errors;

      },      
    )

  }

  ngOnDestroy(): void {
    this.Nafath.ngOnDestroy()
  }

}
