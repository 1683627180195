<!-- start app-drop-down -->
<div class="drop_down border_def_3th border_r position-relative" [ngClass]="{'open': isOpen}" (click)="toggle()">
    <div class="select_v">
        <button class="btn_select" type="button">
            <span class="code label_lg_bold">{{countries[index]?.countryCode}}</span>
            <img [src]="countries[index]?.img" alt="flag country" loading="lazy" />
        </button>
    </div>
    <div class="wrap_btn border_def_3th">
        <button 
            type="button"
            [value]="item.countryCode"
            (click)="selected(item, i)"
            [ngClass]="{'active': index == i }"
            class="btn_select w-100 text-start effect" 
            *ngFor="let item of countries; let i = index"
        >
            <span class="code label_lg_bold text-center">{{item.countryCode}}</span>
            <img [src]="item.img" alt="flag country" />
        </button>
    </div>
</div>
<!-- end app-drop-down -->