import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[textFormat]'
})
export class TextFormatDirective implements OnInit {

  constructor(private el: ElementRef<HTMLElement>) { } 

  ngOnInit(): void {
    
    //
    this.TextTransform()

  }

  private TextTransform(): string {

    if (this.el.nativeElement) {

        //
        let { innerHTML } = this.el.nativeElement;

        innerHTML = innerHTML.replace(/(\n|\.)/gi, '<br>');
        innerHTML = innerHTML.replace(/(&)?quot/gi, '"');

        //
        this.el.nativeElement.innerHTML = innerHTML

    }
    
    return ''

  }

}

