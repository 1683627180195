import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-rate-stars-count',
  templateUrl: './rate-stars-count.component.html',
  styleUrls: ['./rate-stars-count.component.scss']
})
export class RateStarsCountComponent implements OnInit {

  // inputs is coming
  @Input('Rate') Rate: number = 0;
  @Input('ClassList') ClassList: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
