import { Observable, Subscription } from 'rxjs';
import { formatNumber } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, OnDestroy, OnInit } from '@angular/core';

// libs
import * as CryptoJS from 'crypto-js';

// interfaces
import { IArea } from 'src/app/core/interfaces/location/area';
import { ICity } from 'src/app/core/interfaces/location/city';
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

// enums
import { ADS_TYPES } from 'src/app/core/enums/ads/ads-types';
import { LOCAL_DATA } from 'src/app/core/enums/global/local-data';
import { SOCKET_KEY_ADS } from 'src/app/core/enums/sockets/ads-keys';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { SHARED_KEYS_APPS } from 'src/app/core/enums/cryptos/shared-keys-apps';
import { CATEGOR_VALUE_TYPE } from 'src/app/core/enums/category/cate-value-types';
import { CONTACT_REQUEST_SOCKET_KEY } from 'src/app/core/enums/sockets/contact-request-socket';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

@Injectable({
  providedIn: 'root'
})
export class AdsService implements OnInit, OnDestroy {

  //
  private _Subscriptions: Subscription[] = [];

  constructor(
    private _router: Router,
    private G: GlobleService,
    private Auth: AuthService,
    private _alert: AlertService,
    private route: ActivatedRoute,
    private _loader: LoaderService,
    private API_RESPONSE: ApiResponseService    
  ) {}

  // getters & setters
  /**
   * accessor to get & set Subscriptions
  */
  public get Subscriptions(): Subscription[] {
    return this._Subscriptions;
  }

  public set Subscriptions(Subscriber: Subscription[]) {
    this._Subscriptions.push(...Subscriber)
  }

  /**
   * userInfo
   */
  private get userInfo(): IuserInfo {
    return this.G.userInfo
  }

  /**
   * @description for check ad category type,
   */
  public isBuilding(type: CATEGOR_VALUE_TYPE): boolean {
    return type == CATEGOR_VALUE_TYPE.BL
  }
  
  /**
   * @description for check ad category type,
   */
  public isPlaning(type: CATEGOR_VALUE_TYPE): boolean {
    return type == CATEGOR_VALUE_TYPE.PL
  }

  /**
   * @description for check ad category type,
   */
  public isVillas(type: CATEGOR_VALUE_TYPE): boolean {
    return type == CATEGOR_VALUE_TYPE.VL
  }  

  /**
   * @description if ad is not planing || building
   */
  public isNormal(type: CATEGOR_VALUE_TYPE): boolean {
    return (!this.isPlaning(type) && !this.isBuilding(type))
  }

  /**
   * 
   * @param licenseData 
   * @returns 
   */
  public isValidAdNumber(licenseData: string): boolean {

    try {

      /**
       * handler Error
       */
      if (typeof licenseData != 'string') throw `licenseEndDate is ${licenseData}`;

      /**
       * check valid
       */
      const isValid = new Date(licenseData).valueOf() > Date.now();

      return isValid;

    }
    catch (err) {
      return null
    }

  }


  /**
   * 
   * @param city 
   * @param area 
   * @returns 
   * @description handler city & area to returns max string to show city & area
   */ 
  public handlerCityAndAreaNames(city: ICity, area: IArea): string {

    if (typeof city != 'object' && typeof area != 'object') {
      new Error(`city is ${city} and area is ${area} not Object`)
      return ''
    }

    /**
     * handler Area
     */
    let areaName: string ='';

    if (Array.isArray(area)) areaName = area[0].areaName
    else areaName = area.areaName
    
    // ES6 Destruction
    const { cityName } = city;


    return `${areaName ? areaName : ''} ${areaName && cityName ? ' - ' : ''} ${cityName ? cityName : ''}`

  }

  /**
   * 
   * @param title ads title
   * @returns string title after split it to limit chars
   */
  public handlerTitle(title: string, limit: number = 3): string {

    if (typeof title != 'string') {
      new Error(`title is not string, ${title}`)
      return ''
    }

    return title.split(' ').slice(0, limit).join(' ')    

  }


  /**
   * 
   * @param size 
   * @param fromAndTo 
   * @returns 
   */
  public handlerSize(size: number | { sizeTo: number, sizeFrom: number }): string {

    /**
     * 
     * @param s 
     * @returns 
     */
    const format = (s: number) => formatNumber(s, 'en-EN');

    try {
      /**
       * handler Error
       */
      if (size == null) throw new Error(`size is, ${size}`);


      if (typeof size == 'number') return format(size);
      else {
        // ES6 Destruction
        const { sizeTo, sizeFrom } = size;

        return `${format(sizeFrom) + ' - ' + format(sizeTo)}`;

      }

    }
    catch (err) {
      console.log(err)
    }

  }

  /**
   * 
  */
  public ngOnInit(): void {

    /**
     * 
     */
    const watchViewAds = this.Auth.MainListenSocketIo(SOCKET_KEY_ADS.view).subscribe(console.log);

    /**
     * check if io socket is run in browser env
     */
    if (watchViewAds != null)  this.Subscriptions = [watchViewAds]

  }  

  /**
   * 
   * @param rangeStatus it's number from zero to up
   * @returns { from: number, to: number | null}
   */
  public fromToByPrice(rangeStatus: number): { from: number, to: number | null} {
    
    switch(rangeStatus) {
      case 1: return { from: 1, to: 800000 };
      case 2: return { from: 800000, to: 1600000 };
      case 3: return { from: 1600000, to: 2400000 };
      case 4: return { from: 2400000, to: 3200000 };
      case 5: return { from: 3200000, to: null };
      default: return { to: 1, from: 800000 }
    }

  }

  /**
   * 
   * @param id 
   * @returns 
   */
  private decryptOfficeId(id: string): string {

    const iv = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.iv); // 128-bit IV
    const secretKey = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.secretKey); // 256-bit key    
    const officeId = CryptoJS.AES.decrypt(id, secretKey, {
      iv,
      mode: CryptoJS.mode.CBC,
      format: CryptoJS.format.Hex,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return officeId

  }

  /**
   * 
   */
  contactRequest(adsInfo: any = {}, isAdvertisement: boolean) {

    // ES6 Destruction
    const { id, inMarket, contactRequest } = adsInfo;

    if (contactRequest == null && this.G.CorrectUserId) {

      // show screen loading
      this._loader.setLoading(true, CONTACT_REQUEST_SOCKET_KEY.contactRequest);

      // get
      const getMarketingAds: { id: number | string, marketer: string }[] | null = JSON.parse(localStorage.getItem(LOCAL_DATA.marketingAd) || null);

      // ES6 destructuring With rename
      const { office: marketer } = this.route.snapshot.queryParams;
  
      // // handel obj name & value
      const data: {} =  {
        lang: 'ar',
        sender: this.userInfo?.id,
        [isAdvertisement ? 'ads' : 'adsRequest']: + id
      };

      // set office Id for marketing
      if (marketer && inMarket) data['marketer'] = +this.decryptOfficeId(marketer);
      else {

        if (Array.isArray(getMarketingAds) &&  getMarketingAds.length > 0) {

          //
          const isCaching = getMarketingAds.find(ad => id == ad?.id);

          if (isCaching) {
            // ES6 destructuring
            const { marketer: marketedOfficeId } = isCaching || {};
  
            // set
            data['marketer'] = +this.decryptOfficeId(marketedOfficeId);
          }
          
        }

      }

      // // remove from local
      this.removeCachingMarketingAd(id)
      // emit socket
      this.Auth.MainEmitSocketIo(CONTACT_REQUEST_SOCKET_KEY.contactRequest, data, true)
    }

    else {
      
      this._router.navigate(['/sign-in-user'], { queryParams: { redirectTo: this._router.url || encodeURIComponent(this._router.url) } })

    }

  }  


  /**
   * for Increase the number of ad views
   * @param ads ads tata
   */
  public viewAds(ads: {[key: string]: any}): void {
    
    // ES6 destructuring With rename
    const { 
      inMarket,
      id: viewOnId,
      owner: { id: ownerId } 
    } = ads || {}
    const { office: marketer } = this.route.snapshot.queryParams;

    // init body
    let body: {} = {
      viewOnId,
      userType: 'INSIDE-APP',
      relatedTo: ADS_TYPES.advertisement
    }

    // set office Id for marketing
    if (marketer && inMarket) body['marketer'] = +this.decryptOfficeId(marketer);    

    if (this.G.CorrectUserId && ownerId != this.G.CorrectUserId ) {

      //
      body['userId'] = this.G.CorrectUserId

      // emit
      this.Auth.MainEmitSocketIo(SOCKET_KEY_ADS.view, body)
      
    } 
    else if (!this.G.CorrectUserId) {

      this.API_RESPONSE.getUserIp()
      .subscribe(ip => {

        //
        body = { 
          ...body,
          deviceId: ip,
          userType: 'OUTSIDE-APP'
        }
        
        // emit
        this.Auth.MainEmitSocketIo(SOCKET_KEY_ADS.view, body)
        
      })

    }

  }

  public removeCachingMarketingAd(id: number | string) {

    //
    let getMarketingAds: { id: number | string, marketer: string }[] = JSON.parse(localStorage.getItem(LOCAL_DATA.marketingAd) || null);    

    if (Array.isArray(getMarketingAds) && getMarketingAds.length > 0) {

      //
      getMarketingAds = getMarketingAds.filter(ad => ad?.id != id);

      if(getMarketingAds.length > 0) {
        localStorage.setItem(LOCAL_DATA.marketingAd, JSON.stringify(getMarketingAds))
      }
      else localStorage.removeItem(LOCAL_DATA.marketingAd);

    }

  }

  public cachingMarketingAd(id: number | string) {

    // ES6 destructuring
    const { office: currentMarketer } = this.route.snapshot.queryParams;

    // caching in localStorage
    if (currentMarketer) {

      //
      const getMarketingAds: { id: number | string, marketer: string }[] = JSON.parse(localStorage.getItem(LOCAL_DATA.marketingAd) || null);

      if (Array.isArray(getMarketingAds) && getMarketingAds.length > 0) {

        //
        let getIndex = null;

        //
        const isCaching = getMarketingAds.find((ad, index) => {

          if (ad?.id == id) {
            getIndex = index;
            return ad;
          }

        });

        //
        if (isCaching) {

          // ES6 destructuring
          const { id: marketedAdId, marketer: marketedOfficeId } = isCaching || {};
          console.log(currentMarketer != marketedOfficeId, currentMarketer, marketedOfficeId)
          //
          if (currentMarketer != marketedOfficeId) {
            getMarketingAds[getIndex] = { id, marketer: currentMarketer }
            localStorage.setItem(LOCAL_DATA.marketingAd, JSON.stringify(getMarketingAds))
          } 

        }
        else getMarketingAds.push({ id, marketer: currentMarketer })

      }
      else localStorage.setItem(LOCAL_DATA.marketingAd, JSON.stringify([{ id, marketer: currentMarketer }]))

    }

  }

  /**
   * 
   * @param info 
   * @returns 
   */
  public deleteAd(info: {[key: string]: any}): Observable<any> | void {

    // ES6 Destructuring
    const { id, depositPaidUser = null } = info;

    try {
      
      /**
       * handler Error
       */
      if (id == null) throw `id is ${id}`;

      /**
       * because users paid on this ad we can delete it
       */
      if (depositPaidUser) throw `حذف هذا العقار اصبخ غير ممكن بسبب دفع عربون`

      return this.API_RESPONSE.removeAds(id)

    }
    catch (err) {
      this._alert.show({
        msg: err as unknown as string,
        type: LAYOUTS_TYPE.alertFailure,
      })
    }

  }

  //
  public ngOnDestroy(): void {

    //
    if (this.Subscriptions.length > 0) this.Subscriptions.forEach(s => s && s.unsubscribe());

    //
    console.log('ngOnDestroy => AdsService')

  }

}
