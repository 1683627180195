export enum DIRECTION {
    up = 'UP',
    down = 'DOWN'
}

export const scrollToElement = (ElementId: string, config?: {
    direction?: DIRECTION,
    child?: boolean
}) => {
    if (ElementId) {

        // ES6 Destructing
        const { direction, child } = config || {};

        let el = document.getElementById(ElementId); 
        
        if (el) {
            // init
            let 
                body =  document.documentElement,
                currentOffset = child ? (el.getBoundingClientRect().top + body.scrollTop) : el.offsetTop;
                
            switch(direction) {
                case DIRECTION.down:
                    if (currentOffset > body.scrollTop) body.scrollTop = currentOffset
                    break;
                case DIRECTION.up:
                    if (currentOffset < body.scrollTop) body.scrollTop = currentOffset
                    break;
                default:
                    body.scrollTop = currentOffset
                    break;                
            }
        }
        else console.warn('arg ElementId is not defined')

    }
    else console.warn('arg ElementId is not undefined')

}