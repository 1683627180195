<!-- start template -->
<ng-template #emptySearchResult>
    <div class="col-12 text-center" >
        <h3 class="sub_title mb-3">
                نعتذر لك لا توجد نتائج بحث بناءً على مدخلاتك او احدثياتك علي الخريطه
            <br>
            قد يساعدك قسم البحث ب التفاصيل في الاعلي او تصفح الخريطه
        </h3>
    </div>
</ng-template>

<ng-template #loadingSearchResult>
    <div class="col-12 text-center" >
        <h3 class="sub_title mb-3">
            جاري تحميل نتائج البحث...
        </h3>
    </div>
</ng-template>
<!-- end template -->

<section 
    class="search_result_box position-relative"
>
    <div class="custom_container">
        <div class="row">
            <div class="col-12 text-center" id="search_result_box">
                <h2 class="title">{{ SubmitForm ?  'نتائج البحث' : 'العقارات المضافة'}}</h2>
                <p class="sub_title">{{ SubmitForm ? 'تجد ادناه نتائج البحث بناءً على مدخلاتك' : 'شاهد أحدث العقارات المتنوعة المعروضة'}}</p>
            </div>
            <div class="col-12 text-center">
                <div class="wrap_optionView">
                    <ul class="list_none">
                        <li
                            class="d-inline-block"
                            (click)="viewTypeToggle(SearchViewType.Map)"
                            [ngClass]="{'active': ViewType == SearchViewType.Map}"
                        >
                            <a class="d-flex justify-content-center align-items-center pointer h-100 font_size_15 label_lg_bold a_none">
                                <img src="/assets/img/map{{ViewType == SearchViewType.Map ? '_w' : ''}}.svg" alt="map" loading="lazy" />
                                <span class="ms-2">الخريطة</span>
                            </a>
                        </li>
                        <li
                            class="d-inline-block"
                            (click)="viewTypeToggle(SearchViewType.NormalAds)"
                            [ngClass]="{'active': ViewType == SearchViewType.NormalAds}"
                        >
                            <a class="d-flex justify-content-center align-items-center pointer h-100 font_size_15 label_lg_bold a_none">
                                <img src="/assets/img/list{{ViewType == SearchViewType.NormalAds ? '_w' : ''}}.svg" alt="list" loading="lazy" />
                                <span class="ms-2">القائمة</span>
                            </a>
                        </li>
                        <li
                            class="d-inline-block"
                            (click)="viewTypeToggle(SearchViewType.VipAds)"
                            [ngClass]="{'active': ViewType == SearchViewType.VipAds}"
                        >
                            <a class="d-flex justify-content-center align-items-center pointer h-100 font_size_15 label_lg_bold a_none">
                                <span class="vip_text">VIP</span>
                                <span class="ms-2">عروض خاصة</span>
                            </a>
                        </li>                        
                    </ul>
                </div>                        
            </div>

        </div>
    </div>

    <!-- // start list vip -->
    <div 
        *ngIf="ViewType == SearchViewType.VipAds" 
        class="custom_container_2th position-relative"
    >
        <!-- start app-search-result-box -->
        <app-loader [preventScroll]="true" [sm]="true" size="fa-8x" *ngIf="IsLoadingVidAds || IsLoadingSearch"></app-loader>
         
        <div class="row">

            <ng-container *ngIf="IsLoadingVidAds">
                <ng-container *ngTemplateOutlet="loadingSearchResult" />
             </ng-container>          

            <ng-container *ngIf="IsLoadingVidAds == false">
                <div class="col-12 vip_categories">
                    <ul class="list_none text-center mb-4">
                        <li 
                            (click)="VipAdsFilterCategoryTypeChange(null)"
                            [ngClass]="{'active': CategoryTypeActiveAdsVip == null}"
                            class="def_shadow label_lg_bold border_r color_white d-inline-block pointer"
                        >
                            الكل
                        </li>
                        <ng-container 
                            *ngFor="let ctg of (ListOfCategory | filter : 'type' : [
                                CategoryValueType.BL, CategoryValueType.PL, CategoryValueType.VL
                            ])"
                        >
                            <li 
                                (click)="VipAdsFilterCategoryTypeChange(ctg)"
                                [ngClass]="{'active': CategoryTypeActiveAdsVip?.type == ctg?.type}"
                                class="def_shadow label_lg_bold border_r color_white d-inline-block pointer"
                            >
                                {{ctg?.categoryName}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>

            <ng-container *ngIf="ListOfVipAds.length == 0 && !IsLoadingVidAds && ViewType == SearchViewType.VipAds">
                <ng-container *ngTemplateOutlet="emptySearchResult" />
           </ng-container>   

            <ng-container *ngIf="ListOfVipAds.length > 0 && IsLoadingVidAds == false">
                <ng-container *ngFor="let ads of ListOfVipAds | paginate: {  
                    id:'vipAdsPagination',
                    currentPage: CurrentVipPage,
                    totalItems: totalVipPageCount,
                    itemsPerPage: AdVipLimitItems,
                }; let last = last">
                    <div class="col-12 col-lg-6 wrap_realty">
                        <app-info-card [info]="ads" [VipAds]="ads?.specialAdvertisement"></app-info-card>
                        <div class="hr border_r margin_y_3"></div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- // end list vip -->    

    <!-- // start list -->
    <div 
        *ngIf="ViewType == SearchViewType.NormalAds" 
        class="custom_container position-relative"
    >
        <!-- start app-search-result-box -->
        <app-loader [preventScroll]="true" [sm]="true" size="fa-8x" *ngIf="IsLoadingSearch || IsLoadingAds"></app-loader>

        <div class="row">

            <ng-container *ngIf="IsLoadingAds || IsLoadingSearch">
                <ng-container *ngTemplateOutlet="loadingSearchResult" />
             </ng-container> 

            <!-- start container -->
            <ng-container *ngIf="ListOfNormalAds.length == 0 && !IsLoadingSearch && ViewType == SearchViewType.NormalAds">
                <ng-container *ngTemplateOutlet="emptySearchResult" />
            </ng-container>
            <!-- end container -->             

            <ng-container *ngIf="ListOfNormalAds.length > 0 && IsLoadingAds == false">
                <ng-container *ngFor="let ads of ListOfNormalAds | paginate: {  
                    id:'AdsPagination',
                    itemsPerPage: AdLimitItems,
                    currentPage: CurrentAdsPage,
                    totalItems: totalAdsPageCount,
                }">
                    <div class="col-12 col-md-6 mb-3">
                        <div class="wrap_realty">
                            <app-info-card [info]="ads"></app-info-card>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <!-- // end list -->

    <!-- // start map -->
    <div 
        class="wrap_agm_map" 
        *ngIf="ViewType == SearchViewType.Map"
    >
        <ng-container>
            <google-map 
                id="Agm_map"
                width="100%"
                height="659px"
                [zoom]="mapZoom"
                (idle)="NewCoords()"
                (zoomChanged)="changeZoom()"
                [options]="googleMapsOptions"
                (mapInitialized)="Map = $event"
                [center]="{lat: CurrentMapCoords?.lat, lng: CurrentMapCoords?.lng}"
            >

                <map-marker-clusterer [options]="markerClusterStyle">
                    <ng-container *ngFor="let ads of ListOfNormalAds; let i = index">

                        <map-marker 
                            @fade
                            #marker="mapMarker"
                            [options]="markerOptions"
                            [position]="{lat: ads?.location?.coordinates[1], lng: ads?.location?.coordinates[0]}"
                        >
                        </map-marker>
                        <app-map-info-window [marker]="marker" [options]="{disableAutoPan: true, content}">
                            
                            <div 
                                #content
                                (click)="getInfoAds(ads)"
                                class="custom_marker pointer" 
                                [ngClass]="{'on_soom': ads.priceType == PriceType.soom}" 
                            >
                                <ng-container [ngSwitch]="ads?.category?.type">
                                    <div 
                                        class="text_md_bold text" 
                                        *ngSwitchCase="CategoryValueType.BL"
                                    >
                                        عماره {{ ads?.contractType == ContractType.SE ? 'للبيع' : 'للايجار' }}
                                    </div>                                
                                    <ng-container *ngSwitchDefault>
                                        <div 
                                            class="text_md_bold text" 
                                            *ngIf="ads.priceType != PriceType.soom; else ElseSoom"
                                        >
                                            {{ads.price | shortCurrency}}
                                        </div>
                                        <ng-template #ElseSoom>
                                            <div 
                                                class="text_md_bold text" 
                                                
                                            >
                                                علي السوم
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>                        
                        </app-map-info-window>

                    </ng-container>
                </map-marker-clusterer>
            </google-map>
        </ng-container>

        <div id="custom_model" >
            <app-info-card-model 
                @fade
                [viewState]="true" 
                [data]="InfoModel"
                *ngIf="InfoModel != null" 
                (CloseModel)="InfoModel = $event" 
                >
            </app-info-card-model>
        </div>

        <div class="loader_map border_r text-center def_shadow">
            <!-- start app-search-result-box -->
            <app-loader [preventScroll]="true" [sm]="true" size="fa-1x" *ngIf="IsLoadingSearch"></app-loader>

            <div *ngIf="!IsLoadingSearch">
                <ng-container *ngIf="ListOfNormalAds.length > 0; else None_realty">
                    <span class="text_md_bold text">تم العثور علي {{ ListOfNormalAds.length  }} عقار</span>
                </ng-container>
                <ng-template #None_realty>
                    <span class="text_md_bold text">لم يتم العثور علي عقارات</span>
                </ng-template>
            </div>
        </div>
    </div>
    <!-- // end map -->

    <div class="custom_container">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 text-center">

                        <ng-container *ngIf="ViewType == SearchViewType.NormalAds && ListOfNormalAds.length > 0 && IsLoadingAds == false">
                            <div class="wrap_pagination text-center wrap_btn">
                                <pagination-controls 
                                    [maxSize]="5"
                                    id="AdsPagination"
                                    nextLabel="التالي"
                                    [responsive]="true"
                                    previousLabel="السابق"
                                    screenReaderPageLabel="page"
                                    class="label_lg_bold p_bg_black"
                                    (pageChange)="NormalPageNumber($event)"
                                    screenReaderPaginationLabel="Pagination"
                                    screenReaderCurrentLabel="You're on page"
                                >
                                </pagination-controls>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="ViewType == SearchViewType.VipAds && ListOfVipAds.length > 0 && IsLoadingVidAds == false">
                            <div class="wrap_pagination text-center wrap_btn">
                                <pagination-controls 
                                    [maxSize]="5"
                                    nextLabel="التالي"
                                    [responsive]="true"
                                    id="vipAdsPagination"
                                    previousLabel="السابق"
                                    screenReaderPageLabel="page"
                                    class="label_lg_bold p_bg_black"
                                    (pageChange)="VipPageNumber($event)"
                                    screenReaderPaginationLabel="Pagination"
                                    screenReaderCurrentLabel="You're on page"
                                >
                                </pagination-controls>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</section>
<!-- end app-search-result-box -->