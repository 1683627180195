export enum FILE_TYPE {
    pdf = "pdf",
    svg = 'svg',
    text = "text",
    img = "image",
    audio = "audio",
    word = "msword",
    video = "video",
    app = "application",
    document = 'doc',


    // default
    doc = 'docx',
    jpeg = 'jpeg',
    jpg = 'jpg',
    png = 'png',
    gif = 'gif',
    mp4 = 'mp4',
    bmp = 'bmp',
    mov = 'mov',
    avi = 'avi',
    mp3 = 'mp3',
    woff = 'woff',
    woff2 = 'woff2',
    jfif = 'jfif',
    txt = 'txt',
    csv = 'csv',
    html = 'html',
    _3gp = '3gp',
    webm = 'webm',
    flv = 'flv',
    zip = 'zip'

}