import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  animations: [
    trigger('fied', [
      transition(':enter', [
        style({opacity: '0', transform: 'translate(0px, 100px)'}),
        animate('150ms', style({opacity: '1', transform: 'translate(0px, 0px)'}),),
      ]),
      transition(':leave', [
        style({opacity: '1', transform: 'translate(0px, 0px)'}),
        animate('150ms', style({opacity: '0', transform: 'translate(0px, 100px)'}),),
      ])
    ])
  ]
})
export class SuccessComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  @Input('msg') msg: string = ''
  @Input('state') state: boolean = true
  @Input('titleBtn') titleBtn: string = ''
  @Input('type') public type?: LAYOUTS_TYPE = LAYOUTS_TYPE.alertSucess;

  /// out
  @Output() Hide = new EventEmitter()
  @Output() Submit = new EventEmitter()

  // enums
  public LAYOUTS_TYPE = LAYOUTS_TYPE


  constructor() { }

  ngOnInit(): void {
    document.documentElement.classList.add('overflow-hidden')
  }

  // 
  submit() {
    this.Submit.emit(true)
  }

  hide() {
    this.Hide.emit(true)
  }

  ngOnDestroy(): void {
    document.documentElement.classList.remove('overflow-hidden')
  }


}
