import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

// environment
import { environment } from 'src/environments/environment'

// libs
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { deleteToken, getMessaging } from '@angular/fire/messaging';

// custome services
import { GlobleService } from '../globle.service'
import { MainApiService } from '../main/main-api.service';
import { CountersService } from '../counters/counters.service';

// custome enums
import { usersType } from 'src/app/core/enums/user/user-type';
import { User } from 'src/app/core/enums/user/info-user';
import { SOCKET_KEY_ADS } from 'src/app/core/enums/sockets/ads-keys';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { BROWSER_PERMISSION } from 'src/app/core/enums/user/browser-permission';
import { CONTACT_REQUEST_SOCKET_KEY } from 'src/app/core/enums/sockets/contact-request-socket';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

@Injectable()
export class AuthService {

  //
  public IO: Socket

  //
  StepsForgotPassword: number = 1

  // Observable
  Token = new BehaviorSubject<boolean | string | null>(this.G.getUserData(User.token));
  userInfo = new BehaviorSubject<IuserInfo | null>(this.G.getUserData(User.info));

  constructor(
    private G: GlobleService,
    private API: MainApiService,
    private injector: Injector,
    private Counters: CountersService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.IO = this.injector.get<Socket>(Socket);
    }

    this.userInfo.subscribe(
      info => {
        
        if (info) {

          const socketIoConfig: SocketIoConfig =  { 
            url: environment.SERVER_IO,
            options: { 
              transports: [ 'websocket',"xhr-polling" ],
              query: {id: this.G.CorrectUserId?.toString() as string},
            } 
          };
          this.IO = new Socket(socketIoConfig);
        }
      }
    )

  }
  

  // forgot password
     public setStepsForgotPassword(step: number) {
      this.StepsForgotPassword = step
    }

     public get stepsForgotPassword(): number {
      return this.StepsForgotPassword
    }
  // forgot password

  // user info
    updateToken(token: string | null) {
      // save data in localStorage
      // we save date becouse next step has some info from user
      if (token) {
        this.G.saveUserData(User.token, token)   
      }
      else localStorage.removeItem(User.token)

      this.Token.next(token)
    }

    updateUserInfo(data: IuserInfo | null) {
      
      if (data) this.G.saveUserData(User.info, data)
      else localStorage.removeItem(User.info)

      this.userInfo.next(data)

    }
  // user info

  // socket
  MainEmitSocketIo(key: string, data: {}, editMode?: boolean) {

    // ES6 destructuring
    const { type } = this.G.getUserData(User.info) || {};

    if (type == usersType.superVisor && editMode) {
      
      switch(key) {
        case CONTACT_REQUEST_SOCKET_KEY.contactRequest:
          data = { ...data, sender: this.G.CorrectUserId }
          break;
        case SOCKET_KEY_ADS.add:
          data = { ...data, owner: this.G.CorrectUserId }
          break;          
        default:
          data = {
            ...data,
            userId: this.G.CorrectUserId
          }
          break;
      }
    }
    // console.log(key, data, 'editMode => ' + editMode, this.G.getUserData(User.info))
    this.IO?.emit(key, data)
  }

  MainListenSocketIo(key: string) {
    if (isPlatformBrowser(this.platformId)) return this.IO.fromEvent(key)

    return new Observable()
  }

  getUser(redirect?: string | null, redirectNone: boolean = false) {

    let getUser$ = this.API.mainGetAll('findById').pipe(
      map((value: any) => {
        // set value
        let  userInfo = value;
  
        // es6 destructuring
        const { user } = value;
  
        delete userInfo.user;
  
        // es6 spread operator 
        userInfo = {...userInfo, ...user}
  
        // save data in localStorage
        this.G.saveUserData(User.info, userInfo)
  
        // update obvs
        this.updateUserInfo(userInfo)
  
        // navigate to home
        if (!redirectNone) this.G.navigate([redirect ? redirect : '/'])

        return userInfo

      })
    )

    return getUser$;

  }

  getUserParent(id: number | string, userInfo: {[key: string]: any}, redirect?: string) {
    let userInfo$ = this.API.mainGetAll(`${id}/getUser`).pipe(
      map((value: any) => {
        // console.log(value)
        let parrentInfo =  { 
          // globale value
          idType: value?.idType,
          adNumber: value?.adNumber,
          idNumber: value?.idNumber,
          parentAgencyType: value?.agencyType,

          // counts
          adsCount: value?.adsCount,
          viewsCount: value?.viewsCount,     
          saleAdsCount: value?.saleAdsCount,
          rentAdsCount: value?.rentAdsCount,
          availableAds: value?.availableAds,
          adsRequestCount: value?.adsRequestCount,
          contactRequestCount: value?.contactRequestCount,
          completedAdsRequest: value?.completedAdsRequest,
          unCompletedAdsRequest: value?.unCompletedAdsRequest,

          // Package
          adsPackage: value?.adsPackage, 
          hasAdsPackage: value?.hasAdsPackage,
          propertyPackage: value?.propertyPackage, 
          hasPropertyPackage: value?.hasPropertyPackage, 
          packageEndDateMillSec: value?.packageEndDateMillSec,
          packageStartDateMillSec: value?.packageStartDateMillSec,
          propertyPackageEndDateMillSec: value?.propertyPackageEndDateMillSec,
          propertyPackageStartDateMillSec: value?.propertyPackageStartDateMillSec, 
        };

        //
        let FullData = {
          ...userInfo,
          ...parrentInfo,
        }

        // save data in localStorage
        this.G.saveUserData(User.info, FullData)
  
        // update obvs
        this.updateUserInfo(FullData as any)
        // console.log(FullData)
  
        // navigate to home
        this.G.navigate([redirect ? redirect : '/'])        

        return FullData

      })
    )

    return userInfo$
  }

  // send token to connect with firebase notification or more
  sendToken(token: string) {
    return this.API.mainPost('addToken', { token }, { params: { [RANDOM_VALUE.loader]: true } })
  }

  // logOut From Account
  logOut(config?: {[key: string]: any}): Observable<any> | void {

    // es6 destructuring
    const { 
      redirectPath,
      withoutApiRequest,
      enableReturn = false,
      StopNavigate = false 
    } = config || {}

    //
    const userInfo = this.G.getUserData(User.info)
    const token = this.G.getLocalData(BROWSER_PERMISSION.AllowNotification)?.tokenNotifi

    const callBack = () => {
      // delete all user data 
      localStorage.removeItem(User.info)
      localStorage.removeItem(User.token)
      sessionStorage.clear()

      //
      deleteToken(getMessaging())
      .then(console.log)
      .catch(console.log)

      // init obsv
      this.updateToken(null)
      this.updateUserInfo(null)
      this.Counters.setCountAdsRequest = null
      this.Counters.setCountContactRequest = null
      // setTimeout(() => this.IO.disconnect(), 6000)
      if (userInfo?.type == usersType.normalUser) !StopNavigate && this.G.navigate([redirectPath || '/sign-in-user'])
      else if (userInfo?.type == usersType.office || userInfo?.type == usersType.superVisor) !StopNavigate && this.G.navigate([redirectPath || '/sign-in-office'])
    }

    if (withoutApiRequest)  {
      callBack();
      return

    }
    
    //
    const req = this.API.mainPost('logout', token ? { token } : undefined);

    if (enableReturn) {
      return req.pipe(
        map((v) =>  {
          callBack()
          return v
        }),
        catchError(err => {
          callBack()
          return of(err)
      }))
    }
    
    this.API.mainPost('logout', token ? { token } : undefined).subscribe(
      res => callBack(),
      err => callBack()
    )

  }
}