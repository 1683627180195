import { Observable, Subject } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  pure: true,
  name: 'countdownDate'
})
export class CountdownDatePipe implements PipeTransform {

  constructor() {

    //init
    this._$counter = new Subject();
    this._currentDateMilliseconds = new Date().setHours(0, 0, 0, 0);
  }

  /**
   * fields
   */
  private _value: number = null;
  private _currentDateMilliseconds: number =null;
  private _$counter: Subject<number | string | null>;

  transform(value: number, ...args: number[]): Observable<number | string | null> {

    try {

      //
      if (value != null && typeof value != 'number' && typeof value != 'string') {
        throw new Error(`arg 'value' can't parse to number, current value is ${JSON.stringify(value)}`);
      }
      else {
        
        // convert seconds to millSeconds
        this._value = value * 1000;

        this._currentDateMilliseconds += this._value;
      }
      
      const clear = setInterval(() => {
        
        //
        const currentDate = new Date(this._currentDateMilliseconds);

        // 
        const isDone = Math.max(currentDate.getSeconds(), currentDate.getMinutes(), currentDate.getHours()) <= 0;

        if (isDone) {

          clearInterval(clear)
          this._$counter.next(null)

        }
        else this._$counter.next(this._currentDateMilliseconds -= 1000)

      }, 1000)

      return this._$counter

    }
    catch {

      return null

    }

  }

}
