import { Component, SimpleChanges, ViewEncapsulation, } from '@angular/core';

// custom services
import { SearchService } from '../services/search.service';

// custom interfaces
import { ICity } from 'src/app/core/interfaces/location/city';
import { ICategory } from 'src/app/core/interfaces/category/category';

// reusable
import { listOfRentType } from 'src/app/core/reusable-objects/globle-objs/rent-list';
import { ListOfPricesRange } from 'src/app/core/reusable-objects/globle-objs/prices-range';
import { ListOFAdsStatus } from 'src/app/core/reusable-objects/globle-objs/ads-status-list';
import { ListOfContractType } from 'src/app/core/reusable-objects/globle-objs/contract-list';
import { listFormateDate } from 'src/app/core/reusable-objects/globle-objs/formateDate-ads-list';

import { Contract_Type } from 'src/app/core/enums/ads/contract-types';
import { Features_Type } from 'src/app/core/enums/features/features-type';

// functions helper
import { NumberOnly } from 'src/app/core/funcations-helper/Numbers-only';

// custom animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-search-inputs-box',
  templateUrl: './search-inputs-box.component.html',
  styleUrls: ['./search-inputs-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fade]
})
export class SearchInputsBoxComponent {
  
  /**
   * fields
   */
  public AdvancedSearch: boolean = false;

  constructor(
    public Search: SearchService,
  ) { }

  /**
   * Getter & Setter
   */

  /**
   * 
   */
  public get FormIsDisabled(): boolean {
    return !this.Search.FormSearch.dirty || this.Search.FormSearch.invalid || this.IsLoadingSearch
  }

  /**
   * 
   */
  public get IsSubmitForm(): boolean {
    return this.Search.IsSubmitForm
  }
  
  /**
   * 
   */
  public get IsLoadingSearch(): boolean {
    return this.Search.IsLoadingSearch
  }

  /**
   * 
   */
  public get IsApproveCurrentUserLocation(): boolean {
    return this.Search.IsApproveCurrentUserLocation
  }

  /**
   * 
   */
  public get showSearchMethod(): boolean {
    return this.Search.showSearchMethod
  }

  /**
   * 
   */
  public get NumberOnly(): typeof NumberOnly {
    return NumberOnly
  }

  /**
   * 
   */
  public get listOfPricesRange(): typeof ListOfPricesRange {
    return ListOfPricesRange
  }  

  /**
   * 
   */
  public get ListOfContractType(): typeof ListOfContractType {
    return ListOfContractType
  } 

  /**
   * 
   */
  public get ListOfRentType(): typeof listOfRentType {
    return listOfRentType
  }
  
  /**
   * 
   */
  public get ListOFAdStatus(): typeof ListOFAdsStatus {
    return ListOFAdsStatus
  }

  /**
   * 
   */
  public get ListFormateDate(): typeof listFormateDate {
    return listFormateDate
  }  

  /**
   * 
   */
  public get FeatureType(): typeof Features_Type {
    return Features_Type
  }  

  /**
   * Methods
   */

  /**
   * 
   * @param change 
   */
  ngOnChanges(change: SimpleChanges): void {}

  ngOnInit() {

    /**
     * init
     */
    this.Search.init();

  }

  /**
   * @param city 
   */
  public handlerGetAreasByCity(city: ICity) {
    this.Search.HandlerGetAreasByCity(city)
  }

  /**
   * @param category 
   */
  public handlerGetSubByMainCategories(category: ICategory) {
    this.Search.HandlerGetSubByMainCategories(category)
  }

  /**
   * @param subCategory 
   */
  public handlerGetFeature(subCategory: ICategory): void {
    this.Search.HandlerGetFeature(subCategory)
  }

  /**
   * 
   * @param range from 1 to 5 or default
   */
  public pricesRangeStatus(range: number): void {
    this.Search.PricesRangeStatus(range)
  }

  /**
   * 
   * @param type
   */
  public contractToggle(type: Contract_Type) {
    this.Search.contractToggle(type)
  }

  /**
   * 
   * @param checked true or false checkbox
   * @param data feature data
   * @param index index from listOfFeatures
   */
  setFeatures(checked: boolean, data: {[key: string]: any}, index: number) {
    
    // ES6 Destructing
    const { id } = data;
    
    if (checked) this.Search.ArrOfControls('features').at(index).setValue(id)
    else  this.Search.ArrOfControls('features').at(index).reset()

  }
  
  /**
   * 
   */
  public submit(): void {
    this.Search.submit()
  }

  /**
   * 
   */
  public resetForm(): void {
    this.Search.resetForm()
  }

  /**
   * 
   */
  public viewReset(): void {
    this.Search.viewReset()
  }

}
