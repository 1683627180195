import { Injectable, OnInit } from '@angular/core';

// components
import { AudioComponent } from '../audio.component';

@Injectable()
export class AudioService {

  // init props
  listOfAudioComponents: AudioComponent[] = []

  constructor() {}

  public get getAllAudios() {
    return this.listOfAudioComponents
  }

  public setAudioComponents(audio: AudioComponent) {
    this.listOfAudioComponents.push(audio)
  }

  public removeAudioComponents(id: number) {
    
    this.getAllAudios.filter((au, index) => {

      if (id == au.objMedia.id) this.listOfAudioComponents.splice(0, index)
      
    })

  }

  public pauseSiblings(id: number) {

    this.getAllAudios.map(au => {

      if (id != au.objMedia.id) {
        if (!au.Media.nativeElement.paused) au.MediaPlay()
      }
      
    })
  }
}
