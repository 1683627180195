export const parseArabic = (value: string) => {
    
    let arabic = value?.toString();

    if (arabic || arabic === '0') {

        return arabic.replace(
            /[\u0660-\u0669\u06F0-\u06F9]/g,
            function(a) {
                return String.fromCharCode((a.charCodeAt(0)&15)+48);
            }
        );
    }

    return ''

}