import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoMetaDataService {

  constructor(private title: Title, private metaData: Meta) {};

  
  // title
  public get Title(): string {
    return this.title.getTitle()
  }

  public set Title(title: string) {
    
    if (title && typeof title == 'string') this.title.setTitle(title)
    else console.error(`The value of the parameter for the title is not valid ${title}`)    
    
  }

  // meta tags
  public get Description(): string {
    return this.metaData.getTag("name='description'")?.content || ''
  }

  public set Description(content: string) {
    if (content && typeof content == 'string') this.metaData.updateTag({name: 'description', content})
    else console.error(`The value of the parameter for the description is not valid ${content}`)
  }

  public get KeyWords(): string[] | string | null {
    return this.metaData.getTag("name='keywords'")?.content || null
  }

  public set KeyWords(content: string[] | string) {
    
    if (Array.isArray(content) || typeof content == 'string') {
      if (this.KeyWords) {
        this.metaData.updateTag({name: 'keywords', content : Array.isArray(content) ? content.join(', ').trim() : content})
      }
      else this.metaData.addTag({ name: 'keywords', content: Array.isArray(content) ? content.join(', ').trim() : content })
      
    }
    else console.error(`The value of the parameter for the description is not valid ${content}`)

  } 

}
