import { Observable } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';

// services
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

// animations
import { fade } from 'src/app/core/animations/fade';

// enums
import { TERMS_TYPE } from 'src/app/core/enums/user/terms-types';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { PAYMENT_TERMS_TYPE } from 'src/app/core/enums/payment/payment-terms-type';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  animations: [fade]
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {

  /**
   * outPut
   */
  @Output() close = new EventEmitter<boolean>()
  @Output() isReading = new EventEmitter<void>()
  @Output() readingDone = new EventEmitter<boolean>()

  /**
   * inputs
   */
  @Input('termsTitle') public termsTitle?: string
  @Input('privacyTitle') public privacyTitle?: string
  @Input('acceptBtn') public acceptBtn?: boolean = false
  @Input('type') public TermsType: TERMS_TYPE | PAYMENT_TERMS_TYPE = TERMS_TYPE.Default

  /**
   * fields
   */
  public loading: boolean = false
  private _isReadDone: boolean = false
  public termsData: {[key: string]: any}


  constructor(
    private API_RESPONSE: ApiResponseService,
    private TopLevelApi: TopLevelSingleService,
  ) { }

  ngOnInit(): void {

    //
    this.getTAndC();

    //
    document.documentElement.classList.add('overflow-hidden');

  }

  // Getters

  /**
   * 
   */
  public get acceptButton(): boolean {
    return this.acceptBtn
  }

  /**
   * isReadDone
   */
  public get isReadDone(): boolean {
    return this._isReadDone
  } 
  
  public set isReadDone(value: boolean) {
    this._isReadDone = value
  }   


  closePup_up() {
    this.close.emit(false)
  }

  /**
   * 
   */
  public checkForScrollToEndOfView(ev: Event) {
    
    // get container Element
    const el = ev.currentTarget as HTMLElement;

    // 
    ev.stopPropagation();

    //
    this.isReading.emit()

    if (Math.ceil(el.scrollTop + el.clientHeight) >= el.scrollHeight) this.isReadDone = true
    else {
      if (this.isReadDone == true) this.isReadDone = false
    }

  }

  /**
   * 
   */
  public acceptTAndC() {

    if (this.isReadDone) {
      this.readingDone.emit()
    }

  }

  
  /**
   * 
   */
  getTAndC() {

    //
    this.loading = true;

    //
    let req: Observable<any> = null

    switch(this.TermsType) {
      case Object.values(PAYMENT_TERMS_TYPE).find(e => e == this.TermsType):
        req = this.API_RESPONSE.getPaymentTerms({params: { type: this.TermsType,[RANDOM_VALUE.loader]: true}})
        break
      case TERMS_TYPE.Market:
        req = this.API_RESPONSE.MarketTerms()
        break
      case TERMS_TYPE.Default:
      default:
        req = this.TopLevelApi.terms$
        break;
    }

    //
    req.subscribe(
      res => {
        this.loading = false;
        this.termsData = res;
      },
      err => this.loading = false
    )

  }
  
  ngOnDestroy(): void {
    document.documentElement.classList.remove('overflow-hidden')
  } 

}
