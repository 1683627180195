import { NgModule, Type } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

// custome main components app
import { FaqComponent } from './main.components/faq/faq.component';
import { HomeComponent } from './main.components/home/home.component';
import { AboutComponent } from './main.components/about/about.component';
import { ContactUsComponent } from './main.components/contact-us/contact-us.component';
import { InformationPageComponent } from './main.components/information-page/information-page.component';
import { TermsAndCondititonsComponent } from './main.components/terms-and-condititons/terms-and-condititons.component';
import { ImportantInstructionsComponent } from './main.components/important-instructions/important-instructions.component';
import { CheckpointDeleteAccountComponent } from './main.components/checkpoint-delete-account/checkpoint-delete-account.component';

// custome child components app
import { HeaderComponent } from './shared.modules/shared.components/header/header.component';
import { ProfileComponent } from './child.components/user/sub.components.home/profile/profile.component';
import { SignUpUserComponent } from './child.components/user/sub.components.home/sign-up/sign-up.component';
import { SignUpOfficeComponent } from './child.components/office/sub.components.home/sign-up/sign-up.component';
import { SignInComponent } from './child.components/shared.users/sub.components.home/sign-in/sign-in.component';
import { VerifyAuthComponent } from './child.components/shared.users/sub.components.home/verify-auth/verify-auth.component';
import { AccountStateComponent } from './child.components/office/sub.components.home/account-state/account-state.component';
import { ProfileOfficeComponent } from './child.components/office/sub.components.home/profile-office/profile-office.component';
import { PaymentResultComponent } from './high.level.shared.modules/payment/shared.components/payment-result/payment-result.component';
import { ResetPasswordComponent } from './child.components/shared.users/sub.components.home/reset-password/reset-password.component';
import { CompleteProfileUserComponent } from './child.components/user/sub.components.home/complete-profile/complete-profile.component';
import { CompleteProfileOfficeComponent } from './child.components/office/sub.components.home/complete-profile-office/complete-profile-office.component';

// custome enums
import { usersType } from './core/enums/user/user-type';
import { accountState } from './core/enums/user/account-state';
import { NEXT_STEP_AFTER_VERIFY } from './core/enums/user/auth-state';
import { PACKAGES_TYPE } from './core/enums/ads/packages/packages-types';

// custome guards
import { IsAuthGuard } from './core/guards/auth/is-auth.guard';
import { UnAuthGuard } from './core/guards/auth/un-auth.guard';
import { TypeUserGuard } from './core/guards/types/type-user.guard';
import { AuthStepsGuard } from './core/guards/auth/auth-steps.guard';
import { MasterAuthGuard } from './core/guards/auth/master-auth.guard';
import { TypeOfficeGuard } from './core/guards/types/type-office.guard';
import { citiesResolver } from './core/guards/shared/resolve/cities.resolver';
import { CompleteProfileGuard } from './core/guards/ads/complete-profile.guard';
import { userInfoResolver } from './core/guards/shared/resolve/user-info.resolver';
import { ReauthToDeleteAccountGuard } from './core/guards/auth/reauth-to-delete-account.guard';
import { updateUserInfoByTokenResolver } from './core/guards/shared/resolve/userInfoByToken.resolver';

const routerOptions: ExtraOptions = {
  scrollOffset: [0, 64],
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabledBlocking'
};


const routes: Routes = [
  { path: '', component: HomeComponent, children: [
      // start child components => subs HomeComponent
      { path: '', component: HeaderComponent },
      { path: 'sign-up-user', component: SignUpUserComponent, canActivate: [UnAuthGuard]},
      { path: 'info-page', component: InformationPageComponent, canActivate: [IsAuthGuard]},
      { path: 'payment-result', component: PaymentResultComponent, canActivate: [IsAuthGuard]},
      { path: 'new-password', component: ResetPasswordComponent, canActivate: [UnAuthGuard, AuthStepsGuard]},
      { path: 'account-state', component: AccountStateComponent, canActivate: [UnAuthGuard, TypeOfficeGuard]},
      { path: 'complete-profile/:id', component: CompleteProfileUserComponent, canActivate: [UnAuthGuard, TypeUserGuard]},
      { 
        path: 'sign-up-office',
        canActivate: [UnAuthGuard],
        component: SignUpOfficeComponent,
        resolve: { cities: citiesResolver, },
      },
      { 
        path: 'profile-office',
        component: ProfileOfficeComponent,
        canActivate: [IsAuthGuard, TypeOfficeGuard],
        resolve: {
          // areas: areasResolver,
          cities: citiesResolver,
          userInfo: userInfoResolver,
          // categories: categoriesResolver
        }
      },
      { 
        path: 'profile', component: ProfileComponent, data: {
          USERS_TYPES: [
            usersType.normalUser,
            usersType.superVisor            
          ]
        },
        canActivate: [IsAuthGuard, MasterAuthGuard]
      },
      { 
        path: 'sign-in-office', component: SignInComponent, data: {
          USERS_TYPES: [
            usersType.office,
            usersType.superVisor
          ],
          navigation: {
            label: 'تسجيل دخول',
            path: '/'
          },          
        },
        canActivate: [UnAuthGuard]
      },
      { 
        path: 'sign-in-user', component: SignInComponent, data: { 
          USERS_TYPES: [
            usersType.normalUser,
          ],
          navigation: {
            label: 'تسجيل دخول',
            path: '/'
          },          
        }, 
        canActivate: [UnAuthGuard]
      },
      { 
        path: 'reauth-to-delete-waseet', component: SignInComponent, data: {
          USERS_TYPES: [
            usersType.office,
            usersType.superVisor
          ],
          navigation: {
            label: 'تسجيل دخول لحذف الحساب',
            path: '/'
          },
          listToggleBar: false,
          navigationTo: '/checkpoint-delete-account'
        },
        canActivate: [ReauthToDeleteAccountGuard]
      },
      { 
        path: 'reauth-to-delete-user', component: SignInComponent, data: { 
          USERS_TYPES: [
            usersType.normalUser,
          ],
          navigation: {
            label: 'تسجيل دخول لحذف الحساب',
            path: '/'
          },
          listToggleBar: false,
          navigationTo: '/checkpoint-delete-account'
        }, 
        canActivate: [ReauthToDeleteAccountGuard]
      },
      { 
        path: 'complete-profile-office/:id',
        component: CompleteProfileOfficeComponent,
        canActivate: [
          UnAuthGuard,
          TypeOfficeGuard
        ]
      },

      // start VerifyAuthComponent
      { path: 'verify', component: VerifyAuthComponent, data: { 
        label: 'التحقق',
        Method: 'mainPut',
        titleBtn: 'تفعيل',
        endPoint: 'verifyAccount',
        type: accountState.verify,
        alertBtn: 'متابعة',
        alertMsg: 'تم تاكيد كود التحقق بشكل صحيح',
        nextStep: NEXT_STEP_AFTER_VERIFY.completeProfile,
        formData: {
          min: 4,
          max: 10,
          type: 'text',
          name: 'verifycode',
          pattern: '^[0-9]+$',
          title: 'كود التحقق',
          placeholder: 'ادخل الكود',
        }
      }, canActivate: [UnAuthGuard]} ,
      { path: 'verifyAsForgotPassword', component: VerifyAuthComponent, data: { 
        label: 'التحقق',
        Method: 'mainPut',
        titleBtn: 'تفعيل',
        endPoint: 'verifyAccount',
        type: accountState.verify,
        alertBtn: 'متابعة',
        alertMsg: 'تم تاكيد كود التحقق بشكل صحيح',
        nextStep: NEXT_STEP_AFTER_VERIFY.new_password,
        formData: {
          min: 4,
          max: 10,
          type: 'text',
          name: 'verifycode',
          pattern: '^[0-9]+$',
          title: 'كود التحقق',
          placeholder: 'ادخل الكود',
        }
      }, canActivate: [UnAuthGuard]} ,
      { path: 'forget-password', component: VerifyAuthComponent, data: { 
        Method: 'mainPost',
        alertBtn: 'متابعة',
        endPoint: 'sendCode-phone', 
        label: 'نسيت كلمة المرور',
        type: accountState.forgot,
        titleBtn: 'استعادة كلمة المرور',
        alertMsg: 'تم ارسال كود التحقق بشكل صحيح',        
        nextStep: NEXT_STEP_AFTER_VERIFY.new_password,
        formData: {
          min: 10,
          max: 10,
          type: 'text',
          name: 'phone',
          pattern: '^[0-9]+$',
          title: 'رقم الجوال',
          placeholder: '... اكتب رقم الجوال',
        }
      }, canActivate: [UnAuthGuard]},
      // end VerifyAuthComponent

      // starta all lazy loading child components & modules
      { 
        path: 'setting',
        canActivate: [IsAuthGuard],
        resolve: {
          updateUserInfo: updateUserInfoByTokenResolver
        },
        loadChildren: () => import('./child.components/shared.users/sub.components.home/setting/setting.module').then(m => m.SettingModule)
      },
      { 
        path: 'request_and_realty',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('./child.components/shared.users/sub.components.home/my-request-and-ads/my-request-and-ads.module').then(m => m.MyRequestAndAdsModule)
      },      
      { 
        path: 'notification',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('./child.components/shared.users/sub.components.home/notification/notification.module').then(m => m.NotificationModule)
      },
      { 
        path: 'favourites',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('./child.components/shared.users/sub.components.home/favourites/favourites.module').then(m => m.FavouritesModule)
      },
      { 
        path: 'subscriptions',
        data: { type: PACKAGES_TYPE.main },
        canActivate:  [IsAuthGuard, TypeOfficeGuard, CompleteProfileGuard],
        loadChildren: () => import('./child.components/office/sub.components.home/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
      },
      // end all lazy loading child components & modules
    ]
  },
  // end child components => subs HomeComponent
  { 
    path: 'checkpoint-delete-account',
    component: CheckpointDeleteAccountComponent,
    canActivate: [IsAuthGuard] 
  },
  { 
    path: 'important-instructions',
    component: ImportantInstructionsComponent,
  },  
  { path: 'faq', component: FaqComponent},
  { path: 'about', component: AboutComponent},
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'terms', component: TermsAndCondititonsComponent} ,

  // starta all lazy loading main components & modules
  { 
    path: 'tax-invoice/:id',
    loadChildren: () => import('./main.components/tax-invoice/tax-invoice.module').then(m => m.TaxInvoiceModule)
  }, 
  { 
    path: 'user-profile',
    loadChildren: () => import('./main.components/profile-user/profile-user.module').then(m => m.ProfileUserModule)
  },   
  { 
    path: 'news',
    loadChildren: () => import('./main.components/real-estate-news/real-estate-news.module').then(m => m.RealEstateNewsModule)
  },
  { 
    path: 'realty-management',
    loadChildren: () => import('./main.components/Ads-management/ads-management.module').then(m => m.AdsManagementModule)
  },
  { 
    path: 'info_with_chats',
    canActivate: [IsAuthGuard],
    loadChildren: () => import('./main.components/info-and-chats/info-and-chats.module').then(m => m.InfoAndChatsModule),
  },
  { 
    path: 'marketing-management',
    loadChildren: () => import('./main.components/marketing-management/marketing-management.module').then(m => m.MarketingManagementModule),
  },  
  { 
    path: 'office-dashboard',
    data: {
      USERS_TYPES: [
        usersType.office,
        usersType.superVisor
      ]
    },
    canActivate: [IsAuthGuard, MasterAuthGuard],
    loadChildren: () => import('./main.components/office-dashboard/office-dashboard.module').then(m => m.OfficeDashboardModule),
  },
  // end all lazy loading main components & modules

  //
  { 
    path: 'temporary-campaign',
    loadChildren: () => import('./temporary-campaign/temporary-campaign.module').then(m => m.TemporaryCampaignModule)
  },
  
  // not found page
  { path: '**', pathMatch: 'full', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
