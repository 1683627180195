import { Subscription } from 'rxjs';
import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges } from '@angular/core';

// custome enums
import { Countery } from 'src/app/core/enums/global/country';

// custome services
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

// validations
import { ValidationsPhone } from 'src/app/core/validations/validations-phone';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

// funcations helper
import { parseArabic } from 'src/app/core/funcations-helper/text-and-numbers/parseArabic';

@Component({
  selector: 'app-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
})
export class InputPhoneComponent implements OnInit, OnChanges, OnDestroy {
  // init props & inputs is coming
  @Input('value') value: any;
  @Input('name') name: string = 'input';
  @Input('type') type: string = 'number';
  @Input('FormParent') FormParent!: UntypedFormGroup
  @Input('validatorsErrorMsg') validatorsErrorMsg: boolean = false;

  
  // init data will outPut
  @Output() formateNumber = new EventEmitter()  
  
  // init props & inputs is coming
  countryCode: string = ''
  unSubs: Subscription[] = []
  countries: {[key: string]: any}[] = []

  // functions 
  parseArabic = parseArabic

  // 
  limit: number = 0;

  constructor(
    private Single: TopLevelSingleService,
  ) { }

  ngOnChanges() {
  }

  ngOnInit(): void {

    // call functions
    this.getCountriesCode()

  }

  // function will call Single & get all countries
  // then get countriesCode
  getCountriesCode() {

    let countries$ = this.Single.countries$.subscribe((res: any) => {
      this.countries = res.data
    })

    this.unSubs.push(countries$)

  }

  // get info country from component drop-dows as outPut
  getCode_hasSelected(obj: {[key: string]: any}) {

    this.formateNumber.emit(obj)

    this.updateValidationsPhone(obj)

  }

  updateValidationsPhone(obj: {[key: string]: any}) {

    // ES6 Destrucating
    const { numbersCount, countryCode } = obj || {}
    
    setTimeout(() => {

      this.control.setValidators([
        OverrideValidators.required,
        ValidationsPhone(obj.numbersCount), 
      ]);
  
      this.control.updateValueAndValidity()
      this.limit = numbersCount
      this.countryCode = countryCode

    }, 1)
    
  }
  

  // function will catch all errors in input
  get getError() {
    return (this.FormParent.controls[this.name].errors as ValidationErrors)
  }

  // function will get all date about input
  get control() {
    return this.FormParent.controls[this.name]
  }

  get ErrorMsg() {
    
    if (this.getError) {

      if (Object.keys(this.getError || {}).length > 0 && this.validatorsErrorMsg) {
  
        let msg: any = null;
  
        for (const key in (this.getError || {})) {
          if (this.getError[key]?.toString()) {
            msg = this.getError[key]
            break;
          }
        }
  
        return msg;
  
      }
      
    }

    return null

  }  


  ngOnDestroy(): void {
    this.unSubs.forEach(s => s.unsubscribe())
  }

}
