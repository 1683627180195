import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// enums
import { PASSWORD_LIMIT } from 'src/app/core/enums/form/controls/password-limit';
import { 
    leastOneUpperCase,
    leastOneLowerCase,
    leastOneNumber,
    leastOneLowerUpperCase,
} from 'src/app/core/enums/regx/regx-match';

// validators-override
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

abstract class PasswordValidators {

    constructor() {}

    static defaultVaidators(): ValidatorFn | null {

        return Validators.compose([
            PasswordValidators.characters,
            OverrideValidators.maxlength(PASSWORD_LIMIT.max),
            OverrideValidators.minlength(PASSWORD_LIMIT.min),
        ])

    }

    static characters = (control: AbstractControl): ValidationErrors | null => {

        // ES6
        const value = control.value?.toString() || '';

        if (value) {
            if (!leastOneNumber.test(value)) return { leastOneNumber: 'يجب ان يحتوي الحقل علي رقم علي الاقل' }
            else if (!leastOneLowerUpperCase.test(value)) return { leastOneNumber: 'يجب ان يحتوي الحقل علي حرف علي الاقل' }
            // else if (!leastOneUpperCase.test(value)) return { leastOneUpperCase: 'يجب ان يحتوي الحقل علي حرف كبير علي الاقل' }
            // else if (!leastOneLowerCase.test(value)) return { leastOneLowerCase: 'يجب ان يحتوي الحقل علي حرف صغير علي الاقل' }
        }


        return null

    }

    static equalCurrentPassword(mainPassword: string, otherePassword: string): ValidatorFn {

        
        return (control: AbstractControl): ValidationErrors | null => {

            //
            const newPassword = control.parent?.get(mainPassword);
            const currentPassword = control.parent?.get(otherePassword);

            // 
            const callback = () => {
                if (currentPassword?.value == newPassword?.value) return { equal: 'تم ادخال كلمة المرور هذه من قبل' }
                return null
            }            

            currentPassword?.valueChanges.subscribe(value => {
                if (currentPassword?.value == newPassword?.value ) newPassword?.setErrors({ equal: 'تم ادخال كلمة المرور هذه من قبل' })
                else newPassword?.setErrors(null)
            })
                
            return callback()
    
        }
        
    }

    static confirmPassword(mainPassword: string, otherePassword: string): ValidatorFn {

        
        return (control: AbstractControl): ValidationErrors | null => {

            //
            const password = control.parent?.get(mainPassword);
            const confirmPassword = control.parent?.get(otherePassword);

            // 
            const callback = () => {
                if (confirmPassword?.value != password?.value) return { confirmPassword: 'كلمة المرور غير متطابقه' }
                return null
            }

            password?.valueChanges.subscribe(value => {
                if (confirmPassword?.value != password?.value) confirmPassword?.setErrors({ confirmPassword: 'كلمة المرور غير متطابقه' })
                else confirmPassword?.setErrors(null)
            })
            
            return callback()
    
        }
        
    }



}

export default PasswordValidators