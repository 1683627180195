import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';

// guards
import { UnAuthGuard } from './un-auth.guard';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { AuthService } from 'src/app/core/services/user/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReauthToDeleteAccountGuard {

  constructor(
      private G: GlobleService,
      private Auth: AuthService,
      private UnAuthGuard: UnAuthGuard
) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {
            
        // es6 destructuring
        const { url } = state || {}
        const { navigationGuardTo } = route.data || {}

        const token = this.G.getUserData(User.token) || null
        const usrtInfo = this.G.getUserData(User.info) || null

        if (token || usrtInfo) {

            const $o = this.Auth.logOut({ StopNavigate: true, enableReturn: true }) as Observable<any>;

            return $o.pipe(map(v => this.UnAuthGuard.canActivate(route, state)), catchError(err => of(this.UnAuthGuard.canActivate(route, state))))
        }

        return true
    }
  
}
