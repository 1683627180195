import { Pipe, PipeTransform } from '@angular/core';

// enums
import { agencyType } from 'src/app/core/enums/user/agency-type';

@Pipe({
  name: 'agencyTypeTitle'
})
export class AgencyTypeTitlePipe implements PipeTransform {

  transform(value: string, ...args: any[]) {

    if (value) {
      switch(value) {
        case agencyType.office:
            return 'مكتب عقاري';
        case agencyType.company:
            return 'شركة';
        case agencyType.freeWork:
            return 'وسيط عقاري';
        case agencyType.user:
            return 'مستخدم';
        default:
            return 'غير مطابق'
      }
    }
    
    console.warn(`value is not found => ${value}`)

    return ''

  }

}
