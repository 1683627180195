import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// custom modules
import { ChatModule } from '../chat/chat.module';
import { SharedModule } from '../../shared.modules/shared.module';

// main components
import { FloatingChatBoxComponent } from './floating-chat-box.component';




@NgModule({
  declarations: [
    FloatingChatBoxComponent
  ],
  imports: [
    ChatModule,
    SharedModule,
    CommonModule,
  ],
  exports: [
    FloatingChatBoxComponent
  ]
})
export class FloatingChatBoxModule { }
