import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

// services
import { AuthService } from 'src/app/core/services/user/auth.service';

export const updateUserInfoByTokenResolver: ResolveFn<Observable<IuserInfo | null>> = (route, state) => {
  
  //
  const _auth = inject(AuthService);


  return _auth.getUser('', true)


};
