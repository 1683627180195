import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// service
import { GlobleService } from '../globle.service';

// enums
import { User } from 'src/app/core/enums/user/info-user';

@Injectable()
export class CountersService {

  adsRequestCount = new BehaviorSubject<number | null>(this.localData ? this.localData?.adsRequestCount : 0);
  countContact = new BehaviorSubject<number | null>(this.localData ? this.localData?.contactRequestCount : 0);

  constructor(private G: GlobleService) { }


  // ** Contact Request ** /
    get CountContactRequest() {
      return this.countContact
    }

    set setCountContactRequest(value: number | null) {

      if (value) {
        this.setlocalData = {
          ...this.localData,
          contactRequestCount: value
        }
      }

      this.countContact.next(value)
      
    }
  // ** Contact Request ** /

  

  // ** Ads Request ** /
    get CountAdsRequest() {
      return this.adsRequestCount
    }

    set setCountAdsRequest(value: number | null) {

      if (value || value == 0) {
        this.setlocalData = {
          ...this.localData,
          adsRequestCount: value
        }
      }
      
      this.adsRequestCount.next(value)
      
    } 
  // ** Ads Request ** / 


  // globale function
  get localData() {
    return this.G.getUserData(User.info)
  }

  set setlocalData(value: any) {
    this.G.saveUserData(User.info, value)
  }
  
}
