import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit, AfterViewInit, AfterContentChecked, AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-second-header',
  templateUrl: './second-header.component.html',
  styleUrls: ['./second-header.component.scss']
})
export class SecondHeaderComponent implements OnInit, AfterViewInit, AfterContentInit {

  // init props is coming
  @Input('srcImg') backGroundImg: string

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {}
  
  ngAfterViewInit(): void {}
  
  ngAfterContentInit(): void {

    // what happen here and why we get element 
    // and check in url then add or remove className
    // becouse this component some times will view in mobile as webView
    // if component run in webView We want the user to not be able to interact with other components 
    // so that they can't navigate between pages except for the currently displayed page
    this.route.queryParams.subscribe(p => {
      // ES6 Destructuring
      const { view } = p || {};
      
      setTimeout(() => {
        const el =  document.getElementById('wrap_navigate');
            
        if (view == 'mobile') el?.classList.add('d-none')
        else el?.classList.remove('d-none')
      }, 1)
    })
  }

}
