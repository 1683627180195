<!-- start app-about -->
<div class="faq">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">{{FaqPageTitle}}</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>

    <div class="des">
        <div class="custom_container">
            <div 
                [id]="f.id"
                class="wrap_fq" 
                *ngFor="let f of faqData"
            >
                <div 
                    class="
                        d-flex 
                        wrap_img 
                        float-end
                        position-relative
                        align-items-center
                        effect border_def_5th 
                        justify-content-center 
                    " 
                    (click)="toggelShare(f.id)"
                >
                    <div 
                        @fade
                        class="wrap_share_btns" 
                        *ngIf="showShareBtn == f.id" 
                    >
                        <share-buttons
                            theme="circles-dark"
                            [include]="[
                            'facebook',
                            'twitter',
                            'linkedin',
                            'whatsapp',
                            'copy',
                            ]"
                            class="pt-5"
                            [showIcon]="true"
                            [showText]="false"
                            [title]="f.question"
                            [description]="f.answer"
                            [url]="app_url + '/faq' + '?question=' + f.id"
                        >
                        </share-buttons>                            
                    </div>
                    <img src="/assets/img/share.svg"  alt="icon share"/>
                </div>
                <h3 class="label_lg_bold">{{f.question}}</h3>
                <p class="label_lg_normal">{{f.answer}}</p>
                <div class="clearfix"></div>
                <div class="wrap_media" *ngIf="f?.media">
                    <!-- 21:9 aspect ratio -->
                    <ng-container [ngSwitch]="f?.mediaType | lowercase">
                        <ng-container *ngSwitchCase="File_Type.video">
                            <div class="embed-responsive embed-responsive-21by9 mt-3">
                                <video controls  class="_media embed-responsive-item w-100" [src]="f?.media"></video>
                            </div>                    
                        </ng-container>
                        <ng-container *ngSwitchCase="File_Type.img">
                            <div class="wrap_img mt-3">
                                <img 
                                    [src]="f?.media"
                                    appImgUrlCheck
                                    class="img-fluid img_fit _media" 
                                    defaultImg="/assets/img/def_img_ads.png"
                                />
                            </div>                             
                        </ng-container>
                    </ng-container>
                </div>                
            </div>
        </div>
    </div>
</div>
<!-- start app-about -->