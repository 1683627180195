<app-success
    titleBtn="موافق" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="'تم تعديل ملفك بنجاح'"
>
</app-success>

<app-failure
    titleBtn="موافق" 
    *ngIf="alertFailure"
    (Submit)="alertFailure = false"
    msg="تقدم لك منصة ساعي الكثير من المميزات و التجربة الفريدة من نوعها في العرض و الطلب العقاري, لضمان التمتع بكل المميزات يجب استكمال باقي البيانات الخاصه بك و التحقق من رقم الهوية"
>
</app-failure>

<!-- // import app-payment -->
<app-payment-response [callback]="paymentCallback.bind(this)"></app-payment-response>

<!-- start app-profile -->
<div class="profile def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container pt-4 pt-md-0">
            <form id="max1" [formGroup]="FormProfileOffice" (ngSubmit)="updateProfile()">

                <div class="wrao_top_info text-center">

                    <div class="wrap_close text-center position-relative" *ngIf="viewImgHasSelected">
                        <app-close (click)="removeFile()"></app-close>
                    </div>

                    <div class="wra_img_user border_office_4">
                        <div 
                            *ngIf="!editable" 
                            (click)="openImgFullScreen()"
                            class="custom_disabled none border_r pointer"
                        >
                        </div>                        
                        <label for="user_img">
                            <input 
                                type="file" 
                                class="d-none" 
                                id="user_img"
                                formControlName="img" 
                                (change)="upLoadFile($event)"
                            />
                        </label>
                        <img 
                            appImgUrlCheck
                            alt="user photo"
                            class="img_obj_cover"
                            [ngClass]="{'has_img': userInfo?.img || viewImgHasSelected}"
                            #appNgxViewer="NgxViewer" appNgxViewer [viewerOptions]="{transition: true}"
                            [src]="viewImgHasSelected ? (viewImgHasSelected |  safeUrl) : userInfo?.img ? userInfo?.img : '/assets/img/def_user_img.png'" 
                        />
                    </div>

                    <div class="d-flex justify-content-center">
                        <!-- import app-badge -->
                        <app-badge 
                            class="me-3"
                            bg="#21305D"
                            [style]="{padding: '0 16px'}"
                            [title]="userInfo?.agencyType | agencyTypeTitle" 
                        >
                        </app-badge>
                        <app-badge 
                            bg="#21305D"
                            title="الدفع الإلكتروني مفعل" 
                            [style]="{padding: '0 16px'}"
                            *ngIf="userInfo?.isMarketPlace"
                        >
                        </app-badge>
                    </div>
                    <div class="wrap_rate mt-1">
                        <app-rate-stars-count 
                            [Rate]="userInfo?.rate"
                        >
                        </app-rate-stars-count>
                    </div>
                    <div class="wrap_package border_r d-inline-block text_md_bold effect mb-2 dir_left">
                        <a [href]="'tel:' + userInfo?.phone" class="a_none d-block">
                            <div class="wrap d-flex algin-items-center">
                                <span>{{userInfo?.phone | phoneNumber}}</span>
                            </div>
                        </a>
                    </div>
                    <br>
                    <ng-container>
                        <div class="wrap_package border_r d-inline-block text_sm_bold effect">
                            <a routerLink="/subscriptions" class="a_none d-block">
                                <div class="wrap d-flex algin-items-center">
                                    <img src="/assets/img/crown.svg" alt="icon crown">
                                    <span>ترقية الباقة</span>
                                </div>
                            </a>
                        </div>
                    </ng-container>
                    <div 
                        class="package_info"
                        *ngIf="userInfo?.hasAdsPackage || userInfo?.hasPropertyPackage"
                    >
                        <ul 
                            *ngIf="userInfo?.hasAdsPackage"
                            class="list_none color_white d-inline-block"
                        >
                            <li class="label_lg_bold">
                                <span class="package_type">الاعلانات المتبقية</span>
                                <span class="package_name text_md_bold d-inline-block border_r">{{userInfo?.availableAds}}</span>
                            </li>
                            <li class="label_lg_bold">
                                <span class="package_type">باقة الاعلانات الحالية</span>
                                <span class="package_name text_md_bold d-inline-block border_r">
                                    <object>{{userInfo?.adsPackage?.name}}</object>
                                    <object class="ms-1 badge bg-primary">{{userInfo?.adsPackage?.availableAds}}</object>
                                </span>
                            </li>                         
                            <li class="label_lg_bold">
                                <span class="package_type">تاريخ انتهاء باقة الاعلانات</span>
                                <span class="package_name text_md_bold d-inline-block border_r">{{ userInfo?.packageEndDateMillSec | date: 'MM/dd/yyyy'}}</span>
                            </li>
                        </ul>
                        <ul
                            *ngIf="userInfo?.hasPropertyPackage"
                            class="list_none color_white d-inline-block"
                        >
                            <li class="label_lg_bold">
                                <span class="package_type">الاعلانات المتبقية</span>
                                <span class="package_name text_md_bold d-inline-block border_r">{{userInfo?.availableProperty}}</span>
                            </li>
                            <li class="label_lg_bold">
                                <span class="package_type">باقة الاملاك الحالية</span>
                                <span class="package_name text_md_bold  d-inline-block border_r">
                                    <object>{{userInfo?.propertyPackage?.name}}</object>
                                    <object class="ms-1 badge bg-primary">{{userInfo?.propertyPackage?.availableAds}}</object>                                    
                                </span>
                            </li>
                            <li class="label_lg_bold">
                                <span class="package_type">تاريخ انتهاء باقة الاملاك</span>
                                <span class="package_name text_md_bold d-inline-block border_r">{{ userInfo?.propertyPackageEndDateMillSec | date: 'MM/dd/yyyy'}}</span>
                            </li>
                        </ul>                        
                    </div>
                </div>

                <div class="wrap_all custom_scroll">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- import app-label -->
                            <app-label 
                                for="fullname"
                                [style]="{color: '#fff'}"
                                title="اسم المعلن العقاري" 
                            >
                            </app-label>
        
                            <!-- import app-input-text  -->
                            <app-input-text 
                                type="text" 
                                name="fullname" 
                                placeholder="الاسم كامل"
                                [customDisabled]="!editable"
                                [FormParent]="FormProfileOffice"
                                [validations]="[ 
                                    { text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required' },
                                    { text: 'حقل يحتوي علي اكثر من 40 حرف', typeValidation: 'maxlength' },
                                    { text: 'لا يجب ان يحتوي الحقل علي ارقام او علامات', typeValidation: 'letters' }
                                ]"
                            >
                            </app-input-text>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- import app-label -->
                            <app-label 
                                for="email"
                                [style]="{color: '#fff'}"
                                title="البريد الالكتروني" 
                            >
                            </app-label>
        
                            <!-- import app-input-text  -->
                            <app-input-text 
                                type="email" 
                                name="email" 
                                [customDisabled]="!editable"
                                [FormParent]="FormProfileOffice"
                                placeholder="اكتب البريد الكتروني"
                                [validations]="[ 
                                    {text: 'صيغة البريد غير صحيح', typeValidation: 'email'},
                                ]"
                            >
                            </app-input-text>
                        </div>
                        <div class="col-12 col-md-6">
                            <div>
                                <!-- import app-label -->
                                <app-label 
                                    for="idNumber"
                                    [title]="'رقم الهوية'"
                                    [style]="{color: '#fff'}"
                                    class="d-inline-block me-1"
                                >
                                </app-label>
                                <app-badge 
                                    [title]="Nafath.countDown?.toString() + ' ثانية '"
                                    *ngIf="Nafath.countDown && !(Auth.userInfo | async)?.isVerifiedIdNumber" 
                                >
                                </app-badge>
                            </div>
                            <!-- import app-input-text  -->
                            <app-input-text 
                                type="text" 
                                name="idNumber" 
                                [customDisabled]="!editable"
                                [validatorsErrorMsg]="true"
                                placeholder="أكتب الرقم هنا .." 
                                [FormParent]="FormProfileOffice"
                                (InputValue)="getControl('idNumber').setValue(EnglishWithNumbers($event))"
                            >
                            </app-input-text>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- import app-label -->
                            <app-label 
                                for="licenseNumber"
                                [style]="{color: '#fff'}"
                                [title]="licenseNumberTitle" 
                            >
                            </app-label>
                            <!-- import app-input-text  -->
                            <app-input-field 
                                type="text" 
                                matchNumbersOnly
                                name="licenseNumber" 
                                [limitCharacters]="10"
                                [customDisabled]="!editable"
                                [validatorsErrorMsg]="true"
                                formControlName="licenseNumber"
                                placeholder="أكتب الرقم هنا ..."
                            >
                            </app-input-field>
                        </div>                        
                        <div class="col-12">
                            <!-- import app-label -->
                            <app-label 
                                for="brokerLicenseNumber"
                                [style]="{color: '#fff'}"
                                title="رقم ترخيص الوسيط (فال)" 
                            >
                            </app-label>
                            <!-- import app-input-text  -->
                            <app-input-field 
                                type="text" 
                                matchNumbersOnly
                                [limitCharacters]="10"
                                [customDisabled]="!editable"
                                [validatorsErrorMsg]="true"
                                name="brokerLicenseNumber" 
                                placeholder="أكتب الرقم هنا ..."
                                formControlName="brokerLicenseNumber"
                            >
                            </app-input-field>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
                                <!-- import app-label -->
                                <app-label 
                                    for="city"
                                    [style]="{color: '#fff'}"
                                    title="عنوان المعلن-المدينة" 
                                >
                                </app-label>
        
                                <div class="wrap_select text-center position-relative border_r">
                                    <div *ngIf="!editable" class="custom_disabled border_r"></div>
                                    <ng-select 
                                        bindValue="id"
                                        [clearable]="false"
                                        [searchable]="true"
                                        bindLabel="cityName"
                                        placeholder="مدينة"
                                        [items]="listOfcities"
                                        formControlName="city"
                                        notFoundText="لا يوجد مدن متاحة!"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('city').touched && getControl('city').errors"
                                    >
                                    </app-validation-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="bio"
                                    title="نبذة مختصرة" 
                                    [style]="{color: '#fff'}"
                                >
                                </app-label>
        
                                <div class="text-center position-relative border_r wrap_v_control">
                                    <div *ngIf="!editable" class="custom_disabled border_r"></div>
                                    <app-box-text-area 
                                        formControlName="bio"
                                        matchLettersWithNumbers
                                        placeholder="... اكتب افضل المميزات"
                                        [validations]="[
                                            { text: 'هذا الحقل مطلوب', typeValidation: 'required' },
                                        ]"
                                    >
                                    </app-box-text-area>
                                </div>
                            </div>
                        </div>                          
                    </div>

                </div>

                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="errorsMsg.length > 0">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let err of errorsMsg">
                                <!-- import app-validation-input  -->
                                <app-validation-input 
                                    [text]="err.msg"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container> 

                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn
                        title="حفظ" 
                        [submit]="true"
                        *ngIf="editable"
                        [disabled]="submitDisabled" 
                    >
                    </app-btn>
                    <!-- import app-btn -->
                    <app-btn
                        title="تعديل" 
                        [submit]="false"
                        *ngIf="!editable"
                        (click)="editable = true"
                    >
                    </app-btn>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-profile-->