import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-check',
  templateUrl: './input-check.component.html',
  styleUrls: ['./input-check.component.scss']
})
export class InputCheckComponent implements OnInit, OnChanges {

  // init inputs is coming
  @Input('value') value: any
  @Input('style') style: {} = {}
  @Input('name') name: string = ''
  @Input('checked') checked: boolean = false
  @Input('FormParent') FormParent!: UntypedFormGroup
  @Input('disabled') disabled: boolean = false

  // emit
  @Output() checkChange = new EventEmitter()
  @Output() markAsTouched = new EventEmitter()

  // init props
  touched: boolean = false

  constructor() { }

  ngOnChanges(): void {
    if (this.disabled) {
      this.getControl?.disable()
    }    
  }

  ngOnInit(): void {
  }

  change(event: Event) {
    
    // get value from input
    let value = (event.target as HTMLInputElement).checked

    // push value outside component
    this.checkChange.emit(value)

  }

  isTouched() {
    if (!this.touched) {
      this.touched = true
      this.markAsTouched.emit(true)
    }
  }

  get getControl() {
    if (this.FormParent) {
      return this.FormParent.controls[this.name]
    }
    return     
  }

}
