<!-- start app-input-file -->
<ng-container [formGroup]="FormParent" *ngIf="FormParent; else WithOutForm">
    
    <div class="wrap_input_file position-relative">
        <div class="wrap_close text-center" *ngIf="nameFile" (click)="$event.stopPropagation()">
            <app-close (click)="removeFile(); upLoadFile($event)"></app-close>
        </div>             
        <label 
            [for]="Id" 
            class="lable_file text_md_bold border_def_2th border_r text-center pointer"
            [ngClass]="{'border_def_3th': getControl.valid && getControl.value?.length > 0}"            
        >       
            <input 
                #refInput
                [id]="Id"
                type="file"
                [name]="name"
                [style]="style"
                [formControlName]="name"
                (change)="upLoadFile($event)"
                [class]="' input_file pointer ' + class"
            />
            <img *ngIf="getControl.invalid || !nameFile" src="/assets/img/file-2.svg" alt="file">
            <span *ngIf="getControl.valid" class="d-block file_name elp">{{nameFile}}</span>
            <ng-container *ngIf="uploadProgress && uploadProgress != HTTP_EVENT_TYPE.errorResponse">
                <span class="file_progress_bar position-absolute" [style]="{width: (uploadProgress + '%')}">
                    <span *ngIf="uploadProgress == HTTP_EVENT_TYPE.response" class="d-block file_name elp">{{nameFile}}</span>
                    <span class="count label_lg_bold color_white" *ngIf="$any(uploadProgress) > 0">{{uploadProgress + '%'}}</span>
                </span>
            </ng-container>
        </label>
    </div>
</ng-container>

<ng-template #WithOutForm>
    <div class="wrap_input_file position-relative">
        <div 
            class="wrap_close text-center"
            (click)="$event.stopPropagation()"
            *ngIf="nameFile && (uploadProgress == 0 || uploadProgress == HTTP_EVENT_TYPE.response)"
        >
            <app-close (click)="removeFile(); upLoadFile($event)"></app-close>
        </div>             
        <label 
            [for]="Id" 
            [ngClass]="{'border_def_3th': nameFile}"
            class="lable_file text_md_bold border_def_2th border_r text-center pointer"
        >       
            <input 
                #refInput
                [id]="Id"
                type="file"
                [name]="name"
                [style]="style"
                [disabled]="disabled"
                (click)="touched()"
                (cancel)="upLoadFile($event)"
                (change)="upLoadFile($event)"
                [class]="' input_file pointer ' + class"
            />
            <img *ngIf="!nameFile" src="/assets/img/file-2.svg" alt="file">
            <span *ngIf="nameFile" class="d-block file_name elp">{{nameFile}}</span>
            <ng-container *ngIf="uploadProgress && uploadProgress != HTTP_EVENT_TYPE.errorResponse">
                <span class="file_progress_bar position-absolute" [style]="{width: (uploadProgress + '%')}">
                    <span *ngIf="uploadProgress == HTTP_EVENT_TYPE.response" class="d-block file_name elp">{{nameFile}}</span>
                    <span class="count label_lg_bold color_white" *ngIf="$any(uploadProgress) > 0">{{uploadProgress + '%'}}</span>
                </span>
            </ng-container>            
        </label>
    </div>
</ng-template>

<!-- // show if validations have errors -->
<ng-container *ngIf="validations">
    <ng-container *ngFor="let validation of validations">
        <!-- import app-validation-input -->
        <app-validation-input
            [text]="validation.text"
            *ngIf="
            getError
            && 
            getControl?.touched 
            && 
            getError[validation.typeValidation]
            "
        >
        </app-validation-input>
    </ng-container>
</ng-container>
<!-- end app-input-file -->