import { Component, OnInit, OnDestroy } from '@angular/core';

// custom services
import { AuthService } from 'src/app/core/services/user/auth.service';
import { Subscription } from 'rxjs';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  alert: boolean = false
  unSub$: Subscription[] = []
  aboutData?: {[key: string]: any}
  userAuth: boolean | string | null = null  

  constructor(
    private Auth: AuthService,
    public TopLevelApi: TopLevelSingleService
  ) { }

  ngOnInit(): void {

    // liten to obvs to take condition
    let token$ = this.Auth.Token.subscribe(token => {
      // get user token
      this.userAuth = token
    })  

    //
    this.unSub$.push(token$)

  }

  ngOnDestroy(): void {
    this.unSub$.map(s => {
      if (s) s.unsubscribe()
    })
  }

}
