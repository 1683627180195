import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// c services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { FULLNAME_LIMIT } from 'src/app/core/enums/form/controls/fullName-limit';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// validations
import { Validations_LettersOnly } from 'src/app/core/validations/validations-letters-only';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss']
})
export class CompleteProfileUserComponent implements OnInit, OnDestroy {

  // init props & input is coming
  file: any
  citys: [] = []
  id: string = ''
  isSuccess!: IhttpError
  navigation!: Inavigation
  subs: Subscription[] = [];
  isLoading: boolean = false
  alertSuccess: boolean = false
  listInput: InputField[] = []
  FormCompleteProfile!: UntypedFormGroup
  userInfo: {[key: string ]: any} = {}
  FullUserData: {[key: string ]: any} = {}

  constructor(
    private FB: UntypedFormBuilder,
    private G: GlobleService,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private API_RESPONSE: ApiResponseService,
    ) { 

    //** init value DI class **/
    this.FormCompleteProfile = this.FB.group({
      img: [null],
      city: [null, Validators.required],
      email: [null,[Validators.required, OverrideValidators.email]],
      fullname: [null,[ Validators.required, Validators.maxLength(FULLNAME_LIMIT.max) ,Validations_LettersOnly]],
    })


  }

  ngOnInit(): void {

    // set value proparty
    this.userInfo = this.G.getUserData(User.info)

    // ES6 Destructing
    const { country } = this.userInfo;

    // get params
    this.id = this.G.decryptData(UrlRoute.PARAM, this.route.snapshot.params['id'])

    this.navigation = {label: 'استكمال ملفي', path: '/'}

    this.listInput = [
      {
        type: 'file', name: 'img', placeholder: '', title: 'صوره شخصيه', validators: [
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },     
      {
        type: 'text', name: 'fullname', placeholder: 'أكتب الاسم هنا ..', title: 'الاسم', validators: [
          { text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
          { text: 'حقل يحتوي علي اكثر من 15 رقم', typeValidation: 'maxlength'},
          { text: 'لا يجب ان يحتوي الحقل علي ارقام او علامات', typeValidation: 'letters' }
        ]
      },
      {
        type: 'email', name: 'email', placeholder: 'اكتب البريد الالكتروني هنا ..', title: 'البريد', validators: [
          {text: 'صيغة البريد غير صحيح', typeValidation: 'email'},
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },
      {
        type: 'box_select', name: 'city', placeholder: '', title: 'المدينة', validators: [
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },    
    ]

    if (this.userInfo) {
      this.API_RESPONSE.getCitiesByCountry(country?.id).subscribe(
        (res: any) => this.citys = res,
        err => this.isSuccess = err.error
      )
    } else {
      this.G.navigate(['/sign-up-user'])
    }

  }

  // function will get all date about input
  getControl(name: string) {
    return this.FormCompleteProfile.controls[name]
  }

  // get file as output date from component input-img
  getFile(file: {}) {
    this.file = file
    /* 
      this code have problem with ReactiveForm or Template Driven Form
      becouse it not allow init value input file by any type data without string
      to save you from any attacking or hacker will send file
    */
    // this.FormCompleteProfile.patchValue({
    //   img: file,
    // })
    // // Recalculates the value and validation status of the control
    // this.FormCompleteProfile.get('img')?.updateValueAndValidity()
  }
  

  success() {

    this.alertSuccess = false   

    // ES6 Destructuring
    const { token, user } = this.FullUserData

    // update obvs
    this.Auth.updateToken(token)
    this.Auth.updateUserInfo(user)
  
    // navigate to home
    this.G.navigate(['/'])

  }  

  updateProfile() {

    this.isLoading = true
    // spride ECMAScript 2018
    // let data: {} = {...this.FormCompleteProfile.value, img: this.file}

    var formData = new FormData();
    
    for(const key in this.FormCompleteProfile.value) {
      
      if (this.FormCompleteProfile.value[key] != null) {
        formData.append(key,  this.FormCompleteProfile.value[key])
        if (key == 'img') {
          formData.append(key,  this.file)
        }
      } 
    }
    
    // after get id country => post sign up data  
    this.API_RESPONSE.completeUserProfile(this.id, formData).subscribe(
      (res: any) => {

        //
        this.isLoading = false
        this.alertSuccess = true

        // 
        this.FullUserData = res;

      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      },
    )

  }

  ngOnDestroy(): void {}

}
