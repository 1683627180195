<!-- start app-bread-crumb -->
<ng-container [ngSwitch]="type">

    <!-- view ads -->
    <ul 
        *ngSwitchCase="breadCrumbTypes.viewAds"
        class="list_none bread_crumb border_r view_ads text-white d-flex gap-2 flex-column flex-md-row align-items-center justify-content-between"
    >
        <li class="font_20 font_600">( {{ownerName || 'لا يوجد معلن'}} )</li>
        <li class="wrap_user text-center d-flex flex-column flex-md-row align-items-center justify-content-center">
            <span class="type font_16 font_400">صفته: {{AdsInfo?.advertiserClass | advertiserTypeTitle}}</span>
            <a class="position-relative" [routerLink]="['/user-profile/view', AdsInfo?.owner?.id]">
                <div class="wrap_img overflow-hidden d-flex align-items-center justify-content-center mx-auto">
                    <img class="img-fluid border_r_2th img_fit" appImgUrlCheck [src]="AdsInfo?.owner?.img" alt="user photo" />
                </div>
                <span class="user_name d-block font_14 font_700 main_color mt-1 text-decoration-underline">{{AdsInfo?.owner?.fullname}}</span>
            </a>
        </li>
        <li class="font_18 font_600">
            رخصة فال : <span class="font_400">{{AdsInfo?.owner?.brokerLicenseNumber || 'لايوجد'}}</span>
        </li>
    </ul>

    <!-- vip -->
    <!-- <ul *ngSwitchCase="breadCrumbTypes.vip" class="list_none bread_crumb border_r">
        <li [value]="AdsInfo?.category?.categoryName">
            <span>{{AdsInfo?.title || 'العقار'}}</span>
        </li>
        <li class="arrow">
            <img src="/assets/img/arrow-w.svg" alt="img arrow right">
        </li>
        <li 
            [value]="AdsInfo?.date" 
            class="el_count_down position-relative"            
        >
            <ng-container *ngIf="CountDownObj?.value else Available">
                <span>يبدأ البيع بعد </span>
                <span class='count_down'>
                    <span 
                        *ngIf="CountDownObj?.LessThenDate?.value"
                        class="less_then_date text-nowrap text_md_bold"
                    >
                        و {{CountDownObj?.LessThenDate?.value + ' ' + CountDownObj?.LessThenDate?.title}}
                    </span>
                    {{CountDownObj?.value || 'متاح'}}
                </span>
                <span class='date_title'>{{CountDownObj?.title || '----'}}</span>
            </ng-container>
            <ng-template #Available>متاح</ng-template>
        </li>
        <li class="dir_left text_md_bold">
            <span class="me-2">رقم ترخيص الوسيط (فال) : </span>
            <span>{{(AdsInfo?.owner?.brokerLicenseNumber) || 'غير موجود'}}</span>
        </li>
        <div class="clearfix"></div>
    </ul>     -->

    <!-- default -->
    <ul *ngSwitchDefault class="list_none bread_crumb border_r">
        <li [value]="AdsInfo?.category?.categoryName">
            <span>{{AdsInfo?.category?.categoryName || 'العقار'}}</span>
        </li>
        <li class="arrow">
            <img src="/assets/img/arrow-w.svg" alt="img arrow right">
        </li>
        <li value="(" *ngIf="AdsInfo?.contractType == contract_Type.RT">( </li>
        <li [value]="AdsInfo?.contractType"> {{AdsInfo?.contractType | contractType}} </li>
        <li 
            [value]="AdsInfo?.contractType"
            *ngIf="AdsInfo?.contractType == contract_Type.RT"
        >
            - {{ AdsInfo?.rentType | rentType }}
        </li>
        <li value=")" *ngIf="AdsInfo?.contractType == contract_Type.RT"> )</li>
        <li class="arrow">
            <img src="/assets/img/arrow-w.svg" alt="img arrow right">
        </li>
        <!-- <li [value]="AdsInfo?.id">
            <span>الرقم</span>
        </li>
        <li class="arrow">
            <img src="/assets/img/arrow-w.svg" alt="img arrow right">
        </li> -->
        <li [value]="AdsInfo?.id">
            <span>{{AdsInfo?.id}}</span>
        </li>
        <li class="dir_left"><span>{{AdsInfo?.createdAt | date: 'MM/dd/yyy | h:mm a'}}</span></li>
        <div class="clearfix"></div>
    </ul>    
    
</ng-container>
<!-- end app-bread-crumb -->