import { Subscription } from 'rxjs';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {

  // init Inputs is coming
  @Input('showIconChat') showIconChat: boolean = false

  // catch all ElementRef
  @ViewChild('footer', {static: true }) footer!: ElementRef<HTMLElement>

  // init props
  endPage: boolean = false

  //
  unSubs: Subscription[] = [];
  
  constructor(
    public G: GlobleService,
    public TopLevelApi: TopLevelSingleService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // run if user scroll bar
    // window.onscroll = () => this.scroll()

  }
  // get all height window
  scroll(): void {

    // **/
    const top = window.scrollY,
          h = window.innerHeight,
          total = top + h;

    // check if user scroll to footer => run expression
    if (total > this.footer.nativeElement.offsetTop + 54.5) this.endPage = true
    else this.endPage = false
    
  }

  ngOnDestroy(): void {
    this.unSubs.forEach(s => s && s.unsubscribe())
  }
  

}
