<!-- start app-about -->
<div class="about">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">عن ساعي</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>

    <div class="des">
        <div class="custom_container">
            <p class="label_lg_normal" [innerHTML]="(TopLevelApi.about$ | async)?.aboutUs"></p>
        </div>
    </div>
</div>
<!-- start app-about -->