import { Directive, ElementRef, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

import Viewer from 'viewerjs';

@Directive({
  selector: '[appNgxViewer]',
  exportAs: 'NgxViewer'
})
export class NgxViewerDirective implements  AfterViewInit, OnDestroy {

  @Input() viewerOptions: Viewer.Options = {};

  @Output() viewerReady: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerShow: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerShown: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerHide: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerHidden: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerView: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerViewed: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerZoom: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() viewerZoomed: EventEmitter<Event> = new EventEmitter<Event>();

  instance: Viewer;

  consoleCount = 0;

  private nativeElement: HTMLElement;

  constructor(private elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  public ngAfterViewInit(): void {
    this.initViewer();
  }

  private initViewer(): void {
    if (this.instance) {
      this.instance.destroy();
    }

    this.instance = new Viewer(this.nativeElement, {
      // Transitions currently break the Viewer when running optimizations during ng build (i.e in prod mode)
      // TODO: Find a fix for this so we don't have to force disable transitions
      transition: false,
      ...this.viewerOptions
    });

    if (!this.consoleCount) {
      console.log('Viewer instance: ', this.instance);
      this.consoleCount =+ 1;
    }

    this.nativeElement.addEventListener('ready', event => {
      // console.log('ready - addEventListener', event)
      this.viewerReady.emit(event), false
    });
    this.nativeElement.addEventListener('show', event => {
      // console.log('show - addEventListener', event)
      this.viewerShow.emit(event), false
    });
    this.nativeElement.addEventListener('shown', event => {
      // console.log('shown - addEventListener', event)
      this.viewerShown.emit(event), false
    });
    this.nativeElement.addEventListener('hide', event => {
      // console.log('hide - addEventListener', event)
      this.viewerHide.emit(event), false
    });
    this.nativeElement.addEventListener('hidden', event => {
      // console.log('hidden - addEventListener', event)
      this.viewerHidden.emit(event), false
    });
    this.nativeElement.addEventListener('view', event => {
      // console.log('view - addEventListener', event)
      this.viewerView.emit(event), false
    });
    this.nativeElement.addEventListener('viewed', event => {
      // console.log('viewed - addEventListener', event)
      this.viewerViewed.emit(event), false
    });
    this.nativeElement.addEventListener('zoom', event => {
      // console.log('zoom - addEventListener', event)
      this.viewerZoom.emit(event), false
    });
    this.nativeElement.addEventListener('zoomed', event => {
      // console.log('zoomed - addEventListener', event)
      this.viewerZoomed.emit(event), false
    });
  }

  public ngOnDestroy(): void {
    if (this.instance) {
      this.instance.destroy();
    }
  }

}
