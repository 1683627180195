import {NgControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, Optional, Self } from '@angular/core';

// custome interface
import { Ivalidation } from 'src/app/core/interfaces/form/validation';

@Component({
  selector: 'app-box-text-area',
  templateUrl: './box-text-area.component.html',
  styleUrls: ['./box-text-area.component.scss'],
})
export class BoxTextAreaComponent implements OnInit {

  // OutPuts
  @Output() useChange = new EventEmitter;

  // init props & inputs is coming
  @Input('Style') Style?: string | {}
  @Input('placeholder') placeholder: string | undefined
  @Input('validations') validations: Ivalidation[] = []

  fieldValue: string = ''

  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  constructor(@Self() @Optional()public ngControl: NgControl) { 
    
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }


  }

  ngOnInit(): void {}

  writeValue(value: string) {
    this.fieldValue = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  change(event: Event) {

    // ES6
    const { value } = event.target as HTMLTextAreaElement;

    this.markAsTouched();
    
    if (!this.disabled) {

      //
      this.fieldValue = value;

      //
      this.useChange.emit(this.fieldValue);

      //
      this.onChange(this.fieldValue);

    }

  }

}
