<!-- start app-checkpint-dl-account -->
<div class="checkpoint_dl_account">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">تاكيد حذف الحساب</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>

    <form [formGroup]="FormDeleteAccount" (ngSubmit)="checkpointDeleteAccount()">
        <div class="custom_container">
            <h4 class="mb-4 sub_title bold color_black text_under">
                تتيح منصة ساعي للوسطاء العقاريين امكانية حذف حساباتهم وبياناتهم بشكل كامل
                حيث لا يمكن بعد هذه العملية استرجاع البيانات بعد حذفها باي شكل                
            </h4>
            <app-label title="سبب حذف الحساب"></app-label>
            <div class="wrap_v_control">
                <app-box-text-area 
                    matchLettersWithNumbers
                    formControlName="des"
                    placeholder="اكتب السبب"
                    [validations]="[
                        { 
                            typeValidation: 'required',
                            text: 'من فضلك يرجي مل هذا الحقل'
                        }
                    ]"
                >
                </app-box-text-area>
            </div>
            <div class="wrap_recaptcha d-flex justify-content-center align-items-center">
                <ngx-recaptcha2 
                    #captchaElem
                    [hl]="'ar'"
                    [theme]="'light'"
                    [type]="'image'"
                    [size]="'normal'"
                    [siteKey]="siteKey"
                    [useGlobalDomain]="false"
                    formControlName="recaptcha"
                    (success)="recaptchaSuccess($event)"
                >
                </ngx-recaptcha2>
            </div>
    
            <!-- // show if server backend responded by errors -->
            <ng-container *ngIf="isSuccess">
                <div class="wrap_error_server text-center">
                    <div class="wrap d-inline-block">
                        <ng-container *ngFor="let validator of isSuccess?.errors">
                            <!-- import app-validation-input  -->
                            <app-validation-input 
                                [text]="validator.msg"
                                *ngIf="!isSuccess.success"
                                [style]="{'margin-inline-start': '0px', margin: 0}" 
                            >
                            </app-validation-input>
                        </ng-container>
                    </div>
                </div>
            </ng-container>                
    
            <div class="wrap_btn text-center">
                <!-- import app-btn -->
                <app-btn
                    title="تاكيد" 
                    [submit]="true"
                    [disabled]="FormDeleteAccount.invalid || isLoading" 
                >
                </app-btn>
            </div>
        </div>

    </form>
</div>
<!-- start app-app-checkpint-dl-account -->