import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

    // init props & inputs is coming
    @Input('style') style: {} = {}
    @Input('class') class: string | undefined
    @Input('title') title: string | undefined
    @Input('for') for: string | number | undefined
    @Input('required') public required: boolean = null

  constructor() { }

  ngOnInit(): void {
  }

}
