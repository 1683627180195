import { DOCUMENT } from '@angular/common';
import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  @Input('sm') sm: boolean = false
  @Input('size') size: string = ''
  @Input('class') class: string = ''
  @Input('color') color: string = ''
  @Input('preventScroll') preventScroll: boolean = false

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    if (this.preventScroll != true) {
      this.document.documentElement.classList.add('overflow-hidden')
    }
  }

  ngOnDestroy(): void {
    this.document.documentElement.classList.remove('overflow-hidden')
  }

}
