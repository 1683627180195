export enum ID_NUMBER_LIMIT {
    min = 10,
    max = 10
}

export enum BROKER_LICENSE_NUMBER_LIMIT {
    min = 10,
    max = 10
}

export enum LICENSE_NUMBER_LIMIT {
    min = 10,
    max = 10
}