<!-- start app-important-instructions -->
<div class="important_instructions">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">ارشادات تهمك</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>

    <div class="wrap_custom_container">
        <div class="custom_container">
            <div class="wrap_col" >
                <h3 class="overflow-hidden">
                    <a 
                        class="a_none color_black"
                        target="_blank" rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/1CGqFBZuu2lJJvkDLB3W5E7HXCBna0HuA/view?usp=sharing" 
                    >
                        <span>
                            <img src="/assets/img/iconmonstr-idea-.png" alt="iconmonstr-idea" />
                            الملكية الفكرية
                        </span>
                        <i class="fas fa-cloud-download-alt float-end pointer"></i>
                    </a>
                </h3>
                <p class="label_lg_bold">
                    الئحة حماية المعلومات
                    التجارية السرية
                    الصادرة بقرار وزير التجارة والصناعة رقم 3218 وتاريخ
                    1426/3/25هـ، والمعدلة بقرار وزير التجارة والصناعة رقم 4319
                    وتاريخ 1426/5/1هـ                    
                </p>
            </div>
            <hr>
            <div class="wrap_col" >
                <h3 class="overflow-hidden">
                    <a 
                        class="a_none color_black"
                        target="_blank" rel="noopener noreferrer"
                        href="https://drive.google.com/file/d/17bUS5z3J-28jP7b5Rr0gqpUootpgO5WE/view?usp=sharing" 
                    >
                        <span>
                            <img src="/assets/img/list-square.png" alt="list-square" />
                            ضوابط الاعلانات
                        </span>
                        <i class="fas fa-cloud-download-alt float-end pointer"></i>
                    </a>
                </h3>
                <p class="label_lg_bold">
                    بناء علي ما قضت به الفقرة ( الخامسة عشرة ) من المادة ( الثالثة ) من تنظيم الهيئة بان للهيئة
                    <br/>
                    <q>وضع الضوابط اللازمة لتنظيم الاعلانات العقارية التي تنشر في جميع الوسائل و الرقابة عليها بالتنسيق مع الجهات ذات العلاقة</q>                
                </p>
            </div>
            <hr>
            <div class="wrap_col" >
                <h3 class="mb-0">سياسة الخصوصية</h3>
                <ng-container *ngIf="Terms?.terms">
                    <h4 class="label_lg_bold"> - الشروط والأحكام</h4>
                    <p textFormat class="label_lg_normal terms" [innerHTML]="Terms?.terms"></p>
                </ng-container>
                <hr>
                <ng-container *ngIf="Terms?.privacy">
                    <h4 class="label_lg_bold"> - سياسه التطبيق</h4>
                    <p textFormat class="label_lg_normal terms" [innerHTML]="Terms?.privacy"></p>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<!-- start app-important-instructions -->