import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: number | string, ...args: any[]) {

    if (value && Number(value)) {
        
        let 
            init = new Intl.NumberFormat('en-US', {style: 'decimal'}),
            format = init.format(Number(value));

        return format.replace(/,/gi, '-')

    }
    else {
        console.warn('The value cannot be converted to a number', value)
        return 'الرقم غير متاح'
    }

  }

}
