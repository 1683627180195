import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

// classes
import UserHandler from 'src/app/core/classes/user-handler';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';

// custome enums
import { usersType } from 'src/app/core/enums/user/user-type';

@Injectable({
    providedIn: 'root'
})
export class CompleteProfileGuard {

    constructor(
        private G: GlobleService,
        private _auth: AuthService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // ES6 Destructing
        const { type } = this.G.userInfo || {};

        try {

            /**
             * check then will return boolean value
             */
            const isComplete = UserHandler.isUserProfileCompleted(this.G.userInfo);

            // user profile is Completed
            if (isComplete) return true

            /**
             * else check user type then redirect to exact route to complete him profile
             */
            if (type == usersType.office) this.G.navigate(['/profile-office'], { realty: true });
            else if (type == usersType.normalUser || type == usersType.superVisor) this.G.navigate(['/profile'], { realty: true });

            //
            return false


        } catch {
            /**
             * if userInfo not object this mean user data not valid,
             * then logout to login & get valid data
             */
            this._auth.logOut()
        }

    }

}
