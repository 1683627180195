import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// c services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { Countery } from 'src/app/core/enums/global/country';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

@Component({
  selector: 'app-complete-profile-office',
  templateUrl: './complete-profile-office.component.html',
  styleUrls: ['./complete-profile-office.component.scss']
})
export class CompleteProfileOfficeComponent implements OnInit {

  // init props & input is coming
  navigation!: Inavigation
  FormCompleteProfileOffice!: UntypedFormGroup

  // string
  id: string = ''
  
  // boolean
  isLoading: boolean = false
  alertSuccess: boolean = false
  loadingWorkArea: boolean = false
  loadingworkSubCategory: boolean = false
  
  // obj
  userInfo: {[key: string ]: any} = {}
  FullUserData: {[key: string ]: any} = {}
  
  // arrat of any
  errorsMsg: any[] = []
  categories: any[] = []
  listOfAreas: any[] = []
  listOfcities: any[] = []
  subCategories: any[] = []

  constructor(
    private FB: UntypedFormBuilder,
    private G: GlobleService,
    private Auth: AuthService,
    private route: ActivatedRoute,
    private API_RESPONSE: ApiResponseService,
  ) { 

    // set value proparty
    this.userInfo = this.G.getUserData(User.info)

    // get params
    this.id = this.G.decryptData(UrlRoute.PARAM, this.route.snapshot.params['id']);
    
    this.navigation = {label: 'استكمال ملفي', path: '/'};

    // ES6 Destructing
    const { country } = this.userInfo;

    //
    this.API_RESPONSE.mainCategories().subscribe((res: any) => this.categories = res);

    //
    this.API_RESPONSE.getCitiesByCountry(country ? country?.id : Countery.id)
    .subscribe(
      (res: any) => this.listOfcities = res
    );
    

    //** init value DI class **/
    this.FormCompleteProfileOffice = this.FB.group({
      city: [null, Validators.required],
      workCity: [null, Validators.required],
      workArea: [null, Validators.required],
      workCategory: [null, Validators.required],
      workSubCategory: [null, Validators.required],
      fullname: [this.userInfo?.fullname, Validators.required]
    })

  }

  // for Test
  test(a: any) {
    console.log(a)
  }
  // for Test

  ngOnInit(): void {

    // for Test
    // this.FormCompleteProfileOffice.valueChanges.subscribe(res => console.log(res))
    // for Test

    if (!this.userInfo) this.G.navigate(['/sign-up-user'])

  }

  handelSubs(data: any, type: string) {

    if (data) {
      
      if (type == 'location') {

        this.loadingWorkArea = true
  
        // ES6 Destructing
        const { id: city } = data;
        
        // call with server
        this.API_RESPONSE.cityAreas(city, { params: { city, [RANDOM_VALUE.loader]: true }})
        .subscribe(
           (res: any) => {
            
            // stop loading
            this.loadingWorkArea = false

            // pushing data
            this.listOfAreas.push(...res)
  
            this.listOfAreas = [...this.listOfAreas]
            
           },
           err => this.loadingWorkArea = true
        )

      }
      else if (type == 'categories') {
  
        // ES6 Destructing
        const { child } = data;

        this.subCategories.push(...child)

        this.subCategories = [...this.subCategories]

      }
  
    }
    else this.listOfAreas = []

  }

  removeSubs(data: any, type: string) {

    // ES6 Destructing
    const { id } = data.value;

    if (type == 'location') {
      //
      this.listOfAreas = this.listOfAreas.filter(area => {
  
        if (area.city == id) {
  
          let ids = this.getControl('workArea').value as number[];

          if (ids?.length > 0) {

            ids.splice(ids.indexOf(area.id), 1);
            
            this.getControl('workArea').setValue(ids);
          }
          
          
        }
        if (area.city != id) {
  
          return area
        }
  
      });
    }
    else if (type == 'categories') {

      //
      this.subCategories = this.subCategories.filter(cate => {
  
        if (cate.parent == id) {
  
          let ids = this.getControl('workSubCategory').value as number[];

          if (ids?.length > 0 && ids.indexOf(cate.id) != -1) {

            ids.splice(ids.indexOf(cate.id), 1);
            
            this.getControl('workSubCategory').setValue(ids);
          }
          
        }
        if (cate.parent != id) {
          return cate
        }
  
      });      

    }


  }

  // function take tow params to return new array
  filterArray(Arr: any[], key: string): any[] {

    // init
    let filter: any[] = [];

    Arr.filter(it => {

      if (it[key]) filter.push(it[key])

    })

    return filter
  }

  success() {

    this.alertSuccess = false

    // ES6 Destructing
    const { user, token } = this.FullUserData;

    // update obvs
    this.Auth.updateToken(token)
    this.Auth.updateUserInfo(user)
  
    // navigate to home
    this.G.navigate(['/'])

  }  

  // function will get all date about input
  getControl(name: string) {
    return this.FormCompleteProfileOffice.controls[name]
  }

  CompleteProfileOffice() {

    this.isLoading = true

    this.errorsMsg = []

    let formData = new FormData();

    for (const key in this.FormCompleteProfileOffice.value) {
      if (key == 'city' || key == 'fullname' ) {
        formData.append(key, this.FormCompleteProfileOffice.value[key])
      }
      else {
        formData.append(key, `[${this.FormCompleteProfileOffice.value[key]}]`)
      }
    }

    this.API_RESPONSE.completeUserProfile(this.userInfo?.id, formData)
    .subscribe(
      (res: any) => {
        
        this.isLoading = false

        this.alertSuccess = true

        this.FullUserData = res

        // ES6 Destructing
        const { user, token } = res

      },
      (err: HttpErrorResponse) => {
        
        this.isLoading = false

        // ES6 Destructing
        const { errors } = err.error

        this.errorsMsg = errors

      }
    )

  }

  ngOnDestroy(): void {}

}
