import { Component, OnInit, OnDestroy } from '@angular/core';

// c services
import { GlobleService } from 'src/app/core/services/globle.service';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';

@Component({
  selector: 'app-account-state',
  templateUrl: './account-state.component.html',
  styleUrls: ['./account-state.component.scss']
})
export class AccountStateComponent implements OnInit, OnDestroy {

  // init props & input is coming
  userInfo!: {[key: string]: any}
  navigation!: Inavigation

  constructor(private G: GlobleService,) { }

  ngOnInit(): void {

    // set value props
    
    this.navigation = {path: '/'}    

    // get user info from localStorage
    this.userInfo = this.G.getUserData(User.info) || null    


  }

  ngOnDestroy(): void {
    localStorage.clear()
  }

}
