import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core'; 

// env
import { environment } from 'src/environments/environment';

// services
import { MainApiService } from 'src/app/core/services/main/main-api.service';

// enums
import { usersType } from 'src/app/core/enums/user/user-type';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';

interface config {
    ofl: string
    link: string
    type: usersType
}

@Injectable()
export class GenerateDeepLink {

    // static property
    static OFL: string = environment.APP_URL;
    static FIRE_API_DEEP_LINK: string = environment.FIRE_API_DEEP_LINK;
    static domainUriPrefix: string = environment.appsConfig.domainUriPrefix;
    static domainUriPrefixOffice: string = environment.appsConfig.domainUriPrefixOffice;

    // App config
    static iosOfficeInfo = {
        iosBundleId: environment.appsConfig.iosOfficeInfo.iosBundleId,
        iosAppStoreId: environment.appsConfig.iosOfficeInfo.iosAppStoreId,
    }

    static androidOfficeInfo = {
        androidPackageName: environment.appsConfig.androidOfficeInfo.androidPackageName,
    }

    static iosUserInfo = {
        iosBundleId: environment.appsConfig.iosUserInfo.iosBundleId,
        iosAppStoreId: environment.appsConfig.iosUserInfo.iosAppStoreId,
    }

    static androidUserInfo = {
        androidPackageName: environment.appsConfig.androidUserInfo.androidPackageName
    }    

    constructor(private API: MainApiService) {}

    // /**
    //  * 
    //  * @param type 
    //  * @returns 
    //  */
    // private getConfigParams(type: usersType) {

    //     if (type == usersType.office) {
    //         return `&apn=${GenerateDeepLink.androidOfficeInfo.androidPackageName}&ibi=${GenerateDeepLink.iosOfficeInfo.iosBundleId}&isi=${GenerateDeepLink.iosOfficeInfo.iosAppStoreId}`
    //     }
    //     else if (type == usersType.normalUser) {
    //         return `&apn=${GenerateDeepLink.androidUserInfo.androidPackageName}&ibi=${GenerateDeepLink.iosUserInfo.iosBundleId}&isi=${GenerateDeepLink.iosUserInfo.iosAppStoreId}`
    //     }

    //     return ''
    // }

    /**
     * 
     * @param type 
     * @returns 
     */
    private getConfigParamsAsJson(type: usersType, ofl: string) {

        const isOffice = type == usersType.office;

        // init
        const config = {
            desktopInfo: {
                desktopFallbackLink: ofl,
            },            
            iosInfo: {
                iosBundleId: isOffice ? GenerateDeepLink.iosOfficeInfo.iosBundleId : GenerateDeepLink.iosUserInfo.iosBundleId,
                iosAppStoreId: isOffice ? GenerateDeepLink.iosOfficeInfo.iosAppStoreId : GenerateDeepLink.iosUserInfo.iosAppStoreId,
                // iosFallbackLink: isOffice ? 'https://apps.apple.com/us/app/ساعي-الوسيط/id1586113986' : 'https://apps.apple.com/us/app/ساعي-saaei/id1450106367',

            },
            androidInfo: {
                // androidFallbackLink: ofl,
                androidPackageName: isOffice ? GenerateDeepLink.androidOfficeInfo.androidPackageName : GenerateDeepLink.androidUserInfo.androidPackageName,
            },
            domainUriPrefix: isOffice ? GenerateDeepLink.domainUriPrefixOffice : GenerateDeepLink.domainUriPrefix,
        };

        return config

    }

    public generateDeepLink(config: config) {

        // {
        // longDynamicLink: 
        // `${type == usersType.office ? GenerateDeepLink.domainUriPrefixOffice : GenerateDeepLink.domainUriPrefix}/
        // ?link=${link + this.getConfigParams(type) + '&ofl=' + ofl}`,
        // },        

        // ES6 Destrucating
        let { ofl, type, link } = config || {};
 
        ofl = `${GenerateDeepLink.OFL}/${ofl}`;
        link = `${GenerateDeepLink.domainUriPrefix}/${link}`;

        const req = this.API.mainPost(GenerateDeepLink.FIRE_API_DEEP_LINK,
            {             
                dynamicLinkInfo: {
                    link,
                    ...this.getConfigParamsAsJson(type, ofl)
                },
                suffix: {option: "UNGUESSABLE" },
            },
            {
                params: { 
                    [RANDOM_VALUE.loader]: true,
                    key: environment.firebase.apiKey,
                }            
            }
        ).pipe(map((v: any) => v?.shortLink))

        return req
    }   

}