<!-- start app-add-realty-request -->
<section class="search_inputs_box" id="search_inputs_box">
    <div class="custom_container position-relative">
        
        <!-- start app-search-result-box -->
        <app-loader [preventScroll]="true" [sm]="true" size="fa-8x" *ngIf="IsLoadingSearch"></app-loader>

        <form [formGroup]="Search.FormSearch" (ngSubmit)="viewReset(); submit()">
            <div class="wrap_boxs">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="title text-center color_black my-4">الان ابحث عن عقارك</h4>
                            </div>
                            <!-- <div class="col-12">
                                <ul class="p-0">
                                    <li class="label_lg_bold mb-3">طريقة البحث الاولي</li>
                                    <li class="label_lg_bold">طريقة البحث التانيه</li>
                                </ul>
                            </div>                            -->
                            <div class="col-12 col-md-6 wrap_full_control">
                                <app-label title="المدينة"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        class="text-center"
                                        [clearable]="false"
                                        bindLabel="cityName"
                                        formControlName="city"
                                        placeholder="اختيار المدينة"
                                        notFoundText="لا يوجد مدن متاحة!"
                                        [items]="Search.ListOfCity"
                                        (change)="handlerGetAreasByCity($event)"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 wrap_full_control">
                                <app-label title="الحي"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="true"
                                        bindLabel="areaName"
                                        formControlName="area"
                                        placeholder="اختيار الحي"
                                        notFoundText="لا يوجد احياء متاحة!"
                                        [items]="Search.ListOfAreas"
                                        [loading]="Search.isLoadingArea"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12 wrap_full_control ">
                                <app-label title="نوع العقار"></app-label>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="الرئيسي"
                                        bindLabel="categoryName"
                                        formControlName="category"
                                        notFoundText="لا يوجد فئة رئيسية متاحة!"
                                        [items]="Search.listOfCategory"
                                        (change)="handlerGetSubByMainCategories($event)"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="الفرعي"
                                        bindLabel="categoryName"
                                        formControlName="subCategory"
                                        notFoundText="لا يوجد فئة فرعية متاحة!"
                                        [loading]="Search.loadingSubCate"
                                        [items]="Search.listOfSubCategory"
                                        (change)="handlerGetFeature($event)"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="IsApproveCurrentUserLocation && showSearchMethod">
                        <div class="col-12 wrap_full_control">
                            <app-label title="اختيار طريقة البحث"></app-label>
                            <div class="wrap_box">
                                <ng-select 
                                    bindValue="id"
                                    bindLabel="name"
                                    class="text-center"
                                    [clearable]="false"
                                    [searchable]="false"
                                    placeholder="اختيار الطريقة"
                                    formControlName="searchMethod"
                                    notFoundText="لا يوجد اختيارات متاحة!"
                                    (change)="Search.searchMethodSelected($event?.id)"
                                    [items]="[
                                        {name: 'بحث عشوائي', id: 1},
                                        {name: 'بحث بناء علي  احدثيات المستخدم', id: 2},
                                    ]"
                                >
                                </ng-select>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12">
                        <div class="wrap_box d-flex align-items-center">
                            <app-input-check 
                                name="advSearch"
                                (checkChange)="AdvancedSearch = $event"
                            >
                            </app-input-check>
                            <app-label class="pointer" for="advSearch" title="بحث متقدم"></app-label>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{'d-none': !AdvancedSearch}">
                        <div class="col-12 wrap_full_control">
                            <app-label title="السعر"></app-label>
                            <div class="wrap_box">
                                <ng-select 
                                    bindValue="id"
                                    bindLabel="title"
                                    class="text-center"
                                    [clearable]="false"
                                    [searchable]="false"
                                    [items]="listOfPricesRange"
                                    formControlName="priceRange"
                                    placeholder="اختيار السعر"
                                    (change)="pricesRangeStatus($event?.id)"
                                />
                            </div>
                        </div>
                        <div class="col-12 wrap_full_control">
                            <app-label title="مساحة العقار"></app-label>
                            <div class="wrap_box">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="wrap_input">
                                            <input 
                                                type="text"
                                                placeholder="من"
                                                formControlName="sizeFrom"
                                                class="custom_input text_md_bold border_def_2th border_r text-start"
                                                (input)="Search.control('sizeFrom').setValue(NumberOnly($any($event?.target).value, true))"                                                
                                                [ngClass]="{'border_def_3th': Search.control('sizeFrom')?.value && Search.control('sizeFrom')?.valid}"                                                            
                                            />
                                            <div class="wrap_validation m-0">
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'هذا الحقل مطلوب'"
                                                    *ngIf="Search.control('sizeFrom')?.touched && Search.control('sizeFrom')?.errors?.required"
                                                >
                                                </app-validation-input>
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'صيفة الرقم خطاء'"
                                                    *ngIf="
                                                        Search.control('sizeFrom')?.touched
                                                        &&
                                                        Search.control('sizeFrom')?.errors?.pattern
                                                    "
                                                >
                                                </app-validation-input>
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'اقل عدد هو 1'"
                                                    *ngIf="Search.control('sizeFrom')?.touched && Search.control('sizeFrom')?.errors?.min"
                                                >
                                                </app-validation-input>                                                            
                                            </div>
                                        </div>                                  
                                    </div>
                                    <div class="col-6">
                                        <div class="wrap_input">
                                            <input 
                                                type="text"
                                                placeholder="إلى"
                                                formControlName="sizeTo"
                                                class="custom_input text_md_bold border_def_2th border_r text-start"
                                                (input)="Search.control('sizeTo').setValue(NumberOnly($any($event?.target).value, true));"
                                                [ngClass]="{'border_def_3th': Search.control('sizeTo')?.value && Search.control('sizeTo')?.valid}"
                                            />
                                            <div class="wrap_validation m-0">
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'هذا الحقل مطلوب'"
                                                    *ngIf="Search.control('sizeTo')?.touched && Search.control('sizeTo')?.errors?.required"
                                                >
                                                </app-validation-input>
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'صيفة الرقم خطاء'"
                                                    *ngIf="
                                                        Search.control('sizeTo')?.touched
                                                        &&
                                                        Search.control('sizeTo')?.errors?.pattern
                                                    "
                                                >
                                                </app-validation-input>                                            
                                                <app-validation-input 
                                                    class="mb-0"
                                                    [text]="'اقل عدد هو 1'"
                                                    *ngIf="Search.control('sizeTo')?.touched && Search.control('sizeTo')?.errors?.min"
                                                >
                                                </app-validation-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        class="wrap_validation m-0" 
                                        *ngIf="Search.FormSearch?.errors"
                                    >
                                        <app-validation-input 
                                            class="mb-0"
                                            *ngIf="Search.FormSearch?.errors?.inverse_size"
                                            [text]="Search.FormSearch?.errors?.inverse_size"
                                        >
                                        </app-validation-input>
                                        <app-validation-input 
                                            class="mb-0"
                                            *ngIf="Search.FormSearch?.errors?.equal_size"
                                            [text]="Search.FormSearch?.errors?.equal_size"
                                        >
                                        </app-validation-input>
                                    </div>                                
                                </div>
                            </div>
                        </div>                    
                        <div class="col-12">
                            <div class="wrap_full_control">
                                <app-label title="نوع التعاقد"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        bindLabel="name"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="التعاقد"
                                        [items]="ListOfContractType"
                                        formControlName="contractType"
                                        (change)="contractToggle($event?.id)"
                                        notFoundText="لا يوجد انواع تعاقد متاحة!"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="Search.showRent" @fade>
                            <div class="wrap_full_control">
                                <ng-select 
                                    bindValue="id"
                                    bindLabel="name"
                                    class="text-center"
                                    [clearable]="false"
                                    [searchable]="false"
                                    placeholder="نوع التاجير"
                                    formControlName="rentType"
                                    notFoundText="لا يوجد انواع لي تاجير متاحة!"
                                    [items]="ListOfRentType"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="wrap_full_control">
                                <app-label title="الحالة"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        bindLabel="name"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="الحالة"
                                        formControlName="status"
                                        notFoundText="لا يوجد اختيارات متاحة!"
                                        [items]="ListOFAdStatus"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="wrap_full_control">
                                <app-label title="نوع الترتيب"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindValue="id"
                                        bindLabel="name"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        formControlName="sortByPrice"
                                        placeholder="ترتيب حسب السعر"
                                        notFoundText="لا يوجد اختيارات متاحة!"
                                        [items]="Search.sortByPrice"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>     
                        <div class="col-12">
                            <div class="wrap_full_control">
                                <app-label title="تاريخ الاضافة"></app-label>
                                <div class="wrap_box">
                                    <ng-select 
                                        bindLabel="title"
                                        class="text-center"
                                        [clearable]="false"
                                        [searchable]="false"
                                        bindValue="startDate"
                                        formControlName="startDate"
                                        placeholder="الفترة المحددة"
                                        notFoundText="لا يوجد اختيارات متاحة!"
                                        [items]="ListFormateDate"
                                    >
                                    </ng-select>
                                </div>
                            </div>
                        </div>                                     
                        <div class="col-12">
                            <div class="wrap_full_control">
                                <app-label title="تفاصيل أخرى"></app-label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngIf="!Search.showRent">
                            <div class="wrap_box d-flex align-items-center">
                                <app-input-check 
                                    name="enableInstallment"
                                    [FormParent]="Search.FormSearch" 
                                >
                                </app-input-check>
                                <app-label class="pointer" for="enableInstallment" title="التقسيط"></app-label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="wrap_box d-flex align-items-center">
                                <app-input-check 
                                    name="enablePhoneContact"
                                    [FormParent]="Search.FormSearch" 
                                >
                                </app-input-check>
                                <app-label class="pointer" for="enablePhoneContact" title=" التواصل عبر الجوال"></app-label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div class="wrap_box d-flex align-items-center">
                                <app-input-check 
                                    name="orderByRate"
                                    [FormParent]="Search.FormSearch"
                                >
                                </app-input-check>
                                <app-label class="pointer" for="orderByRate" title="حسب التقيم الافضل"></app-label>
                            </div>
                        </div>                    
                        <div class="col-12" *ngIf="Search.listOfProperties.length > 0 || Search.listOfFeatures.length > 0">
                            <ng-container formArrayName="properties">
                                <div class="row">
                                    <ng-container *ngIf="Search.listOfProperties.length > 0">
                                        <div class="col-12">
                                            <div class="wrap_full_control">
                                                <app-label 
                                                    class="main_color"
                                                    title="مواصفات رئيسية"
                                                >
                                                </app-label>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let prop of Search.ArrOfControls('properties')?.controls; let i = index; let control as index">
                                        <ng-container [formGroupName]="i">
                                            <ng-container  *ngIf="Search.listOfProperties[i]?.type == FeatureType.number">
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="wrap_box">
                                                        <app-label [title]="Search.listOfProperties[i]?.name"></app-label>
                                                        <div class="wrap_input wrap_box">
                                                            <input 
                                                                type="text"
                                                                placeholder="من"
                                                                formControlName="from"
                                                                class="custom_input text_md_bold border_def_2th border_r text-start"
                                                                (input)="control?.get('from')?.setValue(NumberOnly($any($event?.target).value, true))"
                                                                [ngClass]="{'border_def_3th': control?.get('from')?.value && control?.get('from')?.valid}"                                                            
                                                            />
                                                            <div class="wrap_validation m-0">
                                                                <app-validation-input 
                                                                    class="mb-0"
                                                                    [text]="'هذا الحقل مطلوب'"
                                                                    *ngIf="control?.get('from')?.touched && control?.get('from')?.errors?.required"
                                                                >
                                                                </app-validation-input>
                                                                <app-validation-input 
                                                                    class="mb-0"
                                                                    [text]="'اقل عدد هو 1'"
                                                                    *ngIf="control?.get('from')?.touched && control?.get('from')?.errors?.min"
                                                                >
                                                                </app-validation-input>                                                            
                                                            </div>
                                                        </div>
                                                        <div class="wrap_input wrap_box">
                                                            <input 
                                                                type="text"
                                                                placeholder="إلى"
                                                                formControlName="to"
                                                                class="custom_input text_md_bold border_def_2th border_r text-start"
                                                                (input)="control?.get('to')?.setValue(NumberOnly($any($event?.target).value, true))"
                                                                [ngClass]="{'border_def_3th': control?.get('to')?.value && control?.get('to')?.valid}"
                                                            />
                                                            <div class="wrap_validation m-0">
                                                                <app-validation-input 
                                                                    class="mb-0"
                                                                    [text]="'هذا الحقل مطلوب'"
                                                                    *ngIf="control?.get('to')?.touched && control?.get('to')?.errors?.required"
                                                                >
                                                                </app-validation-input>
                                                                <app-validation-input 
                                                                    class="mb-0"
                                                                    [text]="'اقل عدد هو 1'"
                                                                    *ngIf="control?.get('to')?.touched && control?.get('to')?.errors?.min"
                                                                >
                                                                </app-validation-input>
                                                            </div>                                                        
                                                        </div>                                              
                                                        <div 
                                                            class="wrap_validation m-0" 
                                                            *ngIf="control?.errors"
                                                        >                                                       
                                                            <app-validation-input 
                                                                class="mb-0"
                                                                *ngIf="control?.errors?.inverse"
                                                                [text]="control?.errors?.inverse"
                                                            >
                                                            </app-validation-input>
                                                            <app-validation-input 
                                                                class="mb-0"
                                                                *ngIf="control?.errors?.equal"
                                                                [text]="control?.errors?.equal"
                                                            >
                                                            </app-validation-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
    
                                            <ng-container *ngIf="Search.listOfProperties[i]?.type == FeatureType.list">
                                                <div class="col-12">
                                                    <div class="wrap_box">
                                                        <app-label [title]="Search.listOfProperties[i]?.name"></app-label>
                                                        <div class="wrap_box">
                                                            <ng-select 
                                                                bindValue="id"
                                                                bindLabel="name"
                                                                [multiple]="true"
                                                                [clearable]="false"
                                                                [searchable]="false"
                                                                class="sm text-center"
                                                                formControlName="value"
                                                                [closeOnSelect]="false"
                                                                notFoundText="لا يوجد اختيارات متاحة!"
                                                                [items]="Search.listOfProperties[i]?.options"
                                                                [placeholder]="Search.listOfProperties[i]?.name"
                                                            >
                                                            </ng-select>                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>                                
                                </div>
                            </ng-container>                        
                        </div>
    
                        <ng-container *ngIf="Search.listOfFeatures.length > 0">
                            <div class="col-12">
                                <div class="wrap_full_control">
                                    <app-label 
                                        class="main_color"
                                        title="مواصفات فرعية"
                                    >
                                    </app-label>
                                </div>
                            </div>
    
                            <ng-container *ngFor="let Ft of Search.ArrOfControls('features').controls; let i = index; let control as index">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="wrap_box d-flex flex-column">
                                        <div class="wrap">
                                            <app-input-check 
                                                [name]="Search.listOfFeatures[i]?.name"
                                                [checked]="control?.value == Search.listOfFeatures[i]?.id"
                                                (checkChange)="setFeatures($event, Search.listOfFeatures[i], i)"
                                            >
                                            </app-input-check>
                                            <app-label 
                                                class="pointer" 
                                                [for]="Search.listOfFeatures[i]?.name"
                                                [title]="Search.listOfFeatures[i]?.name"
                                            >
                                            </app-label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12" [ngClass]="{'col-md-6': Search.FormSearch.dirty && IsSubmitForm}">
                                <div class="btns text-center">
                                    <app-btn 
                                        [submit]="true"
                                        [title]="'بحث'"
                                        [disabled]="FormIsDisabled"
                                    >
                                    </app-btn>   
                                </div>
                            </div>
                            <div 
                                class="col-12 col-md-6" 
                                *ngIf="Search.FormSearch.dirty && IsSubmitForm"
                            >
                                <div class="btns text-center">
                                    <app-btn 
                                        [submit]="false"
                                        (click)="resetForm()"
                                        [disabled]="IsLoadingSearch"
                                        [title]="'ازالة محتوي البحث'"
                                        [style]="'background-color: #FF9917'"
                                    >
                                    </app-btn>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section>
<!-- end app-add-realty-request -->