import { Component, Input, OnInit } from '@angular/core';
import { Inavigation } from 'src/app/core/interfaces/inavigation';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  // init props & inputs is coming
  @Input('style') style!: {}
  @Input('navigate') navigate!: Inavigation

  constructor() { }

  ngOnInit(): void {
  }

}
