<!-- start app-info-card-model -->
<div class="custome_model">
    <div class="wrap_info">
        <div 
            (click)="close()"
            class="wrap_close pointer effect d-flex justify-content-center align-items-center"
        >
            <img src="/assets/img/plus.svg" alt="">
        </div>
        <app-info-card [info]="data || {}" [VipAds]="data?.specialAdvertisement" [viewState]="viewState"></app-info-card>
        <div class="text-center">
            <div class="wrap_price border_r text_sm_bold d-inline-block" >
                <a 
                    class="a_none d-block"
                    [queryParams]="{view: viewState ? true : null}"
                    [routerLink]="['/realty-management/view-realty-advertisement', data?.id]" 
                >
                    المزيد
                </a>
            </div>
        </div>
    </div>
</div>
<!-- end app-info-card-model -->