import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

// funcations helper
import { ArrayHasSameValue } from 'src/app/core/funcations-helper/arrays/array-has-value';

// enums
import { User } from 'src/app/core/enums/user/info-user';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { usersType } from 'src/app/core/enums/user/user-type';

@Injectable({
  providedIn: 'root'
})
export class MasterAuthGuard implements CanActivate {

  USERS_TYPE: usersType[] = []
  userInfo?: {[key: string]: any};

  constructor(private G: GlobleService) {
    this.userInfo = this.G.getUserData(User.info) || null;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    //
    this.USERS_TYPE = route.data.USERS_TYPES;

    if (ArrayHasSameValue(this.USERS_TYPE, this.userInfo?.type)) return true;

    // navigate to home
    this.G.navigate(['/'])
    return false

  }
  
}
