<!-- start app-navbar -->
<nav class="nav_bar">
    <div class="custom_container_2th">
        <div class="pre_nav_sm_screen">
            <a routerLink="/" class=" float-start">
                <img class="img-fluid" src="/assets/img/main_logo.svg" alt="logo">
            </a>
            <button class="btn open_menu border_def_2th float-end" (click)="openMenu()">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div class="clearfix"></div>
        </div>        
        <div class="wrap_nav d-flex justify-content-between" [ngClass]="{'show': isOpenMenu}">
            <div class="wrap_section wrap_info_user_section">
                <div class="logo float-start">
                    <button class="btn open_profile border_def_2th" (click)="openProfile()">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <a routerLink="/">
                        <img class="img-fluid" src="/assets/img/main_logo.svg" alt="logo">
                    </a>
                </div>
                <div 
                    class="user_state float-start" 
                    [ngClass]="{
                        'show': isOpenProfileMdScreen,
                        'isAuth_user': userInfo?.type != usersType.office,
                        'isAuth_office': userInfo?.type == usersType.office || userInfo?.type == usersType.superVisor
                    }"
                >
                    <div class="wrap_img border_def_4th text-center float-start">
                        <a href="#" *ngIf="!token">
                            <img class="img-fluid" src="/assets/img/user.svg" alt="user icon">
                        </a>
                        
                        <a 
                            *ngIf="token" 
                            (click)="openMenu()"
                            [routerLink]="(userInfo?.type == usersType.normalUser || userInfo?.type == usersType.superVisor) ? 'profile' : 'profile-office'" 
                        >
                            <img 
                                alt="img user" 
                                appImgUrlCheck
                                class="img-fluid img_obj_cover" 
                                [src]="userInfo?.img ? userInfo?.img : '/assets/img/def_user_img.png'" 
                            />
                        </a>
                    </div>                  
                    <div class="wrap_state float-start lh_none">
                        <div class="wrap_all float-start">
                            <div 
                                class="wrap_info float-start"
                                [ngClass]="{
                                    'isAuth': token,
                                    'emptyName': token && !userInfo?.fullname
                                }"
                            >
                                <span 
                                    dir="auto"
                                    class="text_md_bold d-inline-block user_name float-start"
                                >
                                    {{token ? (userInfo?.fullname || 'حدث ملفك') : 'غير مسجل'}}
                                </span>
                                <span 
                                    *ngIf="token"
                                    (click)="logOut(); openMenu()"
                                    class="d-inline-block float-start sign_out pointer" 
                                >
                                    <img class="img-fluid" src="/assets/img/sign-out.svg" alt="sign-out icon">
                                </span>
                            </div>
                            <div class="clearfix"></div>
                            <div class="auth float-start" *ngIf="!token" (click)="openMenu()">
                                <a class="text_md_normal float-start" routerLink="sign-up-user">انشاء حساب</a>
                                <a class="text_md_normal float-start" routerLink="sign-in-user">تسجيل الدخول</a>
                            </div>
                            <div class="location float-start" *ngIf="token && userInfo?.city?.cityName">
                                <img class="img-fluid" src="/assets/img/location.svg" alt="location icon">
                                <span class="path text_md_normal lh_none">{{userInfo?.city?.cityName}}</span>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="order_count float-start text-center" *ngIf="token">
                            <a 
                                placement="left"
                                [animation]="true"
                                (click)="openMenu()"
                                [queryParams]="{owner : true}"
                                [ngbTooltip]="'الطلبات العقارية'"
                                class="float-start a_none pointer"
                                tooltipClass="custom_edit_ngbTooltip"
                                routerLink="request_and_realty/realty_required"
                            >
                                <span>
                                    <img src="/assets/img/build_black.svg" alt="build_black">
                                    <small class="text_sm_bold a_none">{{adsRequestCount | limitCount}}</small>
                                </span>
                                <span class="text_md_normal text-decoration-underline">الطلبات</span>
                            </a>
                            <a 
                                (click)="openMenu()"
                                placement="left"
                                [animation]="true"
                                tooltipClass="custom_edit_ngbTooltip"
                                class="float-start a_none pointer"
                                routerLink="request_and_realty/contact_request"                                
                                [ngbTooltip]="'المرسلات علي الطلبات و الاعلانات العقارية'"
                            >
                                <span>
                                    <img src="/assets/img/caht_black.svg" alt="caht_black">
                                    <small class="text_sm_bold a_none">
                                        {{contactRequestCount | limitCount}}
                                    </small>
                                </span>
                                <span class="text_md_normal text-decoration-underline">المراسلات</span>
                            </a>
                            <a 
                                (click)="openMenu()"
                                placement="left"
                                [animation]="true"
                                routerLink="/marketing-management"
                                class="float-start a_none pointer"
                                tooltipClass="custom_edit_ngbTooltip"                                
                                [ngbTooltip]="'خدمة للتسويق العقار و الربح'"
                                *ngIf="userInfo?.type == usersType.office"
                            >
                                <span>
                                    <i class="fa-solid fa-handshake"></i>
                                </span>
                                <span class="text_md_normal text-decoration-underline">سوق</span>
                            </a>                            
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="wrap_section wrap_page_section">
                <ul class="list_none">
                    <li >
                        <a class="text_md_normal a_none effect" routerLink="/about" (click)="openMenu()">عن ساعي</a>
                    </li>
                    <li >
                        <a class="text_md_normal a_none effect" routerLink="/faq" (click)="openMenu()">الاسئله الشائعه</a>
                    </li>
                    <!-- <li >
                        <a class="text_md_normal a_none effect" routerLink="/contact-us" (click)="openMenu()">تواصل معنا</a>
                    </li> -->
                    <li>
                        <a class="text_md_normal a_none effect" routerLink="/news" (click)="openMenu()">اخبار العقار</a>
                    </li>
                    <li *ngIf="!token">
                        <a class="text_md_normal a_none effect" routerLink="/important-instructions" (click)="openMenu()">ارشادات تهمك</a>
                    </li>                    
                </ul>
            </div>
            <div class="wrap_section wrap_tools_section parent">
                <ul class="list_none">
                    <!-- <li>
                        <span class="text_md_normal">العربية</span>
                    </li> -->
                    <ng-container *ngIf="token">
                        <li 
                            tabindex="-1"
                            placement="left"
                            class="position-relative" 
                            (blur)="toggleAds(null, $event)"
                            (click)="toggleAds(null, $event)" 
                            tooltipClass="custom_edit_ngbTooltip"
                            [ngbTooltip]="'خدمة طلب او اضافة عقار'"
                        >
                            <img class="pointer" src="/assets/img/plus_round.svg" alt="plus_round">
                            <ul 
                                @fade
                                *ngIf="isOpenAds"
                                class="list_none text-center" 
                            >
                                <li 
                                    class="pointer" 
                                    (click)="
                                        openMenu();
                                        $event.stopPropagation();
                                        toggleAds('realty-management/realty-request/add');
                                        "
                                >
                                    <a class="a_none effect text_md_bold">طلب عقاري</a>
                                </li>
                                <li 
                                    class="pointer" 
                                    (click)="
                                        openMenu();
                                        $event.stopPropagation();
                                        toggleAds('/realty-management/add-realty-advertisement');
                                    "
                                >
                                    <a class="a_none effect text_md_bold">
                                        إضافة عقار
                                    </a>
                                </li>
                                <li 
                                    class="pointer" 
                                    *ngIf="userInfo?.enableSpecialAds"
                                    (click)="
                                        openMenu();
                                        $event.stopPropagation();
                                        toggleAds('/realty-management/add-realty-vip-advertisement');
                                    "
                                >
                                    <a class="a_none effect text_md_bold">
                                        إضافة اعلان خاص
                                    </a>
                                </li>                                
                            </ul>
                        </li>
                        <li (click)="openMenu()">
                            <a 
                                placement="left"
                                [animation]="true"                                
                                class="a_none pointer"
                                [queryParams]="{owner : true}"
                                [ngbTooltip]="'الاعلانات العقارية'"
                                tooltipClass="custom_edit_ngbTooltip"                                
                                routerLink="request_and_realty/realty"
                            >
                                <img src="/assets/img/build.svg" alt="build">
                            </a>
                        </li>
                        <li (click)="openMenu()">
                            <a 
                                class="position-relative"
                                routerLink="/notification"
                            >
                                <span 
                                    *ngIf="unReadCountNotifi > 0"
                                    class="notification_count"
                                    [ngClass]="{
                                        'text_sm_bold': unReadCountNotifi > 99,
                                        'text_md_bold': unReadCountNotifi < 100
                                    }"
                                >
                                    {{unReadCountNotifi > 99 ? '+99' : unReadCountNotifi}}
                                </span>
                                <img src="/assets/img/notification.svg" alt="notification">
                            </a>
                        </li>
                        <li (click)="openMenu()">
                            <a class="position-relative"  routerLink="/setting">
                                <span 
                                    *ngIf="G.IncompleteUserInfo(userInfo)"
                                    class="position-absolute top-0 left-100 translate-middle p-2 bg-danger border border-light rounded-circle"
                                >
                                </span>                                
                                <img src="/assets/img/setting.svg" alt="setting">
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="!token">
                        <a href="#">
                            <img src="/assets/img/setting-2.svg" alt="setting">
                        </a>
                    </li>                    
                </ul>
            </div>
            <div class="copy">
                <p class="text_md_normal mb-0">حقوق الملكية محفوظة لساعي © {{G.currentDate.getFullYear()}}</p>
            </div>
            <!-- <div class="clearfix"></div> -->
        </div>
    </div>
</nav>
<!-- end app-navbar -->