import { AbstractControl, NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, OnInit, AfterContentInit } from '@angular/core';

// funcations
import { limitCharacters } from 'src/app/core/funcations-helper/text-and-numbers/limit-characters';

@Directive({
  selector: '[limitCharacters]'
})
export class LimitCharactershDirective implements OnInit, OnChanges {

    //
    @Input('limitCharacters') limitCharacters: number = 0;

    ctr: AbstractControl | null;

    @HostListener('input') run() {
        this.limit()
    }

    constructor(
        private ngControl: NgControl,
        private el: ElementRef<HTMLInputElement>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.limit()
    }
    // ngAfterContentInit(): void {
    //     setTimeout(() => {
    //       if (this.ctr?.value) this.ctr.patchValue(this.ctr.value)
    //     }, 1)
    //   }
    
    ngOnInit(): void {
        this.el.nativeElement.setAttribute('dir', 'auto');

        if (this.ngControl) this.ctr = this.ngControl?.control

    }

    limit() {
        if (this.ctr?.value) {
            this.ctr?.patchValue(limitCharacters(this.ctr.value, this.limitCharacters))
        }
    }

}
