import { UntypedFormGroup, NgControl, ValidationErrors } from '@angular/forms';
import { 
  Input,
  OnInit,
  Output,
  Component,
  OnChanges,
  EventEmitter,
  SimpleChanges,
  Optional,
  Self,
} from '@angular/core';

// custome interfaces
import { Ivalidation } from 'src/app/core/interfaces/form/validation';

// custom animations
import { fade } from 'src/app/core/animations/fade';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  animations: [fade]
})
export class InputFieldComponent implements OnInit, OnChanges {

  // init data will outPut
  @Output() file = new EventEmitter()
  @Output() InputValue = new EventEmitter()

  // init inputs
  @Input('style') style: any;
  @Input('class') class: string = '';
  @Input('type') type: string = 'text';
  @Input('name') name: string = 'input';
  @Input('value') fieldValue: string = '';
  @Input('loading') loading: boolean = false;
  @Input('disabled') c_disabled!: boolean;
  @Input('customDisabled') customDisabled!: boolean;
  @Input('validations') validations: Ivalidation[] = []
  @Input('placeholder') placeholder: string = 'type here...';
  @Input('validatorsErrorMsg') validatorsErrorMsg: boolean = false;

  // init props
  nameFile: string = ''
  viewImgHasSelected: string = ''  


  onChange = (value) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  constructor(@Self() @Optional()public ngControl: NgControl) { 
    
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }


  }

  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit(): void {}

  writeValue(value: string) {
    this.fieldValue = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  removeFile() {
    if (this.getControl?.value?.length > 0) {
      this.nameFile = ''
      if (this.ngControl) this.getControl?.reset()
    }
  }

  upLoadFile(event: Event) {

    if (event.type == 'change') {

      const file = (event.target as HTMLInputElement).files?.item(0)
  
      // outPut data
      this.file.emit(file)
      this.nameFile = file?.name as string
    } else {
      this.file.emit(null)
    }
  }

  change(event: Event) {

    // ES6
    const { value } = event.target as HTMLInputElement;

    this.markAsTouched();
    
    if (!this.disabled) {

      //
      this.fieldValue = value;

      //
      this.InputValue.emit(this.fieldValue);

      //
      this.onChange(this.fieldValue);

    }

  }
  
  // getters

  get getControl() {
    return this.ngControl.control
  }
    
  // function will catch all errors in input
  get getError(): ValidationErrors | null {
    
    return this.ngControl.errors

  }

  get ErrorMsg() {
    
    if (this.getError) {

      if (Object.keys(this.getError || {}).length > 0 && this.validatorsErrorMsg) {
  
        let msg: any = null;
  
        for (const key in (this.getError || {})) {
          if (this.getError[key]?.toString()) {
            msg = this.getError[key]
            break;
          }
        }
  
        return msg;
  
      }
      
    }

    return null

  }  


}
