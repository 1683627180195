import { Router } from "@angular/router";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// interceptor //
import { HandelCurdInterceptor } from './core/interceptor/handel-curd.interceptor';

// Module libs outside
import * as Sentry from "@sentry/angular";
import { SocketIoModule } from 'ngx-socket-io';
import { NgxCaptchaModule } from 'ngx-captcha';

// firebase
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';

// environment
import { environment } from 'src/environments/environment';

// custom Modules create by my team
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.modules/shared.module';
import { SearchModule } from "./shared.system.modules/search/search.module";
import { PaymentModule } from './high.level.shared.modules/payment/payment.module';
import { FloatingChatBoxModule } from './high.level.shared.modules/floating-chat-box/floating-chat-box.module';


// custom services
import { CountDownPipe } from './core/pipes/numbers/count-down';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { NafathService } from 'src/app/core/services/user/nafath.service';
import { LayoutsService } from 'src/app/core/services/layouts/layouts.service';
import { CountersService } from 'src/app/core/services/counters/counters.service';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { SeoMetaDataService } from 'src/app/core/services/meta-data/seo-meta-data.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { GenerateDeepLink } from 'src/app/core/services/generate-deep-link/generate-deep-link.service';

// custome directives

// custome  main components app
import { AppComponent } from './app.component';
import { FaqComponent } from './main.components/faq/faq.component';
import { HomeComponent } from './main.components/home/home.component';
import { AboutComponent } from './main.components/about/about.component';
import { ContactUsComponent } from './main.components/contact-us/contact-us.component';
import { TermsAndCondititonsComponent } from './main.components/terms-and-condititons/terms-and-condititons.component';
import { ImportantInstructionsComponent } from './main.components/important-instructions/important-instructions.component';
import { CheckpointDeleteAccountComponent } from './main.components/checkpoint-delete-account/checkpoint-delete-account.component';


// // custome  child components app
import { InformationPageComponent } from './main.components/information-page/information-page.component';
import { ProfileComponent } from './child.components/user/sub.components.home/profile/profile.component';
import { SignUpUserComponent } from './child.components/user/sub.components.home/sign-up/sign-up.component';
import { SignUpOfficeComponent } from './child.components/office/sub.components.home/sign-up/sign-up.component';
import { SignInComponent } from './child.components/shared.users/sub.components.home/sign-in/sign-in.component';
import { AccountStateComponent } from './child.components/office/sub.components.home/account-state/account-state.component';
import { VerifyAuthComponent } from './child.components/shared.users/sub.components.home/verify-auth/verify-auth.component';
import { ProfileOfficeComponent } from './child.components/office/sub.components.home/profile-office/profile-office.component';
import { ResetPasswordComponent } from './child.components/shared.users/sub.components.home/reset-password/reset-password.component';
import { CompleteProfileUserComponent } from './child.components/user/sub.components.home/complete-profile/complete-profile.component';
import { CompleteProfileOfficeComponent } from './child.components/office/sub.components.home/complete-profile-office/complete-profile-office.component';

// shared standalone
import { TabsComponent } from "./shared.standalone/tabs/tabs.component";




@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    HomeComponent,
    AboutComponent,
    SignInComponent,
    ProfileComponent,
    ContactUsComponent,
    VerifyAuthComponent,
    SignUpUserComponent,
    SignUpOfficeComponent,
    AccountStateComponent,
    ResetPasswordComponent,
    ProfileOfficeComponent,
    InformationPageComponent,
    CompleteProfileUserComponent,
    TermsAndCondititonsComponent,
    ImportantInstructionsComponent,
    CompleteProfileOfficeComponent,
    CheckpointDeleteAccountComponent,
  ],
  imports: [
    FormsModule,
    SharedModule,
    SearchModule,
    BrowserModule,
    PaymentModule,
    TabsComponent,
    HttpClientModule,
    AppRoutingModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    FloatingChatBoxModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideAnalytics(() => getAnalytics()),  
    SocketIoModule.forRoot({url: environment.SERVER_IO, options: { transports: [ 'websocket',"xhr-polling" ]}}),
  ],
  providers: [
    // providers Pipes
    CountDownPipe,

    // providers ServiceS
    AuthService,
    NafathService,
    LoaderService,
    LayoutsService,
    CountersService,
    AnalyticsService,
    GenerateDeepLink,
    SeoMetaDataService,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: HandelCurdInterceptor ,multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
