<!-- start app-input-text -->
<ng-container [formGroup]="FormParent">
    <div class="wrap">
        <div class="wrap_input float-start">
            <input 
                type="text"
                [name]="name"
                matchNumbersOnly
                parseArabicToEnglish
                placeholder="5x xxx xxxx" 
                [phoneWithCountryCode]="countryCode"
                [limitCharacters]="limit"
                [formControlName]="name"
                [ngClass]="{'border_def_3th': control.valid && !getError}"
                class="custom_input_phone text_md_bold border_def_2th border_r text-start"
            />
        </div>
        
        <ng-container>
            <div class="wrap_drop_down float-start">
                <!-- import app-drop-down -->
                <app-drop-down 
                    [data]="countries"
                    [defaultCountry]="value"
                    (changeCode)="getCode_hasSelected($event)"
                >
                </app-drop-down>
            </div>
        </ng-container>
        
        <div class="clearfix"></div>
    </div>  

    <app-validation-input
        [text]="ErrorMsg"
        *ngIf="validatorsErrorMsg && ErrorMsg && control?.touched"
    >
    </app-validation-input>    
    
    <!-- // show if validations have errors -->
    <ng-container *ngIf="getError && validatorsErrorMsg == false">
        <!-- import app-validation-input -->
        <app-validation-input
            text="هذا الحقل مطلوب"
            *ngIf="
            getError
            && 
            control?.touched 
            && 
            getError?.required
            "
        >
        </app-validation-input>
        <app-validation-input
            [text]="getError?.min_length"
            *ngIf="
            getError
            && 
            control?.touched 
            && 
            getError?.min_length
            "
        >
        </app-validation-input>
        <app-validation-input
            [text]="getError?.max_length"
            *ngIf="
            getError
            && 
            control?.touched 
            && 
            getError?.max_length
            "
        >
        </app-validation-input>                
    </ng-container>    

</ng-container> 
<!-- end app-input-text -->