<div class="wrap_rate {{ClassList}}">
    <ng-container *ngFor="let r of [1, 2, 3, 4, 5]">
        <img 
            src="/assets/img/star_true.svg"
            *ngIf="Rate >= r; else ElseRate"
        />
        <ng-template #ElseRate>
            <img src="/assets/img/star_false.svg" />
        </ng-template>
    </ng-container>
</div>