import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// c services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { NafathService } from 'src/app/core/services/user/nafath.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// directives
import { NgxViewerDirective } from 'src/app/core/directives/ngx-viewer/ngx-viewer.directive';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { usersType } from 'src/app/core/enums/user/user-type';
import { FULLNAME_LIMIT } from 'src/app/core/enums/form/controls/fullName-limit';
import { AD_NUMBER_LIMIT } from 'src/app/core/enums/form/controls/adNumber-limit';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// validations
import { Validations_LettersOnly } from 'src/app/core/validations/validations-letters-only';
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';

// reusable
import { listOfIdType } from 'src/app/core/reusable-objects/globle-objs/id-types-user';

// functions helper
import { NumberOnly } from 'src/app/core/funcations-helper/Numbers-only';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  //
  @ViewChild('appNgxViewer') ngxViewer: NgxViewerDirective;
  
  // init props & input is coming
  citys: [] = []
  getFile: any
  usersType = usersType
  userInfo!: IuserInfo
  isSuccess?: IhttpError
  navigation!: Inavigation
  listOfIdType = listOfIdType
  listInput: InputField[] = []
  viewImgHasSelected: string = ''
  cloneFrom: {[key: string]: any}
  _subscribers: Subscription[] = []
  FormUpdateProfile!: UntypedFormGroup
  
  // boolean
  realty: boolean = false
  editable: boolean = false
  isLoading: boolean = false
  fullScreen: boolean = false
  alertSuccess: boolean = false
  alertFailure: boolean = false
  isFormHasChange: boolean = false

  // functions
  NumberOnly = NumberOnly  
  

  constructor(
    private G: GlobleService,
    private route: ActivatedRoute,
    private FB: UntypedFormBuilder,
    private API_RESPONSE: ApiResponseService,
    
    //
    public Auth: AuthService,
    public Nafath: NafathService,
  ) { 

    //** init value DI class **/
    this.FormUpdateProfile = this.FB.group({
      img: [null],
      city: [null, Validators.required],
      idType: [null, Validators.required],
      idNumber: [
        null,
        [
          OverrideValidators.minlength(AD_NUMBER_LIMIT.min),
          OverrideValidators.maxlength(AD_NUMBER_LIMIT.max)
        ]          
      ],      
      email: [null, [OverrideValidators.email]],
      type: [usersType.normalUser, Validators.required],
      fullname: [null, 
        [
          OverrideValidators.required,
          Validations_LettersOnly,
          OverrideValidators.minlength(FULLNAME_LIMIT.min),
          OverrideValidators.maxlength(FULLNAME_LIMIT.max),
        ]
      ],
    })

    // get params
    this.route.queryParams.subscribe(res =>  {
      if (res['realty']) {
        this.realty = true
        this.editable = true
        this.alertFailure = true
      }
    })
    
  }
  

  ngOnInit(): void {

    this.SetSubscribers = this.Auth.userInfo.subscribe(res => {

      // disabled
      if (res?.isVerifiedIdNumber) {
        this.getControl('idNumber').disable()
      }
      
    })  
    
    //
    this.userInfo = this.G.getUserData(User.info);

    // ES6 Destructing
    const { country, type } = this.userInfo;          

    // set value proparty
    this.navigation = {label: 'ملفي الشخصي', path: '/'}

    this.updateFormUpdateProfile()

    this.listInput = [
      {type: 'text', name: 'fullname', placeholder: 'الاسم كامل', title: 'الاسم', validatorsErrorMsg: true },
      {
        type: 'box_select', name: 'city', placeholder: '', title: 'المدينة', 
        hideElement: type == usersType.superVisor,
        validators: [
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },
      {type: 'email', name: 'email', placeholder: 'اكتب البريد الكتروني', title: 'البريد', validators: [
        {text: 'صيغة البريد غير صحيح', typeValidation: 'email'},
        // {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
      ]},      
    ]

    this.API_RESPONSE.getCitiesByCountry( country?.id).subscribe(
      (res: any) => this.citys = res,
      err => this.isSuccess = err?.error      
    )

    // watch FormUpdateProfile if has any change
    this.FormUpdateProfile.valueChanges.subscribe(res => {
  
      // loop in value FormUpdateProfile
      // and return any action if user update his info
      for (const key in res) {       
        if (res[key] == this.cloneFrom[key]) this.isFormHasChange = false
        else {
          this.isFormHasChange = true
          break;
        }
      }      
    })

  }

  // start getter & setters
  private get subscribers(): Subscription[] {
    return this._subscribers
  }

  private set SetSubscribers(s: Subscription) {
    this._subscribers.push(s)
  }  
  // end getter & setters


  openImgFullScreen() {
    // if (state) this.fullScreen = true
    // else this.fullScreen = false
    this.ngxViewer.instance.show()
  }

  // function will get all date about input
  getControl(name: string) {
    return this.FormUpdateProfile.controls[name]
  }  

  removeFile() {
    if (this.FormUpdateProfile.controls['img'].value) {
      this.FormUpdateProfile.patchValue({
        img: null
      })
      this.viewImgHasSelected = ''
    }
  }  

  // select file & format him
  upLoadFile(event: Event) {
    // console.log(event)
    const file = (event.target as HTMLInputElement).files?.item(0)  as File

    this.getFile = file

    // check if user update file & back remove the file
    if (file) {
      this.getFile = file
      this.viewImgHasSelected = URL.createObjectURL(file)
    }
    else  {
      this.getFile = null
      this.viewImgHasSelected = ''
    }
  }  

  updateFormUpdateProfile() {
    
    // get user info from localStorage
    this.userInfo = this.G.getUserData(User.info) || null

    // set FormUpdateProfile By the user data
    this.FormUpdateProfile.patchValue({
      img: null,
      type: this.userInfo?.type,
      email: this.userInfo?.email,
      city: this.userInfo?.city?.id,
      idType: +this.userInfo?.idType,
      fullname: this.userInfo?.fullname,
      idNumber: this.userInfo?.idNumber,
    })

    this.cloneFrom = {...this.FormUpdateProfile.value};

    this.isFormHasChange = false

  }  

  success() {

    this.alertSuccess = false

    // ES6 Destructing
    const { idNumber, isVerifiedIdNumber } = this.userInfo || {}

    // if user coming from realty-management module
    // if (this.realty && isVerifiedIdNumber ) this.G.navigate(['/realty-management/add-realty-advertisement'])

    //
    // if (!isVerifiedIdNumber) this.Nafath.idNumberCheck(idNumber)

  }  

  failure() {

    this.alertFailure = false
    this.G.navigate(['/profile'])
  }

  updateProfile() {

    this.isLoading = true

    var formData = new FormData();
    
    // ES6 Destruction
    const { value  } = this.FormUpdateProfile;

    for(const key in value) {
      
      if (value[key] != null && value[key] != '') {
        formData.append(key,  value[key])
        if (key == 'img') {
          formData.append(key,  this.getFile || '')
        }
      }
      
    }
    
    this.API_RESPONSE.userProfileUpdate(this.userInfo?.id, formData).subscribe(
      (res: any) => {

        //
        this.editable = false
        this.isLoading = false
        this.alertSuccess = true
        this.isSuccess = undefined
        this.viewImgHasSelected = ''

        const { user, token } = res || {};

        // es6 spread operator 
        let  userInfo = {...this.userInfo, ...user,}

        // update data
        this.Auth.updateToken(token)
        this.Auth.updateUserInfo(userInfo)
        
        // 
        this.updateFormUpdateProfile()

      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      },      
    )

  }

  ngOnDestroy(): void {

    // remover all listeners
    this.subscribers.forEach(s => s && s.unsubscribe())

  }

}
