export const environment = {
  appsConfig: {
    domainUriPrefix: "https://appsaaei.page.link",
    domainUriPrefixOffice: "https://saaeioffice.page.link",
    iosOfficeInfo: {
      iosAppStoreId: "1586113986",
      iosBundleId: "com.office.saaeiApp",
    },
    androidOfficeInfo: {
      androidPackageName: "com.saaei141122.alwaseet",
    },
    iosUserInfo: {
      iosAppStoreId: "1450106367",
      iosBundleId: "com.smaat.saaeiApp",
    },
    androidUserInfo: {
      androidPackageName: "com.saaei",
    },
  },
  firebase: {
    projectId: 'saaei-b43e6',
    appId: '1:1049231795506:web:ea6940f92134a1e1c2632b',
    storageBucket: 'saaei-b43e6.appspot.com',
    apiKey: 'AIzaSyCTUJMXTu5D_Q2LohApSj8T7TB5HE65QRA',
    authDomain: 'saaei-b43e6.firebaseapp.com',
    messagingSenderId: '1049231795506',
    measurementId: 'G-7JGH5MLMFJ',
  },
  production: true,
  endPointAds: 'theAds',
  SERVER_TYPE: 'production',
  APP_URL: 'https://saaei.co',
  BASE_URL: 'https://saaei.co',
  SERVER: 'https://api.saaei.co/api/v1',
  SERVER_IO: 'https://api.saaei.co/chat',
  SERVER_MAP: 'https://maps.googleapis.com/maps/api',
  SIT_KEY: '6Ld1maweAAAAANiapbfY0cnnfLeiHKtsA2HEYVYt',
  API_KEY_MAP: 'AIzaSyCl_NbnS_4OZI1_kaV0D5gMCPTbO49qrU8',
  PAYMENT_SECRET_API_KEY: 'sk_live_Ft9Bo8ZhLafldc7mqp3iCEYu',
  APP_SECRET_KEY: '6Ld1maweAAAAAM016uNkLG8PuB0wMGi-ETxls40G',
  PAYMENT_PUBLISHABLE_API_KEY: 'pk_live_fRl8jXImHGQC3vFDgc927se6',
  FIRE_API_DEEP_LINK: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks"
};