<!-- start app-navigation -->
<div class="navigation" [style]="style">
    <app-label
        *ngIf="navigate?.label"
        class="float-start"
        [title]="navigate?.label"
        [style]="{color: '#fff'}"
        >
    </app-label>
    <a 
        [routerLink]="[navigate?.path]"
        class="icon_nav float-end border_def_2th text-center d-flex justify-content-center align-items-center" 
    >
        <!-- in future will make icon resable -->
        <img src="../../../../assets/img/plus.svg" alt="icon navigation">
    </a>
    <div class="clearfix"></div>
</div>
<!-- end app-navigation -->