import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

// interfaces
import { ICity } from 'src/app/core/interfaces/location/city';

// enums
import { Countery } from 'src/app/core/enums/global/country';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

export const citiesResolver: ResolveFn<Observable<ICity>> = (route, state) => {

  // 
  const API_RESPONSE = inject(ApiResponseService)

  // ES6 Destructing
  const { country } = inject(GlobleService).userInfo || {}

  return API_RESPONSE.getCitiesByCountry(country ? country?.id : Countery.id);

};
