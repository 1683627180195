import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// custom modules
import { AudioModule } from '../audio/audio.module';
import { ChatRoutingModule } from './chat-routing.module';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from '../../shared.modules/shared.module';

// main component
import { ChatComponent } from './chat.component';

import { registerLocaleData } from '@angular/common';
import localeArSa from '@angular/common/locales/ar-SA';
import localeArSaExtra from '@angular/common/locales/extra/ar-SA';

registerLocaleData(localeArSa, localeArSaExtra);


@NgModule({
  declarations: [
    ChatComponent
  ],
  imports: [
    AudioModule,
    SharedModule,
    CommonModule,
    PaymentModule,
    ChatRoutingModule,
  ],
  exports: [ChatComponent]
})
export class ChatModule { }
