import { Component, OnInit, OnDestroy } from '@angular/core';
import { LOCAL_METHOD } from 'src/app/core/enums/global/local-methods';

// interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { GlobleService } from 'src/app/core/services/globle.service';
import { LOCAL_DATA } from '../../core/enums/global/local-data';

@Component({
  selector: 'app-information-page',
  templateUrl: './information-page.component.html',
  styleUrls: ['./information-page.component.scss']
})
export class InformationPageComponent implements OnInit, OnDestroy {

  // init props 
  navigation!: Inavigation

  // obj
  info: {[key: string]: any}

  constructor(private G: GlobleService) {

    this.info = this.G.getLocalData(LOCAL_DATA.random, LOCAL_METHOD.session);

    // console.log(this.info)

  }

  ngOnInit(): void {

    // check
    if (!this.info) this.G.navigate(['/'])

    // set
    this.navigation = { label: 'استعلام', path: '/' }

  }

  ngOnDestroy(): void {
    sessionStorage.removeItem(LOCAL_DATA.random)
  }

}
