<!-- import app-success -->
<app-success
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="routeData?.alertMsg || 'ناجح'"
    [titleBtn]="routeData?.alertBtn || 'موافق'" 
>
</app-success>

<!-- start app-sign-up -->
<div class="verfiy_auth def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container">
            <form [formGroup]="FormVerifyAuth" (ngSubmit)="verfiyAuth()">
                <ng-container *ngFor="let Input of listInput">
                    <!-- import app-label -->
                    <app-label 
                        [for]="Input.name"
                        [title]="Input.title" 
                        [style]="{color: '#fff'}"
                    >
                    </app-label>                   
        
                    <!-- import app-input-phone  -->
                    <app-input-phone 
                        [type]="Input.type" 
                        [name]="Input.name" 
                        [FormParent]="FormVerifyAuth"
                        (formateNumber)="getCode_hasSelected($event)"
                        *ngIf="routeData.type == accountState.forgot"
                    >
                    </app-input-phone>    
                    
                    <!-- import app-input-text  -->
                    <app-input-text 
                        [type]="Input.type" 
                        [name]="Input.name" 
                        [FormParent]="FormVerifyAuth"
                        [placeholder]="Input.placeholder"
                        *ngIf="routeData.type == accountState.verify"                        
                    >
                    </app-input-text>

                </ng-container>

                <ng-container *ngIf="routeData.type == accountState.verify">
                    <span class="main_color label_lg_bold ms-2 float-start" #elCountDown>
                        {{ durationTime | countdownDate | async | date: 'mm:ss' }}
                    </span>
                    <span 
                        (click)="resendCode()" 
                        class="main_color label_lg_normal me-2 float-end resend_code"
                        [ngClass]="{'main_color text_under pointer': elCountDown?.innerHTML?.trim() == ''}"
                    >
                        اعادة ارسال الكود
                    </span>
                    <div class="clearfix"></div>                    
                </ng-container>                

                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="isSuccess">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let validator of isSuccess?.errors">
                                <app-validation-input 
                                    [text]="validator.msg"
                                    *ngIf="!isSuccess.success"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                
                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn 
                        [submit]="true"
                        [title]="routeData?.titleBtn" 
                        [disabled]="FormVerifyAuth.invalid || isLoading" 
                        >
                    </app-btn>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-sign-up -->