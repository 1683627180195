import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';
import { ICustomAlert } from './core/interfaces/alert/custom-alert';

// custome enums
import { usersType } from './core/enums/user/user-type';
import { User } from 'src/app/core/enums/user/info-user';
import { ADS_TYPES } from 'src/app/core/enums/ads/ads-types';
import { GLOBLE_KEY } from 'src/app/core/enums/sockets/globle-keys';
import { SOCKET_KEY_ADS } from 'src/app/core/enums/sockets/ads-keys';
import { ACTION_TYPE } from 'src/app/core/enums/global/action-types';
import { BROWSER_PERMISSION } from './core/enums/user/browser-permission';
import { NOTIFICATION_TYPE } from 'src/app/core/enums/user/notification-types';

// custome services
import { AlertService } from './core/services/alert/alert.service';
import { GlobleService } from 'src/app/core/services/globle.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { MainApiService } from 'src/app/core/services/main/main-api.service';
import { LayoutsService } from 'src/app/core/services/layouts/layouts.service';
import { CountersService } from 'src/app/core/services/counters/counters.service';
import { SeoMetaDataService } from './core/services/meta-data/seo-meta-data.service';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { ApiResponseService } from './core/services/handel-api-response/api-response.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';


// custom animations
import { fade } from 'src/app//core/animations/fade';
import { fadeOut } from 'src/app//core/animations/fade-out';
import { AutoSlider } from 'src/app//core/animations/auto-slide';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [AutoSlider, fade, fadeOut]
})
export class AppComponent implements OnInit, OnDestroy {

  // interfaces
  alertInfo: ICustomAlert
  unSub: Subscription[] = [];

  // enums
  ads_types = ADS_TYPES
  
  // string
  currentUrl: string = ''
  
  // boolean
  show: boolean = false
  mobileView: boolean = false
  showBoxChat: boolean = false
  dashboardIsOpen: boolean = false
  viewUiNotification: boolean = true
  
  // numbers
  adsRequestCount: number = 0
  unReadCountNotif: number = 0
  contactRequestCount: number = 0
  
  // obj
  userInfo: IuserInfo | null
  userToken: {[key: string]: any} | null

  constructor(

    // private
    private router: Router,
    private G: GlobleService,
    private Auth: AuthService,
    private API: MainApiService,
    private loader: LoaderService,
    public SEO: SeoMetaDataService,
    private Counters: CountersService,
    private Analytics: AnalyticsService,
    private API_RESPONSE: ApiResponseService,
    private TopLevelApi: TopLevelSingleService,
    
    public Alert: AlertService,
    public Layouts: LayoutsService,
    public Notifi: NotificationService,
  ) {

    // set 
    this.userInfo = this.G.getUserData(User.info) || null
    this.userToken = this.G.getUserData(User.token) || null
    
  }


  ngOnInit(): void {

    //
    this.Analytics.App()

    //
    this.Analytics.logEvent('Web_App_Visited')

    this.Auth.userInfo.subscribe(info => this.userInfo = info);

    this.Alert.alert.subscribe((res: any) => {
      setTimeout(() => this.alertInfo = res, 1)
    });

    this.TopLevelApi.about$.subscribe(res => {
      if (res) {
        
        // ES6 Destrucating
        const { shareDescription, shareTitle } = res || {};
  
        this.SEO.Title = shareTitle;
        this.SEO.Description  = shareDescription;

      }
       
    })

    this.router.events.subscribe(navg =>  {

      if (navg instanceof NavigationEnd) {

        this.currentUrl = navg.url;

        //
        if (!navg.url.includes('info_with_chats')) {
          this.showBoxChat = true
        }
        else this.showBoxChat = false

        //
        if (navg.url.includes('chats')) {
          this.viewUiNotification = false
        }
        else this.viewUiNotification = true

        //
        if (navg.url.includes('office-dashboard')) this.dashboardIsOpen = true
        else this.dashboardIsOpen = false

        // for super-visor
        // if (this.userInfo?.type == usersType.superVisor && !navg.url.includes('office-dashboard')) {
        //   this.G.navigate(['/office-dashboard'])
        // }
        
        //
        // what happen here
        // becouse this component some times will view in mobile as webView
        // if component run in webView We want the user to not be able to interact with other components 
        // so that they can't navigate between pages except for the currently displayed page        
        if (navg.url.includes('view=mobile')) this.mobileView = true
        else this.mobileView = false        

      }

    })


    // ** notification ** //

      // this.Notifi.tokenChange().subscribe(s => console.log(s))

      this.Notifi.receiveMessage?.subscribe(message => {
        
        if (this.userInfo?.type != usersType.superVisor) {

          // ES6 Destrucating
          const { info, itemID } = message?.data || {};
  
          // sound
          this.Notifi.playSound();

          if (info != NOTIFICATION_TYPE.msg || !this.currentUrl.includes(itemID || 'W_A#') || this.viewUiNotification) {
            
            // set obsv
            this.Notifi.setPushNotification = {
              value: message.data,
              actionType: ACTION_TYPE.add
            }
            
            // will increment the unReadCountNotif
            
            this.Notifi.setNotificationCount = ++this.unReadCountNotif
  
          }
        }
      })
    // ** notification ** //
    
    // ** Auth ** //
      this.Auth.userInfo.subscribe(info => {
        if (info) {
          this.userInfo = info
          this.Notifi.setNotificationCount = info?.unreadNotifs ? info?.unreadNotifs : 0
          this.Counters.setCountAdsRequest = info?.adsRequestCount ? info?.adsRequestCount : 0
          this.Counters.setCountContactRequest = info?.contactRequestCount ? info?.contactRequestCount : 0
        }
      })

      this.Auth.Token.subscribe(token => {

        if (token) {
          // handel Permission to allow or block notification
          this.Notifi.getToken()
          .then(tokenNotifi => {
            if (tokenNotifi) {

              //
              this.G.saveLocalData(BROWSER_PERMISSION.AllowNotification, { tokenNotifi });

              //
              this.API_RESPONSE.addFCMToken(tokenNotifi)
              .subscribe(
                res => console.log(res, 'Res, FCMToken'),
                err => console.log(err, 'Err, addFCMToken')
              );
              
            }
            else {
              localStorage.removeItem(BROWSER_PERMISSION.AllowNotification as any)
            }
      
          });          

        }

      })

    // ** Auth ** // 


    // ** contactRequest ** //
      // get contactRequest count
      this.Counters.CountContactRequest.subscribe(count => {
        this.contactRequestCount = count ? count : 0
      })
      
      // set contactRequest count
      this.Auth.MainListenSocketIo(GLOBLE_KEY.contactRequest).subscribe((res: any) => {

        // ES6 Destrucating
        const { success, data } = res;
        const { reciever, sender } = data;


        if (success && reciever?.id == this.G.CorrectUserId) {
          
          console.log('contactRequest is success')
    
          let up = ++this.contactRequestCount;

          this.Counters.setCountContactRequest = up

        }

      });     
    // ** contactRequest ** //


    // ** adsRequest **//
    
      // get contactRequest count
      this.Counters.CountAdsRequest.subscribe(count => {
        this.adsRequestCount = count ? count : 0
      })
      
      // set add => adsRequest 
      this.Auth.MainListenSocketIo(SOCKET_KEY_ADS.add).subscribe((res: any) => {

        // es6 destructuring
        const { success, data } = res
        const { owner } = data;

        if (owner?.id == this.G.CorrectUserId) {

          console.log('Ads Request is success')
    
          let up = ++this.adsRequestCount;

          this.Counters.setCountAdsRequest = up        

        }

      })
    // ** adsRequest **//

    // loader
    this.loader.isLoading.subscribe(isLoading => {
      setTimeout(() => {
        this.show = isLoading;
      });
    });    
    
    
  } 

  onShowNotifi(data: any) {
    this.Notifi.startMoveNotification()
  }  

  onCloseNotifi(data: any) {
    this.Notifi.startMoveNotification()
  }

  ngOnDestroy(): void {
  }

}
