import { Validators } from "@angular/forms"

export const Def_Validators = (min: number, max: number, pattern?: string) => {
    let v: Validators[] = [
        Validators.required,
        Validators.maxLength(max),
        Validators.minLength(min),
        Validators.pattern(pattern ? pattern : '')
    ]

    //
    return v
}