<!-- start app-info-page -->
<div class="info_page def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
        <div class="wrap_info text-center">
            <h3 class="label_lg_bold empty" *ngIf="!info">قائمة الاشعار فارغة</h3>
            <h3 class="title" *ngIf="info?.title">
                {{info?.title}}
            </h3>
            <p
                *ngIf="info?.description"
                class="sub_title color_white description"
            >
                {{info?.description}}
            </p>
        </div>
    </div>
</div>
<!-- end app-info-page -->