import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

// custome services
import { AuthService } from '../../services/user/auth.service';
import { GlobleService } from '../../services/globle.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStepsGuard implements CanActivate {

    constructor(
      private G: GlobleService,
      private Auth: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (this.Auth.stepsForgotPassword == 3) return true
        else {
            
            // navigate to forget-password
            this.G.navigate(['/forget-password'])
            return false
        } 
    }
  
}
