<!-- start app-input-check -->
<ng-container *ngIf="FormParent">
    <ng-container [formGroup]="FormParent">
        <input 
            [id]="name"
            [style]="style"
            type="checkbox" 
            class="float-start"
            [formControlName]="name" 
            (change)="change($event)"
            [ngClass]="{'disabled': this.getControl?.disabled}"
        />
    </ng-container>    
</ng-container>

<ng-container *ngIf="!FormParent">
    <input 
        [id]="name"
        [style]="style"
        [value]="value"
        type="checkbox" 
        class="float-start"
        [checked]="checked"
        [disabled]="disabled"
        [ngClass]="{'disabled': disabled}"
        (change)="change($event); isTouched()"
    />
</ng-container>
<!-- end app-input-check -->