import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from "rxjs/operators";

// custome services
import { LoaderService } from '../services/loader.service';
import { GlobleService } from '../services/globle.service';
import { AuthService } from '../services/user/auth.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';

// environment
import { environment } from 'src/environments/environment';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';


@Injectable()
export class HandelCurdInterceptor implements HttpInterceptor {

  handel_req!: HttpRequest<any>

  constructor(
    private G: GlobleService,
    private Auth: AuthService,
    public loader: LoaderService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // es6 destructuring
    // get token user
    const token = this.G.getUserData(User.token) || null;
    const userInfo = this.G.getUserData(User.info) || null;
  
    // check if request important to show loader screen or no
    if (!req.params.get(RANDOM_VALUE.loader)) this.loader.setLoading(true, req?.url);

    // edit any crud operation 
    if (!req.url.match(/(https|http)(:\/\/)/gi)) {
      this.handel_req = req.clone({
        url: `${environment.SERVER}/${req.url}`,
        params: req.params.delete(RANDOM_VALUE.loader),
        headers: new HttpHeaders({
          'Accept-Language': 'ar',
          'Authorization': `Bearer ${token ? token : ''}`,
        }),
      });

    } 
    else this.handel_req = req.clone({
      params: req.params.delete(RANDOM_VALUE.loader),
    });

    /// ******* for Test *************** ///
      if (!environment.production) {
        // console.log(token)
        // console.log(this.handel_req)
      }
      
    /// ******* for Test *************** ///

    return next.handle(this.handel_req).pipe(
      
      catchError((error: HttpErrorResponse) => {         
        if (error && error.status === 401) {

          // spread ES6
          let editError: HttpErrorResponse = {...error}

          if (token && userInfo) this.Auth.logOut();
          else {

            //
            if (!window.location.pathname.includes('sign-in') && !window.location.pathname.includes('reauth-to') && !req.url.includes('ads/RgeaGetData')) this.G.navigate(['/sign-in-user'])

            // destructuring ES6
            const { msg } = editError.error.errors[0]
  
            if (this.handel_req.headers.get('Accept-Language') == 'ar') editError.error.errors[0].msg = 'كلمة المرور او رقم الجوال غير صحيح'
            else editError.error.errors[0].msg = 'password or phone number is incorrect'
            // edit msg error
          }

          // 401 errors are most likely going to be because 
          // we have an expired token or  password or phone is incorrect that we need to refresh.
          return throwError(editError);

        }

        else if (error && error.status === 0) {

          // spread ES6
          let editError: HttpErrorResponse = {
            ...error,
            error: {
              errors: [{msg: 'قد يكون حجم البينات كبير او حدث عطل غير  معروف'}]
            }
          }
          
          return throwError(editError);

        }          
        else {
          return throwError(error);
        }
      }),

      finalize(() => {
        this.loader.setLoading(false,  req.url)
      }));
   
  }

}
