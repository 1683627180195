import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, AfterViewInit, Input, Inject, PLATFORM_ID } from '@angular/core';

// envs
import { environment } from 'src/environments/environment';

// services
import { GlobleService } from 'src/app/core/services/globle.service';

// 
declare var goSell: any

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit, AfterViewInit {

  @Input() callback: (...arg: any) => void = (arg: any) => console.log(arg);

  constructor(private G: GlobleService,  @Inject(PLATFORM_ID) private platformId: any,) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      goSell.showResult({
        callback: (response: any) => {
  
          // ES6 Destructuring
          const { redirect } = response?.callback || {};
  
          this.callback(response?.callback)
          
          // ES6 Destructuring
          const { url } = redirect || {};
          
          const Redirect = url.slice(environment.APP_URL.length, url.indexOf('?token'))
  
          this.G.navigate([Redirect])
  
        }
      });
      
    }

  }  

}
