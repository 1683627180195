<!-- start btn -->
<ng-container [ngSwitch]="type">

    <button 
        #buttonRef
        *ngSwitchCase="'none'"
        [style]="style"
        (click)="onEvent()"
        [disabled]="disabled" 
        [autofocus]="autofocus"
        [type]="submit ? 'submit' : 'button'"
        [ngClass]="{'disabled': disabled}"
        class="main_btn border_r none_btn {{ClassBtn ? ClassBtn : ''}}" 
    >
        <span [class]="ClassTitle">
            {{title || 'بدون اسم'}}
            <ng-container *ngIf="icon">
                <img [src]="icon" alt="icon">
            </ng-container>
        </span>
    </button>

    <button 
        #buttonRef
        *ngSwitchCase="'flex'"
        [style]="style"
        (click)="onEvent()"
        [disabled]="disabled" 
        [autofocus]="autofocus"
        [ngClass]="{'disabled': disabled}"
        [type]="submit ? 'submit' : 'button'"
        class="main_btn border_r flex_btn def_btn active {{ClassBtn ? ClassBtn : ''}}" 
    >
        <span [class]="ClassTitle">
            {{title || 'بدون اسم'}}
            <ng-container *ngIf="icon">
                <img [src]="icon" alt="icon">
            </ng-container>
        </span>
    </button>      

    <button 
        #buttonRef
        *ngSwitchDefault
        [style]="style"
        (click)="onEvent()"
        [disabled]="disabled"
        [autofocus]="autofocus"
        [ngClass]="{'disabled': disabled}"
        [type]="submit ? 'submit' : 'button'"
        class="main_btn border_r effect def_btn {{ClassBtn ? ClassBtn : ''}}" 
    >

        <ng-content select="*"></ng-content>

        <ng-container *ngIf="handlerProjectedContent == false">
            <span [class]="ClassTitle">
                <ng-container *ngIf="!IsLoading; else IsNotLoading">
                    {{title || 'بدون اسم'}}
                    <ng-container *ngIf="icon">
                        <img [src]="icon" alt="icon">
                    </ng-container>
                </ng-container>
                <ng-template #IsNotLoading>
                    <!-- start app-search-result-box -->
                    <app-loader [sm]="true" size="fa-1x" color="#fff"></app-loader>                
                </ng-template>
            </span>
        </ng-container>
    </button>
</ng-container>
<!-- end btn -->