import { AbstractControl, NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

// enums
import { Countery } from 'src/app/core/enums/global/country';

@Directive({
  selector: '[phoneWithCountryCode]'
})
export class PhoneWithCountryCodeDirective implements OnInit, OnChanges {

    //
    @Input('phoneWithCountryCode') countryCode: string = ''

    ctr: AbstractControl | null;

    constructor(
        private ngControl: NgControl,
        private el: ElementRef<HTMLInputElement>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.setCountryCode()
    }
    
    ngOnInit(): void {

        this.el.nativeElement.setAttribute('dir', 'auto');

        if (this.ngControl) {
            this.ctr = this.ngControl?.control
            this.setCountryCode()
        } 

    }

    setCountryCode() {
        
        // ES6 Destrucating
        const { value } = this.ctr || {};

        if (value && value?.includes(this.countryCode || null)) {
            this.ctr?.patchValue((<string>value)?.replace(this.countryCode, ''))
        }

    }

}
