import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitCount'
})
export class limitCountPipe implements PipeTransform {

  transform(value: number | string | null, ...args: any[]) {

    if (!value) return 0
    else if (typeof value == 'string') {
        
        let v = +value;

        return isNaN(v) ? 0 : v

    }
    else if (isNaN(value)) return 0

    // ES6 destructuring
    const [ limit, replace ] = args;
    
    let 
      lmt = limit ? limit : 99,
      rep = replace ? replace : lmt + '+';

    return value > lmt ? rep : value

  }

}
