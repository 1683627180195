import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// env
import { environment } from 'src/environments/environment';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// enums
import { User } from 'src/app/core/enums/user/info-user';

// validators
import OverrideValidators from 'src/app/core/validations/validators-override/validators-override';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, OnDestroy {

  // init props & inputs is coming
  isSuccess!: IhttpError
  navigation!: Inavigation
  FormContactUs!: UntypedFormGroup
  listInput: InputField[] = []

  // boolean
  isLoading: boolean = false

  // env
  siteKey = environment.SIT_KEY

  // obj
  userInfo?: {[key: string]: any} 
  
  
  constructor(
    private FB: UntypedFormBuilder,
    private G: GlobleService,
    private route: ActivatedRoute,
    private API_RESPONSE: ApiResponseService,
  ) { 

    //
    this.userInfo = this.G.getUserData(User.info);

    //** init value DI class **/
    this.FormContactUs = this.FB.group({
      name: [null, Validators.required],
      message: [null, Validators.required],
      recaptcha: ['', Validators.required],
      phone: [this.userInfo ? this.userInfo?.phone : 1],
      email: [null, [Validators.required, OverrideValidators.email]]
    })

  }

  ngOnInit(): void {

    // set value props
    this.navigation = {path: '/'}

    this.listInput = [
      {
        type: 'text', name: 'name', placeholder: 'الاسم كامل', title: 'الاسم', validators: [
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },
      {
        type: 'email', name: 'email', placeholder: 'البريد الكتروني ..', title: 'البريد', validators: [
          {text: 'صيغة البريد غير صحيح', typeValidation: 'email'},
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },
      {
        type: 'textarea', name: 'message', placeholder: 'اكتب الوصف ..', title: 'الوصف', validators: [
          {text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'},
        ]
      },      
    ]    

  }

  contactUs() {

    this.isLoading = true

    this.API_RESPONSE.sendcontactUsRequest(this.FormContactUs.value).subscribe(
      (res: any) => {

        //
        this.isLoading = false
        this.isSuccess = {errors: [], success: false}

        alert('لقد وصلت رسالتك إلى الادارة شكرا لي تعاونكم معنا')
        //
        if (!this.route.snapshot.queryParams['view']) {
          this.G.navigate(['/'])
        }
        this.FormContactUs.reset({
          phone: this.userInfo ? this.userInfo?.phone : 1
        })

      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      }
    )    
  }

  recaptchaSuccess(a: any) {
    console.log('recaptchaSuccess')
  }

  ngOnDestroy(): void {}

}
