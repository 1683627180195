import { BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';

// enums
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {

  private showNavbar$ = new BehaviorSubject<boolean>(true);
  private showFooter$ = new BehaviorSubject<boolean>(true);
  private showNotification$ = new BehaviorSubject<boolean>(true);

  constructor() { }

  //* start getters *//
  public get navbarLayout() {
    return this.showNavbar$
  }

  public get footerLayout() {
    return this.showFooter$
  }

  public get notificationsLayout() {
    return this.showNotification$
  }
  //* end getters *//

  public set setLayout(layoutsConfig: {show: boolean, type: LAYOUTS_TYPE} | {show: boolean, type: LAYOUTS_TYPE}[]) {

    if (Array.isArray(layoutsConfig) && layoutsConfig?.length > 0 ) {
      layoutsConfig.forEach(layout => this.handelLayouts(layout))
    }
    else if (!Array.isArray(layoutsConfig)) this.handelLayouts(layoutsConfig)
    else console.warn(`config paramter is undefined => ${layoutsConfig}`)    

  }

  private handelLayouts(config: {show: boolean, type: LAYOUTS_TYPE}) {

    // ES6 destructuring
    const { show, type } = config || {};

    switch(type) {
      case LAYOUTS_TYPE.navbar:
        this.showNavbar$.next(show)
      break;
      case LAYOUTS_TYPE.footer:
        this.showFooter$.next(show)
      break;
      case LAYOUTS_TYPE.navbar:
        this.showNotification$.next(show)
      break;
      case LAYOUTS_TYPE.all:
        this.showNavbar$.next(show)
        this.showFooter$.next(show)
        this.showNotification$.next(show)
      break;
    }

  }

}
