// enums
import { R_englishWithNumber } from "src/app/core//enums/regx/regx-match";

export const EnglishWithNumbers = (value: string) => {

    // conver to string
    let str = value?.toString() as string;
    
    // defualt return
    return str?.replace(new RegExp(R_englishWithNumber, 'gi'), '')

}