import { trigger, state, style, transition, animate, group } from '@angular/animations';

export const AutoSlider = [
    trigger('auto_slider', [
        transition(':enter', [
            style({opacity: '0', transform: 'translateX(calc(100% + 30px))'}),
            animate('150ms', style({opacity: '1', transform: 'translateX(0px)'})),
        ]),
        transition(':leave', [
            style({opacity: '1'}),
            animate('150ms', style({opacity: '0', transform: 'translateX(calc(100% + 30px))'})),
        ])
    ])    
]