<!-- start app-section-service -->
<section class="service text-center">
    <div class="custom_container">
        <div class="wrapper my-5">
            <div class="wrap_img">
                <img src="../../../../assets/img/home.png" alt="home">
            </div>
            <h4 class="label_lg_bold mb-0">سجل دخولك</h4>
            <p class="text_md_normal mb-0">انظم لأكثر من 50000 مستخدم لساعي</p>
        </div>
        <div class="dash">
            <span *ngFor="let item of [1,2,3,4,5,6,7]"></span>
        </div>
        <div class="wrapper my-5">
            <div class="wrap_img">
                <img src="../../../../assets/img/big_plus.png" alt="Add">
            </div>
            <h4 class="label_lg_bold mb-0">أضف عقارك</h4>
            <p class="text_md_normal mb-0">الآن اضافة العقارات مجانا للبيع أو للايجار</p>
        </div>
        <div class="dash">
            <span *ngFor="let item of [1,2,3,4,5,6,7]"></span>
        </div>
        <div class="wrapper my-5">
            <div class="wrap_img">
                <img src="../../../../assets/img/build.png" alt="build">
            </div>
            <h4 class="label_lg_bold mb-0">تمتع بخدمات ساعي</h4>
            <p class="text_md_normal mb-0">خدمتي السعر المقارن والخدمات المجاورة</p>
        </div>
    </div>
</section>
<!-- end app-section-service -->