import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, timeout, tap, TimeoutError } from 'rxjs';

// services
import { AuthService } from './auth.service';
import { GlobleService } from '../globle.service';
import { AlertService } from '../alert/alert.service';
import { ApiResponseService } from '../handel-api-response/api-response.service';

// pipes
import { CountDownPipe } from 'src/app/core/pipes/numbers/count-down';

// enums
import { Status_Type } from 'src/app/core/enums/ads/status-types';
import { GLOBLE_KEY } from 'src/app/core/enums/sockets/globle-keys';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

@Injectable()
export class NafathService implements OnDestroy {


  //
  private _numberOfTry: number = 0
  private IdNumber: string | number
  private isLoadingIdnumber: boolean = false
  private nafathLoginRes: {[key: string]: string}

  //
  public countDown: number | string = null

  //
  unSubIoNafathRequest: Subscription

  constructor(
    private G: GlobleService,
    private Auth: AuthService,
    private Alert: AlertService,
    private CountDown: CountDownPipe,
    private API_RESPONSE: ApiResponseService
  ) {}

  // start getters & setter
  public get IsLoadingIdnumber(): boolean {
    return this.isLoadingIdnumber
  }

  private get NumberOfTry(): number {
    return this._numberOfTry
  }

  private set NumberOfTry(value: number) {
    this._numberOfTry = value
  }
  // end getters & setter

  // start methods
  private IoNafathRequest(): void {
    
    if (!this.G.userInfo.isVerifiedIdNumber) {

      this.unSubIoNafathRequest = this.Auth.MainListenSocketIo(GLOBLE_KEY.nafath)
      .pipe(timeout(1000 * 60))
      .subscribe(
        (res: {[key: string]: any} = {}) => {
          
          // ES6 Destructuring
          const { data: { PersonId, transId, status } } = res;
          const { transId: transIdLogin, random } = this.nafathLoginRes || {}

          if (transId == transIdLogin && PersonId == this.IdNumber && status == Status_Type.COM) {

            this.Alert.show({
              titleBtn: 'موافق',
              type: LAYOUTS_TYPE.alertSucess,
              msg: 'تم التحقق بشكل ناجح من خلال نفاذ',
              callback: () => {
                this.Alert.hide()
              }
            })

            //
            this.Auth.updateUserInfo({...this.G.userInfo, isVerifiedIdNumber: true})

            //
            this.CountDown.ngOnDestroy()

          }

        },
        err => {

          //
          this.CountDown.ngOnDestroy()          
          
          if (err instanceof TimeoutError && !this.G.userInfo.isVerifiedIdNumber) {

            this.Alert.show({
              titleBtn: 'موافق',
              type: LAYOUTS_TYPE.alertFailure,
              msg: `انتهي الوقت المحدد حاول مره اخري لديك عدد ${this.NumberOfTry > 4 ? 0 : 4 - this.NumberOfTry} محاولات`,
              callback: () => {
                this.Alert.hide()
              }
            }) 

          }
          else console.log(err, "Err => IoNafathRequest")

        },
      )

    }
    
  }

  //
  public idNumberCheck(idNumber: string | number) {

    if (idNumber && this.NumberOfTry <= 4) {

      //
      this.isLoadingIdnumber = true;
  
      //
      this.IdNumber = idNumber

      // remove old subscribe
      if (this.unSubIoNafathRequest) this.unSubIoNafathRequest.unsubscribe()      
  
      const body = {
        id: idNumber,
        service: 'Login',
        action: 'SpRequest',
      }
  
      this.API_RESPONSE.nafathLogin(body).pipe(
        tap(() => this.NumberOfTry++),
        timeout(1000 * 10)
      ).subscribe(
        (res = {}) => {

          //
          this.isLoadingIdnumber = false;
          
           // ES6 Destructuring
          const { 
            data,
            data: { random, transId }
          } = res;
  
          //
          this.nafathLoginRes = data
  
          this.Alert.show({
            typeContent: `
              <h4 class='rounded-circle border_def_3th mb-0 py-1 px-2'>
                ${random}
              </h4>
            `,
            msg: `
              برجاء تاكيد كود التحقق من خلال تطبيق <span class="rounded border_def_3th main_color_bg px-2 color_white">نفاذ</span>
              <br />
              ثم اختار الرقم الصحيح
            `,
          })

          //
          this.CountDown.transform(Date.now() + 60000)
          .subscribe(res => this.countDown = res?.value)

          //
          this.IoNafathRequest()
  
        },
        err => {

          //
          this.isLoadingIdnumber = false;
  
          this.Alert.show({
            titleBtn: 'موافق',
            type: LAYOUTS_TYPE.alertFailure,
            msg: 'خطاء من الخادم او تحتاج من التاكد من رقم الهوية',
            callback: () => {
              this.Alert.hide()
            }
          }) 
  
        }
      )
      
    }
    else if (this.NumberOfTry > 4) {
      this.Alert.show({
        titleBtn: 'موافق',
        type: LAYOUTS_TYPE.alertFailure,
        msg: `
          تم تخطي عدد محاولات التحقق يمكنك التواصل مع الدعم الفني
          <a class="main_color" href="https://wa.me/+966530107471" target="_blank"> من هنا </a>
        `,
        callback: () => {
          this.Alert.hide()
        }
      })
    }

  }

  public ngOnDestroy(): void {

    //
    this.unSubIoNafathRequest?.unsubscribe()
    console.log('ngOnDestroy => All subscribe in nafathServices')

  }
  // start methods
  
  
}
