import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'createUrl'
})
export class createUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, ...args: unknown[]): any {
    
    if (value) {
      return URL.createObjectURL(value)
    }
    return ''
  }

}
