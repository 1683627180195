import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// main component
import { AudioComponent } from './audio.component';

// custom services
import { AudioService } from './services/audio.service';



@NgModule({
  declarations: [ AudioComponent ],
  imports: [ CommonModule ],
  exports: [ AudioComponent ],
  providers: [ AudioService ]
})
export class AudioModule { }
