<div class="def_p bluer_section">
    <div id="payment_gosell"></div>
    <div class="custom_container position-relative">
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>

        //
        <!-- import app-payment-response -->        
        <app-payment-response [callback]="paymentCallback.bind(this)"></app-payment-response>
        <!-- <div class="wrap_info">
            <ul class="list_none">
                <li class="label_lg_normal color_white">
                    الحالة: {{charge?.post?.status == 'SUCCESS' ? 'عملية ناجة' : 'عملية فاشلة'}}
                </li>
                <li class="label_lg_normal">

                </li>
            </ul>
        </div> -->
    </div>
</div>