<!-- start app-success -->
<div class="success d-flex align-items-center justify-content-center flex-column">
    <!-- <div class="wrap_close" @fied>
        <app-close (click)="hide()"></app-close>
    </div> -->
    <div @fied class="wrap">
        <div 
            [ngClass]="{
                'color_wrong': type == LAYOUTS_TYPE.alertFailure,
                'main_color': type != LAYOUTS_TYPE.alertFailure
            }"
            class="wrap_icon d-flex align-items-center justify-content-center"
        >
            <ng-container [ngSwitch]="type">
                <i *ngSwitchCase="LAYOUTS_TYPE.alertInfo" class="fa-solid fa-info main_color"></i>
                <i *ngSwitchCase="LAYOUTS_TYPE.alertSucess" class="fa-solid fa-check main_color"></i>
                <i *ngSwitchCase="LAYOUTS_TYPE.alertFailure" class="fa-solid fa-xmark color_wrong"></i>
                <i *ngSwitchDefault class="fa-solid fa-check main_color"></i>
            </ng-container>
        </div>
        <p class="text_md_bold">{{msg}}</p>
        <app-btn [title]="titleBtn" (click)="submit()"></app-btn>
    </div>
</div>
<!-- end app-success -->