<app-success
    titleBtn="موافق" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    [msg]="'تم استكمال ملفك بنجاح الحساب قيد الاستخدام الان'"
>
</app-success>

<!-- start app-complete-profile -->
<div class="complete_profile def_p bluer_section">
    <div class="custom_container position-relative">

        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div  class="wrap_container">
            <form [formGroup]="FormCompleteProfileOffice" (ngSubmit)="CompleteProfileOffice()">
                <div  class="wrap_all">
                    <div class="row">
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="city"
                                    [style]="{color: '#fff'}"
                                    title="عنوان المعلن-المدينة" 
                                >
                                </app-label>
        
                                <div class="wrap_select">
                                    <ng-select 
                                        class="sm"
                                        bindValue="id"
                                        [clearable]="false"
                                        [searchable]="false"
                                        bindLabel="cityName"
                                        placeholder="مدينة"
                                        [items]="listOfcities"
                                        formControlName="city"
                                        notFoundText="لا يوجد مدن متاحة!"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('city').touched && getControl('city').errors"
                                    >
                                    </app-validation-input>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="workCity"
                                    [style]="{color: '#fff'}"
                                    title="المدينة التي يعمل بها المعلن" 
                                >
                                </app-label>
        
                                <div class="wrap_select">
                                    <ng-select 
                                        class="md"
                                        bindValue="id"
                                        [multiple]="true"
                                        [clearable]="false"
                                        [searchable]="false"
                                        bindLabel="cityName"
                                        placeholder="المدن"
                                        [items]="listOfcities"
                                        [closeOnSelect]="false"
                                        formControlName="workCity"
                                        notFoundText="لا يوجد مدن متاحة!"
                                        (add)="handelSubs($event, 'location')"
                                        (remove)="removeSubs($event, 'location')"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('workCity').touched && getControl('workCity').errors"
                                    >
                                    </app-validation-input>                                     
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="city"
                                    [style]="{color: '#fff'}"
                                    title="الاحياء التي يعمل بها المعلن" 
                                >
                                </app-label>
                                <div class="wrap_select">
                                    <ng-select 
                                        class="md"
                                        bindValue="id"
                                        [multiple]="true"
                                        [clearable]="false"
                                        [searchable]="false"
                                        bindLabel="areaName"
                                        [items]="listOfAreas"
                                        placeholder="المناطق"
                                        [closeOnSelect]="false"
                                        formControlName="workArea"
                                        [loading]="loadingWorkArea"
                                        [disabled]="loadingWorkArea"
                                        notFoundText="لا يوجد مناطق متاحة!"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('workArea').touched && getControl('workArea').errors"
                                    >
                                    </app-validation-input>                                     
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="workCategory"
                                    [style]="{color: '#fff'}"
                                    title="التصنيفات الرئيسية التي يعمل بها المعلن" 
                                >
                                </app-label>
        
                                <div class="wrap_select">
                                    <ng-select 
                                        class="md"
                                        bindValue="id"
                                        [multiple]="true"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="الرئيسي"
                                        [closeOnSelect]="false"
                                        bindLabel="categoryName"
                                        formControlName="workCategory"
                                        [items]="categories | allowedCategory"
                                        (add)="handelSubs($event, 'categories')"
                                        notFoundText="لا يوجد فئة اساسية متاحة!"
                                        (remove)="removeSubs($event, 'categories')"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('workCategory').touched && getControl('workCategory').errors"
                                    >
                                    </app-validation-input>                                       
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="wrap_field">
        
                                <!-- import app-label -->
                                <app-label 
                                    for="workSubCategory"
                                    [style]="{color: '#fff'}"
                                    title="التصنيفات الفرعية التي يعمل بها المعلن" 
                                >
                                </app-label>
        
                                <div class="wrap_select">
                                    <ng-select 
                                        class="md"
                                        bindValue="id"
                                        [multiple]="true"
                                        [clearable]="false"
                                        [searchable]="false"
                                        placeholder="الفرعي"
                                        [closeOnSelect]="false"
                                        [items]="subCategories"
                                        bindLabel="categoryName"
                                        formControlName="workSubCategory"
                                        notFoundText="لا يوجد فئة فرعية متاحة!"
                                    >
                                    </ng-select>
                                    <app-validation-input
                                        class="text-start"
                                        text="من فضلك يرجي مل هذا الحقل "
                                        *ngIf="getControl('workSubCategory').touched && getControl('workSubCategory').errors"
                                    >
                                    </app-validation-input>                                      
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="errorsMsg.length > 0">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let err of errorsMsg">
                                <!-- import app-validation-input  -->
                                <app-validation-input 
                                    [text]="err.msg"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>                

                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn
                        title="ارسال" 
                        [submit]="true"
                        [disabled]="FormCompleteProfileOffice.invalid || isLoading" 
                    >
                    </app-btn>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-complete-profile -->