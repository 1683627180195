import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

// custom interfaces
import { Ivalidation } from 'src/app/core/interfaces/form/validation';

// enums
import { HTTP_EVENT_TYPE } from 'src/app/core/enums/http-request/httpEventType';
import { FILE_TYPE } from 'src/app/core/enums/global/files-types';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {

  // init props & inputs is coming
  @Input('style') style: any;
  @Input('Id') Id: string = '';
  @Input('name') name: string = '';
  @Input('value') value: string = '';
  @Input('class') class: string = '';
  @Input('defaultData') defaultData: File
  @Input('loading') loading: boolean = false;
  @Input('disabled') disabled: boolean = false;
  @Input('FormParent') FormParent!: UntypedFormGroup
  @Input('validations') validations: Ivalidation[] = []
  @Input('uploadProgress') uploadProgress: number | HTTP_EVENT_TYPE | null = 0
  @Input('fileTypes') fileTypes: FILE_TYPE[] = [
    FILE_TYPE.doc,
    FILE_TYPE.svg,
    FILE_TYPE.csv,
    FILE_TYPE.pdf,
    FILE_TYPE.word,
    FILE_TYPE.document,
  ]
  // init props & inputs is coming

  // init data will outPut
  @Output() file = new EventEmitter()  

  // init props
  nameFile: string = '' 
  HTTP_EVENT_TYPE = HTTP_EVENT_TYPE 

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (this.FormParent) {
      if (this.disabled) this.getControl.disable()
      else this.getControl.enable()
    }

    if (this.defaultData) {
      this.nameFile = this.defaultData?.name
    }

    if (this.uploadProgress == HTTP_EVENT_TYPE.errorResponse) this.nameFile = ''    

  }

  ngOnInit(): void {
  }

  // function will catch all errors in input
  get getError() {
    return (this.FormParent?.controls[this.name].errors as ValidationErrors)
  }

  // function will get all date about input
  get getControl() {
    return this.FormParent?.controls[this.name]
  }

  removeFile() {
    if (this.nameFile) {
      this.nameFile = ''
    }
    if (this.FormParent && this.getControl?.value.length > 0) {
      this.getControl.setValue(null)
    }
  }

  touched() {
    this.file.emit(null)
  }

  upLoadFile(event: Event) {

    // ES6 Destruction
    const { type } = event;
    
    try {

      /**
       * handler cancel error
       */
      if (type != 'change') throw null;


      // ES6 Destruction
      const { files } = (event.target as HTMLInputElement);

      /**
       * handler empty file error
       */
      if (files.length == 0) throw null;


      // catch file data
      const file = files.item(0);

      /**
       * check if we have formats for files
       */
      if (Array.isArray(this.fileTypes) && this.fileTypes.length > 0) {
        
        /**
         * check if file type Consistent with allowed formats
         */
        const isAllowed =  this.fileTypes.some(type => file?.type.includes(type));

        if (isAllowed) {
          // outPut data
          this.file.emit(file)
          this.nameFile = file?.name as string
        }
        else throw undefined;

      }
      else {
        // outPut data
        this.file.emit(file)
        this.nameFile = file?.name as string          
      }

    } 
    catch (err) {
      
      if (err === null) this.file.emit(null);
      else if(err === undefined ) this.file.emit(undefined);

    }

  }

}
