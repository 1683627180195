import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

// interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';

// enums
import { LOCAL_DATA } from 'src/app/core/enums/global/local-data';
import { LOCAL_METHOD } from 'src/app/core/enums/global/local-methods';
import { PAYMENT_TYPE } from 'src/app/core/enums/payment/payment-types';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { PAYMENT_AUTH_RES } from 'src/app/core/enums/payment/authorize-response';

@Component({
  selector: 'app-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.scss']
})
export class PaymentResultComponent implements OnInit, AfterViewInit, OnDestroy {

  // enums
  PAYMENT_AUTH_RES = PAYMENT_AUTH_RES

  // init
  unSub: Subscription;
  navigation!: Inavigation

  // boolean
  alertSuccess: boolean = false
  alertFailure: boolean = false

  // obj
  charge: {[key: string]: any}

  constructor(
    private G: GlobleService,
    private Alert: AlertService,
  ) { }

  ngOnInit(): void {

    // set value proparty
    this.navigation = {label: 'عملية الدفع', path: '/'}    

    // // get data from sessionStorage
    // this.charge = this.G.getLocalData(LOCAL_DATA.component, LOCAL_METHOD.session)?.callback;
    
    // if (this.charge?.post?.status == 'SUCCESS') this.alertSuccess = true
    // else this.alertFailure = true

  }

  ngAfterViewInit(): void {}

  paymentCallback(res: any) {
    
    // ES6 Destructuring
    const { status, metadata: { type, adsId } } = res || {};
    
    //
    this.charge = res;
    this.G.saveLocalData(LOCAL_DATA.component, res, LOCAL_METHOD.session)



    if (status == PAYMENT_AUTH_RES.captured) {
      this.Alert.show({
        titleBtn: 'موافق',
        msg: 'عملية دفع ناجحه',
        callback: () => {
          this.Alert.hide();

          if (type == PAYMENT_TYPE.ads) this.G.navigate(['realty-management/view-realty-advertisement', adsId])
          else this.G.navigate(['/'])

        }
      })
    }
    else {
      this.Alert.show({
        titleBtn: 'موافق',
        msg: 'عملية دفع فاشلة',
        type: LAYOUTS_TYPE.alertFailure,
        callback: () => {
          this.Alert.hide()
          this.G.navigate(['/'])
        }
      }) 
    }
    
  }

  ngOnDestroy(): void {
    if (this.unSub) this.unSub.unsubscribe()
    sessionStorage.removeItem(LOCAL_DATA.component);
  }

}
