import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';

// enums
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { HTTP_EVENT_TYPE } from 'src/app/core/enums/http-request/httpEventType';

@Injectable({
  providedIn: 'root'
})
export class MainApiService {

  constructor(private http: HttpClient) { }


  // ** all functions crud operations ** //

  //  start functions get method //

  mainGetAll(path: string, querParams?: {}) {
    return this.http.get(`${path}`, querParams)
  }

  mainGetById(path: string, id: string | number, querParams?: {}) {
    return this.http.get(`${path}/${id}`, querParams)
  }

  mainGetBySubPath(path: string, id: string | number, sub: string, querParams?: {}) {
    return this.http.get(`${path}/${id}/${sub}`, querParams)
  }

  //  end functions get method //





  //  start functions post method //
  mainPost(path: string, data?: {}, querParams?: {}) {
    return this.http.post(`${path}`, data, querParams)
  }  

  
  mainPostById(path: string, id: string | number, data?: {}, querParams?: {}) {
    return this.http.post(`${path}/${id}`, data, querParams)
  }  
  
  mainPostBySubPath(path: string, id: string | number, sub: string, data?: {}, querParams?: {}) {
    return this.http.post(`${path}/${id}/${sub}`, data, querParams)
  }
  //  end functions post method //








  //  start functions put method //
  mainPut(path: string, data?: {} | null | undefined, querParams?: {}) {
    return this.http.put(`${path}`, data, querParams)
  }  

  
  mainPutById(path: string, id: string | number, data?: {}, querParams?: {}) {
    return this.http.put(`${path}/${id}`, data, querParams)
  }  

  mainPutByFirstId(id: string | number, path: string, data?: {}, querParams?: {}) {
    return this.http.put(`${id}/${path}`, data, querParams)
  }    
  
  mainPutBySubPath(path: string, id: string | number, sub: string, data?: {} | null | undefined, querParams?: {}) {
    return this.http.put(`${path}/${id}/${sub}`, data, querParams)
  }  
   //  start functions put method //





   // start  functions delete method 
   mainDelete(path: string) {
    return this.http.delete(`${path}`)
  }

  mainDeletetById(path: string, id: string | number,  querParams?: {}) {
    return this.http.delete(`${path}/${id}`, querParams)
  }

  mainDeleteByFirstId(id: string | number, path: string, querParams?: {}) {
    return this.http.delete(`${id}/${path}`, querParams)
  }    

  // mainDeleteBySubPath(path: string, id: string | number, sub: string) {
  //   return this.http.delete(`${path}/${id}/${sub}`)
  // }

  public handlerProgressHttpEvent (event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        return HTTP_EVENT_TYPE.sending
      case HttpEventType.UploadProgress:
        let progress = Math.round(event.loaded / (event.total || 0) * 100);
        return {event, progress, status: HTTP_EVENT_TYPE.progress}
      case HttpEventType.ResponseHeader:
        return HTTP_EVENT_TYPE.received
      case HttpEventType.DownloadProgress:
        return {event, status: HTTP_EVENT_TYPE.compeleteUpload};      
      case HttpEventType.Response:
        return {event,  status: HTTP_EVENT_TYPE.response};
      case HttpEventType.User:
        return {event, status: HTTP_EVENT_TYPE.userAction};
    }
  }  
  
}
