import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// interfaces
import { ITab } from './interfaces/tab';

@Component({
  standalone: true,
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  imports: [RouterLink, RouterLinkActive, NgFor, NgIf, NgTemplateOutlet],
})
export class TabsComponent implements OnInit {

  /**
   * Inputs
   */
  @Input({ alias: 'noteCount' }) noteCount: number = 0;
  @Input({ alias: 'activeTab' }) public ActiveTab: string | number;
  @Input({ alias: 'tabs', required: true }) public Tabs: ITab[] = [];

  /**
   * outPuts
   */
  @Output('change') Change: EventEmitter<any> = new EventEmitter();


  /**
   * Methods
   */
  ngOnInit(): void {
    
    /**
     * init ActiveTab if it null, then active first tab
     */
    if (this.ActiveTab == null) this.ActiveTab = this.Tabs[0]?.id;

  }

  /**
   * 
   * @param tab 
   */
  public ActiveTabChange(tab: ITab): void {

    /**
     * 
     */
    this.ActiveTab = tab?.id

    /**
     * 
     */
    this.Change.emit(tab)

  }
  

}
