import { Params, Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// libs
import { Socket } from 'ngx-socket-io';

// services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { AdsService } from 'src/app/main.components/Ads-management/services/ads.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

// env
// import { environment } from 'src/environments/environment';

// enums
import { Countery } from 'src/app/core/enums/global/country';
import { SOCKET_KEY_ADS } from 'src/app/core/enums/sockets/ads-keys';
import { Category_Type } from 'src/app/core/enums/category/cat-type';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { Contract_Type } from 'src/app/core/enums/ads/contract-types';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { Features_Type } from 'src/app/core/enums/features/features-type';
import { SEARCH_VIEW_TYPE } from 'src/app/core/enums/layouts/search-view-types';

// validations
import { Validations_Property } from 'src/app/core/validations/ads/validation-property';
import { Validations_Comparison } from 'src/app/core/validations/ads/validation-comparison';

// interfaces
import { ICity } from 'src/app/core/interfaces/location/city';
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';
import { ICategory } from 'src/app/core/interfaces/category/category';

// helper
import { DIRECTION, scrollToElement } from 'src/app/core/funcations-helper/Dom/scroll-to-element';

@Injectable()
export class SearchService {
  
  /**
   * fields
   */
  public adItemsLimit: number = 20
  public CurrentAdsPage: number = 1
  public totalAdsPageCount: number = 0
  public adVipItemsLimit: number = 20
  public CurrentVipPage: number = 1
  public totalVipPageCount: number = 0  
  public sortByPrice: any[] = []
  public ListOfAreas: any[] = []
  public ListOfCity: ICity[] = []
  public ListOfVipAds: any[] = []
  public listOfFeatures: any[] = []
  public ListOfNormalAds: any[] = []
  public listOfProperties: any[] = []
  public listOfCategory: ICategory[] = []
  public listOfSubCategory: ICategory[] = []
  public showRent: boolean = false
  public IsSubmitForm: boolean = false 
  public IsLoadingAds: boolean = false
  public isLoadingArea: boolean = false
  public loadingSubCate: boolean = false
  public AdvancedSearch: boolean = false
  public showSearchMethod: boolean = true  
  public IsLoadingSearch: boolean = false;
  public IsLoadingVidAds: boolean = false
  private _searchByCoords: boolean = false;
  public IsLoadingNormalAds: boolean = false
  public IsApproveCurrentUserLocation: boolean = false
  private _io: Socket = null
  public Map: google.maps.Map
  private _formSearch: UntypedFormGroup = null;
  public FormData: {[key: string]: any} | null = null;
  public InfoModel: {[key: string]: any} | null = null;
  private _currentUserCoords: {lat: number, lng: number} = null;
  public viewType: SEARCH_VIEW_TYPE = SEARCH_VIEW_TYPE.NormalAds
  public readonly FormValueWatching: BehaviorSubject<{[key: string]: any}> = new BehaviorSubject(null);
  
  public CurrentMapCoords: {lat: number, lng: number} = {
    lat: Countery.lat as number,
    lng: Countery.lng as number
  };
  private readonly _defaultFormValue = {
    km: 10,
    lang:'ar',
    limit: 20,
    searchMethod: 1,
    endDate: new Date().toISOString(),        
  }

  /***
   * 
   */
  constructor(
    private _injector: Injector,
    private _adService: AdsService,
    private readonly _router: Router,
    private readonly _g: GlobleService,
    private readonly _alert: AlertService,
    public readonly FB: UntypedFormBuilder,   
    private readonly _route: ActivatedRoute,
    private readonly _api: ApiResponseService,
    private _viewportScroller: ViewportScroller,
    private readonly _top_api: TopLevelSingleService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { 

    /**
     * check env server or browser
     */
    if (isPlatformBrowser(this.platformId)) {
      this._io = this._injector.get<Socket>(Socket);
    }    

  }

  /**
   * Getters & Setter
   */

  public get FormSearch(): UntypedFormGroup {
    return this._formSearch
  }

  /**
   * 
   * @returns 
   */
  private get userInfo(): IuserInfo {
    
    /**
     * handler nullable values
     */
    if (this._g.userInfo != null) return this._g.userInfo;

    /**
     * 
     */
    return {} as any

  } 
  
  /**
   * socket IO
   * @returns list of Ads
   */
  private get _searchResult(): Observable<any> {
    return this._io ? this._io.fromEvent(SOCKET_KEY_ADS.get) : null;
  }  

    /**
   * End Getters & Setters
  */

  /**
   * Start Methods
   */

  public init(): void {

    /**
     * caller
     */
    this.initFormSearch();

    /**
     * listen to socket
     */
    this.initSearchResult();

    /**
     * 
     */
    this._hierarchicalEntityManagerStartPoint()
        .then(_ => {
          /**
           * 
           */
          this._updateSearchFormByQueryParams();

          /**
           * 
           */
          this.submit(true);

          if (location.pathname == '/') {
            /**
             * 
             */
            scrollToElement('search_result_box', {
              child: true,
              direction: DIRECTION.down
            });            
          }
           
        })

    /**
     * 
     */
    this.handlerGetCurrentUserLocation();

    /**
     * fill array
     */
    this.sortByPrice = [
      {name: 'من الاعلي إلى الاقل', id: 'UP'},
      {name: 'من الاقل إلى الاعلي', id: 'DOWN'},      
    ];

    /**
     * 
     */
    this._top_api.setting$.subscribe(res => {
      
      // ES6 Destructing
      const { mainPage } = res || {};
      
      /**
       * 
       */
      if (mainPage == SEARCH_VIEW_TYPE.Map) this.viewType = SEARCH_VIEW_TYPE.Map;
      else if (mainPage == SEARCH_VIEW_TYPE.VipAds) {
        /**
         * 
         */
        this.viewType = SEARCH_VIEW_TYPE.VipAds;

        /**
         * 
         */
        this.getAdsVip()

      }

    })    

  }

  /**
   * 
   */
  private initFormSearch(): void {

    /**
     * init Form
     */
    this._formSearch = this.FB.group(
      {
        // location
        km: [10],
        limit: [20],          
        lang:['ar'],
        lat: [null],
        long: [null],        
        area: [null],
        city: [null],

        // checkbox
        // priceType: [null],
        orderByRate: [null],
        enableInstallment: [null],
        enablePhoneContact: [null],

        // size & price
        sizeTo: [null],
        priceTo: [null],
        sizeFrom:  [null],
        priceFrom: [null],
        priceRange: [null],

        // Category
        category: [null],
        subCategory: [null],

        // dropdown
        status: [null],
        sortByPrice: [null],

        // contract
        rentType: [null],
        contractType: [null],
        
        // form array
        features: this.FB.array([]),
        properties: this.FB.array([]),

        // othere controls
        startDate: [null],
        searchMethod: [1],
        endDate: [new Date().toISOString().split('T')[0]],
      },
      {
        validators: [
          Validations_Comparison(['sizeFrom', 'sizeTo', 'size']),
          Validations_Comparison(['priceFrom', 'priceTo', 'price']),
        ]
      },
    )

  }

  /**
   * 
   * @param name control name
   * @returns
   */
  public control(name: string) {
    return this.FormSearch.controls[name]
  }

  /**
   * 
   * @param FormArrayName control name
   * @returns 
   */
  public ArrOfControls(FormArrayName: string): UntypedFormArray {
    return this.control(FormArrayName) as UntypedFormArray
  }   

  /**
   * 
   */
  private _hierarchicalEntityManagerStartPoint(): Promise<void> {

    // ES6 Destructing
    const { country } = this.userInfo;

    // Observables
    const categories$ = this._top_api.categories$;
    const Cities$ = this._api.getCitiesByCountry(country ? country?.id : Countery?.id, {
      params: { [RANDOM_VALUE.loader]: true } 
    })
    

    return new Promise((res, rej) => {

      /**
       * 
       */
      combineLatest([categories$, Cities$]).
      subscribe((data: any) => {
  
        // ES6 Destruction Array
        const [ categories, Cities ] = data;
  
        //
        this.ListOfCity = Cities;
        this.listOfCategory = categories;

        /**
         * fire promise after loaded data
         */
        res(null);
  
      }) 
      

    })

  }

  /**
   * 
   * @param cty main category Id
   */
  public HandlerGetSubByMainCategories(cty: ICategory) {

    /**
     * 
     */
    if(cty == null) throw new Error(`categories arg is undefine`);

    /**
     * handler reset fields
     */
    if (this.control('subCategory').value != null) {
      this.resetFeatures();
      this.listOfSubCategory = [];
      this.control('subCategory').reset(null);
    }    

    /**
     * 
     */
    this.listOfSubCategory = (cty.child as ICategory[]);

  }

  /**
   * 
   * @param id subCategoryId
   */
  public HandlerGetFeature(subCategory: ICategory) {

    // ES6 Destructing
    const { id } = subCategory

    /**
     * handler Error
     */
    if (id == null) throw new Error('id arg is undefine')

       
    const params = {
      category: id,
      [RANDOM_VALUE.loader]: true       
    }

    /**
     * rest current Features to get new
     */
    this.resetFeatures();    

    /**
     * 
     */
    const main =this._api.Adsfeatures({params: { ...params, type: Category_Type.main }})
    const sub = this._api.Adsfeatures({params: { ...params, type: Category_Type.sub }})

    /**
     * 
     */
    combineLatest([main, sub]).subscribe((res: any) => {

      // ES6 Destructing
      const [ main, sub ] = res;

      // set value
      this.listOfFeatures = sub
      this.listOfProperties = main;

      // loop to create form controls || form Group
      this.listOfProperties.map(prop => this.ArrOfControls('properties').push(this.property(prop)))
      this.listOfFeatures.map(f => this.ArrOfControls('features').push(this.FB.control(null)))

    })

  }

  /**
   * 
   */
  public resetFeatures() {
    this.listOfFeatures = []
    this.listOfProperties = []
    this.ArrOfControls('features').clear()
    this.ArrOfControls('properties').clear()
  }  

  /**
   * 
   * @param data 
   * @returns 
   */
  property(data: any) {

    // init
    let g: UntypedFormGroup;

    // ES6 Destructing
    const { id, required, type } = data;

    if (type == Features_Type.number) {
      g = this.FB.group(
        {
          property: [id],
          to: [null, required && Validators.required],
          from: [null, required && Validators.required]
        },
        {
          validators: Validations_Property(required)
        }
      )
    }
    else {
      g = this.FB.group({
        property: [id],
        value: [null, required && Validators.required]
      })      
    }

    // 
    return g

  } 
  

  /**
   * 
   * @param city 
   */
  public HandlerGetAreasByCity(city: ICity) {
    
    /**
     * handler Error
     */
    if(city == null) throw new Error(`city arg is undefine`);

    // ES6 Detraction & Nested
    const {  location: { coordinates } } = city;
    const [ lng, lat ] = coordinates;

    /**
     * update center Map
     */
    this.CurrentMapCoords = { lng, lat };

    /**
     * stop search by Coords
     */
    this.searchMethodSelected(1)

    /**
     * hide Search Method
     */
    this.searchMethod(false);    

    /**
     * handler reset fields
     */
    if (this.control('area').value != null) {
      this.control('area').reset(null);
      this.ListOfAreas = [];
    }
    
    // ES6 Destruction
    const { id } = city;

    /**
     * start loading
     */
    this.isLoadingArea = true;

    /**
     * 
     */
    this._api.cityAreas(id, { params: {[RANDOM_VALUE.loader]: true} })
    .subscribe(
      data => {
        this.ListOfAreas = data;
        this.isLoadingArea = false;
      },
      err => {
        this.isLoadingArea = false;           
      }
    )

  }

  /**
   * @deprecated i change logic
   * @description if (true) this mean we will using city and area for search and enable it's
   * if (false) disable city and area from search and disable it's
   * @param state boolean
   */  
  public handlerDisOrEnabledPlaces(state: boolean): void {
    
    /**
     * 
     */
    if (state == true) {
      this.control('area').enable();
      this.control('city').enable();      
    } else {
      this.control('area').disable();
      this.control('city').disable();
    }

    /**
     * 
     */
    this._searchByCoords = !state
    
  }
  
  
  /**
   * 
   * @param id if 1 = random search, 2 = Search by user's current latitude and longitude
   */
  public searchMethodSelected(id: number) {

    // 1 == random Search
    // 2 == Search by user's current latitude and longitude
    if (id == 1) {
      /**
       * disable controls
       */
      this.handlerDisOrEnabledCoords(false)

    }
    else if (id == 2) {

      /**
       * enable controls
       */
      this.handlerDisOrEnabledCoords(true)

      
      /**
       * fill
       */
      this.CurrentMapCoords = {...this._currentUserCoords};
      this.control('lat').setValue(this._currentUserCoords.lat)
      this.control('long').setValue(this._currentUserCoords.lng)      
    }

  }

  /**
   * 
   * @param status 
   */
  public searchMethod(status: boolean) {

    if (status) {
      this.showSearchMethod = true
      this.control('searchMethod').enable()
    }
    else {
      this.showSearchMethod = false
      this.control('searchMethod').disable()
    } 

  }

  /**
   * @description if (true) this mean we will using Coords for search and enable lat & lng
   * if (false) remove Coords from search and disable lat & lng
   * @param state boolean
   */
  public handlerDisOrEnabledCoords(state: boolean): void {
    
    /**
     * 
     */
    if (state == true) {
      this.control('km').enable();
      this.control('lat').enable();
      this.control('long').enable();      
    } else {
      this.control('km').disable();
      this.control('lat').disable();
      this.control('long').disable();
    }

    /**
     * 
     */
    this._searchByCoords = state

  }
  
  /**
   * @description this method take number from 1 to 5 or else
   * @param rangeStatus from 1 to 5
   * @returns price object, { from: number, to: number }
   */
  public PricesRangeStatus(rangeStatus: number) {

    // ES6 Destructing
    let { from: priceFrom, to: priceTo } = this._adService.fromToByPrice(rangeStatus);

    // init
    const controlPriceTo = this.control('priceTo');
    const controlPriceFrom = this.control('priceFrom');

    // path value
    controlPriceTo.patchValue(priceTo);
    controlPriceFrom.patchValue(priceFrom);

    //
    this.FormSearch.markAsDirty();

    //
    if (priceTo == null && controlPriceTo.enabled) this.control('priceTo').disable();
    else this.control('priceTo').enable();

  }

  /**
   * 
   * @param type 
   */
  public contractToggle (type: Contract_Type) {

    if (type == Contract_Type.RT) {
      this.showRent = true
      this.control('enableInstallment').disable()
    }
    else {
      this.showRent = false
      this.control('rentType').reset()
      this.control('enableInstallment').enable()
    }

  }  

  /**
   * 
   */
  private async handlerGetCurrentUserLocation() {

    if (isPlatformBrowser(this.platformId)) {
      
      try {
  
        // init
        const geo = await this._handlerGetCurrentLocation();
  
        if (geo instanceof GeolocationPosition) {
  
          // es6 destructuring
          const { latitude: lat, longitude: lng } = geo.coords        
  
          /**
           * assign user GeolocationPosition
           */
          this.CurrentMapCoords = {lat, lng};
          this._currentUserCoords = {lat, lng};
  
          /**
           * 
           */
          this.IsApproveCurrentUserLocation = true;
  
        }
        
      }
      catch (err) {
        if (err instanceof GeolocationPositionError) {
          console.log(err)
          this.IsApproveCurrentUserLocation = false;             
        }
      }

    }
    

  }  


  /**
   * 
   */
  private _handlerGetCurrentLocation(): Promise<GeolocationPosition > | GeolocationPositionError | Error  {

    if (isPlatformBrowser(this.platformId)) {
      
      // chcekc if user browser supported Geolocation API
      // if getCurrentPosition run; the browser will ask user if it allow to access hin location or no
      // after user take any option; getCurrentPosition will be using function success or error
      // if user allow; getCurrentPosition will user success
      // else getCurrentPosition will user error      
      if (!navigator.geolocation) {
        alert('من فضلك قم بتحديث المتصفح الخاص بك لكي تستفيد من خدمة')
        throw new Error('navigator.geolocation is undefined')
      }

      const p = new Promise<GeolocationPosition>((res, rej) => {

        // if user allow to access him location run this function
        const success: PositionCallback = (position: GeolocationPosition) => res(position);
  
        // 
        const error: PositionErrorCallback = (err: GeolocationPositionError) => rej(err)
  
        //
        navigator.geolocation.getCurrentPosition(success, error);

      })

      //
      return p
  
    }

  }


  /**
   * 
   * @param unUpdateQueryParam when it (true) this will not fire func to update QueryParams in url.
   * because handlerUpdateUrlQueryParams will throw error if it work before all data loaded
   * and sometimes the submit func will run before all data load for this we stop fun handlerUpdateUrlQueryParams in this time
   */
  submit(unUpdateQueryParam: boolean = false): void {

    /**
     * 
     */
    this.handlerFormateFromData();

    /**
     * Get Forms Values then Update QueryParams
    */
    if (unUpdateQueryParam == false) {
      this._handlerUpdateUrlQueryParams();
    }

    /**
     * 
     */
    this.ListOfVipAds = [];
    this.ListOfNormalAds = [];

    /**
     * start loading
     */
    this.IsLoadingSearch = true;

    /**
     * 
     */
    this.IsSubmitForm = true

    /**
     * 
     */
    this.getAds();

  }

  /**
   * 
   */
  private handlerFormateFromData(): void {
    
    let properties: any[] = [];
    let features: number[] = [];
    let formData: {[key: string]: any} = {};

    for (const key in this.FormSearch.value) {

      if ((this.FormSearch.value[key] != null && this.FormSearch.value[key] != '')) {
        switch (key) {
          case 'features':
            {
              let arr: number[] = this.FormSearch.value[key];
  
              arr.map(id => id ? features.push(id) : null)
  
              features.length > 0 ? formData[key] = features : null
            }      
            break;
          case 'properties':
            {
              let arr: any[] = this.FormSearch.value[key];
  
              arr.map(props => (props.from && props.to) || props.value ? properties.push(props): null);

              properties.length > 0 ? formData[key] = properties : null
            }
            break;
          case 'priceTo':
          case 'priceFrom':
            formData[key] = parseInt(this.FormSearch.value[key]?.toString().replace(/\D/g,''));
            break;   
          case 'city':
          case 'area':
            if (this._searchByCoords == false) {
              formData[key] = this.FormSearch.value[key]
            }
            break;
          default:
            formData[key] = this.FormSearch.value[key]
            break;
        }
      }

    }  
    
    console.log(formData)

    /**
     * 
     */
    this.FormData = formData;
    

  }

  /**
   * 
   */
  private _handlerUpdateUrlQueryParams() {

    /**
     * 
     */
    const 
    queryParams = {},
      keys = [
        'city', 'area', 'category','subCategory',
        'sizeTo', 'sizeFrom', 'priceRange', 'contractType', 'rentType',
        'status', 'startDate', 'sortByPrice',
        'orderByRate', 'enableInstallment', 'enablePhoneContact'
    ];
    
    /**
     * 
     */
    keys.forEach(key => {   

      if (this.FormData[key]) {
        switch(key) {
          case 'city':
            const city = this.ListOfCity.find(city => city.id == this.FormData[key]);
            queryParams[key] = [city.cityName.split(' ').join('-'), city.id].join(',');
            break;
          case 'area':
            const area = this.ListOfAreas.find(area => area.id == this.FormData[key]);
            queryParams[key] = [area.areaName.split(' ').join('-'), area.id].join(',');
            break;
          case 'category':
            const category = this.listOfCategory.find(category => category.id == this.FormData[key]);
            queryParams[key] = [category.categoryName.split(' ').join('-'), category.id].join(',');
            break;
          case 'subCategory':
            const subCategory = this.listOfSubCategory.find(subCategory => subCategory.id == this.FormData[key]);
            queryParams[key] = [subCategory.categoryName.split(' ').join('-'), subCategory.id].join(',');
            break;
          default:
            queryParams[key] = this.FormData[key]
            break;
        }
        
      }
      
    });
    
    /**
     * 
     */
    if (Object.keys(queryParams).length > 0 && location.pathname == '/') {
      this._router.navigate([], {
        queryParams,
        replaceUrl: true,
        relativeTo: this._route,
        queryParamsHandling: 'merge',
      })
    }

    /**
     * delay to prevent page scroll to top after update queryParams in url
     */
    setTimeout(() => this._viewportScroller.scrollToPosition(this._viewportScroller.getScrollPosition()), 1)

  }  

  /**
   * 
   */
  private _updateSearchFormByQueryParams() {

    // ES6 Destruction
    const { queryParams } = this._route.snapshot;

    // init new ref
    const values = {...queryParams};

    //
    if (Object.keys(values).length > 0 && location.pathname == '/') {

      try {

        /**
         * 
         */
        Object.keys(values).forEach(key => {

          /**
           * 
           */
          try {

            /**
             * check to catch value not number
             */
            if (isNaN(values[key]) === true) throw 'is not Number';

            /**
             * convert string to number
             */
            values[key] = parseInt(values[key]);

          } catch (err) {}

          /**
           * 
           */
          if (typeof values[key] == 'string') {

            /**
             * handler special keys like ( city & category, More...)
             * because it's not normal string but it's collection of Name & ID in one string
             * so we split it to array to get Name & ID
             */
            switch(key) {
              case 'city':
              case 'area':
              case 'category':
              case 'subCategory':
                /**
                 * force convert to String to handler unType error
                 */
                const convertToString = values[key]?.toString();
                /**
                 * convert string to array
                 */
                const splitToArray = convertToString?.split(',');
                /**
                 * get second index and parse it to (ID Number)
                 */
                const secondIndexId = parseInt(splitToArray?.at(1));

                /**
                 * assign new value
                 */
                values[key] = secondIndexId;

                break;
              default:
                values[key] = values[key]?.toString();
                break;
            }

          }

        });

        // ES6 Destruction
        const { city, category, subCategory, contractType, priceRange } = values || {};


        /**
          * 
          */
        if(city != null) {
          this.HandlerGetAreasByCity(this.ListOfCity.find(c => c.id == city))
        }
        if(category != null) {
          this.HandlerGetSubByMainCategories(this.listOfCategory.find(c => c.id == category))
          if (subCategory != null) this.HandlerGetFeature(this.listOfSubCategory.find(s => s.id == subCategory))
        }
        if (contractType == Contract_Type.RT) {
          this.contractToggle(contractType)
        }
        if (priceRange != null) this.PricesRangeStatus(priceRange)
    
        /**
         * set controls values
         */
        this.FormSearch.patchValue({...values});
        this.FormSearch.markAsDirty();

      } catch (err) {
        console.log(err)
      }      
      
    }

  }  

  /**
   * 
   */
  public resetForm() {

    /**
     * remove queryParams From url
     */
    this._router.navigate(['./'])

    /**
     * features
     */
    this.resetFeatures();

    // enabled/show => searchMethod
    this.searchMethodSelected(1)

    /**
     * 
     */
    this.searchMethod(true)

    /**
     * 
     */
    this.contractToggle(null)

    /**
     * 
     */
    // this.handlerDisOrEnabledPlaces(true)

    /**
     * 
     */
    this.IsSubmitForm = false

    /**
     * reset Form
     */
    this.FormSearch.reset(this._defaultFormValue);

    /**
     * default view is list of ads
     */
    this.viewTypeToggle(SEARCH_VIEW_TYPE.NormalAds, true);

    /**
     * reset center map by user coords or default
     */
    this.CurrentMapCoords = this._currentUserCoords != null ? {...this._currentUserCoords} : this.CurrentMapCoords
    
    /**
     * 
     */
    scrollToElement('search_inputs_box', {
      child: true,
      direction: DIRECTION.up
    });
        
  }

  /**
   * viewReset
   */
  public viewReset(): void {

    /**
     * 
     */
    if (this.IsSubmitForm == true) {
      this.viewType = SEARCH_VIEW_TYPE.NormalAds;
      // this.handlerDisOrEnabledPlaces(true)
      this.handlerDisOrEnabledCoords(false)
    }

    /**
     * delay to prevent page scroll to top after update queryParams in url
     */
    setTimeout(() => {
      scrollToElement('search_result_box', {
        child: true,
        direction: DIRECTION.down
      });
    }, 100)    
  }

  /**
   * 
   */
  private initSearchResult(): void {

    this._searchResult?.subscribe(
      res => {
        
        // ES6 Destruction
        const { data, adsCount} = res;

        /**
         * 
         */
        this.totalAdsPageCount = adsCount;

        /**
         * 
         */
        this.ListOfNormalAds = data;
      
        /**
         * 
         */
        this.IsLoadingAds = false;
        this.IsLoadingSearch = false;
        
      },
      err => {
        /**
         * 
         */
        this.IsLoadingAds = false;
        this.IsLoadingSearch = false;
      }
    )

  }

  /**
   * 
   */
  public SearchIoDisconnect(): void {
    
    if (this._io !== null) {
      if (!this._io.ioSocket?.disconnected)this._io.disconnect()
    }

  }

  /**
   * 
   */
  public SearchIoConnect(): void {
    if (this._io !== null) {
      if (this._io.ioSocket?.disconnected) this._io.connect()
    }
  }  
  
  /**
   * 
   * @param data 
   * @param confige 
   */
  public getAds(data?: {[key: string]: any}) {

    /**
     * 
     */
    this.handlerFormateFromData();    

    // ES6 Destructing
    const { properties, ...rest } = data || {};

    // assign
    const body = {
      ...this.FormData,
      ...rest,
      page: this.CurrentAdsPage,
      actionUser: this._g.CorrectUserId || null,
    };

    /**
     * 
     */
    if (properties) body['properties'] = JSON.stringify(JSON.stringify(properties))

    /**
     * 
     */
    this.IsLoadingAds = true

    // emit
    this._io.emit(SOCKET_KEY_ADS.get, body)
    
  }

  /**
   * 
   * @param params 
   */
  public getAdsVip(p?: Params): void {

    /**
     * 
     */
    this.handlerFormateFromData();     

    /**
     * 
     */
    const params = {
      ...this.FormData,
      ...p,
      page: this.CurrentVipPage,
      specialAdvertisement: true, 
      [RANDOM_VALUE.loader]: true,      
    }

    /**
     * 
     */
    this.IsLoadingVidAds = true

    /**
     * 
     */
    this._api.AdsWithOutToken({ params })
    .subscribe(
      (res: any) => {

      // es6 destructuring
      const { data, totalCount } = res || {};

      this.ListOfVipAds = data;

      this.totalVipPageCount = totalCount;

      this.IsLoadingVidAds = false;

    },
    err => this.IsLoadingVidAds = false
    )    

  }

  /**
   * 
   * @param type 
   */
  viewTypeToggle(type: SEARCH_VIEW_TYPE, forceSubmit?: boolean): void {

    if (this.viewType != type) {

      /**
       * 
       */
      if (type == SEARCH_VIEW_TYPE.Map) {

        if (this.IsApproveCurrentUserLocation == false) {
  
          this._alert.show({
            type: LAYOUTS_TYPE.alertInfo,
            callback: () => this._alert.hide(),
            msg: 'لكي تتمكن من تصفح العروض العقارية من خلال الخريطة يجب تفعيل الوصول الي موقعك',
          })
  
        } 
        else {
  
          console.log('Map is Open')
  
          // set Controls
          this.control('limit').setValue(100);
  
          // set new optionView value to change ui
          this.viewType = type;
  
        }          

      }
      else {

        // rest InfoModel if it has value
        this.InfoModel = null;
    
        // set new optionView value to change ui
        this.viewType = type;
    
        // set Controls
        this.control('limit').setValue(20);        

        /**
         * 
         */
        if (type == SEARCH_VIEW_TYPE.NormalAds) this.submit();
        else if (type == SEARCH_VIEW_TYPE.VipAds) this.getAdsVip();

      }

    }

    /**
     * if user reset from & viewType is not change we will make forceSubmit to back to default
     */
    if (forceSubmit) {
      this.submit();
    }

  }

  //
  public NewCoords() {

    // ES6 Destructing
    const { lat, lng } = this.Map?.getCenter()?.toJSON() || {};

    /**
     * 
     */
    this.CurrentMapCoords = {lat, lng }
  
    /**
     * 
     */
    this.handlerDisOrEnabledCoords(true);

    /**
     * 
     */
    this.FormSearch.patchValue({lat, long: lng});

    /**
     * disabled it
     */
    // this.handlerDisOrEnabledPlaces(false);

    /**
     * 
     */
    this.submit();

    console.log('NewCoords');

  }    

    /**
   * End Methods
  */

}
