import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';


// libs
import { logEvent, getAnalytics } from '@angular/fire/analytics';

// env
import { environment } from 'src/environments/environment';

@Injectable()
export class AnalyticsService {

  constructor(
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: any
  ) { 
    
    if (isPlatformBrowser(this.platformId)) {
    }

  }

  logEvent(eventName: string) {
    
    if (environment.production) {
      logEvent(getAnalytics(), eventName)
    }

  }


  App() {
    
    
    if (environment.production) {

    }

  }
}
