<!-- import app-success -->
<app-success
    titleBtn="متابعة" 
    (Submit)="success()"
    *ngIf="alertSuccess"
    msg='تم حفظ ملفك و ارسال كود التحقق علي جوالك'
>
</app-success>

<!-- import app-terms-and-conditions -->
<app-terms-and-conditions 
    *ngIf="showTerms" 
    (close)="showTerms = $event"
>
</app-terms-and-conditions>

<!-- start app-sign-up -->
<div class="sign_up def_p bluer_section">
    <div class="custom_container position-relative">
        <div class="wrap_container">
            <!-- import app-tabs -->
            <app-tabs [tabs]="userTypeTabs"></app-tabs>
        </div>
    
        <div class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation"></app-navigation>
        </div>
    
        <div class="wrap_container">
            <form [formGroup]="FormSingUpOffice" (ngSubmit)="singUpUser()">

                <div class="wrap_all_inputs custom_scroll">
                    <ng-container *ngFor="let Input of listInput">
    
                        <!-- // show if type == img -->
                        <ng-container *ngIf="Input?.elementType == ELEMENT_FORM_TYPE.viewImg">
    
                            <div class="wrap_input_img">
                                <!-- import input-img -->
                                <app-input-img
                                    
                                    [name]="Input.name"
                                    [title]="Input.title" 
                                    (files)="getFileImgs($event)"
                                    [FormParent]="FormSingUpOffice"
                                    [validations]="Input.validators || []"                    
                                >
                                </app-input-img>
                            </div>
    
                            <!-- import app-validation-input  -->
                            <ng-container *ngFor="let validation of Input.validators">
                                <app-validation-input
                                    [text]="validation.text"
                                    [style]="{'margin-inline-start': 0,'text-align': 'center'}"
                                    *ngIf="getControl(Input.name)?.touched && getControl(Input.name)?.errors"
                                >
                                </app-validation-input>
                            </ng-container>
    
                        </ng-container>
    
                        <ng-container *ngIf="getControl(Input.name)?.enabled">

                            <ng-container [ngSwitch]="Input.name">
                                <!-- import app-label -->
                                <ng-container *ngSwitchCase="'licenseNumber'">
                                    <app-label
                                        [for]="Input.name"
                                        [style]="{color: '#fff'}"
                                        [title]="licenseNumberTitle" 
                                        *ngIf="!Input?.disabledLabel"
                                    >
                                    </app-label>                                    
                                </ng-container>
                                
                                <ng-container *ngSwitchDefault>                                    
                                    <app-label
                                        [for]="Input.name"
                                        [title]="Input.title" 
                                        [style]="{color: '#fff'}"
                                        *ngIf="!Input?.disabledLabel"
                                    >
                                    </app-label>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="Input?.elementType == ELEMENT_FORM_TYPE.phoneNumber">
        
                                <!-- import app-input-phone  -->
                                <app-input-phone
                                    [type]="Input.type" 
                                    [name]="Input.name" 
                                    [FormParent]="FormSingUpOffice"
                                    (formateNumber)="getCode_hasSelected($event)"
                                >
                                </app-input-phone>
        
                            </ng-container>
    
                            <ng-container *ngIf="Input?.elementType == ELEMENT_FORM_TYPE.select">
    
                                <div class="wrap_box">
                                    <ng-select
                                        [clearable]="false"
                                        [items]="Input?.items || []"
                                        [bindLabel]="Input?.bindLabel"
                                        [bindValue]="Input?.bindValue"
                                        [formControlName]="Input.name"
                                        [placeholder]="Input.placeholder"
                                        [searchable]="Input.name == 'city'"
                                        [notFoundText]="Input?.notFoundText"
                                        (change)="Input.callback && Input.callback($event)"
                                    >
                                    </ng-select>
                                </div>
    
                            </ng-container>
    
                            <ng-container *ngIf="Input?.elementType == ELEMENT_FORM_TYPE.file">
        
                                <!-- import app-input-file  -->
                                <app-input-file 
                                    [name]="Input.name"
                                    (file)="getCommercialFile($event)"
                                    [validations]="Input.validators || []"
                                    [defaultData]="listFiles['commercialFile']"
                                >
                                </app-input-file>                        
        
                            </ng-container>                         
    
                            <ng-container *ngIf="!Input?.elementType && getControl(Input.name)">
        
                                <!-- import app-input-text  -->
                                <app-input-text 
                                    [type]="Input.type" 
                                    [name]="Input.name" 
                                    [FormParent]="FormSingUpOffice"
                                    [placeholder]="Input.placeholder"
                                    [validations]="Input.validators || []"
                                    (InputValue)="Input.callback && Input.callback($event)"
                                    [validatorsErrorMsg]="Input?.validatorsErrorMsg || false"
                                >
                                </app-input-text>
        
                            </ng-container>
                            
                        </ng-container>

    
                    </ng-container>

                    <div class="wrap_check">
                        <!-- import app-input-check  -->
                        <app-input-check 
                            name="acceptTerms"
                            [FormParent]="FormSingUpOffice" 
                        >
                        </app-input-check>
        
                        <!-- import app-label -->
                        <app-label 
                            for="acceptTerms"
                            class="float-start"
                            title="قراءة الشروط" 
                            [style]="{color: '#fff'}"
                        >
                        </app-label>

                        <a 
                            routerLink="./" 
                            (click)="showTerms = true"
                            class="label_lg_normal float-end main_color"
                        >
                            صفحة الشروط
                        </a>                        
                        
                        <div class="clearfix"></div>
    
                        <!-- import app-validation-input  -->
                        <app-validation-input 
                            text="يجب الموافقة علي الشروط و الاحكام"
                            *ngIf="getControl('acceptTerms')?.touched && getControl('acceptTerms')?.errors"
                        >
                        </app-validation-input>
                    </div>
                </div>
    

                <!-- // show if server backend responded by errors -->
                <ng-container *ngIf="hasErrors.length > 0">
                    <div class="wrap_error_server text-center">
                        <div class="wrap d-inline-block">
                            <ng-container *ngFor="let error of hasErrors">
                                <!-- import app-validation-input  -->
                                <app-validation-input 
                                    [text]="error.msg"
                                    [style]="{'margin-inline-start': '0px', margin: 0}" 
                                >
                                </app-validation-input>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                
                <div class="wrap_btn text-center">
                    <!-- import app-btn -->
                    <app-btn 
                        title="التسجيل" 
                        [submit]="true"
                        [disabled]="FormSingUpOffice.invalid || FormSingUpOffice.pending || isLoading"
                    >
                    </app-btn>
                    <a routerLink="/sign-in-office" class="label_lg_normal d-block">تسجيل دخول</a>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- end app-sign-up -->