import { of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

// libs outside
import * as CryptoJS  from 'crypto-js';

// env
import { environment } from 'src/environments/environment';

// custome service
import { MainApiService } from './main/main-api.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { usersType } from 'src/app/core/enums/user/user-type';
import { RANDOM_VALUE } from 'src/app/core/enums/global/random-value';
import { LOCAL_METHOD } from 'src/app/core/enums/global/local-methods';
import { SHARED_KEYS_APPS } from 'src/app/core/enums/cryptos/shared-keys-apps';
import { cryptoLocalStorage } from 'src/app/core/enums/cryptos/crypto-localstorage';

// interfaces
import { IuserInfo } from 'src/app/core/interfaces/user/user-info';

@Injectable({
  providedIn: 'root'
})
export class GlobleService {

  constructor(
    private router: Router,
    private API: MainApiService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  // function to navigate to any path
  navigate(path: any[], param?: {}, fragment?: string): void {

    if (Object.keys(param || {}).length > 0 || fragment != '') {
      this.router.navigate(path, {queryParams: param, fragment})
    } else {
      this.router.navigate(path)
    }
  }

  // ** //
  saveUserData(key: string, data: any) {
    // Encrypt
    const Encrypt = this.encryptData(cryptoLocalStorage.KEY, data)
    
    // save data by key
    localStorage.setItem(key, Encrypt)
  }

  saveLocalData(key: any, data: {}, type?: LOCAL_METHOD) {

    // Encrypt
    const Encrypt = this.encryptData(cryptoLocalStorage.KEY, data);

    // save data by key
    if (type) sessionStorage.setItem(key, Encrypt);
    else localStorage.setItem(key, Encrypt)
    
  }  

  // ** //
  getUserData(key: string) {

    // get data from localStorage
    let getData = localStorage.getItem(key) as string;

    if (getData) {
      
      // Decrypt
      const Decrypt  = this.decryptData(cryptoLocalStorage.KEY, getData)
          
      // convert it to object
      let userInfo = Decrypt
  
      return userInfo
    }

    return null

  }

  getLocalData(key: any, type?: LOCAL_METHOD) {

    let getData: any;

    // get data from localStorage
    if (type) getData = sessionStorage.getItem(key) as string;
    else getData = localStorage.getItem(key) as string;

    if (getData) {
      
      // Decrypt
      const Decrypt  = this.decryptData(cryptoLocalStorage.KEY, getData)
          
      // convert it to object
      let data = Decrypt
  
      return data
    }

    return null    

  }

  // function encrypt
  encryptData(key: string, data: any){
    // get data and convert it to string
    const convertToStr = JSON.stringify(data)

    // Encrypt
    const encry = CryptoJS.AES.encrypt(convertToStr, key).toString();

    return encry

  }

  // function decrypt
  decryptData(key: string, data: any){
    const decry  = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);

    // debugger
    // convert it to object
    const parse: any = decry ? JSON.parse(decry) : null
      
    return  parse
  }
  
  public encryptDataBySecretKey(data: any): string {

    if (data) {

      try {

        //
        const iv = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.iv); // 128-bit IV
        const secretKey = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.secretKey); // 256-bit key    
        const encrypt_data = CryptoJS.AES.encrypt(typeof data != 'string' ? JSON.stringify(data): data, secretKey, {
            iv,
            mode: CryptoJS.mode.CBC,
            format: CryptoJS.format.Hex,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.format.Hex)
  
        return encrypt_data;
        
      }
      catch (err) {
        console.log(err)
      } 
    }
    else throw new Error('data arg is not found')

  }

  public decryptDataBySecretKey<T>(data: string): T {

    if (data && typeof data == 'string') {

      try {

        //
        const iv = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.iv); // 128-bit IV
        const secretKey = CryptoJS.enc.Utf8.parse(SHARED_KEYS_APPS.secretKey); // 256-bit key    
        const decrypt_data = CryptoJS.AES.decrypt(data, secretKey, {
          iv,
          mode: CryptoJS.mode.CBC,
          format: CryptoJS.format.Hex,
          padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
    
        return decrypt_data as T
        
      }
      catch (err) {
        console.log(err)
      }

    }
    else throw new Error('data arg is not found or not string')
    
  }  

  public get userInfo() {
    
    const data = this.getUserData(User.info) ;

    if (data) return data as IuserInfo

    return null

  }

  public IncompleteUserInfo(userInfo: any): boolean {
    
    //
    const keys = ['fullname', 'email', 'city'];

    //
    let checkStatus: boolean = false
    
    for (let i = 0; i < keys.length; i++) {

      if (!Object(userInfo).hasOwnProperty(keys[i])) {
          checkStatus = true
          break
      }
      
    }

    return checkStatus

  }

  public get CorrectUserId(): string | number | null {

    const { id, parent, type } = this.getUserData(User.info) || {};

    if (type == usersType.superVisor) return parent?.id;
    else if (parent) return parent?.id

    return id
  }

  public get IncompleteRealtyRequest(): boolean {

    // ES6 destructuring
    const { workArea, workSubCategory } = this.userInfo || {};

    if (workArea?.length > 0 && workSubCategory?.length > 0) return true

    return false

  }

  public fillterArray(Arr: any[], keyDepthLevel : string | any[]): any[] {

    // init
    let f: any[] = [];
    
    if (typeof keyDepthLevel == 'string') f = Arr.flatMap((it, index) => it[keyDepthLevel])
    else if (Array.isArray(keyDepthLevel)) {

      let x: any[] = Arr

      for (let i = 0; i < keyDepthLevel.length; i++) {
        x = x.flatMap((it, index) => it[keyDepthLevel[i]])
      }
      
      //
      f = x
    }

    // 
    return f

  } 
  
  public formatNumber(value: string | number | null){
    
    let v = value?.toString();

    if (v) {
      let str = v.replace(/\D/g,'');

      return parseInt(str) ? parseInt(str)?.toLocaleString() : ''

    }
    return null
  }  

  // func will if app make any navigation
  // take action from router-outlet as outPut
  public goTo_TopPage() {
    setTimeout(() => document.documentElement.scrollTop = 0, 100)
  }  

  //
  public handelHash(id: string) {

    window.location.hash = ''
    
    // ES6 destructuring
    const {hash, pathname, } = window.location

    // ES6 Promise
    let hashPromise  = new Promise<Promise<boolean> | boolean>((res, rej) => !hash ? res(true) : rej(false) );
    
    // set 
    hashPromise.then((check => window.location.hash = id))    

  }

  // obsvs
  public regaAuthValidation(data: {[key: string]: any}) {
    return this.API.mainPost(`${environment.endPointAds}/RgeaAUTHValidation`, data, { 
        params: {
        [RANDOM_VALUE.loader]: true,
      }
    }).pipe(
      map((res) => null),
      catchError(err => of({asyncError: true})),
    )
  }

  public get isMobileDevice(): string {
    if (isPlatformBrowser(this.platformId)) {

      if(navigator.userAgent.match(/mobile/gi)) return 'mobile'
      
      return ''

    }

  }

  public get currentDate(): Date {
    return new Date();
  }

}
