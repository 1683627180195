import { AbstractControl, ValidatorFn } from '@angular/forms';

export function Validations_LettersOnly(control: AbstractControl): {} | null {

    let regex = /^(?:[a-zA-Z\s\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,200}$/;

    if (control.value && !regex.test(control.value)) return { 
        letters: 'لا يجب ان يحتوي الحقل علي ارقام او علامات'
     }
    
    return null

}