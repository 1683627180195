/**
 * doc: => https://www.tap.company/eg/en/developers
 * path: PAYMENT => Authorize => Authorize Response
 */

export enum PAYMENT_AUTH_RES {
    /* "VOID" - authorized amount voided successfully. */
    void = 'VOID',

    /* "CAPTURED" - captured the authorized amount successfully. */
    captured = 'CAPTURED',

   /**
    * "INITIATED" - Tap will provide the payment url (transaction.url) to process the payment. customer should be redirected to 
    * this url in order to complete the payment.
    */
    initiated = 'INITIATED',

    /* "AUTHORIZED" - Amount got authorized succesfully. */
    authorized = 'AUTHORIZED',
}