<!-- popUp -->
<div 
    @fadeIn
    *ngIf="openPopUp" 
    (click)="openPopUp = false"
    class="custom_popUp d-flex justify-content-center align-items-center text-center" 
>
    <div class="wrap" (click)="$event.stopPropagation()">
        <h4 class="label_lg_bold">هل تريد حذف {{type == ads_type.request ? 'الطلب' : 'الاعلان'}} رقم ( {{info?.id}} )</h4>
        <div class="wrap_btns d-flex justify-content-between align-items-center">
            <app-btn
                title="الغاء"
                (click)="openPopUp = false"
                [style]="{background: '#dc3545', padding: '0px 45px'}" 
            >
            </app-btn>
            <app-btn 
                title="حذف"
                (click)="deleteAds()"
                [style]="{padding: '0px 45px'}"                 
            >
            </app-btn>
        </div>
    </div>
</div>

<ng-container [ngSwitch]="type">
    <a 
        class="a_none d-block" 
        *ngSwitchCase="ads_type.request"
        [routerLink]="['/realty-management/view-realty-request' , info?.id]"
    >
        <!-- start app-info-card -->
        <div class="info_card border_r_2th border_def_2th position-relative">
            <div 
                *ngIf="fav_icon" 
                class="wrap_fav_icon"
                (click)="
                    onEvent();
                    $event.preventDefault();
                    $event.stopPropagation();
                "
            >
                <img src="/assets/img/favorite-3.svg" alt="favorite">
            </div>
            <div class="wrap_main_info">
                <div class="info float-start" [ngClass]="{'simple_info': ads_type.request}">
                    <div>
                        <div class="float-end">
                            <ng-container *ngIf="showAction && AdsRequestActionShow">
                                <!-- // import <app-float-drop-down  -->
                                <app-float-drop-down 
                                    [Items]="dropDownItems"
                                    (Action)="dropDownAction($event)"
                                    class="wrap_drop_down float-end text-end"
                                    [iconStyle]="{
                                        'margin-inline-end': '-19px',
                                        'padding-inline-end': '19px'
                                    }"
                                >
                                </app-float-drop-down>
                                <div class="clearfix"></div>
                            </ng-container>
                            <div class="wrap_state float-end text-center">
                                <ng-container [ngSwitch]="info?.status">
                                    <app-badge 
                                        title="متاح" 
                                        *ngSwitchCase="statusType.N"
                                        [style]="{padding: '0 22px'}"
                                        >
                                    </app-badge>
                                    <app-badge 
                                        bg="#21305D"
                                        title="مكتمل" 
                                        [style]="{padding: '0 22px'}"
                                        *ngSwitchCase="statusType.COM"
                                    >
                                    </app-badge>
                                    <app-badge 
                                        bg="#FFA803"
                                        title="تحت الاجراء" 
                                        *ngSwitchCase="statusType.PR"
                                        [style]="{padding: '0 22px'}"
                                    >
                                    </app-badge>
                                    <app-badge 
                                        bg="#D83B3B"
                                        title="منتهي" 
                                        [style]="{padding: '0 22px'}"
                                        *ngSwitchCase="statusType.CAN"
                                    >
                                    </app-badge>
                                </ng-container>
                            </div>
                            <div class="clearfix"></div>
                            <div 
                                (click)="
                                    openPopUp = true;
                                    $event.preventDefault();
                                    $event.stopPropagation()
                                "
                                class="wrap_options float-end text-center"
                                *ngIf="info?.owner?.id == G.CorrectUserId && canDelete"
                            >
                                <app-badge 
                                    bg="#D83B3B"
                                    title="حذف"
                                    Class="pointer mt-1"
                                    [style]="{padding: '5px 22px'}"
                                >
                                </app-badge>
                            </div>
                        </div>
                        <h4 class="text_md_bold mb-0 d-inline-block" *ngIf="info?.title">
                            <a class="a_none color_black" [routerLink]="['/realty-management/view-realty-request' , info?.id]">{{info?.title}}</a>
                        </h4>

                        <!-- start Size -->
                        <ng-container *ngIf="Size">
                            <div class="size">
                                <span class="text_md_bold">
                                    {{Size}}
                                </span>
                            </div>
                        </ng-container>    
                        <!-- end Size -->

                        <!-- start Price -->
                        <ng-container *ngIf="Price">
                            <div class="price">
                                <span class="text_md_bold">
                                    {{Price}}
                                </span>
                            </div>
                        </ng-container>
                        <!-- end Price -->

                        <div class="location">
                            <img src="/assets/img/location.svg" alt="icon location">
                            <span class="text_md_normal">
                                {{info?.city?.cityName || ''}}
                                -
                                {{info?.area[0]?.areaName || ''}}
                                <ng-container *ngIf="info?.area.length > 1">
                                    + ( {{info?.area.length  - 1}} حى اخرى )
                                </ng-container>
                            </span>
                            <!-- <ng-container *ngFor="let area of $any(info?.area | slice: 0 : 1); last as last">
                                <span class="text_md_normal">{{area?.areaName}} - {{last ? info?.city?.cityName : ''}}</span>
                            </ng-container> -->
                        </div>
                        <div class="description" *ngIf="info?.description">
                            <p class="text_sm_normal color_black mb-0">{{info?.description}}</p>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="wrap_bread_crumb">
                <app-bread-crumb [AdsInfo]="info"></app-bread-crumb>
            </div>
        </div>
        <!-- end app-info-card -->
    </a>
    <a 
        class="a_none d-block" 
        *ngSwitchDefault
        [queryParams]="{view: viewState ? true : null}"
        [routerLink]="['/realty-management/view-realty-advertisement' , info?.id]"
    >
        <!-- start app-info-card -->
        <div 
            [ngClass]="{'vip_ads': VipAds}"
            class="info_card border_r_2th border_def_2th position-relative"
        >
            <div 
                *ngIf="fav_icon"
                class="wrap_fav_icon"
                (click)="
                    onEvent();
                    $event.preventDefault();
                    $event.stopPropagation();
                "
            >
                <img src="/assets/img/favorite-3.svg" alt="favorite">
            </div>
            <div class="wrap_main_info">           
                <a 
                    *ngIf="info?.imgs"
                    [ngClass]="{'unavailable': !info?.available}"
                    [queryParams]="{view: viewState ? true : null}"
                    [routerLink]="['/realty-management/view-realty-advertisement', info?.id]"
                    class="wrap_img_with_views text_md_bold color_white float-start text-center"                         
                >
                    <div 
                        class="status"
                        *ngIf="StatusTitleType"
                    >
                        <app-badge 
                            [title]="StatusTitleType?.title"
                            [ClassList]="'border_r custom_badge shadow ' + StatusTitleType?.class"
                        >
                        </app-badge>
                    </div>
                    <img 
                        appImgUrlCheck
                        loading="lazy"
                        class="img-fluid" 
                        [alt]="info?.title"
                        [src]="info?.imgs[0]" 
                        defaultImg="/assets/img/main-logo.png"
                    />
                    <div class="views">
                        <span class="text_sm_bold">مشاهدة</span>
                        <span class="text_sm_normal dir_ltr">{{info?.viewsCount ? (info?.viewsCount | shortNumber) : '0'}}</span>
                    </div>
                </a>
                <div 
                    class="info float-start"
                    [ngClass]="{'d-flex justify-content-between flex-column': VipAds}"
                >
                    <div class="wrap_top_info">
                        <div class="right_side">
                            <div class="left_side float-end">
                                <div 
                                    (click)="
                                        openPopUp = true;
                                        $event.preventDefault();
                                        $event.stopPropagation()
                                    "
                                    class="wrap_options float-end text-center"
                                    [ngClass]="{'mb-1': VipAds}"
                                    *ngIf="info?.owner?.id == G.CorrectUserId && canDelete"
                                >
                                    <app-badge 
                                        bg="#D83B3B"
                                        title="حذف"
                                        Class="pointer mt-1"
                                        [style]="{padding: '5px 22px'}"
                                    >
                                    </app-badge>
                                </div>
                                <div class="clearfix" *ngIf="canDelete"></div>
                                <div class="price float-end lh_none text-center" *ngIf="VipAds">
                                    <span class="text_sm_normal color_gray d-block">{{PriceTitleType}}</span>
                                    <span 
                                        class="text_md_bold"
                                        *ngIf="info?.price"
                                    >
                                        {{Price}}
                                    </span>
                                </div>
                                <div 
                                    class="def_map float-end text-center" 
                                    *ngIf="info?.link3D?.length > 0 && !canDelete && !VipAds"
                                >
                                    <ng-lottie 
                                        [styles]="styles"
                                        [options]="options"
                                        containerClass="d-inline-block"
                                    >
                                    </ng-lottie>                                    
                                    <span  class="a_none text_sm_normal text-decoration-underline d-block main_color">
                                        التجول الافتراضي
                                    </span>
                                </div>
                            </div>                            
                            <h4 class="text_md_bold mb-0 d-inline-block" *ngIf="info?.title">
                                <a class="color_black a_none" [routerLink]="['/realty-management/view-realty-advertisement', info?.id]">
                                    <ng-container *ngIf="info?.title?.includes('-'); else withoutDash">
                                        {{(info?.title?.split('-') | slice:0:2)?.join('-') }}
                                    </ng-container>
                                    <ng-template #withoutDash>
                                        {{(info?.title?.split(' ') | slice:0:2)?.join(' ') }}
                                    </ng-template>
                                </a>
                            </h4>
                            <div class="price" *ngIf="info?.price && !VipAds">                     
                                <span class="text_md_bold">{{Price}}</span>                            
                            </div>
                            <div class="location">
                                <img src="/assets/img/location.svg" alt="icon location">
                                <span class="text_md_normal">
                                    {{shortText(info?.area?.areaName, 2).length > 15 ? ((shortText(info?.area?.areaName, 2) | slice:0:15) + '...') : shortText(info?.area?.areaName, 2)}}
                                    -
                                    {{shortText(info?.city?.cityName, 1).length > 15 ? ((shortText(info?.city?.cityName, 1) | slice:0:15) + '...') : shortText(info?.city?.cityName, 1)}}
                                </span>
                            </div>
                            <ng-container *ngIf="VipAds && info?.enableFund">
                                <span class="financing d-inline-block font_12 font_600 text-white padding_y padding_x_2">يقبل التمويل</span>
                            </ng-container>
                            <div class="description" *ngIf="info?.description && !VipAds">
                                <p class="text_sm_normal color_black">{{info?.description}}</p>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <ng-container *ngIf="VipAds">
                        <ul class="list_none vip_ads_options d-flex align-items-center justify-content-between flex-wrap text-center">
                            <li>
                                <span class="vid_ads_option_text text_sm_normal main_color_4th d-block">{{PiecesTitleType.total}}</span>
                                <span 
                                    [ngClass]="{
                                        'color_wrong': info?.unitsCount >= 0,
                                        'color_gray': info?.unitsCount > 0  
                                    }"
                                    class="vid_ads_option_count"
                                >
                                    {{info?.unitsCount}}
                                </span>
                            </li>
                            <li class="col_btw"></li>
                            <li>
                                <span class="vid_ads_option_text text_sm_normal main_color_4th d-block">{{PiecesTitleType.available}}</span>
                                <span 
                                    class="vid_ads_option_count"
                                    [ngClass]="{
                                        'color_wrong': info?.availableUnitsCount >= 0,
                                        'color_gray': info?.availableUnitsCount > 0
                                    }"
                                >
                                    {{info?.availableUnitsCount}}
                                </span>
                            </li>
                            <li class="col_btw" *ngIf="info?.link3D[0]"></li>
                            <li *ngIf="info?.link3D[0]">
                                <span class="d-block">
                                    <ng-lottie 
                                        [styles]="styles"
                                        [options]="options"
                                        containerClass="d-inline-block"
                                    >
                                    </ng-lottie>
                                    <span class="vid_ads_option_text text_sm_normal text_3d_icon d-block">التجول الافتراضي</span>
                                </span>
                            </li>
                        </ul>                        
                    </ng-container>
                    <ul 
                        *ngIf="info?.properties && !VipAds"
                        class="list_none options d-flex align-items-center flex-wrap text-center text-md-start" 
                    >
                        <ng-container *ngFor="let prop of ListOfProperties | slice: 0 : 4; let first = first; let last = last">
                            <li>
                                <div class="wrap_props">
                                    <div class="wrap_icon d-inline-block">
                                        <img 
                                            appImgUrlCheck
                                            loading="lazy"
                                            class="img-fluid"  
                                            [src]="prop?.img" [alt]="prop?.name"
                                        >
                                    </div>
                                    <span class="label_sm_bold">
                                        <ng-container [ngSwitch]="prop?.type">
                                            <ng-container *ngSwitchCase="FeaturesType.list">
                                                {{prop?.optionName}}
                                            </ng-container>
                                            <ng-container *ngSwitchCase="FeaturesType.number">
                                                {{prop?.value}}
                                            </ng-container>                                            
                                            <ng-container *ngSwitchDefault>{{prop?.optionName || prop?.value}}</ng-container>
                                        </ng-container>
                                    </span>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="wrap_bread_crumb" *ngIf="!VipAds">
                <app-bread-crumb [AdsInfo]="info"></app-bread-crumb>
            </div>
        </div>    
    </a>
</ng-container>