//
export const whiteSpace = /\s/;
export const zerosAtFirst = /^0+/
export const SpecialCharacters  = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/

//
export const leastOneNumber  = /(?=.*[0-9])/
export const leastOneLowerCase  = /(?=.*[a-z])/
export const leastOneUpperCase  = /(?=.*[A-Z])/
export const leastOneLowerUpperCase  = /(?=.*[A-z])/

//
export const arabicCharacters = /^[\u0621-\u064A ]*$/
export const notArabicCharacters = /[^\u0621-\u064A ]*$/
export const arabicWithFullNumber = /[^\u0621-\u064A\u0660-\u06690-9 ]/

//
export const englishCharacters = /^[A-z ]*$/
export const englishWithNumber = /^[A-z0-9]*$/
export const R_englishWithNumber = /[^A-z0-9]/
export const notEnglishCharacters = /[^A-z ]*$/

//
export const englishArtical = /[^A-z0-9\s\w\d\?;:,"'»{}()[\]\-_+=!@#$%\^&*|']/
export const arbicArtical = /[^\u0621-\u064A\u0660-\u06690-9\s\?;:,"'»{}()[\]\-_+=!@#$%\^&*|' ]/
export const mainArtical = /[^A-z0-9\u0621-\u064A\u0660-\u06690-9\s\w\d\?;:,"'»{}()[\]\-_+=!@#$%\^&*|']/

//
export const numberOnly = /[^0-9]/
export const lettersOnly = /[^A-z\u0621-\u064A ]/
export const numberEn_Ar_Only = /[^0-9\u0660-\u0669]/
export const simpleLettersWithNumber = /[^A-z0-9\u0621-\u064A\u0660-\u0669\s\w\d\?;:,"'»{}()[\]\-_+=!@#$%\^&*|' ]/
export const lettersWithNumber = /^(?:[A-z0-9\s\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,}$/

//
export const standardEmail = /^\w+([.-]\w+)*@\w+(\.\w{2,40})$/

//
export const standardUrl = /(https?:\/\/)?(?=(w{3}\.[A-z0-9_-]+\.[a-z]{2,}))/
export const advUrl = /^(https?:\/\/|(?!(https?)))(w{3}\.|(?!w{3}))[a-z0-9_-]{2,}(\.[a-z]{2,20})+(\/.*)?$/g
/*
* this regx has Look behind and Look behind not supported in ios safari & chrome
* /https?:\/\/(?=(w{3}\.[A-z0-9_-]+\.[a-z]{2,}|(?<!https?:\/\/(?=w{3}\.))[A-z0-9_-]+\.[a-z]{2,}))/
*/
