<!-- start app-alert -->
<div class="c_alert d-flex align-items-center justify-content-center flex-column">
    <div class="wrap_close" @fied>
        <app-close *ngIf="$any(alertInfo)?.closeBtn" (click)="Alert.hide(); $any(alertInfo)?.closeCallback()"></app-close>
    </div>
    <div @fied class="wrap">
        <div 
            [ngClass]="{
                'wrap_icon': $any(alertInfo)?.type,
                'color_wrong': $any(alertInfo)?.type == LAYOUTS_TYPE.alertFailure,
                'main_color': $any(alertInfo)?.type != LAYOUTS_TYPE.alertFailure
            }"
            class="d-flex align-items-center justify-content-center"
        >
            <ng-container *ngIf="$any(alertInfo)?.type">
                <ng-container [ngSwitch]="$any(alertInfo)?.type">
                    <i *ngSwitchCase="LAYOUTS_TYPE.alertInfo" class="fa-solid fa-info main_color"></i>
                    <i *ngSwitchCase="LAYOUTS_TYPE.alertSucess" class="fa-solid fa-check main_color"></i>
                    <i *ngSwitchCase="LAYOUTS_TYPE.alertFailure" class="fa-solid fa-xmark color_wrong"></i>
                    <i *ngSwitchDefault class="fa-solid fa-check main_color"></i>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="$any(alertInfo)?.typeContent">
                <div [innerHTML]="$any(alertInfo)?.typeContent"></div>
            </ng-container>
        </div>
        <p class="text_md_bold" [innerHTML]="$any(alertInfo)?.msg"></p>
        <app-btn 
            [focus]="true"
            [title]="$any(alertInfo)?.titleBtn || 'موافق'"
            (click)="$any(alertInfo)?.callback ? $any(alertInfo)?.callback() : Alert.hide()"
        />
    </div>
</div>
<!-- end app-alert -->