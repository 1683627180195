import { AfterViewInit, Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss']
})
export class BtnComponent implements OnInit, AfterViewInit {
  
  // ViewChild
  @ViewChild('buttonRef') public buttonRef: ElementRef<HTMLButtonElement>

  // ContentChild
  @ContentChild('ngContainer') public Content: ElementRef<any>


  // init props & inputs is coming
  @Input('icon') icon: string = ''
  @Input('style') style: {} | undefined
  @Input('type') type: string | undefined
  @Input('ClassBtn') ClassBtn: string = ''
  @Input('title') title: string | undefined
  @Input('submit') submit: boolean | undefined
  @Input('ClassTitle') ClassTitle: string = ''
  @Input('focus') autofocus: boolean | undefined
  @Input('disabled') disabled: boolean | undefined
  @Input('isLoading') IsLoading: boolean | undefined
  @Input('call') call: (args?: any) => void = () => {}
  @Input('animate') animate: string | number | undefined

  /**
   * 
   */
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    
    /**
     * focus in button after view it
     */
    if (this.autofocus) this.buttonRef.nativeElement.focus()
  }

  // getters
  public get handlerProjectedContent(): boolean {
    
    if (this.Content == null) return false
    return this.Content.nativeElement ? true : false

  }

  onEvent(): void {
    this.call()
  }

}
