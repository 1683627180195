import { Pipe, PipeTransform } from '@angular/core';

// enums
import { Contract_Type } from 'src/app/core/enums/ads/contract-types';

@Pipe({
  name: 'contractType'
})
export class ContractTypePipe implements PipeTransform {

  transform(value: string, ...args: any[]) {

    if (value) {
        switch(value) {
            case Contract_Type.SE:
                return 'للبيع';
            case Contract_Type.RT:
                return 'للايجار';
            default:
                return 'غير مطابق'
        }
    }
    
    console.warn(`value is not found => ${value}`)

    return null

  }

}
