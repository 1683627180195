import { trigger, state, style, transition, animate, group } from '@angular/animations';

export const fade = [
    trigger('fade', [
        transition(':enter', [
            style({opacity: '0'}),
            animate('150ms', style({opacity: '1'}),),
        ]),
        transition(':leave', [
            style({opacity: '1'}),
            animate('150ms', style({opacity: '0'}),),
        ])
    ])    
]

export const fadeIn = [
    trigger('fadeIn', [
        transition(':enter', [
            style({opacity: '0'}),
            animate('200ms', style({opacity: '1'}),),
        ])
    ])    
]