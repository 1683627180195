import { isPlatformBrowser } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { 
  PLATFORM_ID,
  Inject,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

// libs class
import { MarkerClustererOptions } from '@googlemaps/markerclustererplus';

// custom services
import { SearchService } from '../services/search.service';
import { GlobleService } from 'src/app/core/services/globle.service';
import { AdsService } from 'src/app/main.components/Ads-management/services/ads.service';

// custom enums
import { Price_Type } from 'src/app/core/enums/ads/price-types';
import { Contract_Type } from 'src/app/core/enums/ads/contract-types';
import { SEARCH_VIEW_TYPE } from 'src/app/core/enums/layouts/search-view-types';
import { CATEGOR_VALUE_TYPE } from 'src/app/core/enums/category/cate-value-types';

// interfaces
import { ICategory } from 'src/app/core/interfaces/category/category';

// custom animations
import { fade } from 'src/app/core/animations/fade';
import { DIRECTION, scrollToElement } from 'src/app/core/funcations-helper/Dom/scroll-to-element';

@Component({
  selector: 'app-search-result-box',
  templateUrl: './search-result-box.component.html',
  styleUrls: ['./search-result-box.component.scss'],
  animations: [fade],
  encapsulation: ViewEncapsulation.None
})
export class SearchResultBoxComponent implements OnInit, OnDestroy {

  /**
   * fields
   */
  public mapZoom: number = 8;
  public CategoryTypeActiveAdsVip: ICategory = null;
  public googleMapsOptions: google.maps.MapOptions = {
    zoom: 8,
    minZoom: 8,
    scrollwheel: false,
    mapTypeControl: true,
  }
  public readonly markerOptions: google.maps.MarkerOptions = {
    optimized: true,
    crossOnDrag: false,
    icon: {
      url: '/assets/img/transparent.png',
      size: isPlatformBrowser(this.platformId) ? new google.maps.Size(1, 1) : null,
    },    
  } 

  public readonly markerClusterStyle: MarkerClustererOptions = {
    minimumClusterSize: 2,
    zoomOnClick: false,
    clusterClass: 'Marker_cluster',
    averageCenter: true,
    styles: [{
      textColor: 'white',
      textSize: 10,
      fontStyle: 'bolder',
      width: 43,
      height: 43
    }]
  };


  constructor(
    public G: GlobleService,
    public Search: SearchService,
    private ADS_SERVICE: AdsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  /**
   * getter * setter
   */

  /**
   * 
   */
  public get Map(): google.maps.Map {
    return this.Search.Map
  }

  /**
   * 
   */
  public set Map(map: google.maps.Map) {
    this.Search.Map = map
  }

  /**
   * 
   */
  public get Form(): UntypedFormGroup {
    return this.Search.FormSearch
  }

  /**
   * 
   */
  public get SubmitForm(): boolean {
    return this.Search.IsSubmitForm
  }  

  /**
   * 
   */
  public get IsLoadingSearch(): boolean {
    return this.Search.IsLoadingSearch
  } 

  /**
   * 
   */
  public get IsLoadingAds(): boolean {
    return this.Search.IsLoadingAds
  }
  
  /**
   * 
   */
  public get IsLoadingVidAds(): boolean {
    return this.Search.IsLoadingVidAds
  }

  /**
   * @returns string, list or vip or map
   */
  public get ViewType(): string {
    return this.Search.viewType
  }

  /**
   * 
   */
  public get ListOfCategory(): ICategory[] {
    return this.Search.listOfCategory
  }

  /**
   * 
   */
  public get CurrentMapCoords(): typeof this.Search.CurrentMapCoords {
    return this.Search.CurrentMapCoords
  }

  /**
   * 
   * @returns 
   */
  public get ListOfNormalAds(): any[] {
    return this.Search.ListOfNormalAds
  }

  /**
   * 
   * @returns 
   */
  public get ListOfVipAds(): any[] {
    return this.Search.ListOfVipAds
  }

  /**
   * 
   */
  public get AdLimitItems(): number {
    return this.Search.adItemsLimit
  }

  /**
   * 
   */
  public get CurrentAdsPage(): number {
    return this.Search.CurrentAdsPage
  } 

  /**
   * 
   */
  public set CurrentAdsPage(page: number) {
    this.Search.CurrentAdsPage = page
  }   
  
  /**
   * 
   */
  public get totalAdsPageCount(): number {
    return this.Search.totalAdsPageCount
  }   

  
  /**
   * 
   */
  public get AdVipLimitItems(): number {
    return this.Search.adVipItemsLimit
  }

  /**
   * 
   */
  public get CurrentVipPage(): number {
    return this.Search.CurrentVipPage
  } 

  /**
   * 
   */
  public set CurrentVipPage(page: number) {
    this.Search.CurrentVipPage = page
  }   
  
  /**
   * 
   */
  public get totalVipPageCount(): number {
    return this.Search.totalVipPageCount
  }  

  /**
   * 
   */
  public get InfoModel() {
    return this.Search.InfoModel
  }

  /**
   * 
   */
  public set InfoModel(ad: any) {
    this.Search.InfoModel = ad
  }

  /**
   * 
   * @returns 
   */
  public get PriceType(): typeof Price_Type {
    return Price_Type
  }

  /**
   * 
   * @returns 
   */
  public get ContractType(): typeof Contract_Type {
    return Contract_Type
  }

  /**
   * 
   * @returns 
   */
  public get CategoryValueType(): typeof CATEGOR_VALUE_TYPE {
    return CATEGOR_VALUE_TYPE
  }
  
  /**
   * 
   * @returns 
   */
  public get SearchViewType(): typeof SEARCH_VIEW_TYPE {
    return SEARCH_VIEW_TYPE
  }  


  /**
   * Methods
   */

  ngOnInit(): void { 

    /**
     * 
     */
    this.ADS_SERVICE.ngOnInit();

    /**
     * 
     */
    this.Search.SearchIoConnect();
    
  }

  /**
   * 
   * @param type list or bip or map
   */
  public viewTypeToggle(type: SEARCH_VIEW_TYPE) {

    
    if (this.Search.viewType != type) {
      /**
       * 
       */
      this.CurrentAdsPage = 1;
      this.CurrentVipPage = 1; 
      this.CategoryTypeActiveAdsVip = null;  

      /**
       * 
       */
      this.Search.viewTypeToggle(type);

    }
    
  }
  
  /**
   * 
   */
  public NewCoords(): void {
    this.Search.NewCoords()
  }

  /**
   * 
   */
  public changeZoom(): void {
    this.mapZoom = this.Map.getZoom();
  }

  /**
   * 
   */
  public VipAdsFilterCategoryTypeChange(category: ICategory | null): void {

    if (this.CategoryTypeActiveAdsVip?.type != category?.type) {

      //
      this.CategoryTypeActiveAdsVip = category;

      /**
       * 
       */
      // rest page Number
      this.Search.CurrentVipPage = 1;
      
      /**
       * 
       */
      this.Search.getAdsVip(category instanceof Object ? {category: category.id} : null);

    }

  }

  /**
   * 
   * @param ads 
   */
  getInfoAds(ads : any) {

    //
    let 
      map = document.getElementById('Agm_map'),
      customModel = document.getElementById('custom_model');

    //
    map?.firstElementChild?.firstElementChild?.appendChild(customModel as HTMLElement)

    //
    this.Search.InfoModel = ads;

    //
    this.ADS_SERVICE.viewAds(ads);

  }

  /**
   * 
   * @param page pageNUmber
   */
  public VipPageNumber(page: number) {

    // set new page number
    this.CurrentVipPage = page;

    // ES6 Detraction
    const { id } = this.CategoryTypeActiveAdsVip || {}

    // call
    this.Search.getAdsVip(id != null ? {category: id} : null);

    /**
     * 
     */
    scrollToElement('search_result_box', {
      child: true,
      direction: DIRECTION.up
    });
    
  }
  
  /**
   * 
   * @param page pageNUmber
   */
  public NormalPageNumber(page: number) {

    // set new page number
    this.CurrentAdsPage = page;

    // call
    this.Search.getAds();

    /**
     * 
     */
    scrollToElement('search_result_box', {
      child: true,
      direction: DIRECTION.up
    });    
    
  }  
  
  /**
   * 
   */
  ngOnDestroy(): void {

    /**
     * 
     */
    this.ADS_SERVICE.ngOnDestroy();

    /**
     * 
     */
    this.Search.SearchIoDisconnect();    

  }

}
