import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// custome services
import { GlobleService } from 'src/app/core/services/globle.service';
import { AuthService } from 'src/app/core/services/user/auth.service';
import { AlertService } from 'src/app/core/services/alert/alert.service';
import { MainApiService } from 'src/app/core/services/main/main-api.service';
import { ApiResponseService } from 'src/app/core/services/handel-api-response/api-response.service';

// custome enums
import { User } from 'src/app/core/enums/user/info-user';
import { Countery } from 'src/app/core/enums/global/country';
import { usersType } from 'src/app/core/enums/user/user-type';
import { UrlRoute } from 'src/app/core/enums/cryptos/route-path';
import { accountState } from 'src/app/core/enums/user/account-state';
import { LAYOUTS_TYPE } from 'src/app/core/enums/layouts/layouts-types';
import { NEXT_STEP_AFTER_VERIFY } from 'src/app/core/enums/user/auth-state';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';
import { InputField } from 'src/app/core/interfaces/form/input-field';
import { IhttpError } from 'src/app/core/interfaces/http-response/http-error';

// 
export interface IRouteData {
  label: string
  titleBtn:string,
  endPoint: string
  alertBtn?: string,
  alertMsg?: string,
  type: accountState
  Method: keyof MainApiService
  nextStep: NEXT_STEP_AFTER_VERIFY
  formData: {
    max: number,
    min: number,
    type: string,
    name: string,
    title: string   
    pattern?: string,
    placeholder: string
  }
}

@Component({
  selector: 'app-verify-auth',
  templateUrl: './verify-auth.component.html',
  styleUrls: ['./verify-auth.component.scss']
})
export class VerifyAuthComponent implements OnInit {

  @ViewChild('elCountDown') private _elCountDown: ElementRef<HTMLElement>


  // init props & inputs is coming


  // interfaces
  isSuccess!: IhttpError
  navigation!: Inavigation
  FormVerifyAuth!: UntypedFormGroup
  
  // array of any or with interface
  listInput: InputField[] = []
  
  // enums
  getCode: string = Countery.code
  
  // any
  durationTime: number = null;
  getPhone: string | number = ''
  
  // obj
  userInfo: {[key: string]: any} = {}
  
  // boolean
  isLoading: boolean = false
  alertSuccess: boolean = false
  showCountDown: boolean = false;

  constructor(
    private router: Router,
    private G: GlobleService,
    private Auth: AuthService,
    private Alert: AlertService,
    private API: MainApiService,
    private route: ActivatedRoute,
    private FB: UntypedFormBuilder,
    private API_RESPONSE: ApiResponseService,
  ) {}

  ngOnInit(): void {

    // ES6 Destructuring
    const { min, max, pattern, name, type, title, placeholder } = this.routeData.formData;

    // to remove or append queryParam to handling viewCountDown
    this.handlingViewCountDown()

    //** init value DI class **/
    this.FormVerifyAuth = this.FB.group({
      [this.routeData.formData.name]: [null, [
          Validators.required,
          Validators.minLength(min),
          Validators.maxLength(max),
          Validators.pattern(pattern || '')
        ]
      ],
    })  

    if (this.route.snapshot.queryParams['phone']) {
      // get queryParams
      this.getPhone = this.G.decryptData(UrlRoute.QUERY_PARAM, this.route.snapshot.queryParams['phone'])
    }

    // set value props
    this.navigation = {label: this.routeData.label, path: '/'}

    //
    this.listInput = [
      { type, name, placeholder, title, validators:
        [
          {
            text: 'من فضلك يرجي مل هذا الحقل ', typeValidation: 'required'
          },          
          {
            typeValidation: 'minlength',
            text: `اقل عدد من حرف ${min}`
          },
          {
            typeValidation: 'maxlength',
            text: `حقل يحتوي علي اكثر من ${max} رقم`
          },
          {
            typeValidation: 'pattern',
            text: `اخل ${title} بشكل صحيح بدون حروف`,
          }
        ]
      },
    ]    

  }

  // getters & setters

  /**
   * routeData
   */
  public get routeData(): IRouteData {
    return this.route.snapshot.data as IRouteData
  }

  /**
   * accountState
   */
  public get accountState(): typeof accountState {
    return accountState
  }

  // Methods

  private handlingViewCountDown(): void {

    // ES6 Destructuring
    const { path } = this.route.snapshot.routeConfig;
    const { countdown, ...rest } = this.route.snapshot.queryParams;    

    /**
     * if url has durationTime then show countDown
     * else, hide it
     */
    this.durationTime = countdown ?  120 : null;

    // reset url without durationTime queryParam
    this.router.navigate(path.split(null), { queryParams: rest })    

  }

  /**
   * 
   */
  public resendCode(): void {

    if (this._elCountDown?.nativeElement?.innerHTML?.trim() == '') {

      this.API_RESPONSE.sendCode({ phone: this.getPhone }).subscribe(
        res => {

          // show alert
          this.Alert.show({
            type: LAYOUTS_TYPE.alertSucess,
            msg: 'تم اعادة ارسال الكود بشكل ناجح',
          });

          // reset Time 
          this.durationTime = this.durationTime ? this.durationTime + 1 : 120;

        },
        err => this.isSuccess = err?.error
      )

    }

  }

  // get info country from component drop-dows as outPut
  getCode_hasSelected(obj: {[key: string]: any}) {
    this.getCode = obj.countryCode
  }    

  success() {

    this.alertSuccess = false

    if (this.routeData.nextStep == NEXT_STEP_AFTER_VERIFY.completeProfile) {

      //
      this.userInfo = this.G.getUserData(User.info)

      //
      if (this.userInfo.type == usersType.office && this.userInfo.approved == false) this.G.navigate(['account-state']);
      else {

        // update obvs
        this.Auth.updateToken(this.G.getUserData(User.token))
        this.Auth.updateUserInfo(this.G.getUserData(User.info))
      
        // navigate to home
        this.G.navigate(['/'])

      }
  
      /**
       * after verify don't force user to complete profile,
       * but make it complete profile data any time from profile page
       */
      // if (this.userInfo.type == usersType.normalUser) {
      //   // this.G.navigate(['complete-profile', this.G.encryptData(UrlRoute.PARAM, this.userInfo.id)])

      //   // update obvs
      //   this.Auth.updateToken(this.G.getUserData(User.token))
      //   this.Auth.updateUserInfo(this.G.getUserData(User.info))
      
      //   // navigate to home
      //   this.G.navigate(['/'])
  
      // }
      // else {
      //   if (this.userInfo.approved == false) this.G.navigate(['account-state']);
      //   else {
      //     this.G.navigate(['complete-profile-office', this.G.encryptData(UrlRoute.PARAM, this.userInfo.id)])
      //   }
      // }
    }
    else if (this.routeData.type == accountState.forgot && this.routeData.nextStep == NEXT_STEP_AFTER_VERIFY.new_password) {
      this.G.navigate(['verifyAsForgotPassword'], {phone: this.G.encryptData(UrlRoute.QUERY_PARAM, this.getCode + this.FormVerifyAuth.value.phone), countdown: true })
    }    
    else if (this.routeData.type == accountState.verify && this.routeData.nextStep == NEXT_STEP_AFTER_VERIFY.new_password) {
      this.Auth.setStepsForgotPassword(3)
      this.G.navigate(['new-password'], {phone: this.G.encryptData(UrlRoute.QUERY_PARAM, this.getPhone)})
    }
  
  }  

  verfiyAuth() {

    this.isLoading = true

    let data: any;
    let method: keyof MainApiService = 'mainPost';
    
    if (this.routeData.type == accountState.verify) {
      
      method = 'mainPut'

      // es6 spread operato
      data = {
        ...this.FormVerifyAuth.value, 
        phone: this.getPhone
      }
    } 
    else if (this.routeData.type == accountState.forgot) {
      
      method = 'mainPost'

      // es6 spread operato
      data = {
        ...this.FormVerifyAuth.value,
        phone: this.getCode + this.FormVerifyAuth.value.phone
      }
    }

    this.API[method](this.routeData.endPoint, data).subscribe(
      (res: any) => {

        this.isLoading = false
        
        // es6 destructuring
        const { id } = res.user || {};
        ///
        // console.log(res)
        
        if (this.routeData.type == accountState.verify && this.routeData.nextStep === NEXT_STEP_AFTER_VERIFY.completeProfile) {
          // save data in localStorage
          if (res?.user) this.G.saveUserData(User.info, res?.user)
          if (res?.token) this.G.saveUserData(User.token, res?.token)

        }

        this.alertSuccess = true

      },
      err => {
        this.isLoading = false
        this.isSuccess = err.error
      }      
    )


  }

}
