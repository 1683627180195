import { Pipe, PipeTransform } from '@angular/core';

// enums
import { ADVCLASS_TYPE } from 'src/app/core/enums/ads/advertiser-types';

@Pipe({
  name: 'advertiserTypeTitle'
})
export class AdvertiserTypeTitlePipe implements PipeTransform {

  transform(value: string, ...args: any[]) {

    if (value) {
      switch(value) {
        case ADVCLASS_TYPE.owner:
            return 'مالك';
        case ADVCLASS_TYPE.commissar:
            return 'وسيط';            
        default:
            return 'غير مطابق'
      }
    }
    
    console.warn(`value is not found => ${value}`)

    return ''

  }

}
