import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

// funcations helper
import { Url_404 } from 'src/app/core/funcations-helper/Dom/url-error';

// enums
import { DEFAULT_IMG } from 'src/app/core/enums/default-src/img';

@Directive({
  selector: '[appImgUrlCheck]'
})
export class ImgUrlCheckDirective implements OnInit {

  @Input('ClassList') ClassList?: string
  @Input('defaultImg') defaultImg?: string

  /**
   * 
   */
  @HostListener('load') load () {
    /**
     * 
     */
    this.removePlaceholder();
  }

  /**
   * 
   * @param msg 
   */
  @HostListener('error') urlHas_404 (msg: string) {
    
    if (Url_404({ type: 'img' })) {

      /**
       * replace by default img
       */
      this._img.nativeElement.src = this.defaultImg ? this.defaultImg : DEFAULT_IMG.logo_crop;

    }

  }

  constructor(private _img: ElementRef<HTMLImageElement>) { }

  ngOnInit(): void {

    /**
     * get current Element will show in ui
     */
    this._img.nativeElement.classList.add(this.ClassList)
    
    /**
     *
     */
    this.createPlaceholder();

  }

  /**
   * 
   */
  private removePlaceholder(): void {
    
    this._img.nativeElement.parentElement.classList.remove('placeholder', 'placeholder-wave', 'bg-secondary')

  }

  /**
   * 
   */
  private createPlaceholder(): void {

    // ES6 Destruction
    const { parentElement } = this._img.nativeElement;

    /**
     * add class
     */
    parentElement.classList.add('placeholder', 'placeholder-wave', 'bg-secondary')

  }

}
