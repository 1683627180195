<!-- start app-contact-us -->
<div class="contact_us">
    <!-- import app-second-header -->
    <app-second-header>
        <!-- // start send as content projection -->
        <div id="wrap_navigate" class="wrap_navigate">
            <!-- import app-navigation -->
            <app-navigation [navigate]="navigation" [style]="{top: '69px'}"></app-navigation>
        </div>

        <div class="wrap_content">
            <h1 class="title text-center">تواصل معنا</h1>
        </div>
        <!-- // end send as content projection -->
    </app-second-header>

    <form [formGroup]="FormContactUs" (ngSubmit)="contactUs()">
        <div class="custom_container">
            <ng-container *ngFor="let field of listInput">
                <!-- import app-label -->
                <app-label 
                    [for]="field.name"
                    [title]="field.title" 
                >
                </app-label>
    
                <!-- import app-input-text  -->
                <app-input-text 
                    [type]="field.type" 
                    [name]="field.name" 
                    [FormParent]="FormContactUs"
                    *ngIf="field.type != 'textarea'"
                    [placeholder]="field.placeholder"
                    [validations]="field.validators || []"
                >
                </app-input-text>

                <div class="wrap_v_control" *ngIf="field.type == 'textarea'">
                    <app-box-text-area 
                        matchLettersWithNumbers
                        [formControlName]="field.name"
                        [placeholder]="field.placeholder"
                        [validations]="field.validators || []"
                    >
                    </app-box-text-area>
                </div>
            </ng-container>
            <div class="wrap_recaptcha d-flex justify-content-center align-items-center">
                <ngx-recaptcha2 
                    #captchaElem
                    [hl]="'ar'"
                    [theme]="'light'"
                    [type]="'image'"
                    [size]="'normal'"
                    [siteKey]="siteKey"
                    [useGlobalDomain]="false"
                    formControlName="recaptcha"
                    (success)="recaptchaSuccess($event)"
                >
                </ngx-recaptcha2>
            </div>
    
            <!-- // show if server backend responded by errors -->
            <ng-container *ngIf="isSuccess">
                <div class="wrap_error_server text-center">
                    <div class="wrap d-inline-block">
                        <ng-container *ngFor="let validator of isSuccess?.errors">
                            <!-- import app-validation-input  -->
                            <app-validation-input 
                                [text]="validator.msg"
                                *ngIf="!isSuccess.success"
                                [style]="{'margin-inline-start': '0px', margin: 0}" 
                            >
                            </app-validation-input>
                        </ng-container>
                    </div>
                </div>
            </ng-container>                
    
            <div class="wrap_btn text-center">
                <!-- import app-btn -->
                <app-btn
                    title="ارسال" 
                    [submit]="true"
                    [disabled]="FormContactUs.invalid || isLoading" 
                >
                </app-btn>
            </div>
        </div>

    </form>
</div>
<!-- start app-contact-us -->