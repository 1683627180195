<div 
    [ngClass]="{'position-absolute': sm}"
    [ngStyle]="{'background-color': sm ? 'transparent' : ''}"
    class="loader d-flex justify-content-center align-items-center {{class}}" 
>
    <div class="wrap position-relative text-center">
            <ng-container *ngIf="!sm; else ElseSm">
                <img src="/assets/img/main_logo.svg" />
            </ng-container>
            <ng-template #ElseSm>
                <i class="fas fa-spinner animation_rotate {{size ? size : ''}}" [ngStyle]="{color: color}"></i>
            </ng-template>
            <!-- <div class="loader_dot" *ngFor="let item of [1,2,3]"></div>
            <div class="loade_text"></div> -->
    </div>
</div>