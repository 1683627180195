import { Component, OnInit } from '@angular/core';

// custome interfaces
import { Inavigation } from 'src/app/core/interfaces/inavigation';

// services
import { TopLevelSingleService } from 'src/app/core/services/single-api-top-level-app/singel-top-level.service';

@Component({
  selector: 'app-important-instructions',
  templateUrl: './important-instructions.component.html',
  styleUrls: ['./important-instructions.component.scss']
})
export class ImportantInstructionsComponent implements OnInit {

  //
  public navigation!: Inavigation

  //
  public Terms: {[key: string]: string} = null

  constructor(public TopLevelApi: TopLevelSingleService) {}

  ngOnInit(): void {

    //
    this.navigation = {path: '/setting'}

    //
    this.TopLevelApi.terms$.subscribe(res => this.Terms = res)

  }

}
